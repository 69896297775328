import {
  useInfiniteQuery,
  useQuery,
  useMutation,
  useQueryClient,
} from 'react-query';
import { NewsDataProps, RequestListProps } from 'src/model';
import { useState } from 'react';
import { DataListResponseProps, ResponseProps } from 'src/model/res.model';

import { newsAPi } from '../axios/news';
import { NEWS_KEY } from '../queries/keys';
import { useReduxLoading } from 'src/redux/hooks/useReduxLoading';
import { useTranslation } from 'react-i18next';
import { reduxNotification } from 'src/redux/hooks/ReduxNotification';
import { Howl } from 'howler';
import { SOUND_CONFIG } from 'src/assets/config/soundConfig';

/**
 * @class QueryNews()
 * @method queryList()
 * @method queryDetail()
 * @returns
 */

class QueryNews {
  key: { LIST: string; DETAIL: string; ADVERTISEMENTS: string };

  constructor() {
    this.key = NEWS_KEY;
  }

  queryList(queryData?: RequestListProps) {
    const [query, setQuery] = useState<RequestListProps>(
      queryData || {
        page: 1,
      },
    );
    const useQueryData = useQuery<
      ResponseProps<{ news: DataListResponseProps<NewsDataProps> }>,
      Error
    >([this.key.LIST, query], () => newsAPi.getList(query), {
      // enabled: !!query.type,
      refetchOnMount: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
    });
    return { query, setQuery, useQueryData };
  }

  queryDetail(id: number) {
    return useQuery<
      ResponseProps<{ news: NewsDataProps; related_news: NewsDataProps[] }>,
      Error
    >([this.key.DETAIL, id], () => newsAPi.getDetail(id), {
      // enabled: !!id,
      refetchOnMount: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
    });
  }

  queryAdvertisement(id: number) {
    return useQuery<any, Error>(
      [this.key.ADVERTISEMENTS, id],
      () => newsAPi.getAdvertisement(id),

      {
        enabled: !!id,
        refetchOnMount: false,
        refetchOnReconnect: true,
        refetchOnWindowFocus: false,
      },
    );
  }

  queryScroll() {
    return useInfiniteQuery<
      ResponseProps<{ news: DataListResponseProps<NewsDataProps> }>,
      Error
    >(
      [this.key.LIST],
      async ({ pageParam = 0 }) => {
        const res = await newsAPi.getList({
          page: pageParam + 1,
        });
        return res;
      },
      {
        getPreviousPageParam: (firstPage) =>
          firstPage.data.news.meta.current_page,

        getNextPageParam: (lastPage) =>
          lastPage.data.news.meta.current_page ===
          lastPage.data.news.meta.last_page
            ? undefined
            : lastPage.data.news.meta.current_page,
      },
    );
  }

  queryMarkReadNew() {
    const queryClient = useQueryClient();
    const loading = useReduxLoading();
    const { useAction } = reduxNotification;
    const { openModalError } = useAction();
    const { t } = useTranslation();

    const sound = new Howl({
      src: [SOUND_CONFIG.SOUND_POINT_QUESTION],
      volume: 1,
    });

    return useMutation<ResponseProps<any>, any, any>(
      (id: number) => newsAPi.postMarkReadNew(id),
      {
        onMutate() {
          loading.openLoading();
        },
        onSuccess: async () => {
          setTimeout(() => {
            sound.play();
          },500);
          await queryClient.refetchQueries(this.key.LIST);
          loading.closeLoading();
        },
        onError: (error: any) => {
          openModalError(t('notification.fail'), error.message);
          loading.closeLoading();
        },
      },
    );
  }

  queryMarkReadSection() {
    const queryClient = useQueryClient();
    const loading = useReduxLoading();
    const { useAction } = reduxNotification;
    const { openModalError } = useAction();
    const { t } = useTranslation();

    const sound = new Howl({
      src: [SOUND_CONFIG.SOUND_POINT_QUESTION],
      volume: 1,
    });

    return useMutation<ResponseProps<any>, any, any>(
      (id: number) => newsAPi.postMarkReadSection(id),
      {
        onMutate() {
          loading.openLoading();
        },
        onSuccess: async () => {
          setTimeout(() => {
            sound.play();
          },500);
          await queryClient.refetchQueries(this.key.ADVERTISEMENTS);
          loading.closeLoading();
        },
        onError: (error: any) => {
          openModalError(t('notification.fail'), error.message);
          loading.closeLoading();
        },
      },
    );
  }
}

export const queryNews = new QueryNews();
