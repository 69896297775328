import styled from 'styled-components';

export const WrapperFinger = styled.div`
  transform: scale(1);
  position: absolute;
  left: 78px;
  top: 10px;
  @supports (-webkit-touch-callout: none) {
    left: 50px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) {
    @supports (-webkit-touch-callout: none) {
      left: 50px;
    }
  }
`;

export const WrapperStyleDisplay = styled.div`
  transform: scale(0.4);
  position: absolute;
  right: 0;
  top: -5px;
  @supports (-webkit-touch-callout: none) {
    right: 6px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) {
    @supports (-webkit-touch-callout: none) {
      right: 5px;
    }
  }
`;
