import React, { PropsWithChildren, useImperativeHandle} from 'react';
import styled, { keyframes } from 'styled-components';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';

import { useIsOpen } from 'src/hooks/useIsOpen';
import CustomModal from 'src/components/custom/modal/CustomModal';
import ModalNavbarWidget from 'src/pages/setting/widget/ModalNavbar.Widget';

export interface MainLayoutProps extends PropsWithChildren<StyleProps> {
  screen?: any;
  hiddenSetting?: boolean;
}

const MainLayout = React.forwardRef<any, MainLayoutProps>(
  ({ children, screen, hiddenSetting, minHeight, pt }, ref) => {
    const open = useIsOpen();

    useImperativeHandle(ref, () => {
      return {
        show: open.onOpen,
      };
    });

    return (
      <Style.Wrapper
        imgUrl={screen}
        minHeight={minHeight}
        pt={pt}
        className={'hidden_scroll'}>
        {/* content */}
        {children}

        {/* setting */}
        <Style.ButtonSetting>
          {hiddenSetting ? null : (
            <div>
              <CustomIconButton
                icon={IMG_CONFIG.BUTTON.SETTING}
                onClick={open.onOpen}
              />
            </div>
          )}
        </Style.ButtonSetting>

        <CustomModal
          centered
          closeIcon={<div />}
          footer={null}
          visible={open.isOpen}
          wrapClassName="custom-modal-wrapper"
          maskStyle={{ background: 'rgba(0,0,0,.6)' }}
          onCancel={() => {
            open.onClose();
          }}>
          <ModalNavbarWidget onCancel={open.onClose} />
        </CustomModal>
      </Style.Wrapper>
    );
  },
);

export default MainLayout;

export interface StyleProps {
  imgUrl?: any;
  minHeight?: number;
  pt?: number;
}

const animFadeIn = keyframes`
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
`;

const Style = {
  Wrapper: styled.div`
    position: relative;
    width: 100%;
    min-width: 360px;
    height: fit-content;
    /* height: fit-content; */

    min-height: 100vh;
    background: ${(props: StyleProps) => `url(${props.imgUrl})`};
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;

    padding-top: ${(props: StyleProps) => (props.pt ? `${props.pt}px` : 0)};

    animation: ${animFadeIn} 0.4s;

    @media (min-width: 768px) {
      height: ${(props: StyleProps) =>
        props.minHeight ? `${props.minHeight}px` : 'fit-content'};
    }
  `,
  Children: styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 300px;
  `,

  ButtonSetting: styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 0;
    z-index: 50;
    @media (min-width: 768px) {
      transform: scale(1.5);
      padding: 10px;
    }
  `,
  WrapperGame: styled.div`
    width: 100%;
    min-height: 100vh;
    background-image: url(${IMG_CONFIG.BACKGROUND.START});
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    padding: 20px;
  `,
};
