import { Paths } from '@utils/constants';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import styled, { keyframes } from 'styled-components';

export interface ButtonAdsWidgetProps {}

const ButtonAdsWidget: React.FC<ButtonAdsWidgetProps> = () => {
  const { push } = useHistory();
  return (
    <Style.Wrapper>
      <button onClick={() => push(Paths.Ads)}>
        <img src={IMG_CONFIG.DASHBOARD.ADS} style={{ width: '75px' }} />
      </button>
    </Style.Wrapper>
  );
};

export default ButtonAdsWidget;

const animPaw = keyframes`
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
`;

const Style = {
  Wrapper: styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    animation: ${animPaw} 1.5s ease-in-out infinite both;
  `,
};

/*
In case need to revert animation:
<motion.div
        initial={{ rotate: 10 }}
        animate={{ rotate: 30 }}
        transition={{
          duration: 1,
          times: [0, 0.2, 1],
          yoyo: Infinity,
        }}>
        <button onClick={() => push(Paths.Ads)}>
          <img src={IMG_CONFIG.DASHBOARD.ADS} style={{ width: '75px' }} />
        </button>
      </motion.div>
*/
