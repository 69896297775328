import { setStoredFirebase } from '@utils/helpers/localStorage';
// import { getIdBrowser } from '@utils/uidBrowser';
import { RequestListProps } from 'src/model';
import { request } from './axios';
import { API_URL } from './config';

class NotificationAPi {
  getDetail(token: any) {
    const data = new FormData();

    // const machine_id = getIdBrowser();
    data.append('token', token);
    // data.append('machine_id', machine_id);

    setStoredFirebase(token);

    return request({
      url: '/api/device-token',
      method: 'POST',
      data,
    });
  }

  getList(params: RequestListProps) {
    return request({
      url: API_URL.NOTIFICATION.LIST,
      method: 'GET',
      params,
    });
  }
}

export const notificationAPi = new NotificationAPi();
