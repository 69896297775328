import { Paths } from '@utils/constants';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import {
  DateNotificationEnum,
  NotificationDetailProps,
} from 'src/model/notification.model';
import styled from 'styled-components';

export interface CardListNotificationComProps {
  data: NotificationDetailProps;
}

const CardListNotificationCom: React.FC<CardListNotificationComProps> = ({
  data,
}) => {
  const { push } = useHistory();

  function handlePush() {
    if (data.data.screen === DateNotificationEnum.EVENT) {
      push(Paths.Event + '/detail/' + data.data.event_id);
    }
  }

  return (
    <Style.Wrapper onClick={handlePush}>
      {/* icon */}
      <Style.Icon>
        <div style={{ display: 'flex' }}>
          <img src={IMG_CONFIG.NOTIFICATION.NOTI} />
          <p className="medium_text color_main">Pet Paradise</p>
        </div>

        <p className="small_text color_main">{data.time}</p>
      </Style.Icon>
      {/* title */}
      <Style.Title>
        <h2 className="medium_text">{data.title}</h2>
      </Style.Title>
      {/* mess */}
      <Style.Mess>
        <p className="small_text color_gray">{data.message}</p>
      </Style.Mess>
    </Style.Wrapper>
  );
};

export default CardListNotificationCom;

const Style = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 0x;
    background-color: aliceblue;
    border-radius: 10px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.35);
    /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    cursor: pointer;
    transform:scale(0.97);
  `,

  Icon: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  `,
  Title: styled.div`
    /* h2 {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    } */
  `,

  Mess: styled.div`
    p {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  `,
};
