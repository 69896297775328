import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';

export interface ShowHideIconProps
  extends React.BaseHTMLAttributes<HTMLDivElement> {
  isHide?: boolean;
}

const ShowHideIcon: React.FC<ShowHideIconProps> = ({ isHide, ...props }) => {
  return (
    <Style.Wrapper {...props}>
      {isHide ? (
        <EyeOutlined style={{ color: '#833e29' }} />
      ) : (
        <EyeInvisibleOutlined style={{ color: '#833e29' }} />
      )}
    </Style.Wrapper>
  );
};

export default ShowHideIcon;

const Style = {
  Wrapper: styled.div`
    cursor: pointer;
  `,
};
