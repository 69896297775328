import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams, useHistory } from 'react-router-dom';
import { useSearchParam } from 'react-use';
import { useLogout } from 'src/hooks/useLogout';
import { FormResetPasswordProps } from 'src/model';

import { ResponseProps, RestApi } from 'src/model/res.model';
import { reduxNotification } from 'src/redux/hooks/ReduxNotification';
import { authApi } from '../axios/auth';

export const useQueryAuth = () => {
  const history = useHistory();
  const { useAction } = reduxNotification;

  // loading

  const { openModalError, openModal } = useAction();
  const { t } = useTranslation();
  const queryEmail = () => {
    return useMutation<
      ResponseProps<RestApi>,
      ResponseProps<RestApi>,
      any,
      any
    >((body: string) => authApi.postForgotEmail(body), {
      onError: (error) => {
        openModalError(t('notification.error'), error.message);
      },
    });
  };

  const queryResetPassword = () => {
    const { token } = useParams<any>();
    const email: any = useSearchParam('email');
    return useMutation<
      ResponseProps<RestApi>,
      ResponseProps<RestApi>,
      any,
      any
    >(
      (body: FormResetPasswordProps) =>
        authApi.postResetPassword({ ...body, token, email }),
      {
        onSuccess: (data) => {
          openModal(t('notification.success'), data.data.message);
          history.push('/sign-in');
        },
        onError: (error) => {
          openModalError(t('notification.error'), error.message);
        },
      },
    );
  };

  const queryCheckMail = (email?: string) => {
    const [query, setQuery] = useState<string>(email || '');
    const queryData = useQuery<
      ResponseProps<{ email_available: boolean }>,
      Error
    >(['checkmail'], () => authApi.checkEmail(query), {
      // enabled: !!token,
      refetchOnMount: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
    });

    return { queryData, setQuery };
  };

  const queryLogout = () => {
    const { onLogout } = useLogout();
    return useMutation<any, any, any, any>(() => authApi.logout(), {
      onSuccess: () => {
        // openModal(t('notification.success'), data.data.message);
        onLogout();
        history.push('/sign-in');
      },
      // onError: (error) => {
      //   openModalError(t('notification.error'), error.message);
      // },
    });
  };

  return { queryEmail, queryResetPassword, queryCheckMail, queryLogout };
};
