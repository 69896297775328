import React from 'react';
import { useInView } from 'react-intersection-observer';
import Lottie from 'react-lottie';
import { optionsHeartLoading } from '../views/config';

export interface LoadingMoreInfinityItemProps {
  hasNextPage?: boolean;
  fetchNextPage: any;
}

const LoadingMoreInfinityItem: React.FC<LoadingMoreInfinityItemProps> = ({
  fetchNextPage,
  hasNextPage,
}) => {
  const { ref, inView } = useInView();
  // const { data, isLoading } = useQueryData;
  React.useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);
  return (
    <div ref={ref} style={{ display: 'flex', justifyContent: 'center' }}>
      {hasNextPage ? (
        <Lottie
          options={optionsHeartLoading}
          height={30}
          width={100}
          isClickToPauseDisabled
        />
      ) : (
        // <LoadingOutlined style={{ color: 'red', fontSize: 25 }} />
        <div style={{ height: '15px' }} />
      )}
    </div>
  );
};

export default LoadingMoreInfinityItem;
