import CustomInput from 'src/components/custom/input/CustomInput';
import React from 'react';
import { useHistory } from 'react-router-dom';
import ViewBoardAuth from 'src/components/core/View/ViewBoardAuth';
import ViewResetPassCom from '../components/ViewResetPass.Com';
import ButtonFooterCom from '../components/ButtonFooter.Com';
import styled from 'styled-components';
import { useFormNewPassword } from 'src/hooks/formik/useFormNewPassword';
import { useTranslation } from 'react-i18next';
import { supportErrorFormik } from '@utils/support/supportErrorFormik';

export interface CreateNewPassWidgetProps {}

const CreateNewPassWidget: React.FC<CreateNewPassWidgetProps> = () => {
  const history = useHistory();

  const { formik, query } = useFormNewPassword();

  const { isLoading, isSuccess } = query;

  const ButtonItem = (
    <ButtonFooterCom onBack={history.goBack} onSubmit={formik.handleSubmit} />
  );
  const { t } = useTranslation();

  function renderTextDes(): any {
    if (isLoading) {
      return t('content.auth.sentNewPass');
    }
    if (isSuccess) {
      return t('content.auth.passHasChange');
    }
    return t('content.auth.typePass');
  }
  return (
    <ViewBoardAuth title={t('content.auth.newPass')}>
      <ViewResetPassCom button={ButtonItem} des={renderTextDes()}>
        <Style.Wrapper onSubmit={formik.handleSubmit}>
          <CustomInput
            type={'password'}
            name="password"
            placeholder={t('auth.password')}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={supportErrorFormik(formik, 'password')}
            isPassword
          />
          <CustomInput
            type={'password'}
            isPassword
            name="password_confirmation"
            placeholder={t('auth.confirmPassword')}
            value={formik.values.password_confirmation}
            onChange={formik.handleChange}
            error={supportErrorFormik(formik, 'password_confirmation')}
          />
        </Style.Wrapper>
      </ViewResetPassCom>
    </ViewBoardAuth>
  );
};

export default CreateNewPassWidget;

const Style = {
  Wrapper: styled.form`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    width: 100%;
    flex-direction: column;
    gap: 15px;
  `,
};
