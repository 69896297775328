import { AnswersDataReqProps } from 'src/model/stageGame.model';

export function checkIsSelect(
  id: any,
  question_id: any,
  question: AnswersDataReqProps,
) {
  const itemIndex = question.answers.findIndex(
    (p: any) => p.question_id === question_id,
  );
  if (itemIndex > -1) {
    const answerIndex = question.answers[itemIndex].answer.findIndex(
      (p: any) => p.answer_id === id,
    );
    if (answerIndex > -1) {
      return true;
    }
  } else {
    return false;
  }
}
