import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { queryProfile } from 'src/api/hooks/queryProfile';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import MainLayout from 'src/layouts/MainLayout';
import { useGame } from '../hook/useGame';
import MainMapLayout from '../layout/MainMap.Layout';
import ButtonAdsWidget from '../widget/ButtonAds.Widget';
import PetMoveWidget from '../widget/PetMove.Widget';
import CameraButton from '../widget/CameraButton.Widget';
import { useCheckCameraPoint } from '../hook/useCheckCameraPoint';
import { Paths } from '@utils/constants';

export interface StageGameScreenProps {}

const StageGameScreen: React.FC<StageGameScreenProps> = () => {
  const { useQueryData: getProfile } = queryProfile.queryProfile();
  const { data: profile } = getProfile;
  const history = useHistory();
  const { pos, onAnswer, question } = useGame();
  // const spotId = question.data?.data?.current_spot?.spot_id ?? 0;

  const ref = useRef<any>(null);

  const { cameraPoints, cameraPosition } = useCheckCameraPoint();

  const handleChangeNextPage = (id: number) => {
    history.push(Paths.Stage_Paradise, id);
  };

  return (
    <MainLayout
      minHeight={995}
      ref={ref}
      screen={IMG_CONFIG.BACKGROUND.START}
      hiddenSetting
      pt={20}>
      <MainMapLayout
        onOpen={() => ref.current?.show()}
        star={question?.data?.data.user_star}
        idMap={question.data?.data.current_spot.map_type}
        // idMap={index.index + 1}
        rewards={question?.data?.data.rewards}
        spots={question?.data?.data.spots}
        images = {question.data?.data.images}
        coin={profile?.data.profile.coins}
        stageImg={question?.data?.data.current_stage.media}>
        {/* ads */}
        <ButtonAdsWidget />
        {/* game */}
        {!question.isLoading ? (
          <PetMoveWidget x={pos.x} y={pos.y} onClick={onAnswer} />
        ) : null}

        <Style.CameraBtnWrapper>
          {cameraPoints?.map((item, index) => {
            return (
              <CameraButton
                key={item.id}
                x={item.position.x}
                y={item.position.y}
                invisible={
                  cameraPosition && pos.position === cameraPosition[index].order
                }
                onclick={() => handleChangeNextPage(item.id)}
                className={index === 1 ? 'position-camera' : ''}
              />
            );
          })}
        </Style.CameraBtnWrapper>
      </MainMapLayout>
    </MainLayout>
  );
};

export default StageGameScreen;

const Style = {
  CameraBtnWrapper: styled.div`
    .position-camera {
      bottom: 120px;
    }
  `,
};
