import { useState } from 'react';

export const useIndex = (max: number) => {
  const [index, setIndex] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);
  function onNext() {
    if (index < max) {
      setIndex(index + 1);
      setError(false);
      return;
    }
    setError(true);
  }
  function onPre() {
    if (index > 0) {
      setIndex(index - 1);
      setError(false);
      return;
    }
    setError(true);
  }
  function onIndex(n: number) {
    if (-1 < n && n <= max) {
      setIndex(n);
      setError(false);
      return;
    }
    setError(true);
  }

  return { index, onNext, onPre, onIndex, error };
};
