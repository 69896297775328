import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const MainStyle = {
  Link: styled(Link)`
    color: var(--color-button);
    font-family: DFMRG9 !important;
    font-size: 16px;

    :hover {
      color: var(--color-button);
     
    }
  `,
};
