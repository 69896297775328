import { motion } from 'framer-motion';
import React from 'react';
import { queryProfile } from 'src/api/hooks/queryProfile';
import Frame from 'src/components/custom/item/Frame';
import styled, { keyframes } from 'styled-components';

export interface PetMoveWidgetProps {
  x?: any;
  y?: any;
  onClick?: () => void;
}

const PetMoveWidget: React.FC<PetMoveWidgetProps> = ({ x, y, onClick }) => {
  const { useQueryData } = queryProfile.queryProfile();
  const { data } = useQueryData;

  return (
    <motion.div
      style={{ x, y, width: 'fit-content' }}
      transition={{ ease: [0.17, 0.67, 0.83, 0.67] }}
      onClick={onClick}>
      <Style.Wrapper>
        <Style.Icon>
          {/* <PetAvatarCom gender={gender} /> */}
          <Frame
            linkPet={data?.data.profile.avatar}
            frame={data?.data.profile.current_frame}
          />
        </Style.Icon>
      </Style.Wrapper>
    </motion.div>
  );
};

export default PetMoveWidget;

const animWobble = keyframes`
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-1px, 1px);
            transform: translate(-1px, 1px);
  }
  40% {
    -webkit-transform: translate(-1px, -1px);
            transform: translate(-1px, -1px);
  }
  60% {
    -webkit-transform: translate(1px, 1px);
            transform: translate(1px, 1px);
  }
  80% {
    -webkit-transform: translate(1px, -1px);
            transform: translate(1px, -1px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
`;

const Style = {
  Wrapper: styled.div`
    cursor: pointer;
    animation: ${animWobble} 1s linear infinite both;
  `,
  Icon: styled.div`
    transform: scale(0.8);
  `,
};
