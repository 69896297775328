import { request } from './axios';
import { API_URL } from './config';

class MiniGameAPi {
  url: { LIST: string, TEXT_POPUP : string};

  constructor() {
    this.url = API_URL.MINI_GAME;
  }
  getItems() {
    return request({
      url: this.url.LIST,
      method: 'GET',
    });
  }
  getTextPopup() {
     return request({
       url : this.url.TEXT_POPUP, 
       method : 'GET',
     });
  }
  postSpin(id: number) {
    return request({
      url: this.url.LIST + '/' + id + '/spin',
      method: 'POST',
    });
  }
}

export const miniGameAPi = new MiniGameAPi();
