import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';

import { DataListResponseProps, ResponseProps } from 'src/model/res.model';

import { QUESTION_KEY } from '../queries/keys';

import { questionAPi } from '../axios/question';
import {
  CurrentSpotProps,
  CurrentStageProps,
  QueryQuestionProps,
  QuestionDataProps,
  ResultQuestionProps,
  RewardProps,
  SpotPet,
  ImageStageProps,
  SpotProps,
} from 'src/model/stageGame.model';
import { useState } from 'react';
import { reduxAnswer } from 'src/redux/hooks/ReduxAnswer';
import { PositionGameProps } from 'src/hooks/usePositionGame';
import { useReduxLoading } from 'src/redux/hooks/useReduxLoading';
import { reduxNotification } from 'src/redux/hooks/ReduxNotification';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Paths } from '@utils/constants';
import { DataDimensionProps } from 'src/hooks/useInputUpload';
import { downloadImage } from '@utils/support/downloadImage';

/**
 * @class QueryNews()
 * @method queryList()
 * @method queryDetail()
 * @returns
 */

class QueryQuestion {
  key: {
    CURRENT: string;
    QUESTION: string;
    MAP: string;
    LIST_SPOT_PET: string;
  };

  constructor() {
    this.key = QUESTION_KEY;
  }

  querySpotSentImagePet(id: number , file: any) {
    const queryClient = useQueryClient();
    const { useAction } = reduxNotification;
    const { t } = useTranslation();
    const { openModalError } = useAction();
    const history = useHistory();
    // loading
    const loading = useReduxLoading();
    return useMutation<ResponseProps<{ message: string }>>(
      (body) => questionAPi.postSpotImage(id, body),
      {
        onMutate() {
          loading.openLoading();
        },
        onSuccess:  async () => {
          await queryClient.refetchQueries(QUESTION_KEY.CURRENT);
          loading.closeLoading();
          history.push(Paths.Stage);
          downloadImage(file, 'image');
        },
        onError: (error: any) => {
          openModalError(t('notification.fail'), error.message);
          loading.closeLoading();
        },
      },
    );
  }

  queryRemoveBackgroundPet(dimensions ?: DataDimensionProps, dataImage ?: any) {
    const { state } = useLocation();
    const history = useHistory();
    const { useAction } = reduxNotification;
    const { t } = useTranslation();
    const { openModalError } = useAction();
    // loading
    const loading = useReduxLoading();
    return useMutation<ResponseProps<{ image: string }>>(
      (body) => questionAPi.removeBackgroundImage(body),
      {
        onMutate() {
          loading.openLoading();
        },
        onSuccess: (data: ResponseProps<{ image: string }>) => {
          loading.closeLoading();
          history.replace(Paths.Stage_Paradise_Collage, {
            state,
            image: data.data.image,
            dimensions,
            dataImage
          });
        },
        onError: (error: any) => {
          openModalError(t('notification.fail'), error.message);
          loading.closeLoading();
        },
      },
    );
  }

  querySpotPet(id: number) {
    const queryInfinity = useInfiniteQuery<
      ResponseProps<{
        images: DataListResponseProps<SpotPet>;
      }>,
      any
    >(
      [this.key.LIST_SPOT_PET, id],
      async ({ pageParam = 0 }) => {
        const res = await questionAPi.getSpotPet(id, {
          page: pageParam + 1,
          per_page: 10,
        });
        return res;
      },
      {
        getPreviousPageParam: (firstPage) =>
          firstPage.data.images.meta.current_page,

        getNextPageParam: (lastPage) =>
          lastPage.data.images.meta.current_page ===
          lastPage.data.images.meta.last_page
            ? undefined
            : lastPage.data.images.meta.current_page,
      },
    );
    return queryInfinity;
  }

  queryListPoint() {
    const useQueryData = useQuery<
      ResponseProps<{
        current_spot: CurrentSpotProps;
        current_stage: CurrentStageProps;
        spots: SpotProps[];
        rewards: RewardProps[];
        images : ImageStageProps,
        number_star: number;
        user_star: number;
      }>,
      Error
    >([this.key.CURRENT, 'current'], () => questionAPi.getCurrent(), {
      // enabled: !!query.type,
      refetchOnMount: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
    });
    return { useQueryData };
  }

  queryQuestion(spot_id: number) {
    const [query, setQuery] = useState<QueryQuestionProps>(
      { spot_id } || {
        spot_id: 1,
      },
    );
    const useQueryData = useQuery<
      ResponseProps<{
        questions: QuestionDataProps[];
        survey_id: number;
      }>,
      Error
    >([this.key.QUESTION, query], () => questionAPi.getQuestion(query), {
      enabled: !!spot_id,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    });
    return { useQueryData, query, setQuery };
  }

  queryBackgroundSpot(spot_id: number) {
    const [query, setQuery] = useState<number>(spot_id);
    const useQueryData = useQuery<
      ResponseProps<any>,
      Error
    >([this.key.QUESTION, query], () => questionAPi.getBackgroundSpot(query), {
      enabled: !!spot_id,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    });
    return { useQueryData, query, setQuery };
  }

  queryAnswer() {
    const { useData } = reduxAnswer;
    const queryClient = useQueryClient();
    const { data } = useData();
    const { useAction } = reduxNotification;
    const { t } = useTranslation();
    const { openModalError } = useAction();
    // loading
    const loading = useReduxLoading();
    return useMutation<ResponseProps<{ result: ResultQuestionProps }>>(
      () => questionAPi.postAnswer(data),
      {
        onMutate() {
          loading.openLoading();
        },
        onSuccess: () => {
          queryClient.refetchQueries(QUESTION_KEY.CURRENT);
          loading.closeLoading();
        },
        onError: (error: any) => {
          openModalError(t('notification.fail'), error.message);
          loading.closeLoading();
        },
      },
    );
  }

  queryMap() {
    const queryClient = useQueryClient();
    const initialData: PositionGameProps = {
      x: 0,
      y: 0,
    };

    const { data } = useQuery<PositionGameProps, Error>(
      this.key.MAP,
      () => initialData,
      {
        // enabled: !!query.type,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        initialData,
      },
    );

    const setData = (newState: PositionGameProps) => {
      queryClient.setQueryData(this.key.MAP, newState);
    };
    return { data, setData };
  }
}

export const queryQuestion = new QueryQuestion();
