import React from 'react';
import styled from 'styled-components';

export interface ProcessQuestionBarComProps extends StyleProps {
  currentQ?: number;
  totalQ?: number;
}

const ProcessQuestionBarCom: React.FC<ProcessQuestionBarComProps> = ({
  processPercent,
  currentQ,
  totalQ,
}) => {
  return (
    <Style.Wrapper>
      <p className="color_white " style={{ fontSize: '14px' }}>
        {currentQ || 0}/{totalQ || 0}
      </p>
      <Style.Process processPercent={processPercent}>
        <div className="process_inner" />
      </Style.Process>
    </Style.Wrapper>
  );
};

export default ProcessQuestionBarCom;

interface StyleProps {
  processPercent?: string;
}

const Style = {
  Wrapper: styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
  `,

  Process: styled.div`
    width: 100%;
    height: 20px;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;

    .process_inner {
      height: 100%;
      border-radius: 10px;
      background-color: #d6b19c;
      width: ${(p: StyleProps) => p.processPercent || '0'};
    }
  `,
};
