import React from 'react';
import styled from 'styled-components';
import { useWidget } from '../hook/useWidget';
import ConfirmSoundModal from 'src/components/custom/modal/ConfirmSoundModal';
import { useIsOpen } from 'src/hooks/useIsOpen';
import { queryProfile } from 'src/api/hooks/queryProfile';

export interface ItemMenuComProps {
  icon: string;
  name: string;
  link?: string;
}

const ItemMenuCom: React.FC<ItemMenuComProps> = ({ icon, name, link }) => {
  const { push } = useWidget();
  const open = useIsOpen();
  const query = queryProfile.queryUserSound();

  const handlePushLink = () => {
    if (link) {
      push(link, true);
    } else {
      if (!open.isOpen) {
        open.onOpen();
      }
    }
  };

  const onConfirm = () => {
    query.mutate({is_bgm : 1});
    open.onClose();
  };

  const onCancel = () => {
    query.mutate({is_bgm : 0});
    open.onClose();
  };
  return (
    <Style.Wrapper onClick={handlePushLink}>
      <img src={icon} width="50px" height="auto" />
      <p className="color_white big_text" style={{ marginLeft: '10px' }}>
        {name}
      </p>
      <ConfirmSoundModal
        visible={open.isOpen}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    </Style.Wrapper>
  );
};

export default ItemMenuCom;

const Style = {
  Wrapper: styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    /* gap: 10px; */
    cursor: pointer;
    :hover {
    }
  `,
};
