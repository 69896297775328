import { Paths } from '@utils/constants';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import ViewsViewNumber from 'src/components/custom/views/ViewsViewNumber';
import { EventDataProps } from 'src/model';
import styled from 'styled-components';

export interface DetailEventWidgetProps {
  data?: EventDataProps;
}

function isValidHttpUrl(string: string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

const DetailEventWidget: React.FC<DetailEventWidgetProps> = ({ data }) => {
  const { push } = useHistory();

  const isCheckImage = isValidHttpUrl(data?.media || '');

  return (
    <ViewsViewNumber view={data?.view} className={'hidden_scroll'}>
      <Style.Wrapper>
        <Style.TitleWrapper>
          <div className="inner_title">
            <Style.Title>
              <h5 className="medium_text color_main">{data?.title}</h5>
            </Style.Title>
          </div>
          {isCheckImage && <img src={data?.media} />}
        </Style.TitleWrapper>
        <Style.Content>
          <div dangerouslySetInnerHTML={{ __html: data?.content ?? '' }} />
        </Style.Content>
        <div className="center_wrapper ">
          <CustomIconButton
            icon={IMG_CONFIG.EVENT.CAFE_JOIN}
            onClick={() => push(Paths.Event + '/register/' + data?.id)}
          />
        </div>
      </Style.Wrapper>
    </ViewsViewNumber>
  );
};

export default DetailEventWidget;

const Style = {
  Wrapper: styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: flex-start;
    width: 100%;
    grid-gap: 5px;
    height: fit-content;
    margin-left:5px;
    /* overflow-y: auto; */
    background: #ffffff;
    .center_wrapper {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }
  `,
  TitleWrapper: styled.div`
    position: relative;
    width: 100%;

    img {
      width: 100%;
    }
    h5 {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-align: center;
    }

    .inner_title {
      position: absolute;
      top: 5px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `,

  Title: styled.div`
    width: 70%;
    background: #ffffff;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 6px;
  `,
  Content: styled.div`
    width: 100%;
    padding: 10px;
  `,
};
