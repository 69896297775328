import React, { PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';
import { NewsDataProps, TypeNewsEnum } from 'src/model';
import styled from 'styled-components';
import CardNewsInVideoCom from '../components/CardNewsInVideo.Com';
import { Paths } from '@utils/constants';

export interface NewsDetailLayoutProps extends PropsWithChildren<StyleProps> {
  title?: string;
  des?: string;
  related_news?: NewsDataProps[];
  typeView?: number;
}

const NewsDetailLayout: React.FC<NewsDetailLayoutProps> = ({
  children,
  title,
  des,
  bg_color,
  related_news,
  typeView,
}) => {
  const history = useHistory();

  return (
    <Style.Wrapper bg_color={bg_color}>
      {typeView === TypeNewsEnum.EDITOR ? (
        <div>
          <p
            className="color_main"
            style={{ fontSize: '16px', lineHeight: '18px' }}>
            {title}
          </p>
          <p
            className="color_des"
            style={{ marginTop: '8px', fontSize: '16px', lineHeight: '18px' }}>
            {des}
          </p>
          <div style={{ marginTop: '18px' }}>{children}</div>
          <div style={{ height: '10px' }} />
        </div>
      ) : (
        <div>
          <p className="color_main small_text">{title}</p>
          <p className="color_des small_text" style={{ marginTop: '5px' }}>
            {des}
          </p>
          <div style={{ marginTop: '5px' }}>{children}</div>
          <div style={{ marginTop: '20px', fontSize: '18px' }}>
            <p>最新ニュース:</p>
          </div>
          <Style.Content>
            {related_news?.map((item, i) => {
              return (
                <CardNewsInVideoCom
                  data={item}
                  key={i}
                  onClick={() => history.push(Paths.News + '/' + item.id)}
                />
              );
            })}
          </Style.Content>
          <div style={{ height: '10px' }} />
        </div>
      )}
    </Style.Wrapper>
  );
};

export default NewsDetailLayout;

interface StyleProps {
  bg_color?: string;
}

const Style = {
  Wrapper: styled.section`
    background: ${(props: StyleProps) =>
      props.bg_color ? props.bg_color : null};
    padding: 5px;
    border-radius: 5px;
    min-height: 100%;
  `,

  Content: styled.div`
    margin-top: 20px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 5px;
    /* flex-direction: column; */
    /* gap: 10px; */
    margin-top: 10px;
  `,
};
