import { Paths } from '@utils/constants';
import { formatGender } from '@utils/helpers/formatProfile';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { queryProfile } from 'src/api/hooks/queryProfile';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import ViewBoardAuth from 'src/components/core/View/ViewBoardAuth';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import { useFormatTypePet } from 'src/hooks/feature/useFormatTypePet';

import styled from 'styled-components';

export interface PetSecondDetailWidgetProps {
  id: number;
  setOpen ?: any;
}

const PetSecondDetailWidget: React.FC<PetSecondDetailWidgetProps> = ({
  id,setOpen
}) => {
  const { push } = useHistory();
  const { useQueryData, setQuery } = queryProfile.queryPet(id);
  const { data } = useQueryData;
  const type = useFormatTypePet(data?.data.pet.type ?? 0);
  useEffect(() => setQuery(id), [id]);
  const { t } = useTranslation();
  
  const query = queryProfile.queryChangeParticipant();
  const handleChangePetParticipant = (id : number) => {
       query.mutate(id);
       setOpen.onClose();
  };
  return (
    <ViewBoardAuth title={t('content.profile.pet')}>
      <Style.Wrapper className="hidden_scroll">
        <Style.Img>
          <img src={data?.data.pet.avatar} />
        </Style.Img>
        <Style.TextWrapper>
          <p className="color_white big_text">{data?.data.pet.name}</p>
        </Style.TextWrapper>
        <Style.TextWrapper>
          <p className="color_white big_text">
            {data?.data.pet.dob.toString()}
          </p>
        </Style.TextWrapper>
        <Style.TextWrapper>
          <p className="color_white big_text">{type.value}</p>
        </Style.TextWrapper>
        <Style.TextWrapper>
          <p className="color_white big_text">
            {formatGender(data?.data.pet.gender)}
          </p>
        </Style.TextWrapper>
      </Style.Wrapper>

      <Style.ButtonWrapper>
        <CustomIconButton
            icon={IMG_CONFIG.BUTTON.SWITCH_AVATAR}
            onClick={() => handleChangePetParticipant(id)}
            imgHeight="38px"
        />
        <CustomIconButton
            onClick={() => push(Paths.Profile_Update + '/' + data?.data.pet.id)}
            imgHeight="38px"
        />        
      </Style.ButtonWrapper>
    </ViewBoardAuth>
  );
};

export default PetSecondDetailWidget;

const Style = {
  Wrapper: styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    justify-items: center;
    grid-gap: 10px;
    width: 100%;
    height: 100%;
    margin-top: 10px;
    overflow-y: scroll;
  `,
  Img: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      width: 100px;
      height: 100px;
      border-radius: 10px;
      border: 2px solid white;
      box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
    }
  `,
  TextWrapper: styled.div`
    border-bottom: 1px solid white;
    width: 100%;
    display: flex;
    justify-content: center;
  `,

  ButtonWrapper: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  `,
};
