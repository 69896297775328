import { useEffect, useState } from 'react';

import { MapIdEnum } from 'src/model/stageGame.model';
import { mapPosition } from '../config/position';
import { useGame } from '../hook/useGame';

interface useCheckCameraPointParams {
  id: number;
  position: {
    x: number;
    y: number;
  };
}

interface CameraPositionType {
  id: number;
  order: number;
}

const getMapPoint = (mapId: MapIdEnum) => {
  switch (mapId) {
    case 1:
      return mapPosition.map_1;
    case 2:
      return mapPosition.map_2;
    case 3:
      return mapPosition.map_3;
    case 4:
      return mapPosition.map_4;
    case 5:
      return mapPosition.map_5;
    case 6:
      return mapPosition.map_6;
    case 7:
      return mapPosition.map_7;
    case 8:
      return mapPosition.map_8;
    case 9:
      return mapPosition.map_9;
    case 10:
      return mapPosition.map_10;
    case 11:
      return mapPosition.map_11;
    case 12:
      return mapPosition.map_12;

    default:
      mapPosition.map_1;
  }
};

export const useCheckCameraPoint = () => {
  const { question, loading } = useGame();
  const [cameraPoints, setCameraPoints] =
    useState<useCheckCameraPointParams[]>();
  const [cameraPosition, setCameraPosition] = useState<CameraPositionType[]>();
  const [cameraData, setCameraData] = useState<any>();

  const mapId: MapIdEnum =
    question.data?.data.current_spot.map_type || MapIdEnum.MAP_1;

  const mapValues = getMapPoint(mapId);
  const handleGetCameraPoint = () => {
    const cameraPointList = question?.data?.data.spots.filter(
      (item) => item.is_camera,
    );

    const orders = cameraPointList?.map((order) => {
      return {
        id: order.id,
        order: order.order,
      };
    });

    const points: any = orders?.map((order) => {
      return {
        id: order.id,
        position: mapValues?.[order.order],
      };
    });
    setCameraPosition(orders);
    setCameraPoints(points);
    setCameraData(cameraPointList);
  };

  useEffect(() => {
    if (loading === 'success') {
      handleGetCameraPoint();
    }
  }, [mapId, loading]);

  return { cameraPoints, cameraPosition, cameraData };
};
