import React from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import styled from 'styled-components';

export interface StageNameComProps
  extends React.BaseHTMLAttributes<HTMLDivElement> {
  stageImg: string;
}

const StageNameCom: React.FC<StageNameComProps> = ({ stageImg, ...props }) => {
  return (
    <Style.Wrapper {...props}>
      <img src={IMG_CONFIG.MAP_GAME.STAGE_NAME} />
      <div className="inner_wrapper">
        {stageImg ? <img src={stageImg} /> : null}
      </div>
    </Style.Wrapper>
  );
};

export default StageNameCom;

const Style = {
  Wrapper: styled.div`
    position: relative;
    cursor: pointer;

    .inner_wrapper {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 100%;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      transform: translateY(7px);
    }
  `,
};
