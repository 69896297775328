import { Paths } from '@utils/constants';
import { useHistory, useLocation } from 'react-router-dom';

export const useGoBackSetting = (callback: any) => {
  const { push } = useHistory();
  const { state } = useLocation<boolean | undefined>();

  const onGoBack = () => {
    if (!state) {
      push(Paths.Dashboard);
    } else {
      callback();
    }
  };
  return onGoBack;
};
