import React from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import styled from 'styled-components';

export interface LifeStarComProps {
  star?: number;
}

const LifeStarCom: React.FC<LifeStarComProps> = (star) => {
  const count = star?.star || 0;

  const stars = (count: number) => {
    return Array(count)
      .fill('')
      .map((_, index) => {
        return <img key={index} src={IMG_CONFIG.MAP_GAME.STAR_LIFE} />;
      });
  };

  return (
    <Style.Wrapper>
      <img src={IMG_CONFIG.MAP_GAME.TEXT_LIFE} />
      <Style.StarWrapper>{stars(count)}</Style.StarWrapper>
    </Style.Wrapper>
  );
};

export default LifeStarCom;

const Style = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: scale(1);
  `,

  StarWrapper: styled.div`
    width: 142px;
    display: flex;
    justify-content: center;
  `,
};
