import React from 'react';
import { useHistory } from 'react-router-dom';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import LayoutDashboard from 'src/layouts/newDashboard';
import { Image } from 'antd';
import ListFoodWidget from '../widget/ListFood.Widget';
import { queryFood } from 'src/api/hooks/queryFood';
import { useTranslation } from 'react-i18next';
import ViewCustomScroll from 'src/components/custom/views/ViewCustomScroll';
import { ViewContent } from 'src/components/custom/views/ViewsContent';
import LoadingMoreInfinityItem from 'src/components/custom/item/loading-more-infinity.item';

export interface ListFoodScreenProps {}

const ListFoodScreen: React.FC<ListFoodScreenProps> = () => {
  const { t } = useTranslation();

  const { data: page, fetchNextPage, hasNextPage } = queryFood.queryScroll();
  const history = useHistory();

  const handleChangeView = () => {
    history.goBack();
  };
  return (
    <LayoutDashboard
      isButton
      title={t('content.food.name')}
      footerContent={t('content.food.footer')}
      onGoBackView={handleChangeView}
      // button={
      //   <CustomIconButton icon={IMG_CONFIG.BUTTON.OK} onClick={onGoBack} />
      // }
      leftIcon={<Image src={IMG_CONFIG.DASHBOARD.FOOD_ICON_MINI} preview={false} />}>
      {/* <ListFoodWidget data={data?.data.foods.data} /> */}

      <ViewCustomScroll paddingTop={10} paddingBottom={10}>
        <ViewContent.ListFlex className={'custom_scroll'}>
          {page?.pages.map((data, i) => {
            return <ListFoodWidget data={data?.data.foods.data} key={i} />;
          })}

          {/* loading */}
          <LoadingMoreInfinityItem
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
          />
        </ViewContent.ListFlex>
      </ViewCustomScroll>
    </LayoutDashboard>
  );
};

export default ListFoodScreen;
