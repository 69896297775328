import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  // register as _register,
  // login as _login,
  logout as _logout,
  getProfile as _getProfile,
} from 'src/api';

import type { loginResponseData } from 'src/utils/types';

import cookies from 'js-cookie';
import { TOKEN_AUTHENTICATION } from 'src/utils/constants';

export const loginFake = createAction<string>('loginFake');

export const actionLogin = createAction<loginResponseData>('actionLogin');
export const clearRegisterForm = createAction<void>('clearRegisterForm');

export const logout = createAsyncThunk(
  'logout',
  async (__: void, { rejectWithValue }) => {
    try {
      const token = cookies.get(TOKEN_AUTHENTICATION);
      cookies.remove(TOKEN_AUTHENTICATION, { path: '/' });
      const response = await _logout(token);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getProfile = createAsyncThunk(
  'getProfile',
  async (__: void, { rejectWithValue }) => {
    try {
      const response = await _getProfile();
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);
