export enum Paths {
  Admin = '/admin',
  Intro = '/intro',

  SignIn = '/sign-in',
  Register = '/register',
  RegisterConfirm = '/register/confirm',
  Example = '/example',
  Dashboard = '/dashboard',
  News = '/dashboard/news',
  NewsDetail = '/dashboard/news/:id',
  Ranking = '/dashboard/ranking',
  Game = '/dashboard/game',
  Food = '/dashboard/food',
  Camera = '/dashboard/camera',
  Cafe = '/dashboard/cafe',
  CafeRankingEvent = '/dashboard/cafe/ranking/:id',
  CafeFrame = '/dashboard/cafe/frame',
  CafeTravel = '/dashboard/cafe/travel',
  CafeTravelDetail = '/dashboard/cafe/travel/:id',
  PoExchange = '/dashboard/po-exchange',
  ExchangeHistory = '/dashboard/po-exchange/history',
  Ads = '/ads',
  Question = '/question',
  Auth = '/auth',
  AuthEmail = '/forgot',
  AuthPassword = '/password/reset/:token',

  // event
  Event = '/dashboard/events',
  EventVote = '/dashboard/events/vote/:id',
  EventDetail = '/dashboard/events/detail/:id',
  EventRegister = '/dashboard/events/register/:id',
  // profile

  Profile = '/profile',
  Profile_ListPet = '/profile/list_pet',
  Profile_DetailPet = '/profile/list_pet/:id',
  Profile_AddPet = '/profile/add_pet',
  Profile_Update = '/profile/update',

  // game
  Stage = '/stage',
  Reward = '/reward',
  Stage_Question = '/stage/question',
  Stage_Paradise = '/stage/paradise',
  Stage_Paradise_Upload = '/stage/paradise/upload',
  Stage_Paradise_Collage = '/stage/paradise/collage',
  Stage_Paradise_list = '/stage/paradise/list',

  // comcom
  Terms = '/terms',
  Faqs = '/faqs',
  ListNotification = '/notification',
}
