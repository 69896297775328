import { Paths } from '@utils/constants';
import { clearStoredAuth } from '@utils/helpers/localStorage';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

export const useLogout = () => {
  const query = useQueryClient();
  const { push } = useHistory();
  function onLogout() {
    query.clear();
    clearStoredAuth();
    push(Paths.Intro);
  }

  return { onLogout };
};
