import React from 'react';

import { AdvertisementsDataProps } from 'src/model/dashboard.model';
import styled from 'styled-components';

export interface CardAdsComProps {
  isLager?: boolean;
  data: AdvertisementsDataProps;
}

const CardAdsCom: React.FC<CardAdsComProps> = ({ data, isLager }) => {
  return (
    <Style.Wrapper
      isLager={isLager}
      isNull={data.big_media || data.media ? true : false}
      target="_blank"
      href={data.link || ''}>
      <img
        className="img_no"
        src={isLager ? data.big_media : data.media}
        alt="media"
      />
    </Style.Wrapper>
  );
};

export default CardAdsCom;

interface StyleProps {
  isLager?: boolean;
  isNull?: boolean;
}

const Style = {
  Wrapper: styled.a`
    padding: 10px;
    width: 100%;
    filter: drop-shadow(4px 4px 3px rgba(0, 0, 0, 0.2));
    border-radius: 12px;
    /* height: ${(p: StyleProps) => (p.isLager ? '300px' : '150px')}; */
    height: fit-content;
    display: ${(p: StyleProps) => (p.isNull ? 'block' : 'none')};

    .img_no {
      width: 100%;
      /* height: ${(p: StyleProps) => (p.isLager ? '300px' : '150px')}; */
      border-radius: 12px;
    }
  `,
};
