export interface TypesPet {
  dogs: TypesDataPet[];
  cats: TypesDataPet[];
  others?: TypesDataPet[];
}

export interface TypesDataPet {
  id: number;
  name: string;
  group?: TypePetGroupEnum;
}

export enum TypePetGroupEnum {
  A = 1,
  KA = 2,
  SA = 3,
  TA = 4,
  NA = 5,
  HA = 6,
  MA = 7,
  YA = 8,
  RA = 9,
  WA = 10,
}

export interface AdvertisementsDataProps {
  id: number;
  media: string;
  big_media: string;
  link: string;
  description: string;
  type_banner: number;
  type: TypeAdvertiseEnum;
  number: number;
  status: number;
}

export enum TypeAdvertiseEnum {
  INTERNAL = 1,
  EXTERNAL = 2,
}

export enum TypeBANNERAdvertiseEnum {
  SMALL = 1,
  BIG = 2,
}

export interface FaqDataProps {
  id: number;
  question: string;
  answer: string;
}

export interface FaqsDataProps {
  faqs: FaqDataProps[];
}

export interface TermsDataProps {
  content: string;
}

export interface StagesDataProps {
  id: number;
  name: string;
  media: string;
  order: number;
}

export interface StagesListDataProps {
  stages: StagesDataProps[];
}
