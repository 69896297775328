export enum MapIdEnum {
  MAP_1 = 1,
  MAP_2 = 2,
  MAP_3 = 3,
  MAP_4 = 4,
  MAP_5 = 5,
  MAP_6 = 6,
  MAP_7 = 7,
  MAP_8 = 8,
  MAP_9 = 9,
  MAP_10 = 10,
  MAP_11 = 11,
  MAP_12 = 12,
}

export enum PointRankEnum {
  ZEZO = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
}

export enum RewardTypeEnum {
  COIN = 1,
  POINT = 2,
  PRODUCT = 3,
  FRAME = 4,
}
export enum RewardCameraEnum {
  CAMERA = 1,
  NO_CAMERA = 0,
}

export enum AnswerTypeEnum {
  ONLY_TEXT = 1,
  ONLY_IMAGE = 2,
  ONLY_BIG_IMAGE = 3,
  TEXT_IMAGE = 4,
}

export enum AnswerTypePetEnum {
  ALL = 1,
  DOG = 2,
  CAT = 3,
  OTHERS = 4,
  DEFAULT = 0
}

export enum AnswerIsTextEnum {
  TEXT = 1,
  NO_TEXT = 0,
}

export enum RewardStatusEnum {
  RECEIVED = 1,
  NOT_RECEIVED = 2,
}

export interface CurrentSpotProps {
  spot_id: number;
  road_id: number;
  map_type: MapIdEnum;
  spot_type: number;
  is_camera: boolean;
  order: number;
  is_pass: boolean;
}

export interface CurrentStageProps {
  id: number;
  media: string;
  order: number;
}

export interface SpotProps {
  id: number;
  title: string;
  game_road_id: number;
  number: number;
  isReward?: boolean;
  is_camera: boolean;
  is_pass: boolean;
  order: number;
}

export interface RewardItemProps {
  id: number;
  name: string;
  price: number;
  number: number;
  media: string;
}

export interface RewardProps {
  id: number;
  road_id: number;
  test_id: number;
  number: number;
  spot_id : number;
  is_camera : RewardCameraEnum;
  type: RewardTypeEnum;
  status: RewardStatusEnum;
  item?: RewardItemProps;
}

export interface ImageStageProps {
  back_ground_1 : string;
  back_ground_2 : string;
  foreground_image_1: string;
  foreground_image__2 : string;
}

export interface AnswerDataProps {
  id: number;
  title: string;
  media: string;
  is_text: AnswerIsTextEnum;
}

export interface QuestionDataProps {
  id: number;
  test_id: number;
  title: string;
  video: string;
  image: string;
  pet_type : number;
  type_answer: AnswerTypeEnum;
  answers: AnswerDataProps[];
  max_number_of_answers: number;
}

export interface QueryQuestionProps {
  spot_id: number;
}

export interface AnswerReqProps {
  answer_id: number;
  answer_text?: string;
}

export interface AnswerDataReqProps {
  question_id: number;
  answer: AnswerReqProps[];
  max_number_of_answers: number;
  skip : boolean;
}

export interface SkipReqProp {
   question_id :number;
   skip : boolean;
}

export interface AnswerMultiReqProps {
  question_id: number;
  answer_id: number;
}

export interface AnswersDataReqProps {
  survey_id: number;
  answers: AnswerDataReqProps[];
}

export interface AnswerTextReqProps extends AnswerReqProps {
  question_id: number;
}

export interface ResultQuestionProps {
  last_question: boolean;
  point: number;
  reward: RewardProps;
}

export interface SpotPet {
  id: number;
  spot_id: number;
  user_id: number;
  image: string;
  is_win: number;
  name: string;
}
