import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { authApi } from '../axios/auth';
import { AUTH_KEY } from '../queries/keys';

export const useStartApp = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.prefetchQuery(
      [AUTH_KEY.PROFILE, 'profile'],
      authApi.getProfile,
    );
  }, []);
};
