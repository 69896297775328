import { Paths } from '@utils/constants';
import { formatGender } from '@utils/helpers/formatProfile';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { queryProfile } from 'src/api/hooks/queryProfile';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import ViewCustomScroll from 'src/components/custom/views/ViewCustomScroll';
import { useFormatTypePet } from 'src/hooks/feature/useFormatTypePet';
import { useGetListStages } from 'src/hooks/feature/useGetListStages';
import { ProfileDataProps } from 'src/model';
import styled from 'styled-components';
import ItemFrameAvatarCom from '../components/ItemFrameAvatar.Com';
import ItemInforPetCom from '../components/ItemInforPet.Com';
import ViewsPetProfileCom from '../components/ViewsPetProfile.Com';

export interface PetDetailWidgetProps {
  data?: ProfileDataProps;
  onEdit?: any;
}

const size = '40px';

const PetDetailWidget: React.FC<PetDetailWidgetProps> = ({ data }) => {
  const history = useHistory();

  const type = useFormatTypePet(data?.type_id ?? 0);

  const { t } = useTranslation();

  const { findStage } = useGetListStages();

  const { useQueryData } = queryProfile.queryProfile();
  const { data: user } = useQueryData;
  
  return (
    <ViewsPetProfileCom
      title="アカウント名"
      name={data?.name}
      button={
        <Style.ButtonWrapper>
          <CustomIconButton
            icon={IMG_CONFIG.BUTTON.BACK}
            onClick={() => history.goBack()}
          />
          <CustomIconButton
            icon={IMG_CONFIG.BUTTON.UPDATE_PET}
            onClick={() =>
              history.push(Paths.Profile_Update + '/' + user?.data.profile.pet_id)
            }
          />
        </Style.ButtonWrapper>
      }>
      <Style.Wrapper className="custom_scroll">
        {/* form */}
        <Style.AvatarWrapper>
          <ItemFrameAvatarCom
            linkPet={data?.avatar}
            frame={data?.current_frame}
          />
        </Style.AvatarWrapper>

        {/* form */}

        <ViewCustomScroll style={{ transform: 'translateY(25px)' }}>
          <Style.FormWrapper>
            <ItemInforPetCom
              widthTitle={size}
              title={t('content.profile.name')}
              value={data?.name}
            />
            <ItemInforPetCom
              widthTitle={size}
              title={t('content.profile.gender')}
              value={formatGender(data?.gender)}
            />
            <ItemInforPetCom
              widthTitle={size}
              title={t('content.profile.birthday')}
              value={data?.dob.toString()}
            />
            <ItemInforPetCom
              widthTitle={size}
              title={t('content.profile.type')}
              value={type.value ?? ''}
            />
            <ItemInforPetCom
              widthTitle={size}
              title={t('content.profile.pointOfDeparture')}
              value={findStage(data?.stage_id) || ''}
            />
          </Style.FormWrapper>

          {/* button */}
          <Style.Button>
            {/* <CustomIconButton
            icon={IMG_CONFIG.REGISTER.BUTTON_VIEW_PET}
            text={t('content.profile.edit')}
            onClick={onEdit}
          /> */}
            <CustomIconButton
              icon={IMG_CONFIG.REGISTER.BUTTON_VIEW_PET}
              text={t('content.profile.makeNewPet')}
              onClick={() => history.push(Paths.Profile_ListPet)}
            />
          </Style.Button>
        </ViewCustomScroll>
      </Style.Wrapper>
    </ViewsPetProfileCom>
  );
};

export default PetDetailWidget;

const Style = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    /* overflow-y: hidden; */
    height: 100%;
    width: 90%;
    padding-bottom: 50px;
    padding-top: 20px;
    margin-left: 10px;
  `,
  ButtonWrapper: styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 100%;
    padding: 0 20px;
  `,
  FormWrapper: styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 10px;
    /* margin-bottom: 10px; */
    margin-top: 30px;
    height: 100%;

    /* overflow-y: scroll; */
  `,
  Button: styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 0 20px;
    padding-bottom: 30px;
    margin-top: 25px;
    padding-right: 40px;
    transform: scale(1.2);
  `,
  AvatarWrapper: styled.div`
    transform: scale(1.5);
  `,
};
