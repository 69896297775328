import { Image } from 'antd';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { queryQuestion } from 'src/api/hooks/queryQuestion';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import ViewBoardAuth from 'src/components/core/View/ViewBoardAuth';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';

import styled from 'styled-components';

export interface PreviewCollageImageProps {
  imagePreview?: string;
  setOpen?: any;
  state: number;
}
const PreviewCollageImage: React.FC<PreviewCollageImageProps> = ({
  imagePreview,
  setOpen,
  state,
}) => {
  const [file, setFile] = useState<any>({ image: '' });
  const { t } = useTranslation();
  const query = queryQuestion.querySpotSentImagePet(state , file.image);

  useEffect(() => {
    const getFileName = (dataUrl: string) => {
      const arr = dataUrl.split(',');
      const mimeMatch = arr[0].match(/:(.*?);/);
      const mime = mimeMatch ? mimeMatch[1] : '';

      return Math.round(+new Date() / 1000) + '.' + mime.split('/').pop();
    };

    fetch(imagePreview || '')
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], getFileName(imagePreview || ''), {
          type: 'image/png',
        });
        setFile({ image: file });
      });
  }, [imagePreview]);

  const handleUploadImage = () => {
    query.mutate(file);
    setOpen.onClose();
  };

  const handleCancelPreview = () => {
    setOpen.onClose();
  };

  return (
    <ViewBoardAuth title={t('あなたのイメージ')}>
      <Style.Wrapper>
        <Style.ImageWrapper>
          {imagePreview && (
            <Image
              src={imagePreview}
              alt=""
              preview={false}
              style={{ width: '100%' }}
            />
          )}
        </Style.ImageWrapper>
        <Style.ButtonWrapper>
          <CustomIconButton
            icon={IMG_CONFIG.BUTTON.CANCEL_BUTTON}
            imgHeight="38px"
            className='cancel-btn'
            onClick={handleCancelPreview}
          />
          <CustomIconButton
            imgHeight="42px"
            icon={IMG_CONFIG.BUTTON.OK}
            className='ok-btn'
            onClick={handleUploadImage}
          />
        </Style.ButtonWrapper>
      </Style.Wrapper>
    </ViewBoardAuth>
  );
};

export default PreviewCollageImage;

const Style = {
  Wrapper: styled.div`
    width: 100%;
    height: 86%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
  `,
  ButtonWrapper: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    @media only screen and (max-device-width: 1024px) {
      @supports (-webkit-touch-callout: none) {
        display: flex;
        width: 100%;
        .cancel-btn {
          margin-right: -28px;
        }
        /* .ok-btn {
           margin-right: 20px;
        } */
      }
    }
  `,

  ImageWrapper: styled.div`
    .ant-image {
      width: 100%;
    }
    background: url(${IMG_CONFIG.MAP_GAME.REWARD_BG});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 18px;
  `,
};
