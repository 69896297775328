import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Heart } from '../../../assets/icons/common/heart.svg';
import { Space } from 'antd';
import { renderCheckBoolean } from '@utils/support/renderCheckBoolean';
import ViewCustomScroll from './ViewCustomScroll';
import ViewNoData from './ViewNoData';
import ViewLoading from './ViewLoading';

export interface ViewsViewNumberProps extends React.BaseHTMLAttributes<any> {
  view?: number;
  loading?: boolean;
  noData?: boolean;
  isNotView?: boolean;
  // children?: React.ReactNode;
}

const ViewsViewNumber: React.FC<ViewsViewNumberProps> = ({
  view,
  children,
  loading,
  noData,
  isNotView,
  // ...props
}) => {
  return (
    <Style.Wrapper>
      {/* views */}

      {renderCheckBoolean(
        isNotView,
        null,
        <div>
          <Space>
            <div style={{ marginLeft: '8px'}}>
              <Heart />
            </div>
            <p className="small_text color_white">{view}</p>
          </Space>
        </div>,
      )}

      <ViewCustomScroll>
        {loading ? <ViewLoading /> : !noData && children}
        {noData ? <ViewNoData /> : loading && children}
      </ViewCustomScroll>
    </Style.Wrapper>
  );
};

export default ViewsViewNumber;

const Style = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 0px;
    overflow-x: hidden;
    overflow-y: hidden;
  `,
  ContentWrapper: styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  `,
};
