import React from 'react';
import { useParams } from 'react-router-dom';
import { queryEvent } from 'src/api/hooks/queryEvent';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import LoadingMoreInfinityItem from 'src/components/custom/item/loading-more-infinity.item';
import ViewCustomScroll from 'src/components/custom/views/ViewCustomScroll';
import ViewsViewNumber from 'src/components/custom/views/ViewsViewNumber';
import { EventDataProps } from 'src/model';
import styled from 'styled-components';
import ItemVoteCom from '../components/ItemVote.Com';

export interface VoteEventWidgetProps {
  data?: EventDataProps;
}

const VoteEventWidget: React.FC<VoteEventWidgetProps> = ({ data }) => {
  const { id } = useParams<any>();

  const {
    data: page,
    fetchNextPage,
    hasNextPage,
  } = queryEvent.queryParticipantsScroll(id);

  return (
    <ViewsViewNumber
      view={200}
      isNotView
      // className="hidden_scroll"
      noData={page?.pages.length === 0}>
      <Style.Wrapper>
        <div className="title_wrapper" style={{ minWidth: '50%' }}>
          <h5
            className=" color_main"
            style={{ fontSize: '16px', textAlign: 'center' }}>
            {data?.title}
          </h5>
        </div>
        {/* list */}

        <ViewCustomScroll>
          <Style.ListWrapper className="custom_scroll">
            {page?.pages.map((item) => {
              return item.data.participants.data.map((part, i) => {
                return (
                  <ItemVoteCom data={part} key={i} index={i + 1} idEvent={id} />
                );
              });
            })}
          </Style.ListWrapper>
          <LoadingMoreInfinityItem
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
          />
        </ViewCustomScroll>
      </Style.Wrapper>
    </ViewsViewNumber>
  );
};

export default VoteEventWidget;

const Style = {
  Wrapper: styled.div`
    background-image: url(${IMG_CONFIG.EVENT.BACKGROUND});
    background-position: 0;
    background-repeat: repeat;
    width: 100%;
    height: 100%;
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 10px;
    align-self: center;
    margin-top:10px;
    margin-left:5px;

    .title_wrapper {
      display: block;
      background: #ffffff;
      box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      padding: 6px 12px;
      max-width: 70%;
      align-self: center;
      margin: auto;

      h5 {
        text-overflow: clip;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  `,
  ListWrapper: styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 5px;
    grid-column-gap: 10px;
    grid-row-gap: 5px;
    overflow-y: auto;
  `,
};
