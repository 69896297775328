import React from 'react';
import Frame from 'src/components/custom/item/Frame';
import { RankPointDataProps } from 'src/model/ranking.model';

import styled from 'styled-components';

export interface CardPointRankingComProps {
  data: RankPointDataProps;
}

const CardPointRankingCom: React.FC<CardPointRankingComProps> = ({ data }) => {
  return (
    <Style.Wrapper isTop={data.top === 1}>
      {/* name */}
      <Style.NameWrapper>
        <h4
          className={`big_title ranking_text_dropshadow ${
            data.top === 1 ? 'color_pink' : 'color_main'
          }`}
          style={{ fontSize: '30px'}}>
          {data.top}.
        </h4>
        <Style.NameText
          className={`medium_text ranking_text_dropshadow ${
            data.top === 1 ? 'color_white' : 'color_main'
          }`}
          style={{ fontSize: '18px', marginLeft: '-8px', height:'30px', width:'100px', paddingTop:'8px'}}>
          {data.name}
        </Style.NameText>
      </Style.NameWrapper>

      {/* image */}
      <Style.AvatarWrapper>
        <p
          className={`big_text ranking_text_dropshadow ${
            data.top === 1 ? 'color_white' : 'color_main'
          }`}
          style={{ width: '57px', textAlign: 'right', fontSize: '18px' }}>
          {parseInt(data.points ?? '').toLocaleString('en-GB')}
        </p>

        <Style.Frame>
          <Frame linkPet={data.current_avatar} frame={data.current_frame} />
        </Style.Frame>
      </Style.AvatarWrapper>
    </Style.Wrapper>
  );
};

export default CardPointRankingCom;

interface StyleProps {
  isTop?: boolean;
}

const Style = {
  Wrapper: styled.div`
    background: ${(p: StyleProps) =>
      p.isTop
        ? 'linear-gradient(277.17deg, #FFBD0F 52.52%, #FFE16D 115.22%)'
        : '#ffe5c9'};
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 0px 4px 0px 8px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* gap: 30px; */
    transform: ${(p: StyleProps) => (p.isTop ? 'scale(1.1)' : 'scale(1.05)')};
  `,

  NameWrapper: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    justify-items: flex-start;
    /* gap: 5px; */

    h4 {
      width: 50px;
    }
  `,

  NameText: styled.p`
    margin-top: 7px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 80px;
  `,
  Frame: styled.div`
    transform: scale(0.5) translateY(10px);
    width: 40px;
    height: 40px;
    transform-origin: top;
    position: relative;
  `,

  AvatarWrapper: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* gap: 5px; */

    p {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .avatar_wrapper {
      position: relative;
    }

    .avatar_inner {
      position: absolute;
      bottom: 3px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
    }
  `,
};
