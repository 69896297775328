// import { useState } from 'react';
import { useInfiniteQuery } from 'react-query';
// import { RequestListProps } from 'src/model';
import { NotificationDetailProps } from 'src/model/notification.model';
import { DataListResponseProps, ResponseProps } from 'src/model/res.model';
import { notificationAPi } from '../axios/notification';
import { NOTIFICATION_KEY } from '../queries/keys';

export const useQueryNotification = () => {
  // const [query, setQuery] = useState<RequestListProps>(
  //   queryData || {
  //     page: 1,
  //     per_page: 15,
  //   },
  // );

  // const queryList = useQuery<
  //   ResponseProps<{ notices: DataListResponseProps<NotificationDetailProps> }>,
  //   Error
  // >([NOTIFICATION_KEY.LIST, query], () => notificationAPi.getList(query), {
  //   // enabled: !!query.type,
  //   refetchOnMount: false,
  //   refetchOnReconnect: true,
  //   refetchOnWindowFocus: false,
  // });

  const queryInfinity = useInfiniteQuery<
    ResponseProps<{ notices: DataListResponseProps<NotificationDetailProps> }>,
    any
  >(
    [NOTIFICATION_KEY.LIST_INFI],
    async ({ pageParam = 0 }) => {
      const res = await notificationAPi.getList({
        page: pageParam + 1,
        per_page: 15,
      });
      return res;
    },
    {
      getPreviousPageParam: (firstPage) =>
        firstPage.data.notices.meta.current_page,

      getNextPageParam: (lastPage) =>
        lastPage.data.notices.meta.current_page ===
        lastPage.data.notices.meta.last_page
          ? undefined
          : lastPage.data.notices.meta.current_page,
    },
  );

  return {  queryInfinity };
};
