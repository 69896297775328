import { Paths } from '@utils/constants';
import { supportErrorFormik } from '@utils/support/supportErrorFormik';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import { uploadImgConfig } from 'src/assets/config/imgUploadConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import CustomDate from 'src/components/custom/input/CustomDate';
import CustomInput from 'src/components/custom/input/CustomInput';
import ViewCustomScroll from 'src/components/custom/views/ViewCustomScroll';
import UploadAvatar from 'src/components/feature/UploadAvatar';
import { useUploadAvatar } from 'src/components/feature/UploadAvatar/hook/useUploadAvatar';
import { useFormUpdatePet } from 'src/hooks/formik/useFormUpdatePet';
import { GenderEnum } from 'src/model';
import ImagePetUploadCom from 'src/pages/event/components/ImagePetUpload.Com';
import styled from 'styled-components';

export interface UpdatePetWidgetProps {}

const UpdatePetWidget: React.FC<UpdatePetWidgetProps> = () => {
  const { push } = useHistory();
  const { formik, upload, query, formikChangeImage, image } =
    useFormUpdatePet();
  const { t } = useTranslation();
  const { open, handleOpen } = useUploadAvatar(upload.imgUrl);
  return (
    <>
      <UploadAvatar
        visible={open.isOpen}
        image={upload.imgUrl}
        onCancel={open.onClose}
        handleChange={formikChangeImage}
      />
      <Style.Wrapper>
        <ViewCustomScroll>
          <Style.UploadWrapper>
            <ImagePetUploadCom
              onClick={upload.imgUrl ? handleOpen : upload.handleClick}
              imgSrc={image}
              error={supportErrorFormik(formik, 'avatar')}
            />
            <p
              className="small_text color_white"
              style={{
                padding: '0 10px',
                marginTop: '10px',
                paddingBottom: '10px',
              }}>
              {t('content.register.uploadChange')}
            </p>
            <input
              accept={uploadImgConfig}
              name="avatar"
              type={'file'}
              style={{ display: 'none' }}
              ref={upload.hiddenFileInput}
              onChange={upload.handleChange}
            />
            <CustomIconButton
              icon={IMG_CONFIG.BUTTON.BUTTON_UPLOAD}
              onClick={upload.handleClick}
            />
          </Style.UploadWrapper>

          {/* form */}
          <Style.FormWrapper className="hidden_scroll">
            <Style.List>
              <CustomInput
                name={'name'}
                placeholder={t('content.register.name')}
                value={formik.values.name}
                onChange={formik.handleChange}
                error={supportErrorFormik(formik, 'name')}
              />
              <CustomInput.Dropdown
                name={'gender'}
                placeholder={t('content.register.gender')}
                defaultValue={formik.values.gender || GenderEnum.MALE}
                options={[
                  {
                    label: 'オス',
                    value: GenderEnum.MALE,
                  },
                  {
                    label: 'メス',
                    value: GenderEnum.FEMALE,
                  },
                ]}
                onChange={(name, value) => {
                  formik.setFieldValue(name, value);
                }}
              />
              <CustomDate
                name="dob"
                value={formik.values.dob}
                error={supportErrorFormik(formik, 'dob')}
                onChangeDate={formik.setFieldValue}
                placeholder={t('content.register.birth')}
              />
            </Style.List>
            <div style={{ paddingBottom: '30px' }}></div>
          </Style.FormWrapper>
        </ViewCustomScroll>
        <Style.ButtonWrapper>
          <CustomIconButton
            icon={IMG_CONFIG.BUTTON.BACK}
            onClick={() => push(Paths.Profile_ListPet)}
          />
          <CustomIconButton
            loading={query.isLoading}
            icon={IMG_CONFIG.BUTTON.CONFIRM}
            onClick={() => formik.handleSubmit()}
          />
        </Style.ButtonWrapper>
      </Style.Wrapper>
    </>
  );
};

export default UpdatePetWidget;

const Style = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding-top: 10px;
    padding-left: 25px;
    padding-right: 25px;
    height: 100%;
    margin: auto;
  `,

  UploadWrapper: styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    align-items: center;
    grid-gap: 5px;
    justify-items: center;
  `,
  FormWrapper: styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    border-radius: 10px;
    width: 100%;
    padding: 0 10px;
  `,

  List: styled.div`
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  `,
  ButtonWrapper: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 3px 4px 0 8px;
  `,
};
