import { RequestListProps } from 'src/model';
import { request } from './axios';
import { API_URL } from './config';

class ProductApi {
  url: { LIST: string };

  constructor() {
    this.url = API_URL.PRODUCT;
  }
  getList(params: RequestListProps) {
    return request({
      url: this.url.LIST,
      method: 'GET',
      params,
    });
  }

  postExchange(id: number) {
    return request({
      url: this.url.LIST + '/' + id + '/exchange',
      method: 'POST',
    });
  }

  getListExchangeHistory(params: RequestListProps) {
    return request({
      url: this.url.LIST + '/exchange-history',
      method: 'GET',
      params,
    });
  }
}

export const productApi = new ProductApi();
