import { Paths } from '@utils/constants';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useIndex } from 'src/hooks/useIndex';
import { QuestionDataProps } from 'src/model/stageGame.model';
import QuestionWidget from './Question.Widget';

export interface ListQuestionWidgetProps {
  data?: QuestionDataProps[];
  onEnd?: any;
}

const ListQuestionWidget: React.FC<ListQuestionWidgetProps> = ({
  data,
  onEnd,
}) => {
  const nav = useIndex(data?.length || 1);
  const { push } = useHistory();

  useEffect(() => {
    if (nav.index === (data?.length || 10)) {
      nav.onPre();
      if (onEnd) {
        onEnd();
      }
    }
  }, [nav.onNext]);

  function handlePre() {
    if (nav.index === 0) {
      push(Paths.Stage);
    } else {
      nav.onPre();
    }
  }

  return (
    <>
      {data?.map((item, i) => {
        return (
          <QuestionWidget
            data={item}
            key={i}
            index={i + 1}
            isChoice={nav.index === i}
            onNext={nav.onNext}
            onPre={handlePre}
            totalQ={data.length}
          />
        );
      })}
    </>
  );
};

export default ListQuestionWidget;
