import { useFormik } from 'formik';
import * as Yup from 'yup';
import { queryEvent } from 'src/api/hooks/queryEvent';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useInputUpload } from '../useInputUpload';
import { convertBase64ToBlob } from '@utils/support/covert-base64-to-blob';

import { useState } from 'react';
import { queryProfile } from 'src/api/hooks/queryProfile';

export const useFormRegisterEvent = () => {
  const { id } = useParams<any>();
  const query = queryEvent.queryRegister(id);
  const { useQueryData } = queryProfile.queryProfile();
  const { data } = useQueryData;
  const { t } = useTranslation();
  const upload = useInputUpload();

  const validationSchema = Yup.object().shape({
    image: Yup.mixed().required(t('error.image.required')),
  });

  const formik = useFormik({
    initialValues: {
      image: undefined,
      pet_id: undefined,
      name: undefined,
    },
    validationSchema,
    onSubmit: (values: any) => {
      if (values.name) {
        query.mutate({
          image: values.image,
          pet_id: values.pet_id ?? data?.data.profile.id,
          name : values.name
        });
      } else {
        query.mutate({
          image: values.image,
          pet_id: values.pet_id ?? data?.data.profile.id,
        });
      }
    },
  });

  const [image, setImage] = useState<any>(null);

  const formikChangeImage = (imageData: any) => {
    convertBase64ToBlob(imageData, formik.setFieldValue, 'image', setImage);
  };

  return { formik, query, formikChangeImage, upload, image, id };
};
