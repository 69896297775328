import { Paths } from '@utils/constants';
import { Image } from 'antd';

import React from 'react';
import { useHistory } from 'react-router-dom';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import { EventDataProps } from 'src/model';
import styled from 'styled-components';

export interface CardEventComProps extends StyleProps {
  data?: EventDataProps;
}

// card event

const CardEventCom: React.FC<CardEventComProps> = ({ data, isOld }) => {
  const history = useHistory();

  function handleEvent() {
    history.push('/dashboard/cafe/ranking/' + data?.id);
  }
  return (
    <Style.Wrapper onClick={handleEvent} isOld={isOld}>
      <div>
        <Image src={data?.media} width={'48px'} preview={false} />
      </div>
      <Style.ContentWrapper>
        <h5 className="cap_text color_main">{data?.title}</h5>
        <p className="small_text color_gray ">{data?.description}</p>
      </Style.ContentWrapper>
    </Style.Wrapper>
  );
};

// card frame

const CardFrameMarketCom: React.FC<CardEventComProps> = ({ isOld }) => {
  const history = useHistory();

  function handleEvent() {
    history.push(Paths.CafeFrame);
  }
  return (
    <Style.Wrapper isOld={isOld}>
      <div>
        <Image src={IMG_CONFIG.CAFE.FRAME} width={'48px'} preview={false} />
      </div>
      <Style.ContentWrapper>
        <h5 className="cap_text color_main">フレームコレクション</h5>
        <div className="button_wrapper_card">
          <CustomIconButton
            icon={IMG_CONFIG.CAFE.FRAME_BUTTON_CAFE}
            onClick={handleEvent}
          />
        </div>
        {/* <p className="small_text color_gray ">{data.description}</p> */}
      </Style.ContentWrapper>
    </Style.Wrapper>
  );
};

const CardArticleCom: React.FC<CardEventComProps> = ({ isOld }) => {
  const history = useHistory();

  function handleEvent() {
    history.push(Paths.CafeTravel);
  }
  return (
    <Style.Wrapper onClick={handleEvent} isOld={isOld}>
      <div>
        <Image src={IMG_CONFIG.CAFE.ARTICLE} width={'48px'} preview={false} />
      </div>
      <Style.ContentWrapper>
        <h5 className="cap_text color_main">ペット同伴旅行特集！</h5>

        <p className="small_text color_gray ">
          エリア毎にペットと一緒に楽しめる場所を紹介します。特典なんかもあるよ！
        </p>
      </Style.ContentWrapper>
    </Style.Wrapper>
  );
};

export { CardEventCom, CardFrameMarketCom, CardArticleCom };

interface StyleProps {
  isOld?: boolean;
}

const Style = {
  Wrapper: styled.div`
    width: 305px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    /* gap: 16px; */
    background: ${(p: StyleProps) => (p.isOld ? '#F7F7F7' : '#F9E8C2')};
    /* background: #f9e8c2; */
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 3px 3px 4px 0px rgba(0,0,0,0.25);
    -webkit-box-shadow: 3px 3px 4px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 3px 3px 0px 4px rgba(0,0,0,0.25);
  `,
  ContentWrapper: styled.div`
    padding-left: 10px;
    padding-right: 5px;

    h5 {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size:18px !important;
      line-height:20px !important;
    }

    p {
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-top:5px !important;
      line-height:16px !important;
    }

    .button_wrapper_card {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      margin-top:10px !important;
    }
  `,
};
