import SOUND_BUTTON_1 from '../sound/button1.wav';
import SOUND_BUTTON_2 from '../sound/button2.wav';
import SOUND_GO_BUTTON from '../sound/GO button.wav';
import SOUND_ACQUISITION from '../sound/acquisition.wav';
import SOUND_MISS_GAME_WHEEL from '../sound/miss.wav';
import SOUND_MISS_NO_QUESTION from '../sound/miss2.wav';
import SOUND_POINT_QUESTION from '../sound/point1.wav';
import SOUND_POINT_GAME_WHEEL from '../sound/point2.wav';
import SOUND_POINT_REDEMPTION from '../sound/point3.wav';

export const SOUND_CONFIG = {
    SOUND_BUTTON_1,
    SOUND_BUTTON_2 ,
    SOUND_GO_BUTTON,
    SOUND_ACQUISITION ,
    SOUND_MISS_GAME_WHEEL ,
    SOUND_MISS_NO_QUESTION ,
    SOUND_POINT_QUESTION ,
    SOUND_POINT_GAME_WHEEL ,
    SOUND_POINT_REDEMPTION ,
    SOUND_MAIN : 'https://pet-paradise-jp.s3.ap-northeast-3.amazonaws.com/sounds/main.wav',
};