import React from 'react';

import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import { reduxNotification } from 'src/redux/hooks/ReduxNotification';
import styled from 'styled-components';
import CustomModal from './CustomModal';

export interface ModalConfirmQWidgetProps {
  title?: string;
  des?: string;
  isError?: boolean;
}

const ConfirmModal: React.FC<ModalConfirmQWidgetProps> = () => {
  const { useData, useAction } = reduxNotification;

  const { data } = useData();
  const { closeModal } = useAction();
  return (
    <CustomModal
      visible={data.visible}
      style={{ top: '100px' }}
      onCancel={closeModal}
      footer={null}
      maskStyle={{ background: 'rgba(0,0,0,.3)' }}
      // closeIcon={<CustomIconButton icon={IMG_CONFIG.DASHBOARD.CLOSE} />}
      closeIcon={<div />}>
      <Style.Wrapper>
        <Style.Close>
          <CustomIconButton
            icon={IMG_CONFIG.DASHBOARD.CLOSE}
            onClick={closeModal}
          />
        </Style.Close>
        {data.body ? (
          <img src={IMG_CONFIG.MAP_GAME.GAME_CONFIRM} className="wood-board" />
        ) : (
          <img src={IMG_CONFIG.MAP_GAME.GAME_CONFIRM} />
        )}
        <Style.Title notificationBody={data.body}>
          <p
            className="color_white"
            style={data.title === 'コンテスト登録について' ? { fontSize: '13px' } : {}}>
            {data.title}
          </p>
        </Style.Title>
        <Style.Content notificationBody={data.body}>
          <p className={data.isError ? 'color_error' : 'color_main'}>
            {data.des}
          </p>
          {data.body && <p className="color_main">{data.body}</p>}
        </Style.Content>
      </Style.Wrapper>
    </CustomModal>
  );
};

export default ConfirmModal;

const Style = {
  Wrapper: styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    .wood-board {
      height: 236px;
    }
    @media ${(props) => props.theme.breakpoint.sm} {
      .wood-board {
        height: 190px;
      }
    }
  `,

  Title: styled.div<{ notificationBody?: string }>`
    position: absolute;
    width: fit-content;
    top: ${({ notificationBody }) => (notificationBody ? '32px' : '20px')};
    @media ${(props) => props.theme.breakpoint.sm} {
      top: 20px;
    }
  `,
  Content: styled.div<{ notificationBody?: string }>`
    position: absolute;
    width: 80%;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    padding: ${({ notificationBody }) =>
      notificationBody ? '0 30px' : '0 20px'};
    margin-top: ${({ notificationBody }) =>
      notificationBody ? '80px' : '62px'};
    height: ${({ notificationBody }) => (notificationBody ? '132px' : '80px')};
    ${({ notificationBody }) =>
      notificationBody ? '' : 'justify-content : center'}
    @media ${(props) => props.theme.breakpoint.sm} {
      margin-top: 62px;
      height: 104px;
    }
  `,
  Close: styled.div`
    position: absolute;
    top: 0px;
    right: -10px;
  `,
};
