import { createPosition } from '../utils/createPositon';

export const mapPosition = {
  map_1: [
    createPosition(22, 100),
    createPosition(120, 54),
    createPosition(200, 54),
    createPosition(274, 68),
    createPosition(244, 126),
    createPosition(174, 132),
    createPosition(96, 142),
    createPosition(30, 180),
    createPosition(72, 234),
    createPosition(160, 242),
    createPosition(246, 244),
    createPosition(286, 300),
    createPosition(258, 356),
    createPosition(162, 328),
    createPosition(80, 304),
    createPosition(16, 354),
    createPosition(90, 408),
    createPosition(166, 410),
    createPosition(256, 452),
  ],
  map_2: [
    createPosition(134, 66),
    createPosition(248, 60),
    createPosition(252, 114),
    createPosition(186, 120),
    createPosition(100, 116),
    createPosition(16, 138),
    createPosition(32, 220),
    createPosition(104, 200),
    createPosition(178, 174),
    createPosition(264, 186),
    createPosition(278, 256),
    createPosition(228, 310),
    createPosition(158, 324),
    createPosition(86, 298),
    createPosition(30, 342),
    createPosition(48, 410),
    createPosition(108, 436),
    createPosition(182, 436),
    createPosition(242, 448),
  ],
  map_3: [
    createPosition(234, 88),
    createPosition(140, 58),
    createPosition(62, 66),
    createPosition(80, 114),
    createPosition(150, 124),
    createPosition(220, 138),
    createPosition(262, 204),
    createPosition(262, 270),
    createPosition(246, 346),
    createPosition(182, 340),
    createPosition(152, 278),
    createPosition(134, 216),
    createPosition(74, 194),
    createPosition(44, 254),
    createPosition(34, 330),
    createPosition(34, 404),
    createPosition(92, 436),
    createPosition(166, 436),
    createPosition(226, 456),
  ],
  map_4: [
    createPosition(76, 96),
    createPosition(52, 152),
    createPosition(126, 166),
    createPosition(142, 76),
    createPosition(202, 34),
    createPosition(268, 74),
    createPosition(274, 152),
    createPosition(242, 230),
    createPosition(176, 248),
    createPosition(102, 230),
    createPosition(32, 252),
    createPosition(68, 330),
    createPosition(150, 316),
    createPosition(264, 316),
    createPosition(282, 396),
    createPosition(218, 418),
    createPosition(174, 358),
    createPosition(74, 366),
    createPosition(128, 472),
  ],
  map_5: [
    createPosition(34, 120),
    createPosition(92, 114),
    createPosition(170, 102),
    createPosition(232, 60),
    createPosition(292, 96),
    createPosition(260, 164),
    createPosition(186, 164),
    createPosition(140, 192),
    createPosition(208, 222),
    createPosition(266, 264),
    createPosition(256, 334),
    createPosition(182, 314),
    createPosition(118, 276),
    createPosition(42, 270),
    createPosition(40, 340),
    createPosition(68, 398),
    createPosition(134, 420),
    createPosition(200, 416),
    createPosition(268, 436),
  ],
  map_6: [
    createPosition(188, 58),
    createPosition(84, 64),
    createPosition(36, 104),
    createPosition(68, 154),
    createPosition(136, 144),
    createPosition(206, 112),
    createPosition(258, 154),
    createPosition(260, 218),
    createPosition(218, 262),
    createPosition(148, 246),
    createPosition(86, 200),
    createPosition(36, 240),
    createPosition(76, 310),
    createPosition(146, 338),
    createPosition(228, 338),
    createPosition(268, 392),
    createPosition(218, 420),
    createPosition(152, 400),
    createPosition(94, 472),
  ],
  map_7: [
    createPosition(100, 94),
    createPosition(138, 80),
    createPosition(208, 80),
    createPosition(282, 98),
    createPosition(276, 166),
    createPosition(208, 166),
    createPosition(148, 158),
    createPosition(80, 148),
    createPosition(54, 200),
    createPosition(54, 274),
    createPosition(112, 292),
    createPosition(178, 272),
    createPosition(254, 268),
    createPosition(280, 338),
    createPosition(282, 404),
    createPosition(238, 426),
    createPosition(178, 426),
    createPosition(106, 426),
    createPosition(58, 442),
  ],
  map_8: [
    createPosition(78, 160),
    createPosition(48, 52),
    createPosition(124, 36),
    createPosition(202, 52),
    createPosition(272, 100),
    createPosition(252, 172),
    createPosition(188, 200),
    createPosition(108, 218),
    createPosition(34, 262),
    createPosition(20, 326),
    createPosition(20, 400),
    createPosition(80, 442),
    createPosition(150, 452),
    createPosition(240, 432),
    createPosition(240, 366),
    createPosition(162, 364),
    createPosition(88, 334),
    createPosition(154, 296),
    createPosition(250, 312),
  ],
  map_9: [
    createPosition(62, 118),
    createPosition(132, 34),
    createPosition(206, 34),
    createPosition(270, 82),
    createPosition(276, 150),
    createPosition(216, 178),
    createPosition(132, 166),
    createPosition(54, 176),
    createPosition(30, 246),
    createPosition(86, 278),
    createPosition(154, 246),
    createPosition(230, 248),
    createPosition(180, 300),
    createPosition(116, 344),
    createPosition(84, 416),
    createPosition(116, 460),
    createPosition(184, 462),
    createPosition(236, 418),
    createPosition(270, 400),
  ],
  map_10: [
    createPosition(52, 198),
    createPosition(92, 80),
    createPosition(174, 38),
    createPosition(252, 66),
    createPosition(256, 134),
    createPosition(206, 172),
    createPosition(126, 152),
    createPosition(114, 224),
    createPosition(172, 262),
    createPosition(240, 258),
    createPosition(294, 282),
    createPosition(274, 342),
    createPosition(200, 356),
    createPosition(122, 342),
    createPosition(62, 308),
    createPosition(54, 388),
    createPosition(108, 448),
    createPosition(184, 440),
    createPosition(240, 458),
  ],
  map_11: [
    createPosition(62, 130),
    createPosition(40, 186),
    createPosition(40, 286),
    createPosition(40, 366),
    createPosition(96, 396),
    createPosition(104, 324),
    createPosition(104, 238),
    createPosition(108, 158),
    createPosition(120, 60),
    createPosition(194, 60),
    createPosition(198, 144),
    createPosition(178, 228),
    createPosition(160, 304),
    createPosition(150, 386),
    createPosition(212, 428),
    createPosition(270, 390),
    createPosition(270, 314),
    createPosition(252, 232),
    createPosition(272, 198),
  ],
  map_12: [
    createPosition(46, 96),
    createPosition(162, 60),
    createPosition(240, 66),
    createPosition(282, 110),
    createPosition(250, 162),
    createPosition(188, 172),
    createPosition(110, 144),
    createPosition(42, 148),
    createPosition(42, 220),
    createPosition(130, 236),
    createPosition(214, 228),
    createPosition(282, 262),
    createPosition(240, 322),
    createPosition(148, 314),
    createPosition(66, 308),
    createPosition(54, 378),
    createPosition(106, 434),
    createPosition(186, 460),
    createPosition(252, 422),
  ],
};
