import React from 'react';
import { useHistory } from 'react-router-dom';
import { queryDashboard } from 'src/api/hooks/queryDashboard';

import LayoutDashboard from 'src/layouts/newDashboard';
import ComcomLayout from '../layout/Comcom.Layout';
import TermsWidget from '../widget/Terms.Widget';

export interface TermsScreenProps {}

const TermsScreen: React.FC<TermsScreenProps> = () => {
  const { useQueryData } = queryDashboard.queryTerms();
  const { data, isLoading } = useQueryData;
  const history = useHistory();
  
  const handleChangeView = () => {
    history.goBack();
  };
  return (
    <LayoutDashboard isButton onGoBackView={handleChangeView}>
      <ComcomLayout title="利用規約" loading={isLoading}>
        <TermsWidget content={data?.data.content ?? ''} />
      </ComcomLayout>
    </LayoutDashboard>
  );
};

export default TermsScreen;
