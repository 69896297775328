import { RequestListProps } from 'src/model';
import { request } from './axios';
import { API_URL } from './config';

class FoodApi {
  url: { LIST: string };

  constructor() {
    this.url = API_URL.FOOD;
  }
  getList(params: RequestListProps) {
    return request({
      url: this.url.LIST,
      method: 'GET',
      params,
    });
  }
  getDetail(id: number) {
    return request({
      url: this.url.LIST + '/' + id + '/rate',
      method: 'GET',
    });
  }
  getAdvertisement(id: number) {
    return request({
      url: API_URL.FOOD.ADVERTISEMENT,
      method: 'GET',
      params: { section_id: id },
    });
  }
}

export const foodApi = new FoodApi();
