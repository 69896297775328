import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface NotificationSlideProps {
  visible?: boolean;
  des?: string;
  title?: string;
  body?:string;
  isError?: boolean;
}

const initialState: NotificationSlideProps = {
  visible: false,
  des: '',
  title: '',
  body: '',
  isError: false,
};

const notificationSlide = createSlice({
  name: 'notificationSlide',
  initialState,
  reducers: {
    setModal(state, action: PayloadAction<NotificationSlideProps>): any {
      state.visible = action.payload.visible;
      state.des = action.payload.des;
      state.title = action.payload.title;
      state.body = action.payload.body;
      state.isError = action.payload.isError;
    },
  },
});

export const { setModal } = notificationSlide.actions;

export default notificationSlide.reducer;
