import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyDqLRGk9Q3Z9tjvOSQygDgn6O6cgiQUhb8',
  authDomain: 'pet-paradise-f4f17.firebaseapp.com',
  projectId: 'pet-paradise-f4f17',
  storageBucket: 'pet-paradise-f4f17.appspot.com',
  messagingSenderId: '1034304414509',
  appId: '1:1034304414509:web:ed60f7caecd9d40b61a9a4',
  measurementId: 'G-GDZ956X7M2',
};

const firebaseApp = initializeApp(firebaseConfig);
export const getMessageClient = () => {
  try {
    const message = getMessaging(firebaseApp);
    if (message) {
      return message;
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

export const fetchToken = (setTokenFound) => {
  const message = getMessageClient();

  return getToken(message, {
    vapidKey:
      'BJuikllTA6970oLquvlurzzPM_E4gH6FC-0XrZRY2ynPjf-6-wzqAQGBMALoMCs3oya7zAXoBanYkMgXYZQCEcY',
  })
    .then((currentToken) => {
      console.log(currentToken);
      if (currentToken) {
        setTokenFound(currentToken);
      } else {
        console.log(
          'No registration token available. Request permission to generate one.',
        );
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

// const messaging = getMessageClient();

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       resolve(payload);
//     });
//   });
