import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LoadingSlideProps {
  visible?: boolean;

  title?: string;
}

const initialState: LoadingSlideProps = {
  visible: false,
  title: '',
};

const loadingSlide = createSlice({
  name: 'loadingSlide',
  initialState,
  reducers: {
    setModal(state, action: PayloadAction<LoadingSlideProps>): any {
      state.visible = action.payload.visible;
      state.title = action.payload.title;
    },
  },
});

export const { setModal } = loadingSlide.actions;

export default loadingSlide.reducer;
