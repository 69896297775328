import React from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import MainLayout from 'src/layouts/MainLayout';
import CreateNewPassWidget from '../widget/CreateNewPass.Widget';

export interface MakeNewPasswordScreenProps {}

const MakeNewPasswordScreen: React.FC<MakeNewPasswordScreenProps> = () => {
  return (
    <MainLayout
      screen={IMG_CONFIG.BACKGROUND.MAIN}
      hiddenSetting={true}
      minHeight={500}>
      <CreateNewPassWidget />
    </MainLayout>
  );
};

export default MakeNewPasswordScreen;
