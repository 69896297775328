// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Stage, Layer, Transformer, Image } from 'react-konva';
import useImage from 'use-image';

import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import CustomModal from 'src/components/custom/modal/CustomModal';
import { useIsOpen } from 'src/hooks/useIsOpen';
import PreviewCollageImage from './preview-collage-image';
import { DataDimensionProps } from 'src/hooks/useInputUpload';
interface TypeState {
  state: number;
  image: string;
  dimensions : DataDimensionProps;
}
export interface ViewCollageImageProps {
  stateProp: TypeState;
  backgroundImage?: string;
  foregroundImage ?: string;
  foregroundDimensions ?: DataDimensionProps;
}

const ImagePrimary = ({
  shapeProps,
  isSelected,
  onSelect,
  imageData,
  onChange,
  onHide
}) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();

  const [image] = useImage(`data:image/jpeg;base64,` + imageData);

  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <React.Fragment>
      <Image
        image={image}
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        draggable
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
          onHide(null);
        }}
        onTransformEnd={() => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it} back
          node.scaleX(1);
          node.scaleY(1);
          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
          });

          onHide(null);
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          anchorSize={10}
          anchorFill="transparent"
          // borderDash={[3, 3]}
          rotateAnchorOffset={20}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </React.Fragment>
  );
};

export const ViewCollageImage: React.FC<ViewCollageImageProps> = ({
  stateProp,
  backgroundImage,
  foregroundImage,
}) => {

  const initialImage = [
    {
      x: 25,
      y: 40,
      width: stateProp.dimensions.width,
      height: stateProp.dimensions.height,
      fill: 'transparent',
      id: 'image1',
      imageData : stateProp.image
    }
  ];

  const [imagePrimary, setImagePrimary] = React.useState(initialImage);
  const [selectedId, selectShape] = React.useState(null);
  const [imagePreview, setImagePreview] = React.useState<string>('');

  const open = useIsOpen();

  const stageRef = React.useRef();

  useEffect(() => {
      setImagePrimary(initialImage);
  },[foregroundImage]);

  const [image] = useImage(`data:image/jpeg;base64,` + backgroundImage);

  const [foreground] = useImage(`data:image/jpeg;base64,` + foregroundImage);

  const checkDeselect = () => {
    selectShape(null);
  };

  const handleExport = () => {
    const uri = stageRef.current.toDataURL();
    setImagePreview(uri);
    open.onOpen();
  };

  return (
    <Style.Wrapper>
      <Style.Collage>
        <Stage width={240} height={240} ref={stageRef}>
          <Layer name="lower-layer">
            <Image
              x={0}
              image={image}
              y={0}
              width={240}
              height={240}
              onTap={checkDeselect}
              onClick={checkDeselect}
            />
          </Layer>
          <Layer name="center-layer">
            {imagePrimary.map((image, i) => {
              return (
                <ImagePrimary
                  key={i}
                  shapeProps={image}
                  isSelected={image.id === selectedId}
                  onSelect={() => {
                    selectShape(image.id);
                  }}
                  imageData={image.imageData}
                  onChange={(newAttrs) => {
                    const img = imagePrimary.slice();
                    img[i] = newAttrs;
                    setImagePrimary(img);
                  }}
                  onHide={selectShape}
                />
              );
            })}
          </Layer>
          <Layer name="upper-layer">
            <Image
              x={0}
              image={foreground}
              y={0}
              draggable
              listening={false}
              width={240}
              height={240}
            />
          </Layer>
        </Stage>
      </Style.Collage>
      <Style.ButtonWrapper>
        <CustomIconButton
          icon={IMG_CONFIG.BUTTON.READY_UPLOAD_IMAGE}
          imgWidth="128px"
          imgHeight="42px"
          onClick={handleExport}
        />
      </Style.ButtonWrapper>
      <CustomModal
        closeIcon={<CustomIconButton icon={IMG_CONFIG.DASHBOARD.CLOSE} />}
        footer={null}
        maskStyle={{ background: 'rgba(0,0,0,.6)' }}
        width={'400px'}
        centered
        wrapClassName="custom-modal-wrapper"
        visible={open.isOpen}
        onCancel={open.onClose}>
        <PreviewCollageImage
          imagePreview={imagePreview}
          setOpen={open}
          state={stateProp.state}
        />
      </CustomModal>
    </Style.Wrapper>
  );
};

const Style = {
  Wrapper: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: -8px;
  `,
  Collage: styled.div`
    border-radius: 20px;
    overflow: auto;
  `,
  ButtonWrapper: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
  `,
};
