import React from 'react';

import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import MainLayout from 'src/layouts/MainLayout';

import SignInWidget from '../widget/SignIn.Widget';

export interface SignInScreenProps {}

const SignInScreen: React.FC<SignInScreenProps> = () => {
  return (
    <MainLayout
      screen={IMG_CONFIG.BACKGROUND.MAIN}
      hiddenSetting={true}
      minHeight={500}>
      <SignInWidget />
    </MainLayout>
  );
};

export default SignInScreen;
