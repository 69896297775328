import React from 'react';
import { useUploadAvatar } from 'src/components/feature/UploadAvatar/hook/useUploadAvatar';
import { useUploadSpot } from '../hook/useUploadSpot';
import { supportErrorFormik } from '@utils/support/supportErrorFormik';
import { uploadImgConfig } from 'src/assets/config/imgUploadConfig';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import ItemUploadSpot from '../components/camera/item-upload';
import PreviewImageUpload from './preview-image-upload';

export interface ViewUploadCameraProps {
  dataImage?: any;
}

export const ViewUploadCamera: React.FC<ViewUploadCameraProps> = ({
  dataImage,
}) => {
  // const upload = useInputUpload();
  const { formik, query, formikChangeImage, image, upload, errorLoad } =
    useUploadSpot(dataImage);
  const { open } = useUploadAvatar(upload.imgUrl);
  return (
    <>
      {' '}
      <PreviewImageUpload
        visible={open.isOpen}
        image={upload.imgUrl}
        onCancel={open.onClose}
        handleClick={formikChangeImage}
      />
      {!query.isSuccess && (
        <>
          {' '}
          <ItemUploadSpot
            onOpen={open.onOpen}
            imgSrc={image}
            error={supportErrorFormik(formik, 'image')}
          />
          {/* upload */}
          <input
            name="image"
            type={'file'}
            style={{ display: 'none' }}
            ref={upload.hiddenFileInput}
            onChange={upload.handleChange}
            accept={uploadImgConfig}
            onClick={(e: any) => (e.target.value = null)}
          />
          { errorLoad && <p className="color_error" style={{ fontSize : '8px'}}>{errorLoad}</p>}
          <CustomIconButton
            icon={IMG_CONFIG.BUTTON.SELECT_IMAGE}
            onClick={upload.handleClick}
          />
          <CustomIconButton
            icon={IMG_CONFIG.BUTTON.CONTINUE}
            onClick={() => formik.handleSubmit()}
          />
        </>
      )}
    </>
  );
};
