import { supportErrorFormik } from '@utils/support/supportErrorFormik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { queryProfile } from 'src/api/hooks/queryProfile';
import ViewBoardAuth from 'src/components/core/View/ViewBoardAuth';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import CustomInput from 'src/components/custom/input/CustomInput';
import { useFormUpdateUser } from 'src/hooks/formik/useFormUpdateUser';
import styled from 'styled-components';

export interface UpdateUserWidgetProps {
  onSuccess?: any;
}

const UpdateUserWidget: React.FC<UpdateUserWidgetProps> = ({ onSuccess }) => {
  const { useQueryData } = queryProfile.queryProfile();
  const { data } = useQueryData;
  const { formik, query } = useFormUpdateUser();
  const { t } = useTranslation();
  useEffect(() => {
    if (query.isSuccess) {
      onSuccess();
    }
  }, [query.isSuccess]);
  return (
    <ViewBoardAuth title={t('content.auth.user')}>
      <Style.Wrapper>
        <Style.Img>
          <img src={data?.data.profile.avatar} />
        </Style.Img>
        {query.isError && (
          <p className="color_error small_text error_wrapper">
            {query.error.message}
          </p>
        )}

        <Style.Form className="hidden_scroll">
          <CustomInput
            name={'name'}
            placeholder={t('content.profile.name')}
            value={formik.values.name}
            error={supportErrorFormik(formik, 'name')}
            onChange={formik.handleChange}
          />
          <CustomInput
            name={'line_id'}
            placeholder={t('content.profile.lineId')}
            value={formik.values.line_id}
            error={supportErrorFormik(formik, 'line_id')}
            onChange={formik.handleChange}
          />
          <CustomInput
            name={'line_name'}
            placeholder={t('content.profile.lineName')}
            value={formik.values.line_name}
            error={supportErrorFormik(formik, 'line_name')}
            onChange={formik.handleChange}
          />
        </Style.Form>

        {/* button */}
        <CustomIconButton onClick={() => formik.handleSubmit()} />
      </Style.Wrapper>
    </ViewBoardAuth>
  );
};

export default UpdateUserWidget;

const Style = {
  Wrapper: styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    justify-items: center;
    width: 100%;
    height: 100%;
    grid-gap: 20px;
    padding-top: 20px;
  `,
  Img: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      width: 100px;
      height: 100px;
      border-radius: 10px;
      border: 2px solid white;
      box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
    }
  `,
  Form: styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    grid-gap: 10px;
    width: 100%;
    overflow-y: auto;
    height: 120px;
  `,
};
