import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryAds } from 'src/api/hooks/useQueryAds';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import LoadingMoreInfinityItem from 'src/components/custom/item/loading-more-infinity.item';
import ViewCustomScroll from 'src/components/custom/views/ViewCustomScroll';
import { ViewContent } from 'src/components/custom/views/ViewsContent';
import LayoutAdsCom from '../components/LayoutAds.Com';
import ListAdsWidget from '../widget/ListAds.Widget';

export interface ListAdsScreenProps {}

const ListAdsScreen: React.FC<ListAdsScreenProps> = () => {
  const { queryInfinity } = useQueryAds();

  const { data: page, fetchNextPage, hasNextPage } = queryInfinity;
  const history = useHistory();
  return (
    <LayoutAdsCom
      button={
        <CustomIconButton
          icon={IMG_CONFIG.BUTTON.OK}
          onClick={history.goBack}
        />
      }>
      <ViewCustomScroll>
        <ViewContent.List>
          {page?.pages.map((data, i) => {
            return <ListAdsWidget data={data?.data?.ads?.data} key={i} />;
          })}
          {/* loading */}
          <LoadingMoreInfinityItem
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
          />
        </ViewContent.List>
      </ViewCustomScroll>
    </LayoutAdsCom>
  );
};

export default ListAdsScreen;
