import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  withRouter,
} from 'react-router-dom';
import { ProtectedRoute } from './guards';
import { Paths } from '@utils/constants';
import { BasicTemplate, AppTemplate } from '@layouts';
import { useRouter } from '@hooks';
import { Home } from '@pages';
import { useEffect, useRef, useState } from 'react';
import ForgotEmailScreen from 'src/pages/auth/screen/ForgotEmail.Screen';
import MakeNewPasswordScreen from 'src/pages/auth/screen/MakeNewPassword.Screen';
import ListNewsScreen from 'src/pages/news/screen/ListNews.Screen';
import DetailNewsScreen from 'src/pages/news/screen/DetailNews.Screen';
import TopPointScreen from 'src/pages/ranking/screen/TopPoint.Screen';
import ListFoodScreen from 'src/pages/food/screen/ListFood.Screen';
import ListCafeScreen from 'src/pages/cafePage/screen/ListCafe.Screen';
import ListShopScreen from 'src/pages/shop/screen/ListShop.Screen';
import ListExchangeHistoryScreen from 'src/pages/shop/screen/ListExchangeHistory.Screen';
import DetailCafeScreen, {
  DetailCafeTypeEnum,
} from 'src/pages/cafePage/screen/DetailCafe.Screen';
import DetailCafeTravelScreen from 'src/pages/cafePage/screen/DetailCafeTravel.Screen';
import ListEventScreen from 'src/pages/event/screen/ListEvent.Screen';
import DetailEventScreen from 'src/pages/event/screen/DetailEvent.Screen';
import RegisterEventScreen from 'src/pages/event/screen/RegisterEvent.Screen';
// import { queryProfile } from 'src/api/hooks/queryProfile';
import SignInScreen from 'src/pages/auth/screen/SignIn.Screen';
import RegisterScreen from 'src/pages/registerPage/screen/Register.Screen';
// import { queryDashboard } from 'src/api/hooks/queryDashboard';
import DashboardScreen from 'src/pages/dashboard/screen/Dashboard.Screen';
import ListAdsScreen from 'src/pages/ads/screens/ListAds.Screen';
import PetScreens from 'src/pages/registerPage/screen/Pet.Screens';
import ListPetScreen from 'src/pages/registerPage/screen/ListPet.Screen';
import RegisterMoreScreen from 'src/pages/registerPage/screen/RegisterMore.Screen';
import UpdatePetScreen from 'src/pages/registerPage/screen/UpdatePet.Screen';
import StageGameScreen from 'src/pages/stageGame/screen/StageGame.Screen';
import QuestionStageGameScreen from 'src/pages/stageGame/screen/QuestionStageGame.Screen';
import { useStartApp } from 'src/api/hooks/useStartApp';
import PlayMiniGameScreen from 'src/pages/miniGame/screen/PlayMiniGame.Screen';
import FaqsScreen from 'src/pages/Comcom/screen/Faqs.Screen';
import TermsScreen from 'src/pages/Comcom/screen/Terms.Screen';
import ConfirmRegisterScreen from 'src/pages/registerPage/screen/ConfirmRegister.Screen';
import ListNotificationScreen from 'src/pages/Comcom/screen/ListNotification.Screen';
import RewardGameScreen from 'src/pages/stageGame/screen/RewardGame.Screen';
import { UploadImageStageScreen } from 'src/pages/stageGame/screen/upload-image-screen';
import { CollageImageScreen } from 'src/pages/stageGame/screen/collage-image-screen';
import { ListImageSpotCameraScreen } from 'src/pages/stageGame/screen/list-image-spot-camera.screen';
import { UploadPetScreen } from 'src/pages/stageGame/screen/upload-pet-screen';
import ConfirmSoundModal from 'src/components/custom/modal/ConfirmSoundModal';
import { useIsOpen } from 'src/hooks/useIsOpen';
import { reduxSound } from 'src/redux/hooks/ReduxSound';
import { SOUND_CONFIG } from 'src/assets/config/soundConfig';
import { queryProfile } from 'src/api/hooks/queryProfile';

const Routing = () => {
  // const token = getStoredAuth();
  // queryProfile.queryProfile();
  // queryDashboard.queryStart();

  useStartApp();

  const open = useIsOpen();

  const { useData, useAction } = reduxSound;
  const { data } = useData();
  const { setUserSound } = useAction();
  const query = queryProfile.queryUserSound();

  const [muted, setMuted] = useState<boolean>(!!data.is_bgm);

  const backgroundSound = useRef<any>(null);

  useEffect(() => {
    if (data.user_id && !data.is_bgm) {
      open.onClose();
    } else {
      open.onOpen();
    }
  }, []);

  useEffect(() => {
    setMuted(!!data.is_bgm);
  }, [data.is_bgm]);

  // Turn off sound
  function pauseAudio() {
    backgroundSound.current.pause();
  }

  // Turn on sound
  function playAudio() {
    backgroundSound.current.play();
  }

  const onConfirm = () => {
    if (!data.user_id) {
      setUserSound({
        is_bgm: 1,
        user_id: undefined,
      });
    } else {
      query.mutate({ is_bgm: 1 });
    }
    setTimeout(() => {
      backgroundSound.current.play();
    }, 200);
    open.onClose();
  };

  const onCancel = () => {
    if (!data.user_id) {
      setUserSound({
        is_bgm: 0,
        user_id: undefined,
      });
    } else {
      query.mutate({ is_bgm: 0 });
    }
    setTimeout(() => {
      backgroundSound.current.play();
    }, 3000);
    open.onClose();
  };

  // handle audio when blur
  window.addEventListener('blur', pauseAudio);

  // handle audio when focus
  window.addEventListener('focus', playAudio);

  return (
    <AppTemplate>
      <BasicTemplate>
        <Switch>
          <Route exact path={Paths.SignIn} component={SignInScreen} />

          <Switch>
            <Route exact path={Paths.Intro} component={Home} />
            {/* <Route exact path={Paths.Register} component={Register} /> */}

            <Route exact path={Paths.Register} component={RegisterScreen} />
            <Route
              exact
              path={Paths.RegisterConfirm}
              component={ConfirmRegisterScreen}
            />

            <Route exact path={Paths.Faqs} component={FaqsScreen} />
            <Route exact path={Paths.Terms} component={TermsScreen} />

            <Route exact path={Paths.AuthEmail} component={ForgotEmailScreen} />
            <Route
              exact
              path={Paths.AuthPassword}
              component={MakeNewPasswordScreen}
            />
            <ProtectedRoute redirect={Paths.Intro}>
              <Switch>
                <Route exact path={Paths.Ads} component={ListAdsScreen} />

                {/* <Route exact path={Paths.Stage} component={Stage} /> */}

                {/* stage game */}
                <Route exact path={Paths.Stage} component={StageGameScreen} />
                <Route exact path={Paths.Reward} component={RewardGameScreen} />
                <Route
                  exact
                  path={Paths.Stage_Question}
                  component={QuestionStageGameScreen}
                />

                {/* upload anh */}
                <Route
                  exact
                  path={Paths.Stage_Paradise}
                  component={UploadImageStageScreen}
                />
                <Route
                  exact
                  path={Paths.Stage_Paradise_Collage}
                  component={CollageImageScreen}
                />
                <Route
                  exact
                  path={Paths.Stage_Paradise_Upload}
                  component={UploadPetScreen}
                />
                <Route
                  exact
                  path={Paths.Stage_Paradise_list}
                  component={ListImageSpotCameraScreen}
                />

                {/* dashboard */}
                <Route
                  exact
                  path={Paths.Dashboard}
                  component={DashboardScreen}
                />
                {/* mini game */}

                <Route exact path={Paths.Game} component={PlayMiniGameScreen} />

                {/* <Route exact path={Paths.News} component={News} /> */}
                <Route exact path={Paths.News} component={ListNewsScreen} />
                <Route
                  exact
                  path={Paths.NewsDetail}
                  component={DetailNewsScreen}
                />
                {/* <Route exact path={Paths.Ranking} component={Ranking} /> */}
                <Route exact path={Paths.Ranking} component={TopPointScreen} />
                {/* <Route exact path={Paths.Food} component={Food} /> */}
                <Route exact path={Paths.Food} component={ListFoodScreen} />
                {/* <Route exact path={Paths.Camera} component={Camera} /> */}
                <Route exact path={Paths.Event} component={ListEventScreen} />
                <Route
                  exact
                  path={Paths.EventVote}
                  component={DetailEventScreen}
                />
                <Route
                  exact
                  path={Paths.EventDetail}
                  component={DetailEventScreen}
                />
                <Route
                  exact
                  path={Paths.EventRegister}
                  component={RegisterEventScreen}
                />

                {/* profile */}
                <Route exact path={Paths.Profile} component={PetScreens} />
                <Route
                  exact
                  path={Paths.Profile_ListPet}
                  component={ListPetScreen}
                />
                <Route
                  exact
                  path={Paths.Profile_AddPet}
                  component={RegisterMoreScreen}
                />
                <Route
                  exact
                  path={Paths.Profile_Update + '/:id'}
                  component={UpdatePetScreen}
                />

                {/* notification */}

                <Route
                  exact
                  path={Paths.ListNotification}
                  component={ListNotificationScreen}
                />

                <Route
                  exact
                  path={Paths.ListNotification + '/:id'}
                  component={ListNotificationScreen}
                />

                {/* cafe */}
                <Route exact path={Paths.Cafe} component={ListCafeScreen} />
                <Route
                  exact
                  path={Paths.CafeRankingEvent}
                  children={
                    <DetailCafeScreen type={DetailCafeTypeEnum.EVENT} />
                  }
                />
                <Route
                  exact
                  path={Paths.CafeFrame}
                  children={
                    <DetailCafeScreen type={DetailCafeTypeEnum.FRAME} />
                  }
                />
                <Route
                  exact
                  path={Paths.CafeTravel}
                  children={
                    <DetailCafeScreen type={DetailCafeTypeEnum.ARTICLE} />
                  }
                />
                <Route
                  exact
                  path={Paths.CafeTravelDetail}
                  component={DetailCafeTravelScreen}
                />

                <Route
                  exact
                  path={Paths.PoExchange}
                  component={ListShopScreen}
                />
                <Route
                  exact
                  path={Paths.ExchangeHistory}
                  component={ListExchangeHistoryScreen}
                />
                {/* <Route exact path={Paths.PoExchange} component={PoExchange} /> */}
                <Redirect to={Paths.Stage} />
              </Switch>
            </ProtectedRoute>

            <Redirect to={Paths.Intro} />
          </Switch>
        </Switch>
      </BasicTemplate>
      <ConfirmSoundModal
        visible={open.isOpen}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
      <audio
        src={SOUND_CONFIG.SOUND_MAIN}
        controls={false}
        loop={true}
        ref={backgroundSound}
        muted={!muted}
      />
    </AppTemplate>
  );
};

export const AppRouter = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routing />
    </Router>
  );
};

const ScrollToTop = withRouter(() => {
  const router = useRouter();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [router.pathname]);

  return null;
});
