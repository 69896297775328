import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import MainLayout from 'src/layouts/MainLayout';
import LayoutRegisterCom from '../components/LayoutRegister.Com';
import { configData } from '../config';
import ConfirmRegisterWidget from '../widget/ConfirmRegister.Widget';

export interface ConfirmRegisterScreenProps {}

const ConfirmRegisterScreen: React.FC<ConfirmRegisterScreenProps> = () => {
  const { state } = useLocation<any>();
  const { t } = useTranslation();

  return (
    <MainLayout
      screen={IMG_CONFIG.BACKGROUND.MAIN}
      hiddenSetting
      minHeight={configData.minHeight}>
      <LayoutRegisterCom title={t('content.register.makeAccount')}>
        <ConfirmRegisterWidget data={state.values} />
      </LayoutRegisterCom>
    </MainLayout>
  );
};

export default ConfirmRegisterScreen;
