import { Paths } from '@utils/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import LayoutDashboard from 'src/layouts/newDashboard';
import ListFeatureWidget from '../widget/ListFeature.Widget';

export interface DashboardScreenProps {}

const DashboardScreen: React.FC<DashboardScreenProps> = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <LayoutDashboard
      button={
        <CustomIconButton
          icon={IMG_CONFIG.BUTTON.OK}
          onClick={() => history.push(Paths.Stage)}
        />
      }
      footerContent={t('content.dashboard.footer')}>
      <ListFeatureWidget />
    </LayoutDashboard>
  );
};

export default DashboardScreen;
