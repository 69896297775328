import { Image } from 'antd';
import React from 'react';
import { EventResultDataProps } from 'src/model';
import styled from 'styled-components';

export interface CardRankingComProps {
  data?: EventResultDataProps;
  no?: number;
}

const CardRankingCom: React.FC<CardRankingComProps> = ({ data, no }) => {
  const isTop = no === 1;
  return (
    <Style.Wrapper isTop={isTop}>
      {/* name */}
      <Style.NameWrapper>
        <h5 className={`big_title ranking_text_dropshadow  ${isTop ? 'color_pink' : 'color_main'}`} style={{ fontSize: '24px'}}>
          {no || 0}.
        </h5>
        <h5
          className={`medium_text ranking_text_dropshadow  ${isTop ? 'color_white' : 'color_main'}`}
          style={{ marginTop: '8px', fontSize: '18px'}}>
          {data?.name}
        </h5>
      </Style.NameWrapper>
      {/* point */}
      <div>
        <h5 className={`big_text ranking_text_dropshadow ${isTop ? 'color_white' : 'color_main'}`}>
          {data?.total_vote || 0}票
        </h5>
      </div>
      {/* image */}
      <div style={{marginRight: '10px'}}>
        <Image src={data?.image} width={'24px'} preview={false} />
      </div>
    </Style.Wrapper>
  );
};

export default CardRankingCom;

interface StyleProps {
  isTop?: boolean;
}

const Style = {
  Wrapper: styled.div`
    background: ${(p: StyleProps) =>
      p.isTop
        ? 'linear-gradient(277.17deg, #FFBD0F 52.52%, #FFE16D 115.22%)'
        : '#ffe5c9'};
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* transform: ${(p: StyleProps) => (p.isTop ? 'scale(1.1)' : 'none')}; */
  `,
  NameWrapper: styled.div`
    display: flex;
    align-items: flex-start;
    width: 150px;
    margin-left:10px;
  `,
};
