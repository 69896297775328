import { RequestListProps } from 'src/model';
import { request } from './axios';
import { API_URL } from './config';

class FrameApi {
  url: { LIST: string; DEFAULT: string };

  constructor() {
    this.url = API_URL.FRAME;
  }
  getList(params: RequestListProps) {
    return request({
      url: this.url.LIST,
      method: 'GET',
      params,
    });
  }
  getDefault() {
    return request({
      url: this.url.DEFAULT,
      method: 'GET',
    });
  }
  postSet(id: number) {
    return request({
      url: this.url.LIST + '/' + id + '/exchange',
      method: 'POST',
    });
  }
  postSetDefault(id: number) {
    return request({
      url: this.url.LIST + '/' + id + '/default',
      method: 'POST',
    });
  }
}

export const frameApi = new FrameApi();
