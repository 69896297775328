import React, { useState } from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import LayoutDashboard from 'src/layouts/newDashboard';
import ListShopWidget from '../widget/ListShop.Widget';
import { Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CustomModal from 'src/components/custom/modal/CustomModal';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import { useIsOpen } from 'src/hooks/useIsOpen';
import ShopDetailWidget from '../widget/ShopDetail.Widget';
import { ProductDataProps } from 'src/model/product.model';

export interface ListShopScreenProps {}

const ListShopScreen: React.FC<ListShopScreenProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const open = useIsOpen();

  const [productSelected, setProductSelected] = useState<ProductDataProps>();

  const handleChangeView = () => {
    history.goBack();
  };
  const handleOpenDetailShop = (product : ProductDataProps) => {
    setProductSelected(product);
    open.onOpen();
  };
  return (
    <LayoutDashboard
      sizeTitle={20}
      title={t('content.shop.name')}
      isButton
      onGoBackView={handleChangeView}
      leftIcon={<Image src={IMG_CONFIG.DASHBOARD.POINTS_ICON_MINI} />}
      footerContent="貯めたポイントを交換できるよ！<br/>定期的に景品も変わるのでマメにチェック<br/>してみてね。">
      <ListShopWidget onClick={handleOpenDetailShop} />

      {/* modal */}
      <CustomModal
        closeIcon={<CustomIconButton icon={IMG_CONFIG.DASHBOARD.CLOSE} />}
        footer={null}
        maskStyle={{ background: 'rgba(0,0,0,.6)' }}
        width={'400px'}
        centered
        wrapClassName="custom-modal-wrapper"
        visible={open.isOpen}
        onCancel={open.onClose}>
        <ShopDetailWidget productSelected={productSelected} setOpen={open} />
      </CustomModal>
    </LayoutDashboard>
  );
};

export default ListShopScreen;
