import React from 'react';
import CustomButton from 'src/components/custom/button/CustomButton';
import ViewModalConfirm from 'src/components/custom/views/ViewModalConfirm';
import styled from 'styled-components';

export interface ButtonsConfirmProps {
  onConfirm?: () => void;
  onCancel?: () => void;
}

const ButtonsConfirm: React.FC<ButtonsConfirmProps> = ({
  onCancel,
  onConfirm,
}) => {
  return (
    <ViewModalConfirm des="ログアウトしますか？">
      <Style.Wrapper>
        <CustomButton
          text="いいえ"
          style={{ marginRight: '50px', color: 'green' }}
          onClick={onCancel}
        />
        <CustomButton
          text="はい"
          style={{ color: 'red' }}
          onClick={onConfirm}
        />
      </Style.Wrapper>
    </ViewModalConfirm>
  );
};

export default ButtonsConfirm;

const Style = {
  Wrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
};
