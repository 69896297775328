import React from 'react';
import { useTranslation } from 'react-i18next';
import { queryProfile } from 'src/api/hooks/queryProfile';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import CustomModal from 'src/components/custom/modal/CustomModal';
import { useIsOpen } from 'src/hooks/useIsOpen';
import MainLayout from 'src/layouts/MainLayout';
import LayoutRegisterCom from '../components/LayoutRegister.Com';
import { configData } from '../config';
import PetDetailWidget from '../widget/PetDetail.Widget';
import UpdateUserWidget from '../widget/UpdateUser.Widget';

export interface PetScreensProps {}

const PetScreens: React.FC<PetScreensProps> = () => {
  const { useQueryData } = queryProfile.queryProfile();
  const { data } = useQueryData;
  const open = useIsOpen();
  const { t } = useTranslation();
  return (
    <MainLayout
      screen={IMG_CONFIG.BACKGROUND.MAIN}
      minHeight={configData.minHeight}>
      <LayoutRegisterCom title={t('content.register.account')}>
        <PetDetailWidget data={data?.data.profile} onEdit={open.onOpen} />
      </LayoutRegisterCom>
      {/* modal */}
      <CustomModal
        closeIcon={<CustomIconButton icon={IMG_CONFIG.DASHBOARD.CLOSE} />}
        footer={null}
        width={'375px'}
        visible={open.isOpen}
        onCancel={open.onClose}>
        <UpdateUserWidget onSuccess={open.onClose} />
      </CustomModal>
    </MainLayout>
  );
};

export default PetScreens;
