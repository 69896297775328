import React, { PropsWithChildren } from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import styled from 'styled-components';
import useScrollPercentage from './useScrollPercent';

export interface ViewCustomScrollProps
  extends PropsWithChildren<any>,
    StyleProps {
  style?: React.CSSProperties;
}

const ViewCustomScroll: React.FC<ViewCustomScrollProps> = ({
  children,
  paddingTop,
  paddingBottom,
  style,
}) => {
  const [scrollRef, scrollPercentage] = useScrollPercentage();

  return (
    <Style.Wrapper
      style={style}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      percent={scrollPercentage}
      isMax={scrollPercentage === 100}
      className="custom_scroll"
      ref={scrollRef}>
      {children}
    </Style.Wrapper>
  );
};

export default ViewCustomScroll;

interface StyleProps {
  isMax?: boolean;
  percent?: any;
  paddingTop?: number;
  paddingBottom?: number;
}

const Style = {
  Wrapper: styled.div`
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: ${({ paddingTop }: StyleProps) => `${paddingTop || 0}px`};
    padding-bottom: ${({ paddingBottom }: StyleProps) =>
      `${paddingBottom || 0}px`};
    &.custom_scroll {
      overflow-y: auto;
      ::-webkit-scrollbar {
        width: 15px;
        background-image: url(${IMG_CONFIG.SCROLL.SCROLL_BG});
        background-repeat: repeat-y;
        background-position: center;
        cursor: pointer;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        height: 20px;
        cursor: pointer;
      }

      ::-webkit-resizer {
      }

      ::-webkit-scrollbar-track-piece {
        height: 20px;
        cursor: pointer;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb:vertical {
        /* background: #888; */
        height: 12px;
        background-image: url(${IMG_CONFIG.SCROLL.SCROLL});
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: ${(p: StyleProps) => `${p.percent || 0}%`};
        max-height: 12px;
        transform: translateX(3px) scale(1.5);
        cursor: pointer;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        /* background: #555; */
        transform: scale(1.02);
        cursor: pointer;
      }
    }
  `,
};
