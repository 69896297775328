import React from 'react';
import styled from 'styled-components';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';

export interface TitleRegisterComProps {
  title?: string;
  type?: number;
}

const TitleRegisterCom: React.FC<TitleRegisterComProps> = ({ title }) => {
  return (
    <Style.Wrapper>
      {/* <img src={img} /> */}
      <div className="inner_text">
        <p className="color_main" style={{ fontSize: '14px', lineHeight: '19px' }}>
          {title}
        </p>
      </div>
    </Style.Wrapper>
  );
};

export default TitleRegisterCom;

const Style = {
  Wrapper: styled.div`
    position: relative;
    background-color: #fadeb1;
    width: 260px;
    padding: 13px;
    border-radius: 8px;
    background-image: url(${IMG_CONFIG.PANEL_HIGHLIGHT});
    background-repeat:no-repeat;
    background-size:30px auto;
    background-position: 7px 7px;
    -webkit-box-shadow: 0px 7px 0px 3px rgba(0,0,0,0.20), inset -2px -8px 0px 2px rgba(245, 212, 169,1); 
    box-shadow: 0px 7px 0px 3px rgba(0,0,0,0.20), nset -2px -8px 0px 2px rgba(245, 212, 169,1);
    .inner_text {
      /* position: absolute; */
      top: 0;
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      padding: 0 5px;
      p {
        text-align: left;
      }
    }
  `,
};
