export interface CafeSortListQuery {
    complete?: number;
    page?: number;
}
export interface CafeSortDataProps {
     key : string;
     type : number;
}

export enum CafeSortKey {
      ADVERTISEMENT = 1,
      FRAME = 3,
      EVENT = 2,
      ARTICLES = 4,
}