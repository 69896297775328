import React from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import LayoutDashboard from 'src/layouts/newDashboard';
import { Image } from 'antd';
import { useParams } from 'react-router-dom';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DetailCafeTravelWidget from '../widget/DetailCafeTravel.Widget';
import { queryArticle } from 'src/api/hooks/queryArticle';

export interface DetailCafeTravelScreenProps {}

const DetailCafeTravelScreen: React.FC<DetailCafeTravelScreenProps> = () => {
  const { id } = useParams<any>();
  const { data, isLoading } = queryArticle.queryDetail(Number(id));

  const history = useHistory();
  const { t } = useTranslation();
  return (
    <LayoutDashboard
      loading={isLoading}
      title={t('content.cafe.name')}
      button={
        <CustomIconButton
          icon={IMG_CONFIG.BUTTON.RETURN}
          onClick={() => history.push('/dashboard/cafe')}
        />
      }
      leftIcon={
        <Image src={IMG_CONFIG.DASHBOARD.CAFE_ICON_MINI} preview={false} />
      }>
      <DetailCafeTravelWidget data={data?.data.article} />
    </LayoutDashboard>
  );
};

export default DetailCafeTravelScreen;
