import React from 'react';
import { useHistory } from 'react-router-dom';
import { queryDashboard } from 'src/api/hooks/queryDashboard';
import LayoutDashboard from 'src/layouts/newDashboard';
import ComcomLayout from '../layout/Comcom.Layout';
import ListFaqsWidget from '../widget/ListFaqs.Widget';

export interface FaqsScreenProps {}

const FaqsScreen: React.FC<FaqsScreenProps> = () => {
  const { useQueryData } = queryDashboard.queryFaqs();
  const { data, isLoading } = useQueryData;
  const history = useHistory();
  
  const handleChangeView = () => {
    history.goBack();
  };
  return (
    <LayoutDashboard isButton onGoBackView={handleChangeView}>
      <ComcomLayout title="FAQs" loading={isLoading}>
        <ListFaqsWidget data={data?.data.faqs || []} />
      </ComcomLayout>

      {/* modal */}
    </LayoutDashboard>
  );
};

export default FaqsScreen;
