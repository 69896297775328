import React, { useEffect, useState } from 'react';
import {
  AnswerDataProps,
  AnswerMultiReqProps,
  AnswerTextReqProps,
  AnswerTypeEnum,
  AnswerTypePetEnum,
} from 'src/model/stageGame.model';
import { reduxAnswer } from 'src/redux/hooks/ReduxAnswer';
import styled from 'styled-components';
import CardAnswerCom from '../components/CardAnswer.Com';
import CardAnswerTextCom from '../components/CardAnswerText.Com';
import { checkIsSelect } from '../utils/checkIsSelect';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';

export interface ListAnswerWidgetProps {
  data: AnswerDataProps[];
  type: AnswerTypeEnum;
  question_id: number;
  type_pet: number;
}

const ListAnswerWidget: React.FC<ListAnswerWidgetProps> = ({
  data,
  type,
  question_id,
  type_pet  
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const { useData, useAction } = reduxAnswer;

  const { data: question } = useData();

  const { changeAnswerMulti, changeAnswerText, typeAnswerText, changeSkip } = useAction();

  useEffect(() => {
    const payload = {
      question_id: question_id || 1,
      skip: isChecked,
    };
    changeSkip(payload);
  }, [isChecked]);

  const handleChangeChecked = () => {
    setIsChecked(!isChecked);
  };

  function getIsCol() {
    switch (type) {
      case AnswerTypeEnum.ONLY_TEXT:
        return false;
        break;
      case AnswerTypeEnum.ONLY_IMAGE:
        return true;
      case AnswerTypeEnum.TEXT_IMAGE:
        return true;
      case AnswerTypeEnum.ONLY_BIG_IMAGE:
        return false;
    }
  }
  const renderCheckBox = () => {
    switch (type_pet) {
      case AnswerTypePetEnum.DOG:
        return (
          <Style.SkipBox>
            <Style.Checkbox onClick={handleChangeChecked}>
              <img src={IMG_CONFIG.REGISTER.CHECKBOX} />
              <div>{isChecked && <img src={IMG_CONFIG.REGISTER.CHECK} />}</div>
            </Style.Checkbox>
            <p className="cap_text color_main">
              犬以外の飼い主さんはココをチェック
            </p>
          </Style.SkipBox>
        );
      case AnswerTypePetEnum.CAT:
        return (
          <Style.SkipBox>
            <Style.Checkbox onClick={handleChangeChecked}>
              <img src={IMG_CONFIG.REGISTER.CHECKBOX} />
              <div>{isChecked && <img src={IMG_CONFIG.REGISTER.CHECK} />}</div>
            </Style.Checkbox>
            <p className="cap_text color_main">
              猫以外の飼い主さんはココをチェック
            </p>
          </Style.SkipBox>
        );
      case AnswerTypePetEnum.OTHERS:
        return (
          <Style.SkipBox>
            <Style.Checkbox onClick={handleChangeChecked}>
              <img src={IMG_CONFIG.REGISTER.CHECKBOX} />
              <div>{isChecked && <img src={IMG_CONFIG.REGISTER.CHECK} />}</div>
            </Style.Checkbox>
            <p className="cap_text color_main">
              犬と猫以外の飼い主さんはココをチェック
            </p>
          </Style.SkipBox>
        );
      default:
        return null;
    }
  };

  const handleChange = (answer_id: any) => {
    const payload: AnswerMultiReqProps = {
      question_id,
      answer_id,
    };
    changeAnswerMulti(payload);
  };

  const handleChangeText = (answer_id: any) => {
    const payload: AnswerMultiReqProps = {
      question_id,
      answer_id,
    };
    changeAnswerText(payload);
  };

  const handleTypeText = (answer_id: any, e: any) => {
    const payload: AnswerTextReqProps = {
      question_id,
      answer_id,
      answer_text: e.target.value,
    };
    typeAnswerText(payload);
  };
  return (
    <>
      <Style.Wrapper isCol={getIsCol()}>
        {data.map((item, i) => {
          return (
            <CardAnswerCom
              isHide={item.is_text || false}
              isSelect={checkIsSelect(item.id, question_id, question) || false}
              data={item}
              type={type}
              key={i}
              onClick={() => handleChange(item.id)}
            />
          );
        })}
        <Style.WrapperSkipBox>
           {renderCheckBox()}
        </Style.WrapperSkipBox>
      </Style.Wrapper>
      {/* text */}
      <Style.WrapperText>
        {data.map((item, i) => {
          return (
            <CardAnswerTextCom
              isHide={item.is_text || false}
              isSelect={checkIsSelect(item.id, question_id, question) || false}
              data={item}
              key={i}
              onClick={() => handleChangeText(item.id)}
              onChange={(e: any) => handleTypeText(item.id, e)}
            />
          );
        })}
      </Style.WrapperText>
    </>
  );
};

export default ListAnswerWidget;

interface StyleProps {
  isCol?: boolean;
}

const Style = {
  Wrapper: styled.div`
    display: grid;
    grid-template-columns: ${(p: StyleProps) =>
      p.isCol ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)'};
    grid-gap: 10px;
    width: 100%;
    flex-direction: column;
    margin: 0 0 150px;
    height: fit-content;

    @media ${(props) => props.theme.breakpoint.md} {
      margin: 0;
    }
  `,
  WrapperText: styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    width: 100%;
    flex-direction: column;
    height: fit-content;
  `,
  WrapperSkipBox: styled.div`
    min-height: 30px;
  `,
  SkipBox: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  `,
  Checkbox: styled.div`
    position: relative;
    width: fit-content;
    cursor: pointer;
    div {
      position: absolute;
      top: 2px;
      left: 5px;
    }
  `,
};
