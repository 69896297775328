import { Paths } from '@utils/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import ViewBoardAuth from 'src/components/core/View/ViewBoardAuth';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import CustomInput from 'src/components/custom/input/CustomInput';
import { useFormEmail } from 'src/hooks/formik/useFormEmail';
import ButtonFooterCom from '../components/ButtonFooter.Com';
import ViewResetPassCom from '../components/ViewResetPass.Com';

export interface ForgotEmailWidgetProps {}

const ForgotEmailWidget: React.FC<ForgotEmailWidgetProps> = () => {
  const history = useHistory();

  const { formik, query } = useFormEmail();
  const { isSuccess, isLoading } = query;

  const { t } = useTranslation();

  const renderButton = () => {
    if (isSuccess) {
      return (
        <CustomIconButton
          icon={IMG_CONFIG.BUTTON.OK}
          onClick={() => history.push(Paths.SignIn)}
        />
      );
    } else {
      return (
        <ButtonFooterCom
          onBack={history.goBack}
          onSubmit={formik.handleSubmit}
        />
      );
    }
  };

  function renderTextDes(): any {
    if (isLoading) {
      return t('content.auth.checking');
    }
    if (isSuccess) {
      return t('content.auth.emailHasSent');
    }

    return t('content.auth.typeEmail');
  }

  return (
    <ViewBoardAuth title={t('content.auth.forgotPass')}>
      <ViewResetPassCom des={renderTextDes()} button={renderButton()}>
        <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
          <CustomInput
            type={'email'}
            name="email"
            placeholder="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.errors.email}
          />
        </form>
      </ViewResetPassCom>
    </ViewBoardAuth>
  );
};

export default ForgotEmailWidget;
