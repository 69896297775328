import { Paths } from '@utils/constants';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { queryQuestion } from 'src/api/hooks/queryQuestion';
import { usePositionGame } from 'src/hooks/usePositionGame';
import { MapIdEnum } from 'src/model/stageGame.model';
import { reduxNotification } from 'src/redux/hooks/ReduxNotification';
import { mapPosition } from '../config/position';

export const useGame = () => {
  const { useQueryData: question } = queryQuestion.queryListPoint();
  const numberStar = question?.data?.data.user_star;

  const { data } = question;
  const { useAction } = reduxNotification;
  const { openModal } = useAction();

  const isCamera = data?.data?.current_spot.is_camera;
  // bien
  const spot_type = data?.data?.current_spot?.spot_type ?? 0;

  const spotId = data?.data?.current_spot?.spot_id ?? 0;
  const map_type = data?.data?.current_spot?.map_type;
  const [map, setMap] = useState(mapPosition.map_1);
  const pos = usePositionGame(map, spot_type);
  const { push } = useHistory();
  const onAnswer = () => {
    // push(Paths.Stage_Question + '/' + spotId);

    if (numberStar === 0) return openModal('星が不足しています', '質問に回答できる星が不足しています。１時間ごとに星が一つづつ回復しますので今しばらくお待ちください');

    if (isCamera) {
      return push(Paths.Stage_Paradise, spotId);
    }
    return push(Paths.Stage_Question, spotId);
  };

  useEffect(() => {
    pos.onPos(spot_type ?? 0);
  }, [spot_type]);

  useEffect(() => {
    switch (map_type) {
      case MapIdEnum.MAP_1:
        return setMap(mapPosition.map_1);
      case MapIdEnum.MAP_2:
        return setMap(mapPosition.map_2);
      case MapIdEnum.MAP_3:
        return setMap(mapPosition.map_3);
      case MapIdEnum.MAP_4:
        return setMap(mapPosition.map_4);
      case MapIdEnum.MAP_5:
        return setMap(mapPosition.map_5);
      case MapIdEnum.MAP_6:
        return setMap(mapPosition.map_6);
      case MapIdEnum.MAP_7:
        return setMap(mapPosition.map_7);
      case MapIdEnum.MAP_8:
        return setMap(mapPosition.map_8);
      case MapIdEnum.MAP_9:
        return setMap(mapPosition.map_9);
      case MapIdEnum.MAP_10:
        return setMap(mapPosition.map_10);
      case MapIdEnum.MAP_11:
        return setMap(mapPosition.map_11);
      case MapIdEnum.MAP_12:
        return setMap(mapPosition.map_12);
      default:
        return setMap(mapPosition.map_1);
    }
  }, [map_type]);

  return { pos, onAnswer, question, loading: question.status };
};
