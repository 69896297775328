import { createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface SoundSlideProps {
    is_bgm : any;
    user_id : any;
}



const initialState: SoundSlideProps = {
    is_bgm : 0,
    user_id : null
};

const soundSlide = createSlice({
  name: 'soundSlide',
  initialState,
  reducers: {
    setSound(state, action: PayloadAction<{is_bgm ?: number, user_id ?: number}>): any {
        state.is_bgm = action.payload.is_bgm;
        state.user_id = action.payload.user_id;
      },
  },
});

export const {setSound } = soundSlide.actions;

export default soundSlide.reducer;
