export function convertBase64ToBlob(
  imageData: any,
  formikSet?: any,
  name?: string,
  setState?: any,
) {
  const url = imageData;
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      const file = new File([blob], 'image.png', { type: 'image/png' });
      formikSet(name, file);
    });

  setState(imageData);
}
