import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ViewCustomScroll from 'src/components/custom/views/ViewCustomScroll';
import LayoutDashboard from 'src/layouts/newDashboard';

import { ViewContent } from 'src/components/custom/views/ViewsContent';
import LoadingMoreInfinityItem from 'src/components/custom/item/loading-more-infinity.item';
import { useHistory } from 'react-router-dom';
import ListExchangeHistoryWidget from '../widget/ListExchangeHistory.Widget';
import { queryProduct } from 'src/api/hooks/queryProduct';
import CustomModal from 'src/components/custom/modal/CustomModal';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import { useIsOpen } from 'src/hooks/useIsOpen';
import ShopDetailWidget from '../widget/ShopDetail.Widget';
import {
  ExchangeHistoryDataProps,
} from 'src/model/product.model';

export interface ListExchangeHistoryScreenProps {}

const ListExchangeHistoryScreen: React.FC<
  ListExchangeHistoryScreenProps
> = () => {
  const { data, fetchNextPage, hasNextPage } =
    queryProduct.queryInfinityHistory();

  const [historyItemSelected, setHistoryItemSelected] = useState<ExchangeHistoryDataProps>();

  const { t } = useTranslation();
  const history = useHistory();

  const open = useIsOpen();

  const handleChangeView = () => {
    history.goBack();
  };

  const handleOpenDetailShop = (productHistory: ExchangeHistoryDataProps) => {
    setHistoryItemSelected(productHistory);
    open.onOpen();
  };

  return (
    <LayoutDashboard
      title={t('content.shop.history')}
      isButton
      onGoBackView={handleChangeView}>
      <ViewCustomScroll paddingTop={10} paddingBottom={10}>
        <ViewContent.ListFlex style={{ marginLeft: '2px' }}>
          {data?.pages.map((item, i) => {
            return (
              <ListExchangeHistoryWidget
                key={i}
                data={item.data.purchase.data}
                onOpenDetail={handleOpenDetailShop}
              />
            );
          })}
          {/* loading */}
          <LoadingMoreInfinityItem
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
          />
        </ViewContent.ListFlex>
      </ViewCustomScroll>

      {/* <ListNotificationWidget data={data?.data.notices.data} /> */}
      {/* modal */}
      <CustomModal
        closeIcon={<CustomIconButton icon={IMG_CONFIG.DASHBOARD.CLOSE} />}
        footer={null}
        maskStyle={{ background: 'rgba(0,0,0,.6)' }}
        width={'400px'}
        centered
        wrapClassName="custom-modal-wrapper"
        visible={open.isOpen}
        onCancel={open.onClose}>
        <ShopDetailWidget setOpen={open} historyItemSelected={historyItemSelected}/>
      </CustomModal>
    </LayoutDashboard>
  );
};

export default ListExchangeHistoryScreen;
