import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { HeaderQuestionComProps } from '../components/HeaderQuestion.Com';

export interface QuestionLayoutProps
  extends PropsWithChildren<any>,
    HeaderQuestionComProps,
    StyleProps {
  header?: React.ReactNode;
  button?: React.ReactNode;
  footer?: React.ReactNode;
}

const QuestionLayout: React.FC<QuestionLayoutProps> = ({
  isChoice,
  children,
  header,
  button,
  footer,
}) => {
  return (
    <Style.Wrapper className="hidden_scroll" isChoice={isChoice}>
      <Style.Body>
        <Style.HeaderWrapper>
          {/* header */}
          <Style.Header>{header}</Style.Header>

          {/* main */}
          <Style.Main>{children}</Style.Main>
        </Style.HeaderWrapper>

        {/* button */}

        {/* <Style.Button>{button}</Style.Button> */}

        <Style.FooterWrapper>
          {button}
          {/* footer */}
          <Style.Footer>{footer}</Style.Footer>
        </Style.FooterWrapper>
      </Style.Body>
    </Style.Wrapper>
  );
};

export default QuestionLayout;

interface StyleProps {
  isChoice?: boolean;
}

const Style = {
  Wrapper: styled.div`
    display: ${(p: StyleProps) => (p.isChoice ? 'flex' : 'none')};
    top: 0;
    height: 100%;
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  `,

  HeaderWrapper: styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    overflow-y: auto;
    ::-webkit-scrollbar {
    border-radius: 0;
    width: 4px;
    }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: transparent;
   }

  ::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
   }
  `,

  Body: styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;

    > div {
      padding: 10px;
    }
  `,

  Header: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    /* min-height: 100px; */
    /* height: fit-content; */
    /* overflow-y: auto; */
    min-height: fit-content;
    /* height: 100px; */
    background-color: var(--color-bg-question);
  `,
  Main: styled.div`
    padding: 10px;

    /* height: fit-content; */
    /* overflow-y: auto; */
  `,

  Button: styled.div`
    display: flex;
    justify-content: space-between;

    margin: 10px 10px 20px 10px;
  `,

  FooterWrapper: styled.section`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    min-height: fit-content;
    position: fixed;
    bottom: 0;
  `,

  Footer: styled.div`
    display: flex;
    align-items: center;
    height: 80px !important;
    background-color: var(--color-process);
    width: 100%;
    padding: 10px;
  `,

  End: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
};
