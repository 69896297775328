import { Paths } from '@utils/constants';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import ViewBoardAuth from 'src/components/core/View/ViewBoardAuth';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import { RewardTypeEnum } from 'src/model/stageGame.model';
import styled from 'styled-components';
import { ItemRewardComProps } from '../components/item-reward';
import { getStar } from './ListReward.Widget';

export interface ItemRewardEndQuestionProps extends ItemRewardComProps {}

const ItemRewardEndQuestion: React.FC<ItemRewardEndQuestionProps> = ({
  data,
}) => {
  function renderReward() {
    switch (data?.type) {
      case RewardTypeEnum.COIN:
        return (
          <img
            src={IMG_CONFIG.MINI_GAME.MINIGAME_COIN}
            className={'img_coin'}
          />
        );
      case RewardTypeEnum.POINT:
        return (
          <img src={IMG_CONFIG.MAP_GAME.STAR_LIFE} className={'img_coin'} />
        );
      case RewardTypeEnum.FRAME:
        return <img src={data?.item?.media} style={{ maxHeight: '90%' }} />;
      default:
        return <img src={data?.item?.media} style={{ maxHeight: '50%' }} />;
    }
  }

  function renderMessage() {
    switch (data?.type) {
      case RewardTypeEnum.COIN:
        return 'あなたは300ポイント受け取りました';
      case RewardTypeEnum.POINT:
        return 'あなたは' + data?.number + 'ポイントを獲得しました';
      case RewardTypeEnum.FRAME:
        return null;
      default:
        return data?.item?.name;
    }
  }

  const { push } = useHistory();

  function goHome() {
    push(Paths.Stage);
  }

  return (
    <ViewBoardAuth onCancel={goHome} style={{ margin: '30px 0' }}>
      <Style.TotalWrapper>
        {/* main */}
        <Style.Wrapper>
          {/* star */}
          {getStar(3)}
          {/* title */}
          <h4 className="color_white" style={{ fontSize: '20px' }}>
            クリアテージ報酬
          </h4>
          {/* image */}
          <img src={IMG_CONFIG.MAP_GAME.REWARD_BOX} />
          {/* content */}
          <Style.ContentWrapper>
            {/* title */}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={IMG_CONFIG.MAP_GAME.REWARD_HEART} />
              <p
                style={{ fontSize: '9px', margin: '0 5px' }}
                className={'color_white'}>
                このステージをクリアしたらもらえるよ！
              </p>
              <img src={IMG_CONFIG.MAP_GAME.REWARD_HEART} />
            </div>
            {/* content */}
            {renderReward()}
            {/* footer */}
            {
              <p
                style={{ fontSize: '14px', textAlign: 'center' }}
                className={'color_main'}>
                {renderMessage()}
              </p>
            }
          </Style.ContentWrapper>
        </Style.Wrapper>
        {/* button ok */}
        <CustomIconButton icon={IMG_CONFIG.BUTTON.OK} onClick={goHome} />
      </Style.TotalWrapper>
    </ViewBoardAuth>
  );
};

export default ItemRewardEndQuestion;

const Style = {
  TotalWrapper: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 40;
  `,

  Wrapper: styled.div`
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    justify-items: center;
    align-content: flex-start;
  `,
  ContentWrapper: styled.div`
    .img_coin {
      width: 90px;
    }
    height: 160px;
    width: 100%;
    background: url(${IMG_CONFIG.MAP_GAME.REWARD_BG});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  `,
};
