import {
  ProfileUpdateProps,
  RequestListProps,
  SignUpMoreDataProps,
} from 'src/model';
import { request } from './axios';
import { API_URL } from './config';

class ProfileApi {
  url: {
    LIST_FRAME: string;
    LIST_PET: string;
    UPDATE: string;
    PET_UPDATE: string;
    PET_CHANGE:string;
  };
  constructor() {
    this.url = API_URL.PROFILE;
  }
  getListFrame(params: RequestListProps) {
    return request({
      url: this.url.LIST_FRAME,
      method: 'GET',
      params,
    });
  }
  postSetFrame(id: number) {
    return request({
      url: this.url.LIST_FRAME + '/' + id + '/default',
      method: 'POST',
    });
  }

  getListPet(params: RequestListProps) {
    return request({
      url: this.url.LIST_PET,
      method: 'GET',
      params,
    });
  }
  getPet(id: number) {
    return request({
      url: this.url.LIST_PET + '/' + id + '/info',
      method: 'GET',
    });
  }
  postUpdatePet(dataUpdate: SignUpMoreDataProps, id: number) {
    const data = new FormData();
    data.append('avatar', dataUpdate.avatar);
    data.append('dob', dataUpdate.dob.toString());
    data.append('gender', dataUpdate.gender.toString());
    data.append('type_id', dataUpdate.type_id.toString());
    data.append('name', dataUpdate.name.toString());

    return request({
      url: this.url.PET_UPDATE + '/' + id,
      method: 'POST',
      data,
    });
  }

  changePetParticipant(id:number) {
      return request({
          url : this.url.PET_CHANGE + '/' + id,
          method: 'GET',
      });
  }
  // update user
  postUpdateUser(dataUpdate: ProfileUpdateProps) {
    const data = new FormData();

    data.append('name', dataUpdate.name.toString());
    // data.append('line_id', dataUpdate.line_id.toString());
    // data.append('line_name', dataUpdate.line_name.toString());
    return request({
      url: this.url.UPDATE,
      method: 'POST',
      data,
    });
  }
}

export const profileApi = new ProfileApi();
