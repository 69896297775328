import * as walking from '../json/dog.json';
import * as heartLoading from '../json/108591-heart-loading.json';

export const optionsKittyLoading = {
  loop: true,
  autoplay: true,
  animationData: walking.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const optionsHeartLoading = {
  loop: true,
  autoplay: true,
  animationData: heartLoading.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
