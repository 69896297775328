import { RequestListProps } from 'src/model';
import { request } from './axios';
import { API_URL } from './config';

class NewsAPi {
  getList(params: RequestListProps) {
    return request({
      url: API_URL.NEWS.LIST,
      method: 'GET',
      params,
    });
  }
  getDetail(id: number) {
    return request({
      url: API_URL.NEWS.LIST + '/' + id + '/show',
      method: 'GET',
    });
  }
  getAdvertisement(id: number) {
    return request({
      url: API_URL.NEWS.ADVERTISEMENT,
      method: 'GET',
      params: { section_id: id },
    });
  }

  postMarkReadNew (id : number) {
    return request({
        url : API_URL.NEWS.LIST + '/' + id + '/mark-read',
        method : 'POST'
    });
  }

  postMarkReadSection (id : number) {
    return request({
        url : API_URL.NEWS.ADVERTISEMENTS + '/' + id + '/mark-read',
        method : 'POST'
    });
  }
}

export const newsAPi = new NewsAPi();
