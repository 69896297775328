import React from 'react';
import ViewNoData from 'src/components/custom/views/ViewNoData';
import { TermsDataProps } from 'src/model/dashboard.model';

export interface TermsWidgetProps {}

const TermsWidget: React.FC<TermsDataProps> = ({ content }) => {
  return (
    <>
      {content ? (
        <div dangerouslySetInnerHTML={{ __html: content ?? '' }} />
      ) : (
        <ViewNoData />
      )}
    </>
  );
};

export default TermsWidget;
