import { queryDashboard } from 'src/api/hooks/queryDashboard';
import { TypePetEnum } from 'src/model';
import { TypePetGroupEnum, TypesDataPet } from 'src/model/dashboard.model';

export interface ListPetProps {
  label: string;
  value: number;
  group?: TypePetGroupEnum;
}

export const useGetListTypePet = (typePet?: TypePetEnum) => {
  function getTypeSelectPet(data: TypesDataPet[]) {
    const array: ListPetProps[] = [];
    data.forEach((item) => {
      return array.push({
        label: item.name,
        value: item.id,
        group: item.group,
      });
    });
    return array;
  }
  const { useQueryData } = queryDashboard.queryStart();

  const { data: dataTypesPet } = useQueryData;
  function getList() {
    switch (typePet) {
      case TypePetEnum.DOG:
        return getTypeSelectPet(dataTypesPet?.data.types.dogs ?? []);
      case TypePetEnum.CAT:
        return getTypeSelectPet(dataTypesPet?.data.types.cats ?? []);
      case TypePetEnum.OTHER:
        return getTypeSelectPet(dataTypesPet?.data.types.others ?? []);
      default:
        return getTypeSelectPet(dataTypesPet?.data.types.dogs ?? []);
    }
  }

  return { getList };
};
