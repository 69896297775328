import { Paths } from '@utils/constants';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useWidget } from '../hook/useWidget';
import QuestionLayout from '../layout/Question.Layout';
import { Howl } from 'howler';
import { SOUND_CONFIG } from 'src/assets/config/soundConfig';

export interface NoQuestionWidgetProps {}

const NoQuestionWidget: React.FC<NoQuestionWidgetProps> = () => {
  const { push, t } = useWidget();

  const sound = new Howl({
    src: [SOUND_CONFIG.SOUND_MISS_NO_QUESTION],
    volume: 1
  });

  useEffect(() => {
      setTimeout(() => {
          sound.play();
      }, 500);
  },[]);
  
  return (
    <QuestionLayout
      isChoice
      header={
        <Style.Header>
          <h5 className="color_question big_text">
            {t('content.game.noQuestion')}
          </h5>
        </Style.Header>
      }>
      <Style.Wrapper>
        <Style.Board>
          <h5>{t('content.game.noQuestion')}</h5>
          <Style.Text>
            <p>{t('content.game.noQuestionContent')}</p>
          </Style.Text>
          <Style.Button onClick={() => push(Paths.Stage)}>
            <p>{t('content.game.returnGame')}</p>
          </Style.Button>
        </Style.Board>
      </Style.Wrapper>
    </QuestionLayout>
  );
};

export default NoQuestionWidget;

const Style = {
  Wrapper: styled.div`
    height: max-content;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    margin-top: 200px;

    p {
      color: #fbebe1;
    }
    h5 {
      color: #fbebe1;
    }
  `,
  Header: styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 10px 0;
  `,

  Board: styled.div`
    display: grid;
    padding: 20px;

    flex-direction: column;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 15px;
    align-items: center;
    justify-items: center;
    background: #706863;
    box-shadow: 0px 12px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
  `,

  Text: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      text-align: center;
    }
  `,
  Button: styled.button`
    border: 1.5px solid #fbebe1;
    border-radius: 2000px;
    padding: 8px 23px;
  `,
};
