import React from 'react';
import styled from 'styled-components';

export interface HeaderQuestionComProps {
  index?: number;
  question?: string;
  image?: string;
}

const HeaderQuestionCom: React.FC<HeaderQuestionComProps> = ({
  index,
  question,
  image,
}) => {
  return (
    <Style.Wrapper className="hidden_scroll">
      <Style.Question isImage={image ? true : false}>
        <h5 className="max_title color_question">Q.{index}</h5>
        <p className="cap_text color_question">{question}</p>
      </Style.Question>
      {image && (
        <Style.Img>
          <img src={image} alt={'img'} />
        </Style.Img>
      )}
    </Style.Wrapper>
  );
};

export default HeaderQuestionCom;

interface StyleProps {
  isImage?: boolean;
}

const Style = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    max-height: 100%;
    height: fit-content;

    width: 100%;
    padding: 10px 0;
  `,
  Question: styled.div`
    display: flex;
    flex-direction: column;
    width: ${(p: StyleProps) => (p.isImage ? '70%' : '100%')};
    h5 {
      line-height: 28px;
    }
  `,
  Img: styled.div`
    width: 80px;
    height: 80px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
    }
  `,
};
