import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  DataListResponseProps,
  ResponseProps,
  RestApi,
} from 'src/model/res.model';

import { AUTH_KEY } from '../queries/keys';

import { authApi } from '../axios/auth';
import {
  clearStoredAuth,
  getStoredAuth,
  setStoredAuth,
} from '@utils/helpers/localStorage';
import {
  LoginDataProps,
  LoginProps,
  PetDataProps,
  ProfileDataProps,
  ProfileUpdateProps,
  RequestListProps,
  SignUpDataProps,
  SignUpMoreDataProps,
  TypeActiveEnum,
  SoundDataProps,
} from 'src/model';
import { useHistory, useLocation } from 'react-router-dom';
import { Paths } from '@utils/constants';
import { useState } from 'react';
import { profileApi } from '../axios/profile';

import { reduxNotification } from 'src/redux/hooks/ReduxNotification';
import { fetchToken } from '@utils/firebase/firebase';
import { notificationAPi } from '../axios/notification';
import { useTranslation } from 'react-i18next';
import { FrameDataProps } from 'src/model/frame.model';
import { useReduxLoading } from 'src/redux/hooks/useReduxLoading';
import { reduxSound } from 'src/redux/hooks/ReduxSound';
import { AxiosError } from 'axios';

/**
 * @class QueryNews()
 * @method queryList()
 * @method queryDetail()
 * @returns
 */

class QueryProfile {
  key: { PROFILE: string; LIST_FRAME: string; LIST_PET: string; PET: string };

  constructor() {
    this.key = AUTH_KEY;
  }

  queryProfile() {
    const token = getStoredAuth();
    const { push } = useHistory();
    const { pathname } = useLocation();
    const useQueryData = useQuery<
      ResponseProps<{ profile: ProfileDataProps }>,
      Error
    >([this.key.PROFILE, 'profile', pathname], () => authApi.getProfile(), {
      enabled: !!token,
      refetchOnMount: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      onError: () => {
        clearStoredAuth();
        const password = pathname.slice(0, 9);
        if (password !== '/password') {
          push(Paths.Stage);
        }

        // push(Paths.Stage);
      }
    });
    return { useQueryData };
  }

  queryUserSound() {
    const queryClient = useQueryClient();
    const { useAction } = reduxNotification;
    const { useAction: useActionSound } = reduxSound;
    const { setUserSound } = useActionSound();
    const { t } = useTranslation();
    const { openModalError } = useAction();

    // loading
    const loading = useReduxLoading();

    return useMutation<
      ResponseProps<SoundDataProps>,
      AxiosError,
      { is_bgm: number }
    >((body: { is_bgm: number }) => authApi.postUserSound(body), {
      onMutate() {
        loading.openLoading();
      },
      onSuccess: async (data: ResponseProps<SoundDataProps>) => {
        await queryClient.refetchQueries(this.key.PROFILE, { active: true });
        setUserSound({
          is_bgm: Number(data.data.profile.is_bgm),
          user_id: data.data.profile.id,
        });
        loading.closeLoading();
      },
      onError: (error) => {
        loading.closeLoading();
        openModalError(t('notification.error'), error.message);
      },
    });
  }

  queryLogin() {
    const queryClient = useQueryClient();
    const history = useHistory();
    const { useAction } = reduxNotification;
    const { useAction: useActionSound } = reduxSound;
    const { setUserSound } = useActionSound();
    const { t } = useTranslation();
    const { openModalError } = useAction();

    // loading
    const loading = useReduxLoading();

    return useMutation<ResponseProps<LoginDataProps>, AxiosError, LoginProps>(
      (body: LoginProps) => authApi.postLogin(body),
      {
        onMutate() {
          loading.openLoading();
        },
        onSuccess: async (data: ResponseProps<LoginDataProps>) => {
          setStoredAuth(data.data.access_token);
          await fetchToken(notificationAPi.getDetail);
          await queryClient.refetchQueries(this.key.PROFILE, { active: true });
          loading.closeLoading();
          history.push(Paths.Stage);
          setUserSound({
            is_bgm: data.data.profile.is_bgm,
            user_id: data.data.profile.id,
          });
        },
        onError: (error) => {
          loading.closeLoading();
          openModalError(t('notification.error'), error.message);
        },
      },
    );
  }
  querySignUp() {
    const history = useHistory();
    const queryClient = useQueryClient();
    const { useAction } = reduxNotification;
    const { t } = useTranslation();
    const { openModal, openModalError } = useAction();

    const loading = useReduxLoading();
    return useMutation<
      ResponseProps<LoginDataProps>,
      ResponseProps<RestApi>,
      any,
      any
    >((body: SignUpDataProps) => authApi.postSignUp(body), {
      onMutate() {
        loading.openLoading();
      },
      onSuccess: async (data) => {
        setStoredAuth(data.data.access_token);
        await queryClient.refetchQueries(this.key.PROFILE, { active: true });
        await fetchToken(notificationAPi.getDetail);
        loading.closeLoading();
        openModal(t('notification.success'), data.data.message);
        history.push(Paths.Profile_AddPet);
      },
      onError: (error) => {
        loading.closeLoading();
        openModalError(t('notification.error'), error.message);
      },
    });
  }
  querySignUpMoveHome() {
    const history = useHistory();
    const queryClient = useQueryClient();
    const { useAction } = reduxNotification;
    const { t } = useTranslation();
    const { openModal, openModalError } = useAction();

    const loading = useReduxLoading();
    return useMutation<
      ResponseProps<LoginDataProps>,
      ResponseProps<RestApi>,
      any,
      any
    >((body: SignUpDataProps) => authApi.postSignUp(body), {
      onMutate() {
        loading.openLoading();
      },
      onSuccess: async (data) => {
        setStoredAuth(data.data.access_token);
        await queryClient.refetchQueries(this.key.PROFILE, { active: true });
        await fetchToken(notificationAPi.getDetail);
        loading.closeLoading();
        openModal(t('notification.success'), data.data.message);
        history.push(Paths.Stage);
      },
      onError: (error) => {
        loading.closeLoading();
        openModalError(t('notification.error'), error.message);
      },
    });
  }

  // active
  queryActive() {
    const queryClient = useQueryClient();
    const { useAction } = reduxNotification;

    const { openModalError } = useAction();
    const { t } = useTranslation();

    // loading
    const loading = useReduxLoading();
    return useMutation<
      ResponseProps<RestApi>,
      ResponseProps<RestApi>,
      any,
      any
    >((body: TypeActiveEnum) => authApi.postActiveLive(body), {
      onMutate() {
        loading.openLoading();
      },
      onSuccess: async () => {
        loading.closeLoading();
        await queryClient.refetchQueries(this.key.PROFILE);
        // openModal(t('notification.success'), data.data.message);
      },
      onError: (error) => {
        loading.closeLoading();
        openModalError(t('notification.error'), error.message);
      },
    });
  }

  queryListFrame(queryData?: RequestListProps) {
    const [query, setQuery] = useState<RequestListProps>(
      queryData || {
        page: 1,
      },
    );
    const token = getStoredAuth();
    const useQueryData = useQuery<
      ResponseProps<{
        frames: DataListResponseProps<FrameDataProps>;
        current_frame: FrameDataProps;
      }>,
      Error
    >([this.key.LIST_FRAME, query], () => profileApi.getListFrame(query), {
      enabled: !!token,
      refetchOnMount: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
    });
    return { query, setQuery, useQueryData };
  }

  // querySetFrame
  querySetFrame() {
    const queryClient = useQueryClient();
    const { useAction } = reduxNotification;
    // loading
    const loading = useReduxLoading();
    const { openModal, openModalError } = useAction();
    const { t } = useTranslation();
    return useMutation<
      ResponseProps<RestApi>,
      ResponseProps<RestApi>,
      any,
      any
    >((body: number) => profileApi.postSetFrame(body), {
      onMutate() {
        loading.openLoading();
      },
      onSuccess: async (data) => {
        loading.closeLoading();
        await queryClient.refetchQueries(this.key.PROFILE);
        openModal(t('notification.success'), data.data.message);
      },
      onError: (error) => {
        loading.closeLoading();
        openModalError(t('notification.error'), error.message);
      },
    });
  }

  queryListPet(queryData?: RequestListProps) {
    const [query, setQuery] = useState<RequestListProps>(
      queryData || {
        page: 1,
      },
    );
    const token = getStoredAuth();
    const useQueryData = useQuery<
      ResponseProps<{
        pets: PetDataProps[];
      }>,
      Error
    >([this.key.LIST_PET, query], () => profileApi.getListPet(query), {
      enabled: !!token,
      refetchOnMount: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
    });
    return { query, setQuery, useQueryData };
  }

  // get infor pet

  queryPet(id: number) {
    const [query, setQuery] = useState<number>(id);
    const token = getStoredAuth();
    const useQueryData = useQuery<
      ResponseProps<{
        pet: PetDataProps;
      }>,
      Error
    >([this.key.PET, query], () => profileApi.getPet(query), {
      enabled: !!token,
      refetchOnMount: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
    });
    return { query, setQuery, useQueryData };
  }
  // get infor pet
  //query change pet participants

  queryChangeParticipant() {
    const { useAction } = reduxNotification;
    // loading
    const loading = useReduxLoading();
    const history = useHistory();
    const { t } = useTranslation();
    const { openModalError } = useAction();
    const queryClient = useQueryClient();
    return useMutation<ResponseProps<LoginDataProps>, any, any>(
      (id: number) => profileApi.changePetParticipant(id),
      {
        onMutate() {
          loading.openLoading();
        },
        onSuccess: async () => {
          await queryClient.refetchQueries(this.key.PROFILE);
          loading.closeLoading();
          history.push(Paths.Stage);
        },
        onError: (error) => {
          loading.closeLoading();
          openModalError(t('notification.error'), error.message);
        },
      },
    );
  }

  querySignUpMore() {
    // loading
    const loading = useReduxLoading();
    const queryClient = useQueryClient();
    const { useAction } = reduxNotification;
    const { t } = useTranslation();
    const { openModal, openModalError } = useAction();
    const history = useHistory();
    return useMutation<ResponseProps<any>, ResponseProps<any>, any, any>(
      (body: SignUpMoreDataProps) => authApi.postSignUpMore(body),
      {
        onMutate() {
          loading.openLoading();
        },
        onSuccess: async (data) => {
          await queryClient.refetchQueries(this.key.LIST_PET);
          queryClient.refetchQueries(this.key.PROFILE);
          loading.closeLoading();
          history.push(Paths.Profile_ListPet);
          openModal(t('notification.success'), data.data.message);
        },
        onError: (error) => {
          loading.closeLoading();
          openModalError(t('notification.error'), error.message);
        },
      },
    );
  }
  // update
  queryUpdatePet(id: number) {
    // loading
    const loading = useReduxLoading();
    const queryClient = useQueryClient();
    const history = useHistory();
    const { useAction } = reduxNotification;
    const { t } = useTranslation();
    const { openModal, openModalError } = useAction();
    return useMutation<ResponseProps<any>, ResponseProps<any>, any, any>(
      (body: SignUpMoreDataProps) => profileApi.postUpdatePet(body, id),
      {
        onMutate() {
          loading.openLoading();
        },
        onSuccess: async (data) => {
          await queryClient.refetchQueries(this.key.PROFILE);
          await queryClient.refetchQueries(this.key.LIST_PET);
          queryClient.refetchQueries(this.key.PET);
          loading.closeLoading();
          history.push(Paths.Profile_ListPet);
          openModal(t('notification.success'), data.data.message);
        },
        onError: (error) => {
          loading.closeLoading();
          openModalError(t('notification.error'), error.message);
        },
      },
    );
  }

  // update user
  queryUpdateUser() {
    // loading
    const loading = useReduxLoading();
    const queryClient = useQueryClient();
    const history = useHistory();
    const { useAction } = reduxNotification;
    const { t } = useTranslation();
    const { openModal, openModalError } = useAction();
    return useMutation<ResponseProps<any>, ResponseProps<any>, any, any>(
      (body: ProfileUpdateProps) => profileApi.postUpdateUser(body),
      {
        onMutate() {
          loading.openLoading();
        },
        onSuccess: async (data) => {
          loading.closeLoading();
          await queryClient.refetchQueries(this.key.PROFILE);
          queryClient.refetchQueries(this.key.LIST_PET);
          openModal(t('notification.success'), data.data.message);
          history.push(Paths.Profile);
        },
        onError: (error: any) => {
          loading.closeLoading();
          openModalError(t('notification.error'), error.message);
        },
      },
    );
  }
}

export const queryProfile = new QueryProfile();
