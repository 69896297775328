import { saveAs } from 'file-saver';

export function downloadImage(file: any, fileName: string) {
  const url = URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  if (navigator.userAgent.match(/iPad|iPhone|iPod/)) {
    setTimeout(() => {
      saveAs(file, fileName);
    }, 4000);
  } else {
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  URL.revokeObjectURL(url);
}
