import { useMutation, useQuery, useQueryClient } from 'react-query';
import { RequestListProps } from 'src/model';
import { useState } from 'react';
import {
  DataListResponseProps,
  ResponseProps,
  RestApi,
} from 'src/model/res.model';

import { AUTH_KEY, FRAME_KEY } from '../queries/keys';

import { frameApi } from '../axios/frame';
import { reduxNotification } from 'src/redux/hooks/ReduxNotification';
import { useTranslation } from 'react-i18next';
import { FrameDataProps } from 'src/model/frame.model';
import { useReduxLoading } from 'src/redux/hooks/useReduxLoading';

/**
 * @class QueryNews()
 * @method queryList()
 * @method queryDetail()
 * @returns
 */

class QueryFrame {
  key: { LIST: string; FRAME: string };

  constructor() {
    this.key = FRAME_KEY;
  }

  queryList(queryData?: RequestListProps) {
    const [query, setQuery] = useState<RequestListProps>(
      queryData || {
        page: 1,
      },
    );
    const useQueryData = useQuery<
      ResponseProps<{
        current_frame: FrameDataProps;
        frames: DataListResponseProps<FrameDataProps>;
      }>,
      Error
    >([this.key.LIST, query], () => frameApi.getList(query), {
      // enabled: !!query.type,
      refetchOnMount: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
    });
    return { query, setQuery, useQueryData };
  }

  queryDefault() {
    const useQueryData = useQuery<
      ResponseProps<{
        frames: FrameDataProps[];
      }>,
      Error
    >('FRAME_DEFAULT', () => frameApi.getDefault(), {
      // enabled: !!query.type,
      refetchOnMount: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
    });
    return { useQueryData };
  }

  querySet(id: number) {
    // loading
    const loading = useReduxLoading();
    const queryClient = useQueryClient();
    const { useAction } = reduxNotification;
    const { t } = useTranslation();

    const { openModalError } = useAction();

    return useMutation<
      ResponseProps<RestApi>,
      ResponseProps<RestApi>,
      any,
      any
    >(() => frameApi.postSet(id), {
      onMutate() {
        loading.openLoading();
      },
      onSuccess: async () => {
        await queryClient.refetchQueries(AUTH_KEY.PROFILE);
        loading.closeLoading();
        // openModal(t('notification.success'), data.data.message);
      },
      onError: (error) => {
        loading.closeLoading();
        openModalError(t('notification.error'), error.message);
        // notification.error({ message: error.message || 'Create failure!' });
      },
    });
  }
}

export const queryFrame = new QueryFrame();
