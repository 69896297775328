import React from 'react';
import { useQueryMiniGame } from 'src/api/hooks/useQueryMiniGame';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import {
  ItemDataMiniGame,
  TypeItemMiniGameEnum,
} from 'src/model/miniGame.model';
import styled from 'styled-components';

export interface RollBoardComProps extends StyleProps {}

const RollBoardCom: React.FC<RollBoardComProps> = ({ isRo, deg }) => {
  const { queryListItem } = useQueryMiniGame();

  const { data } = queryListItem();

  const list = data?.data.game.items;
  return (
    <Style.Wrapper>
      {list?.length === 12 ? (
        <Style.PointBg>
          <img src={IMG_CONFIG.MINI_GAME.MINIGAME_KIM_BG} />
        </Style.PointBg>
      ) : (
        <Style.PointBg eight>
          <img src={IMG_CONFIG.MINI_GAME.MINIGAME_KIM_BG} />
        </Style.PointBg>
      )}

      {list?.length === 12 ? (
        <Style.Point>
          <img src={IMG_CONFIG.MINI_GAME.MINIGAME_KIM} />
        </Style.Point>
      ) : (
        <Style.Point eight>
          <img src={IMG_CONFIG.MINI_GAME.MINIGAME_KIM} />
        </Style.Point>
      )}

      <Style.BoardRoll isRo={isRo} deg={deg}>
        {list?.length === 12 ? (
          <img src={IMG_CONFIG.MINI_GAME.MINIGAME_BOARD}
          style={{ marginTop: '0px' }}
           />
        ) : (
          <img
            src={IMG_CONFIG.MINI_GAME.MINIGAME_BOARD2}
            style={{ marginTop: '-12px' }}
          />
        )}

        {list?.map((item, i) => {
          return (
            <>
              {list?.length === 12 ? (
                <Style.Value deg={(i + 3) * 30} key={i} twelve>
                  <ItemReward data={item} isOld={i % 2 === 1} list={list}/>
                </Style.Value>
              ) : (
                <Style.Value deg={(i + 2) * 45} key={i}>
                  <ItemReward data={item} isOld={i % 2 !== 1} list={list}/>
                </Style.Value>
              )}
            </>
          );
        })}
      </Style.BoardRoll>
    </Style.Wrapper>
  );
};

export default RollBoardCom;

const ItemReward = ({
  data,
  isOld,
  list
}: {
  data: ItemDataMiniGame;
  isOld: boolean;
  list: ItemDataMiniGame[] | undefined;
}) => {
  return (
    <Style.WrapperItem isOld={isOld} twelve>
      {/* coin number */}
      {data.type !== TypeItemMiniGameEnum.COIN
        ? titleRender({ data, isOld })
        : null}

      {/* null */}
      {data.type === TypeItemMiniGameEnum.NULL ? (
        <img
          src={IMG_CONFIG.MINI_GAME.MINIGAME_NULL}
          style={{ width: '22px' }}
        />
      ) : null}

      {/* image */}
      {data.type === TypeItemMiniGameEnum.PRODUCT ||
      data.type === TypeItemMiniGameEnum.FRAME ? (
        <img
          src={data.item?.media}
          style={{ width: '19px', marginRight: '3px' }}
        />
      ) : null}

      {/* coin number */}
      {data.type === TypeItemMiniGameEnum.COIN ? (
        <p
          className="small_text"
          style={{
            fontSize: '12px',
            marginTop: list?.length === 12 ? '0px' : '0px'
          }}
        >
          {data.coin}
        </p>
      ) : null}


      {/* coin */}
      {data.type === TypeItemMiniGameEnum.COIN ? (
        <img
          src={IMG_CONFIG.MINI_GAME.MINIGAME_COIN}
          style={{ 
          width: '19px',
          marginTop: list?.length === 12 ? '0px' : '4px',
          marginRight: list?.length === 12 ? '0px' : '4px',
        }}
        />
      ) : null}
    </Style.WrapperItem>
  );
};

const titleRender = ({
  data,
  isOld,
}: {
  data: ItemDataMiniGame;
  isOld: boolean;
}) => {
  switch (data.type) {
    case TypeItemMiniGameEnum.FRAME:
      return (
        <img
          src={
            isOld
              ? IMG_CONFIG.MINI_GAME.MINIGAME_FRAME_BLUE_TEXT
              : IMG_CONFIG.MINI_GAME.MINIGAME_FRAME_WHITE_TEXT
          }
          style={{ transform: 'rotateZ(-60deg)', width: '23px', marginTop:'-3px', marginRight:'3px' }}
        />
      );
    case TypeItemMiniGameEnum.PRODUCT:
      return (
        <img
          src={
            isOld
              ? IMG_CONFIG.MINI_GAME.MINIGAME_PICTURE_BLUE_TEXT
              : IMG_CONFIG.MINI_GAME.MINIGAME_PICTURE_WHITE_TEXT
          }
          style={{ transform: 'rotateZ(32deg)', width: '34px', marginTop:'-3px' }}
        />
      );
    case TypeItemMiniGameEnum.NULL:
      return (
        <img
          src={
            isOld
              ? IMG_CONFIG.MINI_GAME.MINIGAME_NOTHING_BLUE_TEXT
              : IMG_CONFIG.MINI_GAME.MINIGAME_NOTHING_WHITE_TEXT
          }
          style={{ transform: 'rotateZ(60deg)', width: '20px' }}
        />
      );
    default:
      return null;
  }
};
interface StyleProps {
  twelve?: boolean;
  deg?: number;
  isRo?: boolean;
  isOld?: boolean;
  eight?: boolean;
}

const Style = {
  Wrapper: styled.div`
    margin-top: 27px;
    padding-top: 10px;
    width: 100%;
    position: fixed;

    display: flex;
    justify-content: center;
    transform: scale(1.5);
  `,

  PointBg: styled.div`
    position: absolute;
    top: ${(p: StyleProps) => (p.eight ? '29px' : '27px')};
    display: flex;
    justify-content: center;
  `,

  Point: styled.div`
    position: absolute;
    top: ${(p: StyleProps) => (p.eight ? '30px' : '29px')};
    z-index: 21;
    display: flex;
    justify-content: center;
  `,

  BoardRoll: styled.div`
    top: 30px;
    position: relative;
    z-index: 20;
    transform-origin: 50% 50% 0;
    -webkit-transition: transform 5.8s 1s ease;
    -moz-transition: transform 5.8s 1s ease;
    -o-transition: transform 5.8s 1s ease;
    transition: transform 5.8s 1s ease;

    transform: ${(p: StyleProps) => `rotate(${p.deg}deg)`};
    .imgBoardRoll {
      /* width: 210px; */
    }
  `,

  Value: styled.div`
    position: absolute;
    z-index: 22;
    top: ${(p: StyleProps) => (p.twelve ? '44%' : '43%')};
    padding: ${(p: StyleProps) => (p.twelve ? 'unset' : '0 6px')};
    right: ${(p: StyleProps) => (p.twelve ? '9%' : '10%')};
    display: flex;
    justify-content: flex-end;
    width: ${(p: StyleProps) => (p.twelve ? '82%' : '80%')};
    transform-origin: 50% 50% 0;
    transform: ${(p: StyleProps) => `rotate(-${p.deg}deg)`};
  `,

  WrapperItem: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3px;
    transform: ${(p: StyleProps) => (p.twelve ? 'scale(1.1)' : 'scale(1.2)')};
    margin-top: ${(p: StyleProps) => (p.twelve ? '0px' : '3px')};
    p {
      line-height: 25px !important;
      overflow: hidden;
      max-width: 26px;
      font-weight: bold;
      color: ${(p: StyleProps) => (p.isOld ? '#1f17b2' : 'white')};
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  `,
};
