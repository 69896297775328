import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Howl } from 'howler';
import { queryQuestion } from 'src/api/hooks/queryQuestion';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomModal from 'src/components/custom/modal/CustomModal';
import { useIsOpen } from 'src/hooks/useIsOpen';
import MainLayout from 'src/layouts/MainLayout';
import { reduxAnswer } from 'src/redux/hooks/ReduxAnswer';
import { reduxNotification } from 'src/redux/hooks/ReduxNotification';
import styled from 'styled-components';
import EndQuestionWidget from '../widget/EndQuestion.Widget';

import ListQuestionWidget from '../widget/ListQuestion.Widget';
import ModalConfirmQWidget from '../widget/ModalConfirmQ.Widget';
import NoQuestionWidget from '../widget/NoQuestion.Widget';
import { SOUND_CONFIG } from 'src/assets/config/soundConfig';

export interface QuestionStageGameScreenProps {}

const QuestionStageGameScreen: React.FC<QuestionStageGameScreenProps> = () => {
  const { state } = useLocation<number>();

  // const { id } = useParams<any>();
  const { useQueryData } = queryQuestion.queryQuestion(state);
  const { data } = useQueryData;
  const { useAction, useCheck } = reduxAnswer;
  const { useAction: notification } = reduxNotification;

  const { openModalError } = notification();

  const check = useCheck();
  const open = useIsOpen();

  const end = useIsOpen();

  const { queryAnswer } = queryQuestion;
  const { mutate, isSuccess, data: point } = queryAnswer();

  const { setIdSpot, setFirthAnswer } = useAction();

  const sound = new Howl({
    src: [SOUND_CONFIG.SOUND_POINT_QUESTION],
    volume: 1,
  });

  useEffect(() => {
    setIdSpot(data?.data.survey_id || 0);
    setFirthAnswer(data?.data.questions || []);
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        sound.play();
      }, 800);

      end.onOpen();
      open.onClose();
      
    }
  }, [isSuccess]);

  function handleAnswer() {
    open.onClose();
    if (check) {
      openModalError('error', 'please select all quetion');
      open.onClose();
    } else {
      mutate();
    }
  }

  return (
    <MainLayout screen={IMG_CONFIG.BACKGROUND.QUESTION} hiddenSetting>
      <Style.Wrapper>
        {end.isOpen ? (
          <EndQuestionWidget point={point?.data.result} />
        ) : (
          <ListQuestionWidget
            data={data?.data.questions || []}
            onEnd={open.onOpen}
          />
        )}
        {data?.data.questions.length === 0 ? <NoQuestionWidget /> : null}
      </Style.Wrapper>

      {/* modal */}

      <CustomModal
        closeIcon={<div />}
        visible={open.isOpen}
        onCancel={open.onClose}
        style={{ top: '100px' }}>
        <ModalConfirmQWidget
          title="確認"
          onConfirm={handleAnswer}
          onCancel={open.onClose}
        />
      </CustomModal>
    </MainLayout>
  );
};

export default QuestionStageGameScreen;

const Style = {
  Wrapper: styled.div`
    height: 100vh;
    width: 100%;
    min-height: 800px;
  `,
};
