import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { queryProfile } from 'src/api/hooks/queryProfile';
import { useTranslation } from 'react-i18next';
import { useInputUpload } from '../useInputUpload';
import { useState } from 'react';
import { convertBase64ToBlob } from '@utils/support/covert-base64-to-blob';

export const useFormSignUpMore = () => {
  const query = queryProfile.querySignUpMore();
  const { t } = useTranslation();
  const upload = useInputUpload();

  const [image, setImage] = useState<any>(null);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('error.name.required'))
      .max(100, t('error.name.max')),
    avatar: Yup.mixed().required(t('error.image.required')),
    gender: Yup.number().required(t('error.gender.required')).nullable(),
    dob: Yup.string().required(t('error.dob.required')).nullable(),
    type_id: Yup.number().required(t('error.typeId.required')).nullable(),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      avatar: undefined,
      gender: '',
      dob: null,
      type_id: '',
    },
    validationSchema,
    onSubmit: (values) => {
      query.mutate({...values, dob : moment(values?.dob, 'YYYY-MM-DD').format('YYYY-MM-DD').toString()});
    },
  });

  const formikChangeImage = (imageData: any) => {
    convertBase64ToBlob(imageData, formik.setFieldValue, 'avatar', setImage);
  };

  return { formik, query, upload, formikChangeImage, image };
};
