import React from 'react';
import { useTranslation } from 'react-i18next';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import { useIndex } from 'src/hooks/useIndex';
import MainLayout from 'src/layouts/MainLayout';
import { TypePetEnum } from 'src/model';
import LayoutRegisterCom from '../components/LayoutRegister.Com';
import { configData } from '../config';
import AddPetMoreWidget from '../widget/AddPetMore.Widget';
import SuccessRegisterWidget from '../widget/SuccessRegister.Widget';
import TypePetRegisterWidget from '../widget/TypePetRegister.Widget';

export interface RegisterMoreScreenProps {}

const RegisterMoreScreen: React.FC<RegisterMoreScreenProps> = () => {
  const nav = useIndex(2);
  const [typePet, setTypePet] = React.useState<TypePetEnum>(TypePetEnum.DOG);

  function renderScreen(): any {
    switch (nav.index) {
      case 0:
        return (
          <TypePetRegisterWidget
            typePet={typePet}
            onChange={setTypePet}
            onClick={() => nav.onIndex(1)}
          />
        );
      case 1:
        return (
          <AddPetMoreWidget
            typePet={typePet}
            onBack={nav.onPre}
            onNext={nav.onNext}
          />
        );
      case 2:
        return <SuccessRegisterWidget />;
    }
  }
  const { t } = useTranslation();

  return (
    <MainLayout
      screen={IMG_CONFIG.BACKGROUND.MAIN}
      minHeight={configData.minHeight}>
      <LayoutRegisterCom title={t('content.register.addMore')}>
        {/* <AddPetMoreWidget /> */}
        {renderScreen()}
      </LayoutRegisterCom>
    </MainLayout>
  );
};

export default RegisterMoreScreen;
