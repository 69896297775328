import React, { useEffect, useState } from 'react';
import { useIsOpen } from 'src/hooks/useIsOpen';
import styled from 'styled-components';
import { Divider, Modal } from 'antd';
import CustomInput, { OptionsProps } from './CustomInput';
import { ArrowDownIcon } from '@icons';
import CustomIconButton from '../button/CustomIconButton';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';

export interface CustomBoxSearchProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  name?: any;
  error?: string;
  options?: OptionsProps[];
  onChange?: any;
  registerEvent?: boolean;
  placeholder?: any;
  title?: string;
}
const CustomBoxSearch: React.FC<CustomBoxSearchProps> = (props) => {
  const open = useIsOpen();
  const [value, setValue] = useState(props.defaultValue);
  const [label, setLabel] = useState('');
  const [name, setName] = useState('');
  const handleSelect = (valueData: any) => {
    setValue(valueData);
    props.onChange(props.name, valueData);
    props.onChange('name', undefined);
    open.onClose();
  };

  useEffect(() => {
    const result = props.options?.find((word) => word.value === value);
    setLabel(result?.label ?? '');
  }, [value]);

  const onClickButton = () => {
    setLabel(name);
    props.onChange('name', name);
    open.onClose();
    setName('');
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  return (
    <Style.InputWrapper>
      <Style.Wrapper>
        <Style.Inner onClick={open.onOpen}>
          <p className="color_main">{label || props.placeholder}</p>
          <div style={{ transform: open.isOpen ? 'rotate(180deg)' : 'none' }}>
            <ArrowDownIcon />
          </div>
        </Style.Inner>

        <Style.ModalAntd
          maskStyle={{ background: 'rgba(0,0,0,.6)' }}
          footer={null}
          centered
          visible={open.isOpen}
          wrapClassName="custom-modal-wrapper"
          onCancel={open.onClose}>
          <Style.SelectWrapper>
            <h5 className="color_main medium_text" style={{ fontSize: '16px' }}>
              {props.title}
            </h5>
            {props.registerEvent && (
              <div style={{ width : '100%'}}>
                <p className="color_main">
                    名前を入力するか、下から名前を選択します。
                </p>
                <div className="add-new">
                  <CustomInput
                    type={'name'}
                    name={'name'}
                    value={name}
                    placeholder={'名前を入力'}
                    onChange={handleChangeName}
                    backGroundColor='#e9d1a9'
                  />
                  <CustomIconButton
                    icon={IMG_CONFIG.RANKING.RANKING_ARROW_RIGHT}
                    imgHeight="34px"
                    onClick={onClickButton}
                    className='icon-arrow'
                  />
                </div>
              </div>
            )}
            <div
              className="inner_select hidden_scroll"
              style={{ marginTop: '10px' }}>
              {props.options?.map((item, i) => {
                return (
                  <ItemSelect
                    onClick={() => handleSelect(item.value)}
                    label={item.label}
                    index={i + 1}
                    key={i}
                  />
                );
              })}
            </div>
          </Style.SelectWrapper>
        </Style.ModalAntd>
      </Style.Wrapper>
      {props.error && <p className="small_text color_error">{props.error}</p>}
    </Style.InputWrapper>
  );
};

export const ItemSelect: React.FC<{
  label?: string;
  index?: number;
  onClick: any;
  hidden?: boolean;
}> = (props) => {
  return (
    <Style.ItemSelect onClick={props.onClick}>
      <div className="content_wrapper">
        <p className="index_wrapper">{props.index}.</p>
        <p>{props.label}</p>
      </div>
      <Divider style={{ margin: 0 }} />
    </Style.ItemSelect>
  );
};

export default CustomBoxSearch;

interface StyleProps {
  isSelect?: boolean;
}

const Style = {
  InputWrapper: styled.div`
    display: grid;

    grid-template-columns: repeat(1, 1fr);
    grid-gap: 5px;
    width: 100%;
  `,
  Wrapper: styled.div`
    position: relative;
    cursor: pointer;

    width: 100%;
    height: 40px;

    border-radius: 20px;
    padding: 0 12px;
    background-color: #fadeb1;
    -webkit-box-shadow: 0px 6px 0px 0px rgba(0, 0, 0, 0.25);

    box-shadow: 0px 6px 0px 0px rgba(0, 0, 0, 0.25);
  `,
  Inner: styled.div`
    /* position: absolute; */
    left: 15px;
    top: 3px;
    width: 230px;
    min-height: 25px;
    font-size: 16px;
    display: flex;
    line-height: 40px;
    justify-content: space-between;
    align-items: center;
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `,

  ItemSelect: styled.div`
    display: block;
    align-items: flex-end;
    justify-content: flex-start;
    cursor: pointer;
    padding: 0 10px;
    border-radius: 10px;
    /* height: 100%; */
    font-size: 16px;

    ${(props: StyleProps) =>
      props.isSelect && ' background-color: var(--color-main);'}

    .content_wrapper {
      border-bottom: solid 2px var(--color-main);
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      padding-bottom: 10px;
      padding-top: 5px;
    }
    .index_wrapper {
      min-width: 50px;
    }
  `,

  SelectWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    min-height: 350px;
    max-height: 430px;

    .inner_select {
      overflow-y: scroll;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: 100%;
      overflow-y: scroll;
    }

    .add-new {
      display: flex;
      gap: 14px;
      align-items: center;
      justify-content: center;
      padding: 0 30px 0 0;
      margin-top: 12px;
    }
  `,

  ModalAntd: styled(Modal)`
    .ant-modal-body {
      padding: 10px !important;
      height: fit-content;
      min-height: 350px;
      max-height: 450px;
    }

    .ant-modal-content {
      border-radius: 10px;
      background-color: #fadeb1;
      border: 1px solid rgba(131, 62, 41, 0.302);
      height: 100%;
      overflow-y: hidden;
    }

    .ant-modal-close-x {
      width: fit-content;
      padding-right: 15px;
      transform: translateY(-5px);
    }
  `,
};
