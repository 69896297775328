import { Paths } from '@utils/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import styled from 'styled-components';

export interface SuccessRegisterWidgetProps {}

const SuccessRegisterWidget: React.FC<SuccessRegisterWidgetProps> = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <Style.Wrapper>
      <p className="medium_text color_white">
        {t('content.register.successRegister')}
      </p>
      <CustomIconButton
        icon={IMG_CONFIG.BUTTON.OK}
        onClick={() => {
          history.push(Paths.SignIn);
        }}
      />
    </Style.Wrapper>
  );
};

export default SuccessRegisterWidget;

const Style = {
  Wrapper: styled.div`
    display: grid;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    justify-items: center;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  `,
};
