export enum TypeItemMiniGameEnum {
  COIN = 1,
  NULL = 2,
  PRODUCT = 3,
  FRAME = 4,
}

export interface ItemMiniGame {
  id: number;
  name: string;
  price: number;
  number: number;
  media: string;
}

export interface ItemDataMiniGame {
  id: number;
  game_id: number;
  title: string;
  coin: number;
  type: TypeItemMiniGameEnum;
  item?: ItemMiniGame;
}

export interface GameDataMiniGame {
  id: number;
  name: string;
  items: ItemDataMiniGame[];
}

export interface ListDataMiniGame {
  number_of_plays: number;
  game: GameDataMiniGame;
}

export interface ItemTextPopup {
   header : string;
   title : string;
   main_text : string;
   key : string;
}

export interface ListItemTextPopup {
  end_of_turns : ItemTextPopup;
  nothing : ItemTextPopup;
  frame : ItemTextPopup;
  product : ItemTextPopup;
  coin : ItemTextPopup;
}

export interface ResultSpinType {
  coin: number;
  game_id: number;
  id: number;
  item?: ItemMiniGame;
  title: string;
  type: TypeItemMiniGameEnum;
}

export interface ResultSpinData {
  number_of_plays: number;
  result: ResultSpinType;
}
