import { queryProfile } from 'src/api/hooks/queryProfile';
import { OptionsProps } from 'src/components/custom';
import { PetDataProps } from 'src/model';

export const useGetListPet = () => {
  const { useQueryData } = queryProfile.queryListPet();
  const { data } = useQueryData;

  function getListPets(data: PetDataProps[]) {
    const array: OptionsProps[] = [];
    data.forEach((item) => {
      return array.push({
        label: item.name,
        value: item.id,
      });
    });
    return array;
  }
  function getList() {
    return getListPets(data?.data.pets ?? []);
  }

  return { getList };
};
