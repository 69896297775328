import axios from 'axios';
import { ResponseCode } from '../constants';
import type { BaseResponse } from '../types';
import get from 'lodash/get';
import { getStoredAuth } from '@utils/helpers/localStorage';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || '',
});

axiosInstance.interceptors.request.use(
  (config: any) => {
    // const token = get(cookies.get(), TOKEN_AUTHENTICATION, false);

    // const protectedKey = get(cookies.get(), PROTECTED_KEY, false);
    const token = getStoredAuth();

    if (token) {
      config.headers.Authorization = `${token}`;
    }
    // if (protectedKey) {
    //   config.headers['protected-key'] = protectedKey;
    // }
    return config;
  },
  (error) => {
    // Do something with request error
    // console.log(error);

    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause
    // this function to trigger Do something with response data
    return response;
  },
  (err: { response: { data: BaseResponse } }) => {
    // Any status codes that falls outside the range of 2xx
    // cause this function to trigger Do something with
    // response error

    // console.log(err);

    const data = err.response?.data || {};
    // @ts-ignore
    data.message = get(ResponseCode, data.code, 'Default error message');
    return Promise.reject(data);
  },
);

export default axiosInstance;
