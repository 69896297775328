import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { checkMultiAnswer } from '@utils/game/checkMultiAnswer';
import {
  AnswerDataReqProps,
  AnswerMultiReqProps,
  AnswersDataReqProps,
  AnswerTextReqProps,
  QueryQuestionProps,
  QuestionDataProps,
  SkipReqProp,
} from 'src/model/stageGame.model';

const initialState: AnswersDataReqProps = {
  survey_id: 0,
  answers: [],
};

const answersSlide = createSlice({
  name: 'answersSlide',
  initialState,
  reducers: {
    setSpotId(state, action: PayloadAction<QueryQuestionProps>): any {
      state.survey_id = action.payload.spot_id;
    },
    setFirthAnswer(state, action: PayloadAction<QuestionDataProps[]>) {
      const answers: any = [];
      const { payload } = action;

      payload.forEach((item) => {
        const answer = {
          question_id: item.id,
          answer: [],
          max_number_of_answers: item.max_number_of_answers,
          skip: false,
        };
        answers.push(answer);
      });
      state.answers = answers;
    },

    changeAnswerMulti(state, action: PayloadAction<AnswerMultiReqProps>) {
      const { payload } = action;

      const itemIndex = state.answers.findIndex(
        (p) => p.question_id === payload.question_id,
      );

      if (itemIndex > -1) {
        state.answers[itemIndex].answer = checkMultiAnswer(
          state.answers[itemIndex].answer,
          payload.answer_id,
          state.answers[itemIndex].max_number_of_answers,
        );
      }
    },
    changeSkip(state, action: PayloadAction<SkipReqProp>) {
      const { payload } = action;
      const itemIndex = state.answers.findIndex(
        (p) => p.question_id === payload.question_id,
      );
      if (itemIndex > -1) {
        state.answers[itemIndex].skip = payload.skip;
      }
    },

    changeAnswerText(state, action: PayloadAction<AnswerMultiReqProps>) {
      const { payload } = action;

      const itemIndex = state.answers.findIndex(
        (p) => p.question_id === payload.question_id,
      );
      if (itemIndex > -1) {
        state.answers[itemIndex].answer = checkMultiAnswer(
          state.answers[itemIndex].answer,
          payload.answer_id,
          state.answers[itemIndex].max_number_of_answers,
          true,
        );
      }
    },

    typeAnswerText(state, action: PayloadAction<AnswerTextReqProps>) {
      const { payload } = action;
      const itemIndex = state.answers.findIndex(
        (p) => p.question_id === payload.question_id,
      );
      if (itemIndex > -1) {
        const itemIndex2 = state.answers[itemIndex].answer.findIndex(
          (p) => p.answer_id === payload.answer_id,
        );
        if (itemIndex2 > -1) {
          state.answers[itemIndex].answer[itemIndex2].answer_text =
            payload.answer_text;
        }
      }
    },

    changeAnswer(state, action: PayloadAction<AnswerDataReqProps>) {
      const { payload } = action;
      const itemIndex = state.answers.findIndex(
        (p) => p.question_id === payload.question_id,
      );
      if (itemIndex > -1) {
        state.answers[itemIndex] = payload;
      }
    },
  },
});

export const {
  setSpotId,
  setFirthAnswer,
  changeAnswer,
  changeAnswerMulti,
  changeSkip,
  changeAnswerText,
  typeAnswerText,
} = answersSlide.actions;

export default answersSlide.reducer;
