import React from 'react';
import { useHistory } from 'react-router-dom';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import LayoutDashboard from 'src/layouts/newDashboard';
import ListEventWidget from '../widget/ListEvent.Widget';
import { queryEvent } from 'src/api/hooks/queryEvent';
import ViewCustomScroll from 'src/components/custom/views/ViewCustomScroll';
import { Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { ViewContent } from 'src/components/custom/views/ViewsContent';
import LoadingMoreInfinityItem from 'src/components/custom/item/loading-more-infinity.item';
import { Paths } from '@utils/constants';

export interface ListEventScreenProps {}

const ListEventScreen: React.FC<ListEventScreenProps> = () => {
  
  const { data: page, fetchNextPage, hasNextPage } = queryEvent.queryScroll();
  
  const { t } = useTranslation();
  const history = useHistory();
  const handleChangeView = () => {
    if(history.location.state) {
      history.push(Paths.Stage);
    }
    else {
      history.push(Paths.Dashboard);
    }
  };
  
  return (
    <LayoutDashboard
      isButton
      title={t('content.event.name')}
      onGoBackView={handleChangeView}
      leftIcon={<Image src={IMG_CONFIG.DASHBOARD.CONTEST_ICON_MINI} />}
      footerContent={t('content.event.footerList')}>
        
      <ViewContent.List
        style={{ overflowY: 'auto', overflowX: 'hidden', gridGap:'0px',marginTop:'5px' }}
        className={'hidden_scroll'}>
          <ViewCustomScroll>
        {/* data */}
        {page?.pages.map((data, i) => {
          return <ListEventWidget data={data?.data.events.data} key={i} />;
        })}
        </ViewCustomScroll>
        {/* loading */}
        <LoadingMoreInfinityItem
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
        />
      </ViewContent.List>

      {/* <ListEventWidget data={data?.data.events.data} /> */}
    </LayoutDashboard>
  );
};

export default ListEventScreen;
