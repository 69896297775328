import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryNotification } from 'src/api/hooks/useQueryNotification';

import ViewCustomScroll from 'src/components/custom/views/ViewCustomScroll';
import LayoutDashboard from 'src/layouts/newDashboard';
import ListNotificationWidget from '../widget/ListNotification.Widget';

import { ViewContent } from 'src/components/custom/views/ViewsContent';
import LoadingMoreInfinityItem from 'src/components/custom/item/loading-more-infinity.item';
import { useHistory } from 'react-router-dom';

export interface ListNotificationScreenProps {}

const ListNotificationScreen: React.FC<ListNotificationScreenProps> = () => {
  const { queryInfinity } = useQueryNotification();

  const { data: page, fetchNextPage, hasNextPage } = queryInfinity;
  const { t } = useTranslation();
  const history = useHistory();

  const handleChangeView = () => {
    history.goBack();
  };
  return (
    <LayoutDashboard title={t('content.notification.name')} isButton onGoBackView={handleChangeView}>
      <ViewCustomScroll paddingTop={10} paddingBottom={10}>
        <ViewContent.List style={{marginLeft: '2px'}}>
          {page?.pages.map((data, i) => {
            return (
              <ListNotificationWidget data={data?.data.notices.data} key={i} />
            );
          })}
          {/* loading */}
          <LoadingMoreInfinityItem
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
          />
        </ViewContent.List>
      </ViewCustomScroll>

      {/* <ListNotificationWidget data={data?.data.notices.data} /> */}
    </LayoutDashboard>
  );
};

export default ListNotificationScreen;
