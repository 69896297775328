import React, { PropsWithChildren } from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import MainLayout from '../MainLayout';
import { Image } from 'antd';
import styled, { keyframes,css } from 'styled-components';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import { useHistory, useLocation } from 'react-router-dom';
// import { Paths } from '@utils/constants';
import ViewLoading from 'src/components/custom/views/ViewLoading';
import { useGoBackSetting } from 'src/hooks/use-go-back-setting';
import { useIsOpen } from 'src/hooks/useIsOpen';
import CustomModal from 'src/components/custom/modal/CustomModal';
import ModalNavbarWidget from 'src/pages/setting/widget/ModalNavbar.Widget';
//import { scaleCorrectors } from 'framer-motion/types/projection/styles/scale-correction';

export interface LayoutDashboardProps extends PropsWithChildren<any> {
  title?: string;
  button?: React.ReactNode;
  footerContent?: string;
  leftIcon?: React.ReactNode;
  loading?: boolean;
  sizeTitle?: number;
  isButton?: boolean;
  onGoBackView?: () => void;
}

const LayoutDashboard: React.FC<LayoutDashboardProps> = ({
  children,
  title,
  button,
  footerContent,
  leftIcon,
  loading,
  sizeTitle,
  isButton,
  onGoBackView,
}) => {
  const history = useHistory();

  const open = useIsOpen();
  const onGoBack = useGoBackSetting(open.onOpen);

  const onClickButton = () => {
    onGoBackView ? onGoBackView() : onGoBack();
  };

  const handleClose = () => {
    onGoBackView ? onGoBackView() : history.goBack();
  };

  return (
    <MainLayout screen={IMG_CONFIG.BACKGROUND.MAIN} minHeight={980}>
      <div style={{ height: '30px' }} />
      {!title && <div style={{ height: '20px' }} />}
      <Style.Wrapper>
        <div className="close-wrapper">
          <CustomIconButton
            icon={IMG_CONFIG.DASHBOARD.CLOSE}
            // onClick={() => history.push(Paths.Stage)}
            onClick={handleClose}
          />
        </div>
        {/* left icon */}
        <div className="left-wrapper">{leftIcon}</div>

        {title ? (
          <div className="title-wrapper-2">
            <Image src={IMG_CONFIG.DASHBOARD.POPUP_TITLE} preview={false} />
            <div className="title">
              <h5
                className="color_text big_title"
                style={{ fontSize: `${sizeTitle || 22}px`, fontWeight: 'bold', textShadow: '0 0 1px #53321c', marginTop: '-2px' }}>
                {title}
              </h5>
            </div>
          </div>
        ) : (
          <div className="title-wrapper">
            <Image
              src={IMG_CONFIG.DASHBOARD.TITLE}
              preview={false}
              style={{ width: '125px', marginTop: '-10px' }}
            />
          </div>
        )}
        <img
          src={
            title
              ? IMG_CONFIG.DASHBOARD.GLASS_BOARD
              : IMG_CONFIG.DASHBOARD.BG_DASHBOARD
          }
          className={'img_bg'}
        />

        {/* inner */}
        {history.location.pathname === '/dashboard' ? (
          <div className="inner_dashboard">{loading ? <ViewLoading /> : children} </div>
        ) : history.location.pathname === '/notification' ? (
          <div className="inner_notification">{loading ? <ViewLoading /> : children} </div>
        ) : history.location.pathname === '/terms' ? (
          <div className="inner_terms">{loading ? <ViewLoading /> : children} </div>
        ) : history.location.pathname === '/faqs' ? (
          <div className="inner_faqs">{loading ? <ViewLoading /> : children} </div>
        ) : history.location.pathname === '/dashboard/events' ? (
          <div className="inner_dashboard_events">{loading ? <ViewLoading /> : children} </div>
        ) : history.location.pathname === '/dashboard/game' ? (
          <div className="inner_dashboard_game">{loading ? <ViewLoading /> : children} </div>
        ) : history.location.pathname.startsWith('/dashboard/cafe/') ? (
          <div className="inner_dashboard_cafe">{loading ? <ViewLoading /> : children} </div>
        ) : (
          <div className="inner">{loading ? <ViewLoading /> : children} </div>
        )}

        
        {/* button */}
        {history.location.pathname==='/dashboard' ? (
          <div className="button_wrapper_dashboard">
          {button}
          {isButton && (
            <CustomIconButton
              icon={IMG_CONFIG.BUTTON.OK}
              onClick={onClickButton}
            />
          )}
          {/* <CustomIconButton /> */}
          </div>
        ) : (
          <div className="button_wrapper">
          {button}
          {isButton && (
            <CustomIconButton
              icon={IMG_CONFIG.BUTTON.OK}
              onClick={onClickButton}
            />
          )}
          {/* <CustomIconButton /> */}
          </div>
        )}
        

        {/* footer */}
        {Boolean(footerContent) && (
          <Style.FooterMainWrapper>
          {history.location.pathname==='/dashboard' ? (
            <Style.FooterWrapper>
              <Image src={IMG_CONFIG.DASHBOARD.FOOTER_BOARD} preview={false} />
              <div className="inner_button">
                <h5
                  className="color_main medium_text"
                  style={{ fontSize: '15px', lineHeight: '20px' }}
                  dangerouslySetInnerHTML={{ __html: footerContent || '' }}
                />
              </div>
            </Style.FooterWrapper>
          ) : (
            <Style.FooterWrapperSubscreen>
              <Image src={IMG_CONFIG.DASHBOARD.FOOTER_BOARD} preview={false} />
              <div className="inner_button">
                <h5
                  className="color_main medium_text"
                  style={{ fontSize: '15px', lineHeight: '20px' }}
                  dangerouslySetInnerHTML={{ __html: footerContent || '' }}
                />
              </div>
            </Style.FooterWrapperSubscreen>
          )}
          </Style.FooterMainWrapper>
        )}
      </Style.Wrapper>
      {/* modal */}
      <CustomModal
        centered
        closeIcon={<div />}
        footer={null}
        visible={open.isOpen}
        wrapClassName="custom-modal-wrapper"
        maskStyle={{ background: 'rgba(0,0,0,.6)' }}
        onCancel={() => {
          open.onClose();
        }}>
        <ModalNavbarWidget onCancel={open.onClose} />
      </CustomModal>
    </MainLayout>
  );
};

export default LayoutDashboard;

const animContentEntryOriginal = keyframes`
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
`;

const animContentEntryLarge = keyframes`
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    opacity: 1;
  }
`;

const animContentEntryOriginalSubpage = keyframes`
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
    opacity: 1;
  }
`;

const animContentEntryLargeSubpage = keyframes`
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    opacity: 1;
  }
`;

const animFooterEntry = keyframes`
  0% {
    -webkit-transform: translateY(500px);
            transform: translateY(500px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
  }
`;


const Style = {
  Wrapper: styled.div`
    position: relative;
    width: 375px;
    max-width: 375px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (min-width: 768px) {
      animation: ${() => {
        const location = useLocation();
        if (location.pathname === '/dashboard') {
          return css`${animContentEntryLarge} 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) both`;
        } else {
          return css`${animContentEntryLargeSubpage} 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) both`;
        }
        }};
    }
    
      animation: ${() => {
        const location = useLocation();
        if (location.pathname === '/dashboard') {
          return css`${animContentEntryOriginal} 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) both;`;
        } else {
          return css`${animContentEntryOriginalSubpage} 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) both;`;
        }
        }};

    .close-wrapper {
      position: absolute;
      top: 20px;
      right:15px;
      z-index: 40;
      transform:scale(1.1);
    }

    .left-wrapper {
      position: absolute;
      top: 50px;
      left: 35px;
      z-index: 20;
      width:55px;
      height:auto;
    }

    .title-wrapper {
      position: absolute;
      top: -10px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
      z-index: 20;
      .title {
        position: absolute;
        top: 10px;
      }
    }

    .title-wrapper-2 {
      position: absolute;
      top: 45px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
      z-index: 20;
      transform:scale(1.1);
      .title {
        position: absolute;
        top: 10px;
      }
    }

    .inner {
      position: absolute;
      top: 0;
      width: 375px;
      max-width: 375px;
      padding: 0px 25px 0 15px;
      margin: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-height: 370px;
      margin-top: 115px;
      margin-left:7px;
      overflow: hidden;
      transform:scale(0.96);
    }

    .inner_dashboard_cafe {
      position: absolute;
      top: 0;
      width: 375px;
      max-width: 375px;
      padding: 0px 25px 0 15px;
      margin: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-height: 370px;
      margin-top: 115px;
      margin-left:2px;
      overflow: hidden;
      transform:scale(0.94);
    }

    .inner_dashboard_events {
      position: absolute;
      top: 0;
      width: 375px;
      max-width: 375px;
      padding: 0px 25px 0 15px;
      margin: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-height: 390px;
      margin-top: 112px;
      overflow: hidden;
      transform:scale(0.97);
    }

    .inner_dashboard_game {
      position: absolute;
      top: 0;
      width: 375px;
      max-width: 375px;
      padding: 0px 25px 0 15px;
      margin: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-height: 370px;
      margin-top: 115px;
      margin-left:4px;
      overflow: hidden;
      transform:scale(0.94);
    }

    .inner_notification {
      position: absolute;
      top: 0;
      width: 375px;
      max-width: 375px;
      padding: 0px 25px 0 15px;
      margin: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-height: 375px;
      margin-top: 110px;
      overflow-y: hidden;
      transform:scale(0.96);
    }

    .inner_terms {
      position: absolute;
      top: 0;
      width: 385px;
      max-width: 385px;
      padding: 0px 25px 0 15px;
      margin: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-height: 350px;
      margin-top: 107px;
      overflow-y: hidden;
      margin-left:15px;
      transform:scale(0.95);
    }

    .inner_faqs{
      position: absolute;
      top: 0;
      width: 375px;
      max-width: 375px;
      padding: 0px 25px 0 15px;
      margin: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-height: 350px;
      margin-top: 107px;
      overflow-y: hidden;
      margin-left:25px;
      transform:scale(0.95);
    }

    .inner_dashboard {
      position: absolute;
      top: 0;
      width: 375px;
      max-width: 375px;
      padding: 0px 45px 0 45px;
      margin: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-height: 300px;
      margin-top: 110px;
      overflow-y: visible;
    }
    .button_wrapper {
      position: absolute;
      width: 100%;
      top: 493px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      transform:(scale:1.2);
    }
    .button_wrapper_dashboard {
      position: absolute;
      width: 100%;
      top: 470px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      transform:(scale:1.2);
    }
  `,
  FooterWrapper: styled.div`
    position: relative;
    margin: auto;
    width: 350px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
    margin-top:0px;
    /*animation: ${animFooterEntry} 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275)
      both;*/
    @media (max-height: 667px) {
        margin-top:2px;
        transform:scale(0.85);
    }

    .inner_button {
      position: absolute;
      top: 0;
      padding: 0 30px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-top: -15px;
    }
  `,
  FooterWrapperSubscreen: styled.div`
    position: relative;
    margin: auto;
    width: 350px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
    margin-top:20px;
    /*animation: ${animFooterEntry} 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275)
      both;*/
    @media (max-height: 667px) {
        margin-top:8px;
        transform:scale(0.85);
    }

    .inner_button {
      position: absolute;
      top: 0;
      padding: 0 30px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-top: -15px;
    }
  `,
  FooterMainWrapper: styled.div`
    @media (min-width: 768px) {
      transform: scale(1.2);
    }
  `,
};
