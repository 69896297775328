import React from 'react';
import { queryProduct } from 'src/api/hooks/queryProduct';
import LoadingMoreInfinityItem from 'src/components/custom/item/loading-more-infinity.item';
import ViewCustomScroll from 'src/components/custom/views/ViewCustomScroll';
import styled from 'styled-components';
import CardProductPriceCom from '../components/CardProductPrice.Com';
// import { useChangeItem } from '../hooks/useChangeItem';

export interface ListShopWidgetProps {
  onClick ?: any;
}
const item_per_page = 8;

const ListShopWidget: React.FC<ListShopWidgetProps> = ({onClick}) => {
  // const { exChange } = useChangeItem();

  const { data: page, fetchNextPage, hasNextPage } = queryProduct.queryScroll();

  return (
    <ViewCustomScroll>
      <Style.Wrapper>
        {page?.pages.map((item, j) => {
          return item.data.products.data.map((part, i) => {
            return (
              <CardProductPriceCom
                data={part}
                key={part.id}
                index={j * item_per_page + i + 1}
                onExchange={() => onClick(part)}
              />
            );
          });
        })}
        <LoadingMoreInfinityItem
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
        />
      </Style.Wrapper>
    </ViewCustomScroll>
  );
};

export default ListShopWidget;

const Style = {
  Wrapper: styled.div`
    width: 100%;
    display: grid;
    align-content: center;
    justify-items: center;
    /* flex-direction: grid; */
    grid-template-columns: repeat(1, 1fr);
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: stretch;
    /* gap: 20px; */
  `,
};
