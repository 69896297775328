import React from 'react';
import { useIsOpen } from 'src/hooks/useIsOpen';
import { FaqDataProps } from 'src/model/dashboard.model';
import styled from 'styled-components';
import { ArrowDownIcon } from '@icons';

export interface ItemListFaqsComProps
  extends React.BaseHTMLAttributes<HTMLDivElement> {
  data: FaqDataProps;
  index: number;
}

const ItemListFaqsCom: React.FC<ItemListFaqsComProps> = ({ data, index }) => {
  const open = useIsOpen();
  return (
    <Style.FaqWrapper>
      <Style.Header>
        <Style.Wrapper onClick={open.onToggle}>
          <h5
            className="color_error cap_text"
            style={{ wordBreak: 'keep-all' }}>
            {index}.
          </h5>
          <p className="color_main" style={{ wordBreak: 'break-word', fontSize:'14px', lineHeight: '18px' }}>
            {data.question}
          </p>
        </Style.Wrapper>
        <div
          style={{
            transform: open.isOpen ? 'rotate(180deg)' : 'none',
            cursor: 'pointer',
          }}
          onClick={open.onToggle}>
          <ArrowDownIcon />
        </div>
      </Style.Header>
      {/* {open.isOpen && <p>answer: {data.answer}</p>} */}
      <Style.Answer isOpen={open.isOpen}>
        <p className="color_pink" style={{ wordBreak: 'break-word', fontSize:'14px', lineHeight: '18px' }}>{data.answer}</p>
      </Style.Answer>
    </Style.FaqWrapper>
  );
};

export default ItemListFaqsCom;

interface StyleProps {
  isOpen?: boolean;
}

const Style = {
  Wrapper: styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;

    margin-bottom: 5px;
    margin-top:10px;

    cursor: pointer;

    /* border-bottom: solid 2px #6e6e6e; */
    

    h5 {
      width: 30px;
    }
  `,

  Header: styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  `,

  FaqWrapper: styled.div`
    width: 95%;
    display: flex;
    flex-direction: column;
    border-bottom: solid 2px #6e6e6e;
  `,

  Answer: styled.div`
    overflow: hidden;
    margin-bottom:5px;
    height: ${(p: StyleProps) => (p.isOpen ? 'fit-content' : '0px')};
  `,
};
