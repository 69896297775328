// Scripts for firebase and firebase messaging
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { onBackgroundMessage } from 'firebase/messaging/sw';

// Initialize the Firebase app in the service worker by passing the generated config
const firebaseConfig = {
  apiKey: 'AIzaSyDqLRGk9Q3Z9tjvOSQygDgn6O6cgiQUhb8',
  authDomain: 'pet-paradise-f4f17.firebaseapp.com',
  projectId: 'pet-paradise-f4f17',
  storageBucket: 'pet-paradise-f4f17.appspot.com',
  messagingSenderId: '1034304414509',
  appId: '1:1034304414509:web:ed60f7caecd9d40b61a9a4',
  measurementId: 'G-GDZ956X7M2',
};

const myUrl = 'https://pet-paradise-dev.adamo.tech/';

const myUrlEvent = myUrl + 'dashboard/events/detail/';

// firebase.initializeApp(firebaseConfig);

const firebaseApp = initializeApp(firebaseConfig);

const getMessageClient = () => {
  try {
    const message = getMessaging(firebaseApp);
    if (message) {
      return message;
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

// Retrieve firebase messaging

export function register(config) {
  const message = getMessageClient();

  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('./serviceworker.js')
      .then((reg) => {
        const swUrl = `${reg.scope}service-worker.js`;

        registerValidSW(swUrl, config);

        console.log('Success swUrl : ', reg.scope);
      })
      .catch((err) => console.log('Failure: ', err));
  });

  navigator.serviceWorker.ready.then(() => {
    console.log(
      'This web app is being served cache-first by a service ' +
        'worker. To learn more, visit https://cra.link/PWA',
    );

    onBackgroundMessage(message, (payload) => {
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
      };
      console.log(payload);

      self.registration?.showNotification(
        notificationTitle,
        notificationOptions,
      );
      self?.addEventListener('notificationclick', function (event) {
        if (payload.data.screen === 'EventDetailScreen') {
          event.waitUntil(
            self?.clients.openWindow(myUrlEvent + payload.data.event_id),
          );
        } else {
          event.waitUntil(self?.clients.openWindow(myUrl));
        }

        event.notification.close();
      });
    });
  });
  onMessage(message, (payload) => {
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
      body: payload.notification.body,
    };

    console.log(payload);
    self?.registration?.showNotification(notificationTitle, notificationOptions);
    self?.addEventListener('notificationclick', function (event) {
      if (payload.data.screen === 'EventDetailScreen') {
        event.waitUntil(
          self?.clients.openWindow(myUrlEvent + payload.data.event_id),
        );
      } else {
        event.waitUntil(self?.clients.openWindow(myUrl));
      }

      event.notification.close();
    });
  });
}

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.log(
                'New content is available and will be used when all ' +
                  'tabs for this page are closed. See https://cra.link/PWA.',
              );

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log('Content is cached for offline use.');

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error);
    });
}
