import { useInfiniteQuery, useQuery } from 'react-query';
import { RequestListProps } from 'src/model';
import { useState } from 'react';
import { DataListResponseProps, ResponseProps } from 'src/model/res.model';

import { FOOD_KEY } from '../queries/keys';

import { foodApi } from '../axios/food';
import { FoodDataProps } from 'src/model/food.model';

/**
 * @class QueryNews()
 * @method queryList()
 * @method queryDetail()
 * @returns
 */

class QueryFood {
  key: { LIST: string; ADVERTISEMENTS: string };

  constructor() {
    this.key = FOOD_KEY;
  }

  queryList(queryData?: RequestListProps) {
    const [query, setQuery] = useState<RequestListProps>(
      queryData || {
        page: 1,
      },
    );
    const useQueryData = useQuery<
      ResponseProps<{ foods: DataListResponseProps<FoodDataProps> }>,
      Error
    >([this.key.LIST, query], () => foodApi.getList(query), {
      // enabled: !!query.type,
      refetchOnMount: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
    });
    return { query, setQuery, useQueryData };
  }
  queryAdvertisement(id: number) {
    return useQuery<any, Error>(
      [this.key.ADVERTISEMENTS, id],
      () => foodApi.getAdvertisement(id),

      {
        enabled: !!id,
        refetchOnMount: false,
        refetchOnReconnect: true,
        refetchOnWindowFocus: false,
      },
    );
  }

  queryScroll() {
    return useInfiniteQuery<
      ResponseProps<{ foods: DataListResponseProps<FoodDataProps> }>,
      Error
    >(
      [this.key.LIST],
      async ({ pageParam = 0 }) => {
        const res = await foodApi.getList({
          page: pageParam + 1,
        });
        return res;
      },
      {
        getPreviousPageParam: (firstPage) =>
          firstPage.data.foods.meta.current_page,

        getNextPageParam: (lastPage) =>
          lastPage.data.foods.meta.current_page ===
          lastPage.data.foods.meta.last_page
            ? undefined
            : lastPage.data.foods.meta.current_page,
      },
    );
  }
}

export const queryFood = new QueryFood();
