import React from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import styled from 'styled-components';

export interface CustomSwitchProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isOn?: boolean;
}

const CustomSwitch: React.FC<CustomSwitchProps> = ({ isOn, ...props }) => {
  return (
    <Style.Wrapper {...props}>
      <img
        src={isOn ? IMG_CONFIG.REGISTER.TOGGLE_2 : IMG_CONFIG.REGISTER.TOGGLE_1}
      />
    </Style.Wrapper>
  );
};

export default CustomSwitch;

const Style = {
  Wrapper: styled.button``,
};
