import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ArrowDownIcon } from '@icons';
import { useIsOpen } from 'src/hooks/useIsOpen';
import CustomModal from 'src/components/custom/modal/CustomModal';
import { configData } from './config';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import { ItemSelect } from 'src/components/custom/input/CustomBoxSearch';
import { ListPetProps } from 'src/hooks/useGetListTypePet';
import { TypePetGroupEnum } from 'src/model/dashboard.model';
import { CloseOutlined } from '@ant-design/icons';
import { TypePetEnum } from 'src/model';

export interface CustomSelectTypePetProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  name?: any;
  error?: string;
  options?: ListPetProps[];
  onChange?: any;
  typePet?: number;
  placeholder?: any;
  title?: string;
}

const CustomSelectTypePet: React.FC<CustomSelectTypePetProps> = (props) => {
  const open = useIsOpen();
  const openSelect = useIsOpen();

  const [value, setValueA] = useState(props.defaultValue);
  const [label, setLabel] = useState('');
  const [group, setGroup] = useState<TypePetGroupEnum | null>(
    TypePetGroupEnum.A,
  );

  const handleSelect = (valueData: any) => {
    setValueA(valueData);
    props.onChange(props.name, valueData);
    open.onClose();
  };

  useEffect(() => {
    return () => {
      openSelect.onClose();
    };
  }, [open.isOpen]);

  useEffect(() => {
    const result = props.options?.find((word) => word.value === value);
    setLabel(result?.label ?? '');
  }, [value]);

  function renderSelect() {
    const data = props.options?.filter((item) => item.group === group);
    return data?.map((item, i) => {
      return (
        <ItemSelect
          hidden={item.group !== group}
          onClick={() => handleSelect(item.value)}
          label={item.label}
          index={i + 1}
          key={i}
        />
      );
    });
  }

  function renderHeight(a: any) {
    if (a < 5) {
      return '80%';
    } else if (a < 9) {
      return '67%';
    } else {
      return '55%';
    }
  }

  function renderButtonColor(g: TypePetGroupEnum) {
    if (group !== g && openSelect.isOpen === true) {
      return { filter: 'grayscale(50%)' };
    } else return {};
  }

  function handleChangeGroup(g: TypePetGroupEnum) {
    if (g !== group) {
      setGroup(g);
      openSelect.onOpen();
    } else {
      openSelect.onToggle();
    }
  }

  return (
    <Style.InputWrapper>
      <Style.Wrapper>
        <Style.Inner onClick={open.onOpen}>
          <p className="color_main"> {label || props.placeholder}</p>
          <div style={{ transform: open.isOpen ? 'rotate(180deg)' : 'none' }}>
            <ArrowDownIcon />
          </div>
        </Style.Inner>

        <CustomModal
          closeIcon={<div />}
          maskStyle={{ background: 'rgba(0,0,0,.6)' }}
          footer={null}
          centered
          visible={open.isOpen}
          wrapClassName="custom-modal-wrapper"
          onCancel={open.onClose}>
          <Style.SelectWrapper>
            <Style.Title>
              <h5
                className="color_white medium_text"
                style={{ fontSize: '16px' }}>
                {props.title}
              </h5>
              <Style.Close>
                <CloseOutlined
                  onClick={open.onClose}
                  style={{
                    color: 'white',
                    fontSize: 25,
                    transform: 'translateY(-10px)',
                    cursor: 'pointer',
                  }}
                />
              </Style.Close>
            </Style.Title>

            {/* buttoin */}
            <Style.ButtonWrapper>
              {props.typePet === TypePetEnum.CAT
                ? configData.slice(0, configData.length - 1).map((data, i) => {
                    return (
                      <CustomIconButton
                        style={renderButtonColor(data.type)}
                        icon={data.img}
                        key={i}
                        onClick={() => {
                          handleChangeGroup(data.type);
                        }}
                      />
                    );
                  })
                : configData.map((data, i) => {
                    return (
                      <CustomIconButton
                        style={renderButtonColor(data.type)}
                        icon={data.img}
                        key={i}
                        onClick={() => {
                          handleChangeGroup(data.type);
                        }}
                      />
                    );
                  })}
            </Style.ButtonWrapper>
            {/* select */}
            {openSelect.isOpen && (
              <Style.ChosseWrapper heightSelect={renderHeight(group)}>
                <div
                  className="inner_select hidden_scroll"
                  style={{ marginTop: '10px' }}>
                  {renderSelect()}
                </div>
              </Style.ChosseWrapper>
            )}
          </Style.SelectWrapper>
        </CustomModal>
      </Style.Wrapper>
      {props.error && <p className="small_text color_error">{props.error}</p>}
    </Style.InputWrapper>
  );
};

export default CustomSelectTypePet;

interface StyleProps {
  isSelect?: boolean;
  heightSelect?: string;
}

const Style = {
  InputWrapper: styled.div`
    display: grid;

    grid-template-columns: repeat(1, 1fr);
    grid-gap: 5px;
    width: 100%;
  `,
  Wrapper: styled.div`
    position: relative;
    cursor: pointer;

    width: 100%;
    height: 40px;

    border-radius: 20px;
    padding: 0 12px;
    background-color: #fadeb1;
    -webkit-box-shadow: 0px 6px 0px 0px rgba(0, 0, 0, 0.25);

    box-shadow: 0px 6px 0px 0px rgba(0, 0, 0, 0.25);
  `,
  Inner: styled.div`
    /* position: absolute; */
    left: 15px;
    top: 3px;
    width: 230px;
    min-height: 25px;
    font-size: 16px;
    display: flex;
    line-height: 40px;
    justify-content: space-between;
    align-items: center;
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `,

  ItemSelect: styled.div`
    display: block;
    align-items: flex-end;
    justify-content: flex-start;
    cursor: pointer;
    padding: 0 10px;
    border-radius: 10px;
    height: 100%;
    font-size: 16px;

    ${(props: StyleProps) =>
      props.isSelect && ' background-color: var(--color-main);'}

    .content_wrapper {
      border-bottom: solid 2px var(--color-main);
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      padding-bottom: 10px;
      padding-top: 5px;
    }
    .index_wrapper {
      min-width: 50px;
    }
  `,

  SelectWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;

    min-height: 350px;
    height: 430px;
    max-height: 100vh;

    max-width: 100%;
    width: 290px;
    overflow: hidden;
    /* 
    background-color: #fadeb1; */
    border-radius: 10px;
    padding: 10px;

    .inner_select {
      overflow-y: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: 100%;
      justify-content: flex-start;
    }
  `,

  ButtonWrapper: styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 10px;
    margin-top: 10px;
  `,
  ChosseWrapper: styled.div`
    position: absolute;
    width: 100%;
    height: ${(props: StyleProps) =>
      props.heightSelect ? props.heightSelect : '80%'};
    background-color: #fadeb1;
    bottom: 0;
    left: 0;
    border-radius: 20px;
  `,
  Title: styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
  `,
  Close: styled.div`
    :hover {
      transform: translateY(-3px) scale(1.05);
    }
  `,
};
