import { useEffect, useState } from 'react';
import { queryFrame } from 'src/api/hooks/queryFrame';
import { queryProfile } from 'src/api/hooks/queryProfile';
import { useIsOpen } from 'src/hooks/useIsOpen';
import { ActiveLineEnum } from 'src/model';

interface RegisterProps {
  active_line_id: ActiveLineEnum;
  active_line_name: ActiveLineEnum;
  frame_id: number | undefined;
}

export const useConfirmRegister = () => {
  const { queryDefault } = queryFrame;
  const query = queryProfile.querySignUp();
  const querySignUpMoveHome = queryProfile.querySignUpMoveHome();

  const { useQueryData } = queryDefault();

  const { data: frames } = useQueryData;

  const [register, setRegister] = useState<RegisterProps>({
    active_line_id: ActiveLineEnum.UNACTIVE,
    active_line_name: ActiveLineEnum.UNACTIVE,
    frame_id: 0,
  });
  const imgIndex = useIsOpen();

  const [frameImg, setFrameImg] = useState<any>(null);
  const [frameImg2, setFrameImg2] = useState<any>(null);

  useEffect(() => {
    if (frames ? frames?.data.frames.length > 1 : null) {
      setRegister({ ...register, frame_id: frames?.data.frames[0].id });
      setFrameImg(frames?.data.frames[0]);
      setFrameImg2(frames?.data.frames[1]);
    }
  }, [frames]);

  function switchType(type: ActiveLineEnum) {
    if (type === ActiveLineEnum.ACTIVE) {
      return ActiveLineEnum.UNACTIVE;
    } else {
      return ActiveLineEnum.ACTIVE;
    }
  }

  function switchLineId() {
    setRegister({
      ...register,
      active_line_id: switchType(register.active_line_id),
    });
  }

  function switchLineName() {
    setRegister({
      ...register,
      active_line_name: switchType(register.active_line_name),
    });
  }

  function switchFrame() {
    imgIndex.onToggle();
    if (frames ? frames?.data.frames.length > 0 : null) {
      setRegister({
        ...register,
        frame_id: frames?.data.frames[imgIndex.isOpen ? 0 : 1].id,
      });
      setFrameImg(frames?.data.frames[imgIndex.isOpen ? 0 : 1]);
      setFrameImg2(frames?.data.frames[imgIndex.isOpen ? 1 : 0]);
    }
  }

  return {
    register,
    switchLineId,
    switchLineName,
    switchFrame,
    frames,
    frameImg,
    frameImg2,
    query,
    querySignUpMoveHome
  };
};
