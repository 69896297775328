import React from 'react';
import styled from 'styled-components';

export interface CustomButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  loading?: boolean;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  children,
  text,
  loading,
  ...props
}) => {
  return (
    <Style.Wrapper {...props}>
      {loading ? <>loading</> : children}
      {text}
    </Style.Wrapper>
  );
};

export default CustomButton;

const Style = {
  Wrapper: styled.button`
    display: flex;
    justify-content: center;
    width: fit-content;
    background-color: var(--color-button);
    border-radius: 10px;
    box-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    color: var(--color-main);
    font-size: 14px;
    /* padding: 10px auto; */

    padding: 5px 20px;
    :hover {
      background-color: var(--color-button);
    }
  `,
};
