import { CafeSortListQuery } from 'src/model/cafesort.model';
import { request } from './axios';
import { API_URL } from './config';

class CafeSortApi {
    url: { LIST: string };
    constructor() {

        this.url = API_URL.CAFE;
    }

    getList(params : CafeSortListQuery) {
        return request({
          url: this.url.LIST,
          method: 'GET',
          params
        });
      }
}

export const cafeSortApi = new CafeSortApi();