import { AnswerReqProps } from 'src/model/stageGame.model';

export function checkMultiAnswer(
  arr: AnswerReqProps[],
  value: any,
  number?: any,
  isText?: boolean,
) {
  const itemIndex = arr.findIndex((p: AnswerReqProps) => p.answer_id === value);

  let data: any = [];
  const answer: any = {
    answer_id: value,
  };

  if (itemIndex > -1) {
    data = arr.filter((item) => item.answer_id !== value);
  } else {
    if (isText) {
      answer.answer_text = '';
    }
    arr.push(answer);
    if (arr.length > number) {
      arr.shift();
    }
    data = arr;
  }

  return data;
}
