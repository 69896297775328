import { RequestListProps } from 'src/model';
import { request } from './axios';
import { API_URL } from './config';

class RankingAPi {
  url: { LIST_POINT: string; STAGES_PET: string };

  constructor() {
    this.url = API_URL.RANKING;
  }
  getListPoint(params: RequestListProps) {
    return request({
      url: this.url.LIST_POINT,
      method: 'GET',
      params,
    });
  }

  getListPetStages(id: number) {
    return request({
      url: this.url.STAGES_PET + '/' + id + '/images',
      method: 'GET',
    });
  }
}

export const rankingAPi = new RankingAPi();
