import React, { useRef } from 'react';
import { queryProfile } from 'src/api/hooks/queryProfile';
import { queryQuestion } from 'src/api/hooks/queryQuestion';
import MainLayout from 'src/layouts/MainLayout';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import MainMapLayout from '../layout/MainMap.Layout';
import { Redirect, useLocation } from 'react-router-dom';
import { Paths } from '@utils/constants';
import { ViewSelectCamera } from '../widget/view-select-camera';

export interface UploadImageScreenProps {}

export const UploadImageStageScreen: React.FC<UploadImageScreenProps> = () => {
  const { useQueryData: question } = queryQuestion.queryListPoint();
  const { useQueryData: getProfile } = queryProfile.queryProfile();
  const { data: profile } = getProfile;
  const { state } = useLocation<number>();
  const ref = useRef<any>();

  return (
    <MainLayout
      ref={ref}
      minHeight={995}
      screen={IMG_CONFIG.BACKGROUND.START}
      hiddenSetting
      pt={20}>
      <MainMapLayout
        isReward
        onOpen={() => ref.current?.show()}
        star={question?.data?.data.user_star}
        rewards={question?.data?.data.rewards}
        spots={question?.data?.data.spots}
        images = {question.data?.data.images}
        coin={profile?.data.profile.coins}
        stageImg={question?.data?.data.current_stage.media}
        children_2={
          <>
            {!state && <Redirect to={Paths.Stage} />}

            {state && <ViewSelectCamera id={state} />}
            {/* {!state && <Redirect to={Paths.Stage} />}
            {state && <ItemRewardEndQuestion data={state} />} */}
          </>
        }
      />
    </MainLayout>
  );
};
