import React from 'react';
import { Image } from 'antd';

import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import styled from 'styled-components';
import { ExchangeHistoryDataProps } from 'src/model/product.model';

export interface CardExchangeHistoryComProps {
  data?: ExchangeHistoryDataProps;
  onOpenDetail : any;
}

const CardExchangeHistoryCom: React.FC<CardExchangeHistoryComProps> = ({
  data,
  onOpenDetail
}) => {

  return (
    <Style.Wrapper onClick={onOpenDetail}>
      <Style.Img>
        <Image
          src={data?.media || 'error'}
          fallback={IMG_CONFIG.ERROR}
          style={{ width: '100px', borderRadius: '5px' }}
          preview={false}
        />
      </Style.Img>
      <Style.Price>
        <img src={IMG_CONFIG.MAP_GAME.COIN_GAME_BG} style={{ width: '66px' }} />
        <div className="inner_wrapper">
          <p className="color_main medium_text" >{data?.price ?? 0}</p>
        </div>
      </Style.Price>
      <div className="text-wrapper">
        <h5 className="color_text small_text ">{data?.product_name}</h5>
        <p className="color_des small_text">{data?.purchase_time}</p>
      </div>
    </Style.Wrapper>
  );
};

export default CardExchangeHistoryCom;

const Style = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background: #ffffff;
    border-radius: 4px;
    padding: 8px;
    /* gap: 12px; */
    height: fit-content;
    box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 3px 3px 0px 4px rgba(0, 0, 0, 0.25);
    width: 310px !important;
    position: relative;
    cursor: pointer;

    .text-wrapper {
      margin: 10px 0 0 10px;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
    h5 {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    p {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  `,

  Price: styled.div`
    position: absolute;
    cursor: pointer;
    top: -8px;
    right: 0;
    .inner_wrapper {
      position: absolute;
      bottom: 2px;
      right: 24px;
      height: 100%;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      font-size: 30px;
    }
  `,

  Img: styled.div`
    transform: translateY(0px);
  `,
};
