import React from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import {
  ResultSpinType,
  TypeItemMiniGameEnum,
  ListItemTextPopup,
} from 'src/model/miniGame.model';
import styled from 'styled-components';

export interface GetCoinAndFrameComProps {
  data?: ResultSpinType;
  dataTextPopup?: ListItemTextPopup;
}

const GetCoinCom: React.FC<GetCoinAndFrameComProps> = ({
  data,
  dataTextPopup,
}) => {
  const handleLinkLine = () => {
    const link = 'https://page.line.me/357wemvl';
    window.open(link, '_blank');
  };
  return (
    <Style.Wrapper>
      {data?.type === TypeItemMiniGameEnum.COIN && (
        <>
          <div>
            <p style={{ textAlign: 'center' }}>
              {' '}
              {`${dataTextPopup?.coin.main_text}`}{' '}
            </p>
          </div>
        </>
      )}
      {data?.type === TypeItemMiniGameEnum.FRAME && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <div>
            <p>{`${dataTextPopup?.frame.main_text}`} </p>
          </div>
          <Style.Frame>
            <img src={data.item?.media} />
          </Style.Frame>
        </div>
      )}
      {data?.type === TypeItemMiniGameEnum.PRODUCT && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <p>
            スタンプセットは
            <span style={{ color: '#22AC38' }}>LINE上で作ります。</span>
          </p>
          <Style.Des>
            <CustomIconButton
              icon={IMG_CONFIG.MINI_GAME.LINE_IMAGE}
              onClick={handleLinkLine}
            />
            <p> {`${dataTextPopup?.product.main_text}`}</p>
          </Style.Des>
        </div>
      )}
      {data?.type === TypeItemMiniGameEnum.NULL && (
        <>
          <div>
            <p>{`${dataTextPopup?.nothing.main_text}`}</p>
          </div>
        </>
      )}
    </Style.Wrapper>
  );
};

export default GetCoinCom;

const Style = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 6px;
  `,

  Img: styled.div`
    margin-left: 10px;
    img {
      width: 50px;
    }
  `,

  Frame: styled.div`
    img {
      height: 75px;
    }
  `,
  Des: styled.div`
    margin-top: 8px;
    display: flex;
    align-items: self-start;
    img {
      height: 32px;
    }
    p {
      font-weight: 400;
      font-size: 12px;
    }
  `,
};
