import { useQuery } from 'react-query';
import { RequestListProps } from 'src/model';
import { useState } from 'react';
import { DataListResponseProps, ResponseProps } from 'src/model/res.model';

import { RANK_KEY } from '../queries/keys';
import { RankPointDataProps } from 'src/model/ranking.model';
import { rankingAPi } from '../axios/ranking';

/**
 * @class QueryNews()
 * @method queryList()
 * @method queryDetail()
 * @returns
 */

class QueryRank {
  key: { POINT: string; LIST_PET_STAGES: string };

  constructor() {
    this.key = RANK_KEY;
  }

  queryListPoint(queryData?: RequestListProps) {
    const [query, setQuery] = useState<RequestListProps>(
      queryData || {
        page: 1,
      },
    );
    const useQueryData = useQuery<
      ResponseProps<{ users: DataListResponseProps<RankPointDataProps> }>,
      Error
    >([this.key.POINT, query], () => rankingAPi.getListPoint(query), {
      // enabled: !!query.type,
      refetchOnMount: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
    });
    return { query, setQuery, useQueryData };
  }

  queryListPetStage(id: number) {
    const useQueryData = useQuery<
      ResponseProps<{ images: RankPointDataProps[] }>,
      Error
    >(['LIST_PET_STAGES', id], () => rankingAPi.getListPetStages(id), {
      // enabled: !!query.type,
      refetchOnMount: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
    });
    return { useQueryData };
  }
}

export const queryRank = new QueryRank();
