import React, { PropsWithChildren } from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import ViewLoading from 'src/components/custom/views/ViewLoading';
import styled, { keyframes } from 'styled-components';

export interface LayoutRegisterComProps extends PropsWithChildren<any> {
  loading?: boolean;
  title?: string;
}

const LayoutRegisterCom: React.FC<LayoutRegisterComProps> = ({
  children,
  loading,
  title,
}) => {
  return (
    <Style.Wrapper>
      <Style.InnerText>
        <p className="color_white">{title}</p>
      </Style.InnerText>
      <img src={IMG_CONFIG.BACKGROUND.REGISTER} />
      <Style.Inner>{loading ? <ViewLoading /> : children}</Style.Inner>
    </Style.Wrapper>
  );
};

export default LayoutRegisterCom;

const animScaleOriginal = keyframes`
  from {
    transform:scale(0.5);
    opacity:0;
  }
  to {
    transform:scale(1);
    opacity:1;
  }
`;

const animScaleLarge = keyframes`
  from {
    transform:scale(1);
    opacity:0;
  }
  to {
    transform:scale(1.3);
    opacity:1;
  }
`;

const Style = {
  Wrapper: styled.div`
    position: relative;
    margin: auto;
    @media (min-width: 768px) {
      animation: ${animScaleLarge} .5s cubic-bezier(.175,.885,.32,1.275) 0.4s both}
    }
    animation: ${animScaleOriginal} .5s cubic-bezier(.175,.885,.32,1.275) 0.4s both}
  `,
  Inner: styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 0;
    align-items: center;
    padding: 60px 10px;
  `,

  InnerText: styled.div`
    position: absolute;
    top: 14px;
    width: 100%;
    justify-content: center;
    display: flex;
    font-size:16px;
  `,
};
