export const AUTH_KEY = {
  PROFILE: 'AUTH_PROFILE',
  LIST_FRAME: 'LIST_FRAME',
  LIST_PET: 'LIST_PET',
  PET: 'PET',
};

export const NEWS_KEY = {
  LIST: 'NEWS_LIST',
  DETAIL: 'NEWS_DETAIL',
  ADVERTISEMENTS: 'ADVERTISEMENTS',
};

export const RANK_KEY = {
  POINT: 'LIST_POINT',
  LIST_PET_STAGES: 'LIST_PET_STAGES',
};

export const FOOD_KEY = {
  LIST: 'LIST_FOOD',
  ADVERTISEMENTS: 'ADVERTISEMENTS',
};

export const EVENT_KEY = {
  LIST: 'LIST_EVENT',
  RESULT: 'RESULT_EVENT',
  DETAIL: 'DETAIL_EVENT',
  PARTICIPANTS: 'PARTICIPANTS_EVENT',
  ADVERTISEMENTS: 'ADVERTISEMENTS',
};

export const PRODUCT_KEY = {
  LIST: 'LIST_PRODUCT',
  LIST_HISTORY : 'LIST_EXCHANGE_HISTORY'
};

export const ARTICLE_KEY = {
  LIST: 'LIST_ARTICLE',
  DETAIL : 'ARTICLE_DETAIL'
};

export const FRAME_KEY = {
  LIST: 'LIST_FRAME',
  FRAME: 'DEFAULT_FRAME',
};

export const DASHBOARD_KEY = {
  LIST: 'LIST_DASHBOARD',
  TERMS: 'TERMS',
  FAQS: 'FAQS',
  STAGES: 'STAGES',
};

export const QUESTION_KEY = {
  CURRENT: 'STAGE_CURRENT',
  QUESTION: 'QUESTION',
  MAP: 'MAP',
  LIST_SPOT_PET: 'LIST_SPOT_PET',
};

export const MINI_GAME_KEY = {
  LIST: 'MINI_GAMEMINI_GAME',
  TEXT_POPUP : 'MINI_GAMEMINI_TEXT_POPUP'
};

export const NOTIFICATION_KEY = {
  LIST: 'NOTIFICATIONS',
  LIST_INFI: 'NOTIFICATIONS_INFI',
};
export const ADS_KEY = {
  LIST: 'ADS_LIST',
  LIST_INFI: ' ADS_INFI',
};

export const CAFE_KEY = {
    LIST : 'CAFE_LIST',
};
