import { useAppSelector } from '@hooks';
import { useDispatch } from 'react-redux';

import * as Slide from '../slide';

class ReduxSound {
  useData() {
    const data = useAppSelector((p) => p.soundSlide);
    return { data };
  }

  useAction() {
    const dispatch = useDispatch();

    function setUserSound(payload: { is_bgm: number , user_id ?: number }) {
      dispatch(Slide.soundSlide.setSound(payload));
    }

    return {
      setUserSound,
    };
  }
}

export const reduxSound = new ReduxSound();
