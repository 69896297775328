import { ItemDataMiniGame, ResultSpinType } from 'src/model/miniGame.model';

export function getDegResult(
  result?: ResultSpinType,
  items?: ItemDataMiniGame[],
) {
  let deg = 0;

  {
    items?.length === 12
      ? items?.some((item, i) => {
          if (result?.id === item.id) {
            return (deg = 30 * i);
          }
        })
      : items?.some((item, i) => {
          if (result?.id === item.id) {
            return (deg = 45 * i);
          }
        });
  }

  return deg;
}
