import { Paths } from '@utils/constants';
import { Image } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { queryNews } from 'src/api/hooks/queryNews';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import { NewsDataProps, TypeNewsEnum } from 'src/model';
import styled from 'styled-components';

export interface CardNewsComProps {
  data: NewsDataProps;
}

const CardNewsCom: React.FC<CardNewsComProps> = ({ data }) => {
  const history = useHistory();
  const query = queryNews.queryMarkReadNew();

  const linkNewTab = () => {
    if (data.is_click === 0) {
      query.mutate(data.id);
    }
    window.open(data.link, '_blank');
  };

  const linkNavigate = () => {
    if (data.is_click === 0) {
      query.mutate(data.id);
    }
    history.push(Paths.News + '/' + data.id);
  };
  return (
    <div>
      <Style.Wrapper
        onClick={data.type === TypeNewsEnum.LINK ? linkNewTab : linkNavigate}>
        <Style.Img>
          <Image
            src={data.image || 'error'}
            fallback={IMG_CONFIG.ERROR}
            style={{ width: '50px', height: '50px', borderRadius: '5px' }}
            preview={false}
          />
        </Style.Img>

        <div style={{ marginLeft: '10px' }}>
          <h5 className="color_text small_text" style={{ fontWeight: '600' }}>
            {data.title}
          </h5>
          <p className="color_des small_text " style={{ marginTop: '4px' }}>
            {data.description}
          </p>
        </div>
      </Style.Wrapper>
    </div>
  );
};

export default CardNewsCom;

const Style = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background: #ffffff;
    border-radius: 4px;
    padding: 8px;
    /* gap: 12px; */
    cursor: pointer;
    height: fit-content;
    box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 3px 3px 0px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 10px !important;
    width: 310px !important;

    h5 {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    p {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  `,

  Img: styled.div`
    transform: translateY(0px);
  `,
};
