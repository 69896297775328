import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useQueryAuth } from 'src/api/hooks/useQueryAuth';

export const useFormNewPassword = () => {
  const { t } = useTranslation();
  const { queryResetPassword } = useQueryAuth();
  const query = queryResetPassword();

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('error.password.required'))
      .min(6, t('error.password.min'))
      .matches(
        /^(?=.*[0-9])(?=.*[a-z])/,
        'パスワードは6文字以上で、文字と数字が含まれている必要があります',
      )
      .max(12, t('error.password.max'))
      .nullable(),
    password_confirmation: Yup.string()
      .required(t('error.passwordConfirm.required'))
      .oneOf([Yup.ref('password'), null], t('error.passwordConfirm.match')),
  });
  const formik = useFormik({
    initialValues: {
      password: '',
      password_confirmation: '',
    },
    validationSchema,
    onSubmit: (values) => {
      query.mutate({
        password: values.password,
        password_confirmation: values.password_confirmation,
      });
    },
    validateOnChange: false,
  });

  return { formik, query };
};
