import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import { useIndex } from 'src/hooks/useIndex';
import MainLayout from 'src/layouts/MainLayout';
import { SignUpDataProps, TypePetEnum } from 'src/model';
import LayoutRegisterCom from '../components/LayoutRegister.Com';
import { configData } from '../config';

import RegisterPetWidget from '../widget/RegisterPet.Widget';
import SlashRegisterWidget from '../widget/SlashRegister.Widget';
import SuccessRegisterWidget from '../widget/SuccessRegister.Widget';
import TypePetRegisterWidget from '../widget/TypePetRegister.Widget';

export interface RegisterScreenProps {}

const RegisterScreen: React.FC<RegisterScreenProps> = () => {
  const nav = useIndex(3);
  const [typePet, setTypePet] = React.useState<TypePetEnum>(TypePetEnum.DOG);

  const { state } = useLocation<SignUpDataProps>();

  useEffect(() => {
    if (state) {
      nav.onIndex(2);
    }
  }, []);

  function renderScreen(): any {
    switch (nav.index) {
      case 0:
        return <SlashRegisterWidget onClick={() => nav.onIndex(1)} />;
      case 1:
        return (
          <TypePetRegisterWidget
            typePet={typePet}
            onChange={setTypePet}
            onClick={() => nav.onIndex(2)}
          />
        );
      case 2:
        return (
          <RegisterPetWidget
            typePet={typePet}
            onBack={nav.onPre}
            onNext={nav.onNext}
            data={state}
          />
        );
      case 3:
        return <SuccessRegisterWidget />;
      default:
        return <SlashRegisterWidget onClick={nav.onNext} />;
    }
  }

  const { t } = useTranslation();

  return (
    <MainLayout
      screen={IMG_CONFIG.BACKGROUND.MAIN}
      hiddenSetting
      minHeight={configData.minHeight}>
      <LayoutRegisterCom title={t('content.register.makeAccount')}>
        {renderScreen()}
      </LayoutRegisterCom>
    </MainLayout>
  );
};

export default RegisterScreen;
