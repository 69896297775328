import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import {
  EventDataProps,
  EventListQuery,
  EventRegisterDataProps,
  EventResultDataProps,
  ParticipantDataProps,
  PostVoteProps,
  RequestListProps,
} from 'src/model';
import { useState } from 'react';
import {
  DataListResponseProps,
  ResponseProps,
  RestApi,
} from 'src/model/res.model';

import { EVENT_KEY } from '../queries/keys';

import { eventApi } from '../axios/event';
import { reduxNotification } from 'src/redux/hooks/ReduxNotification';
import { useTranslation } from 'react-i18next';
import { useReduxLoading } from 'src/redux/hooks/useReduxLoading';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import { Paths } from '@utils/constants';
/**
 * @class QueryNews()
 * @method queryList()
 * @method queryDetail()
 * @returns
 */
class QueryEvent {
  key: {
    LIST: string;
    RESULT: string;
    DETAIL: string;
    PARTICIPANTS: string;
    ADVERTISEMENTS: string;
  };

  constructor() {
    this.key = EVENT_KEY;
  }

  queryList(queryData?: EventListQuery) {
    const [query, setQuery] = useState<EventListQuery>(
      queryData || {
        complete: 1,
      },
    );
    const history = useHistory();
    const useQueryData = useQuery<
      ResponseProps<{ events: DataListResponseProps<EventDataProps> }>,
      Error
    >([this.key.LIST, query], () => eventApi.getList(query), {
      // enabled: !!query.type,
      refetchOnMount: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      onError: () => {
        history.push(Paths.Event);
      },
    });
    return { query, setQuery, useQueryData };
  }

  queryAdvertisement(id: number) {
    return useQuery<any, Error>(
      [this.key.ADVERTISEMENTS, id],
      () => eventApi.getAdvertisement(id),

      {
        enabled: !!id,
        refetchOnMount: false,
        refetchOnReconnect: true,
        refetchOnWindowFocus: false,
      },
    );
  }

  queryResult(id: number) {
    const [query, setQuery] = useState<number>(id);
    const useQueryData = useQuery<
      ResponseProps<{ result: DataListResponseProps<EventResultDataProps> }>,
      Error
    >([this.key.RESULT, id], () => eventApi.getResult(query), {
      // enabled: !!query.type,
      refetchOnMount: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
    });
    return { useQueryData, query, setQuery };
  }
  queryDetail(id: number) {
    const [query, setQuery] = useState<number>(id);
    const useQueryData = useQuery<
      ResponseProps<{ event: EventDataProps }>,
      Error
    >([this.key.DETAIL, id], () => eventApi.getDetail(query), {
      // enabled: !!query.type,
      refetchOnMount: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
    });
    return { useQueryData, query, setQuery };
  }

  // vote
  queryParticipants(id: number) {
    const [queryPage, setQueryPage] = useState<RequestListProps>({
      page: 1,
    });
    const [queryId, setQueryId] = useState<number>(id);
    const useQueryData = useQuery<
      ResponseProps<{
        participants: DataListResponseProps<ParticipantDataProps>;
      }>,
      Error
    >(
      [this.key.PARTICIPANTS, id],
      () => eventApi.getParticipants(queryPage, queryId),
      {
        // enabled: !!query.type,
        refetchOnMount: false,
        refetchOnReconnect: true,
        refetchOnWindowFocus: false,
      },
    );
    return { useQueryData, queryPage, setQueryPage, queryId, setQueryId };
  }

  queryVote() {
    const queryClient = useQueryClient();
    const { useAction } = reduxNotification;
    const { t } = useTranslation();
    const { openModal, openModalError } = useAction();
    // loading
    const loading = useReduxLoading();
    return useMutation<
      ResponseProps<RestApi>,
      ResponseProps<RestApi>,
      any,
      any
    >((body: PostVoteProps) => eventApi.postVote(body), {
      onMutate() {
        loading.openLoading();
      },
      onSuccess: async (data) => {
        await queryClient.refetchQueries(this.key.PARTICIPANTS);
        await queryClient.refetchQueries(this.key.LIST);
        openModal(t('notification.voted'), data.data.message);
        loading.closeLoading();
      },
      onError: (error) => {
        openModalError(t('notification.fail'), error.message);
        loading.closeLoading();
      },
    });
  }

  queryMark() {
    // loading
    const queryClient = useQueryClient();
    return useMutation<
      ResponseProps<RestApi>,
      ResponseProps<RestApi>,
      any,
      any
    >((id: number) => eventApi.postMark(id), {
      onSuccess: () => {
        queryClient.refetchQueries(this.key.LIST);
      },
    });
  }

  // register
  queryRegister(id: number) {
    const { useAction } = reduxNotification;
    const { t } = useTranslation();
    const { openModalError } = useAction();
    const queryClient = useQueryClient();

    // loading
    const loading = useReduxLoading();
    return useMutation<
      ResponseProps<RestApi>,
      AxiosError<RestApi>,
      EventRegisterDataProps
    >((body) => eventApi.postRegister(body, id), {
      onMutate() {
        loading.openLoading();
      },
      onSuccess: () => {
        queryClient.refetchQueries(this.key.LIST);
        // openModal(t('notification.success'), data.data.message);
        loading.closeLoading();
      },
      onError: (error: any) => {
        openModalError(t('コンテスト登録について'), error.message);
        loading.closeLoading();
      },
    });
  }

  queryScroll() {
    return useInfiniteQuery<
      ResponseProps<{ events: DataListResponseProps<EventDataProps> }>,
      Error
    >(
      [this.key.LIST],
      async ({ pageParam = 0 }) => {
        const res = await eventApi.getList({
          page: pageParam + 1,
          complete: 0,
        });
        return res;
        
      },
      {
        getPreviousPageParam: (firstPage) =>
          firstPage.data.events.meta.current_page,

        getNextPageParam: (lastPage) =>
          lastPage.data.events.meta.current_page ===
          lastPage.data.events.meta.last_page
            ? undefined
            : lastPage.data.events.meta.current_page,
      },
    );
  }

  // vote
  queryParticipantsScroll (id: number) {
    return useInfiniteQuery<
      ResponseProps<{
        participants: DataListResponseProps<ParticipantDataProps>;
      }>,
      Error
    >(
      [this.key.PARTICIPANTS, id],
      async ({ pageParam = 0 }) => {
        const res = await eventApi.getParticipants(
          {
            page: pageParam + 1,
          },
          id,
        );
        
        return res;
      },
      {
        getPreviousPageParam: (firstPage) =>
          firstPage.data.participants.meta.current_page,

        getNextPageParam: (lastPage) =>
          lastPage.data.participants.meta.current_page ===
          lastPage.data.participants.meta.last_page
            ? undefined
            : lastPage.data.participants.meta.current_page,
      },
    );
  }
}

export const queryEvent = new QueryEvent();
