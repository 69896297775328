import React from 'react';
import { EventDataProps } from 'src/model';
import CardEventSoonCom from '../components/CardEventSoon.Com';

export interface ListEventWidgetProps {
  data?: EventDataProps[];
}

const ListEventWidget: React.FC<ListEventWidgetProps> = ({ data }) => {  
  return (
    <>
      {data?.map((item, i) => {
        return <CardEventSoonCom key={i} data={item} isOld={i % 2 === 0} />;
      })}
    </>
  );
};

export default ListEventWidget;
