import { request } from './axios';
import { API_URL } from './config';

class DashboardApi {
  url: { START: string; TERMS: string; FAQS: string; STAGES: string };

  constructor() {
    this.url = API_URL.DASHBOARD;
  }
  getStart() {
    return request({
      url: this.url.START,
      method: 'GET',
    });
  }
  getFaqs() {
    return request({
      url: this.url.FAQS,
      method: 'GET',
    });
  }
  getTerms() {
    return request({
      url: this.url.TERMS,
      method: 'GET',
    });
  }

  getStages() {
    return request({
      url: this.url.STAGES,
      method: 'GET',
    });
  }
}

export const dashboardApi = new DashboardApi();
