import { useEffect, useState } from 'react';
import { queryProfile } from 'src/api/hooks/queryProfile';
import { useEventListener } from 'usehooks-ts';

import { createSocketConnection } from './socketService';

function listen(
  callBack: (payload: any) => void,
  channel: string,
  event: string,
) {
  window.Echo.private(channel).listen(event, (payload: any) => {
    callBack(payload);
  });

  return function cleanUp() {
    window.Echo.leaveChannel(`private-${channel}`);
  };
}

type Options = {
  type: 'NOTIFICATION';
  callBack: (payload: any) => void;
  id?: any;
  refresh?: any;
};

export const useSocket = ({ type, callBack, id }: Options) => {
  const { useQueryData } = queryProfile.queryProfile();
  const { data } = useQueryData;
  const [s, setS] = useState(1);

  useEventListener('focus', () => {
    setS(s + 1);
  });

  useEffect(() => {
    createSocketConnection();

    switch (type) {
      case 'NOTIFICATION': {
        return listen(callBack, `notification.` + id, '.SendNotification');
      }
      default: {
        return listen(callBack, `notification.` + id, '.SendNotification');
      }
    }
  }, [data?.data.profile.id, s]);
};
