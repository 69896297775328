import { Paths } from '@utils/constants';
import { formatGender } from '@utils/helpers/formatProfile';
import moment from 'moment';
import React from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import { useFormatTypePet } from 'src/hooks/feature/useFormatTypePet';
import { useGetListStages } from 'src/hooks/feature/useGetListStages';
import { SignUpDataProps } from 'src/model';
import styled from 'styled-components';
import ItemFrameAvatarCom from '../components/ItemFrameAvatar.Com';
import ItemInforPetCom from '../components/ItemInforPet.Com';
import ViewsPetProfileCom from '../components/ViewsPetProfile.Com';
import { useConfirmRegister } from '../hook/useConfirmRegister';
import { useWidget } from '../hook/useWidget';

export interface ConfirmRegisterWidgetProps {
  data?: SignUpDataProps;
}

const ConfirmRegisterWidget: React.FC<ConfirmRegisterWidgetProps> = ({
  data,
}) => {
  const { push, t } = useWidget();
  const type = useFormatTypePet(data?.type_id ?? 0);
  
  const size = '50px';

  const { register, switchFrame, frameImg2, frameImg, query ,querySignUpMoveHome } =
    useConfirmRegister();
  const { findStage } = useGetListStages();

  return (
    <ViewsPetProfileCom
      title="アカウント名"
      name={data?.name}
      button={
        <Style.ButtonWrapper>
          <CustomIconButton
            icon={IMG_CONFIG.BUTTON.BACK}
            onClick={() => push(Paths.Register, data)}
          />
          <CustomIconButton
            loading={query.isLoading}
            icon={IMG_CONFIG.BUTTON.BACK_GAME_CENTER}
            onClick={() => 
              querySignUpMoveHome.mutate({
                ...data,
                dob : moment(data?.dob, 'YYYY-MM-DD').format('YYYY-MM-DD').toString(),
                frame_id: register.frame_id,
                active_line_id: register.active_line_id,
                active_line_name: register.active_line_name,
              })
            }
          />
        </Style.ButtonWrapper>
      }>
      <Style.Avatar>
        <div style={{ transform: 'scale(1.4)' }}>
          <ItemFrameAvatarCom
            linkPet={URL.createObjectURL(data?.avatar)}
            frame={frameImg}
          />
        </div>
        <div className="frame_secon">
          <ItemFrameAvatarCom
            linkPet={URL.createObjectURL(data?.avatar)}
            frame={frameImg2}
            onClick={switchFrame}
          />
        </div>
      </Style.Avatar>
      <Style.Wrapper>
        <Style.FormWrapper className="hidden_scroll">
          <ItemInforPetCom
            widthTitle={size}
            title={'Email'}
            value={data?.email}
          />
          <ItemInforPetCom
            widthTitle={size}
            title={t('content.profile.name')}
            value={data?.name}
          />
          <ItemInforPetCom
            widthTitle={size}
            title={t('content.profile.gender')}
            value={formatGender(data?.gender)}
          />
          <ItemInforPetCom
            widthTitle={size}
            title={t('content.profile.birthday')}
            value={moment(data?.dob, 'YYYY-MM-DD').format('YYYY年MM月DD日')}
          />
          <ItemInforPetCom
            widthTitle={size}
            title={t('content.profile.type')}
            value={type.value ?? ''}
          />
          <ItemInforPetCom
            widthTitle={size}
            title={t('content.profile.pointOfDeparture')}
            value={findStage(data?.stage_id) || ''}
          />
        </Style.FormWrapper>

        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '10px',
            paddingRight: '25px',
          }}>
          <CustomIconButton
            icon={IMG_CONFIG.REGISTER.BUTTON_VIEW_PET}
            text={t('content.profile.makeNewPet')}
            onClick={() =>
              query.mutate({
                ...data,
                dob : moment(data?.dob, 'YYYY-MM-DD').format('YYYY-MM-DD').toString(),
                frame_id: register.frame_id,
                active_line_id: register.active_line_id,
                active_line_name: register.active_line_name,
              })
            }
          />
        </div>
      </Style.Wrapper>
    </ViewsPetProfileCom>
  );
};

export default ConfirmRegisterWidget;

const Style = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    overflow-x: hidden;
    height: auto;
    width: 90%;
    padding-bottom: 0;
    padding-top: 10px;
    margin-bottom: 0;
    margin-left: 10px;
  `,
  ButtonWrapper: styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 100%;
    padding: 0 10px;
  `,
  FormWrapper: styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 7px;
    /* margin-bottom: 10px; */
    margin-top: 20px;
    height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 0px;
    padding: 10px 0;
    /* overflow-y: scroll; */
  `,
  Button: styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 20px;
    margin-bottom: 10px;
    margin-top: 10px;
  `,
  Avatar: styled.div`
    position: relative;
    margin-top: 25px;

    .frame_secon {
      position: absolute;
      right: 20px;
      bottom: 0;
      top: 11px;
      transform: scale(0.7);
      transform-origin: bottom;
    }
  `,
};
