import moment from 'moment';

// Helper to get user from localStorage

export function getStoredAuth(): any {
  const storedAuth =
    typeof window !== 'undefined' ? localStorage.getItem('auth') : '';
  return storedAuth ? JSON.parse(storedAuth) : null;
}

export function getStoredFirebase(): any {
  const storedFirebase =
    typeof window !== 'undefined' ? localStorage.getItem('firebase') : '';
  return storedFirebase ? JSON.parse(storedFirebase) : null;
}

export function checkAuth(): string {
  const now: number = moment().unix();
  const signature: any = getStoredAuth();
  const accessToken: string | null = signature ? signature.accessToken : null;
  const expiredAt: number = signature ? +signature.expiredAt : 0;
  if (!!accessToken && +now < +expiredAt) return accessToken;
  return '';
}

// set
export function setStoredAuth(auth: any): void {
  localStorage.setItem('auth', JSON.stringify(auth));
}

export function clearStoredAuth(): void {
  localStorage.removeItem('auth');
}

export function setStoredFirebase(token: any): void {
  localStorage.setItem('firebase', JSON.stringify(token));
}

export function clearStoredFirebase(): void {
  localStorage.removeItem('firebase');
}

// Set localStorage common
export function getLocalStored(key: string): any {
  const stored = typeof window !== 'undefined' ? localStorage.getItem(key) : '';
  return stored ? JSON.parse(stored) : null;
}

export function setLocalStored(key: string, data: any): void {
  localStorage.setItem(key, JSON.stringify(data));
}

export function clearLocalStored(key: string): void {
  localStorage.removeItem(key);
}
