import { Modal, ModalProps } from 'antd';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export interface CustomModalProps extends PropsWithChildren<ModalProps> {
  titleModal?: React.CSSProperties;
}

const CustomModal: React.FC<CustomModalProps> = ({
  children,

  ...props
}) => {
  return (
    <Style.Modal width={'100%'} {...props} title={null} footer={null}>
      {children}
    </Style.Modal>
  );
};

export default CustomModal;

const Style = {
  Modal: styled(Modal)`
    .ant-modal-content {
      background-color: transparent !important;
      background-clip: none;
      width: fit-content;
    }

    .ant-modal-close-x {
      width: fit-content !important;
    }

    .ant-modal-mask {
      display: none;
    }

    .ant-modal-body {
      width: fit-content;
      padding: 0;
    }
    .ant-modal-close {
      top: 30px;
      right: 30px;
    }
    .ant-modal-content {
      box-shadow: none;
      margin: auto;
    }
    &.ant-modal-mask {
      background-color: transparent !important;
    }
  `,
};
