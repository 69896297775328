import React, { useEffect, useRef } from 'react';
import CustomRatio from 'src/components/custom/button/CustomRatio';
import { AnswerDataProps } from 'src/model/stageGame.model';
import styled from 'styled-components';

export interface CardAnswerTextComProps {
  onClick?: any;
  isHide?: any;
  isSelect?: boolean;
  data?: AnswerDataProps;
  onChange?: any;
}

const CardAnswerTextCom: React.FC<CardAnswerTextComProps> = ({
  onClick,
  isHide,
  isSelect,
  data,
  onChange,
}) => {
  const ref: React.RefObject<HTMLTextAreaElement> = useRef(null);

  useEffect(() => {
    if (ref.current?.value) {
      ref.current.value = '';
    }
  }, [isSelect]);

  return (
    <Style.Wrapper isHide={isHide}>
      <Style.Header onClick={onClick}>
        <CustomRatio isSelect={isSelect} />
        <div style={{ width: '100%', marginLeft: '10px' }}>
          <p>{data?.title}</p>
        </div>
      </Style.Header>
      <Style.Input ref={ref} rows={4} cols={50} onChange={onChange} />
    </Style.Wrapper>
  );
};

export default CardAnswerTextCom;

const Style = {
  Wrapper: styled.div`
    display: ${(p: CardAnswerTextComProps) => (p.isHide ? 'grid' : 'none')};
    flex-direction: column;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;

    grid-gap: 10px;
  `,

  Header: styled.div`
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* gap: 10px; */
    cursor: pointer;
  `,

  Input: styled.textarea`
    background: #ffffff;
    border: 2px solid #d9b6a1;
    border-radius: 8px;
    width: 100%;
    padding: 10px;
    resize: none;
  `,
};
