import { useEffect } from 'react';
import { useIsOpen } from 'src/hooks/useIsOpen';

export const useUploadAvatar = (imageUrl: any, handle?: any) => {
  const open = useIsOpen();

  function handleOpen() {
    if (imageUrl) {
      open.onOpen();
    } else {
      if (handle) {
        handle();
      }
    }
  }

  useEffect(() => {
    if (imageUrl) {
      open.onOpen();
    }
  }, [imageUrl]);

  return { open, handleOpen };
};
