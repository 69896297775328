import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { queryProfile } from 'src/api/hooks/queryProfile';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import CustomModal from 'src/components/custom/modal/CustomModal';
import { useIsOpen } from 'src/hooks/useIsOpen';
import MainLayout from 'src/layouts/MainLayout';
import LayoutRegisterCom from '../components/LayoutRegister.Com';
import { configData } from '../config';
import PetListWidget from '../widget/PetList.Widget';
import PetSecondDetailWidget from '../widget/PetSecondDetail.Widget';

export interface ListPetScreenProps {}

const ListPetScreen: React.FC<ListPetScreenProps> = () => {
  const { useQueryData } = queryProfile.queryListPet();
  const { data } = useQueryData;
  const open = useIsOpen();

  const [id, setId] = useState(0);
  // function
  function handleOpenDetail(hi: number) {
    setId(hi);
    open.onOpen();
  }
  const { t } = useTranslation();
  
  return (
    <MainLayout
      screen={IMG_CONFIG.BACKGROUND.MAIN}
      minHeight={configData.minHeight}>
      <LayoutRegisterCom title={t('content.register.listPet')}>
        <PetListWidget data={data?.data.pets} onChange={handleOpenDetail} />
      </LayoutRegisterCom>

      {/* modal */}
      <CustomModal
        closeIcon={<CustomIconButton icon={IMG_CONFIG.DASHBOARD.CLOSE} />}
        footer={null}
        maskStyle={{ background: 'rgba(0,0,0,.6)' }}
        width={'400px'}
        centered
        wrapClassName="custom-modal-wrapper"
        visible={open.isOpen}
        onCancel={open.onClose}>
        <PetSecondDetailWidget id={id} setOpen={open}/>
      </CustomModal>
    </MainLayout>
  );
};

export default ListPetScreen;
