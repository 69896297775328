export enum AuthStatus {
  Verifying = 0,
  Active = 1,
  Inactive = 2,
  Fail = -1,
}

export enum RoleType {
  Admin = 1,
  User = 0,
  Develop = 2,
}
