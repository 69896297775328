// import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { ListDataMiniGame, ResultSpinData, ListItemTextPopup} from 'src/model/miniGame.model';

import { ResponseProps, RestApi } from 'src/model/res.model';
// import { reduxNotification } from 'src/redux/hooks/ReduxNotification';
import { useReduxLoading } from 'src/redux/hooks/useReduxLoading';
import { miniGameAPi } from '../axios/miniGame';
import { MINI_GAME_KEY } from '../queries/keys';

export const useQueryMiniGame = () => {
  // const { useAction } = reduxNotification;
  // const { t } = useTranslation();
  // const { openModalError } = useAction();

  // loading
  const loading = useReduxLoading();
  const queryListItem = () => {
    return useQuery<ResponseProps<ListDataMiniGame>, Error>(
      MINI_GAME_KEY.LIST,
      () => miniGameAPi.getItems(),
      {
        // enabled: !!query.type,
        refetchOnMount: false,
        refetchOnReconnect: true,
        refetchOnWindowFocus: false,
      },
    );
  };

  const queryTextPopup = () => {
    return useQuery<ResponseProps<ListItemTextPopup>, Error>(
      MINI_GAME_KEY.TEXT_POPUP,
      () => miniGameAPi.getTextPopup(),
      {
        // enabled: !!query.type,
        refetchOnMount: false,
        refetchOnReconnect: true,
        refetchOnWindowFocus: false,
      },
    );
  };

  const querySpin = () => {
    return useMutation<
      ResponseProps<ResultSpinData>,
      ResponseProps<RestApi>,
      any,
      any
    >((body: number) => miniGameAPi.postSpin(body), {
      onMutate() {
        loading.openLoading();
      },
      onSuccess: () => {
        loading.closeLoading();
      },
      onError: () => {
        loading.closeLoading();
        // openModalError(t('notification.error'), error.message);
      },
    });
  };

  return { queryListItem, querySpin, queryTextPopup};
};
