import ReactDOM from 'react-dom';
import App from './App';
import '../src/theme/i18n';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as serviceWorkerFirebase from './firebase-messaging-sw';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorkerRegistration.register();

serviceWorkerFirebase.register();
