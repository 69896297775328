import React from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import LayoutDashboard from 'src/layouts/newDashboard';
import { Image } from 'antd';
import { useHistory } from 'react-router-dom';
import RegisterEventWidget from '../widget/RegisterEvent.Widget';
import { useTranslation } from 'react-i18next';

export interface RegisterEventScreenProps {}

const RegisterEventScreen: React.FC<RegisterEventScreenProps> = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <LayoutDashboard
      title={t('content.event.name')}
      button={
        <CustomIconButton
          icon={IMG_CONFIG.BUTTON.RETURN}
          onClick={history.goBack}
        />
      }
      leftIcon={<Image src={IMG_CONFIG.DASHBOARD.CONTEST_ICON_MINI} />}
      footerContent={t('content.event.footerRegister')}>
      <RegisterEventWidget />
    </LayoutDashboard>
  );
};

export default RegisterEventScreen;
