import React from 'react';
import { useParams } from 'react-router-dom';
import { queryEvent } from 'src/api/hooks/queryEvent';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import LayoutDashboard from 'src/layouts/newDashboard';
import { useHistory } from 'react-router-dom';
import { Image } from 'antd';
import DetailEventWidget from '../widget/DetailEvent.Widget';
import { EventStatusEnum } from 'src/model';
import VoteEventWidget from '../widget/VoteEvent.Widget';
import { useTranslation } from 'react-i18next';
export interface DetailEventScreenProps {}

const DetailEventScreen: React.FC<DetailEventScreenProps> = () => {
  const { id } = useParams<any>();
  const history = useHistory();
  const { useQueryData } = queryEvent.queryDetail(id);
  const { data, isLoading, isFetching } = useQueryData;
  const { t } = useTranslation();

  function renderContent() {
    switch (data?.data.event.status) {
      case EventStatusEnum.REGISTER:
        return <DetailEventWidget data={data.data.event} />;
      case EventStatusEnum.VOTE:
        return <VoteEventWidget data={data.data.event} />;
    }
  }

  function renderFooter() {
    switch (data?.data.event.status) {
      case EventStatusEnum.REGISTER:
        return t('content.event.footerDetail');
      case EventStatusEnum.VOTE:
        return t('content.event.footerVote');
    }
  }

  return (
    <LayoutDashboard
      loading={isLoading || isFetching}
      title={t('content.event.name')}
      button={
        <CustomIconButton
          icon={IMG_CONFIG.BUTTON.RETURN}
          onClick={history.goBack}
        />
      }
      leftIcon={<Image src={IMG_CONFIG.DASHBOARD.CONTEST_ICON_MINI} preview={false} />}
      footerContent={renderFooter()}>
      {renderContent()}
    </LayoutDashboard>
  );
};

export default DetailEventScreen;
