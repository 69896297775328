import React from 'react';
import styled from 'styled-components';
import { Image } from 'antd';

export interface ItemPetSpotProps {
  pet_url?: string;
  name?: string;
}

export const ItemPetSpot: React.FC<ItemPetSpotProps> = ({ pet_url, name }) => {
  return (
    <Style.Wrapper>
      <Image src={pet_url} className="pet-img-item" />

      <Style.ImageTitle className="color_white">{name}</Style.ImageTitle>
    </Style.Wrapper>
  );
};

const Style = {
  Wrapper: styled.div`
    width: 100%;
    padding: 5px;
    /* display: grid;
    grid-template-columns: repeat(1, 1fr); */
    background-color: #ca9e67;
    border-radius: 8px;

    img {
      aspect-ratio: 1 !important;
    }

    .pet-img-item {
      border-radius: 8px;
      width: 100%;
    }
  `,

  ImageTitle: styled.div`
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    font-family: 'DFMaruGothic-SB';
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-shadow: 0px 1px 2px #af663d;
  `,
};
