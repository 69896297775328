import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { queryProfile } from 'src/api/hooks/queryProfile';

export const useFormSignIn = () => {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('error.email.email'))
      .required(t('error.email.required'))
      .max(100, t('error.email.max'))
      .nullable(),
    password: Yup.string()
      .required(t('error.password.required'))
      .min(6, t('error.password.min'))
      .max(12, t('error.password.max'))
      .nullable(),
  });

  const query = queryProfile.queryLogin();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values) => {
      query.mutate(values);
    },
  });

  return { formik, query };
};
