import React from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import styled from 'styled-components';

export interface CoinDisplayComProps {
  point?: number;
}

const CoinDisplayCom: React.FC<CoinDisplayComProps> = ({ point }) => {
  return (
    <Style.Wrapper>
      <img src={IMG_CONFIG.MAP_GAME.COIN_GAME_BG} />
      <div className="inner_wrapper">
        <p className="color_main">{point ?? 0}</p>
      </div>
    </Style.Wrapper>
  );
};

export default CoinDisplayCom;

const Style = {
  Wrapper: styled.div`
    position: relative;
    cursor: pointer;

    .inner_wrapper {
      position: absolute;
      bottom: 5px;
      right: 12px;
      height: 100%;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      padding-right: 45px;
      font-size: 30px;
    }
  `,
};
