import React from 'react';
import styled from 'styled-components';
import { ArticleDataProps } from 'src/model';
import ViewNoData from 'src/components/custom/views/ViewNoData';

export interface DetailEditorComProps {
  data?: ArticleDataProps;
}

const DetailEditorCom: React.FC<DetailEditorComProps> = ({ data }) => {
  return (
    <Style.Wrapper>
      <Style.Title>
        <p className="color_main small_text">{data?.title}</p>
      </Style.Title>
      <Style.Description style={{ marginTop: '5px' }}>
        <p className="color_des small_text">{data?.description}</p>
      </Style.Description>
      {data?.content ? (
        <div dangerouslySetInnerHTML={{ __html: data.content ?? '' }} />
      ) : (
        <ViewNoData />
      )}
    </Style.Wrapper>
  );
};

const Style = {
  Wrapper: styled.div`
    padding: 5px;
    border-radius: 5px;
    min-height: 100%;
  `,
  Title: styled.div`
    margin-bottom: 10px;
  `,
  Description: styled.div`
    margin-bottom: 5px;
  `,
  Content: styled.div`
    margin-top: 10px;
  `,
};
export default DetailEditorCom;
