import { Paths } from '@utils/constants';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import { DashboardItemData } from '../components/DashboardItem.Com';

export const listDashboard: DashboardItemData[] = [
  {
    name: 'ニュース',
    img: IMG_CONFIG.DASHBOARD.NEWS,
    path: Paths.News,
  },
  {
    name: 'ランキング',
    img: IMG_CONFIG.DASHBOARD.RANK,
    path: Paths.Ranking,
  },
  {
    name: 'GAME',
    img: IMG_CONFIG.DASHBOARD.GAME,
    path: Paths.Game,
  },
  {
    name: 'グッズ',
    img: IMG_CONFIG.DASHBOARD.FOOD,
    path: Paths.Food,
  },
  {
    name: 'コンテスト',
    img: IMG_CONFIG.DASHBOARD.EVENT,
    path: Paths.Event,
  },
  {
    name: 'CAFE',
    img: IMG_CONFIG.DASHBOARD.CAFE,
    path: Paths.Cafe,
  },
  {
    name: 'ポイントの交換',
    img: IMG_CONFIG.DASHBOARD.MARKET,
    path: Paths.PoExchange,
  },
];
