import React from 'react';
import { FoodDataProps } from 'src/model/food.model';
import styled from 'styled-components';

export interface CardFoodComProps {
  data: FoodDataProps;
}

const CardFoodCom: React.FC<CardFoodComProps> = ({ data }) => {
  return (
    <Style.Wrapper onClick={() => window.open(data?.link)}>
      {/* content______ */}
      <img src={data.image} width={'56px'} />
      <p className="color_main medium_text" style={{ marginTop: '5px' }}>
        {data.title}
      </p>
      {/* rate ______ */}
    </Style.Wrapper>
  );
};

export default CardFoodCom;

const Style = {
  Wrapper: styled.div`
    position: relative;
    width:320px !important;
    display: flex;
    flex-direction: row;
    padding: 8px;
    height: fit-content;
    justify-content: flex-start;
    align-items: center;
    background: #ffffff;
    border-radius: 8px;
    /* gap: 10px; */
    cursor: pointer;
    transform:scale(0.97);
    p {
      margin-left: 10px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    box-shadow: 3px 3px 4px 0px rgba(0,0,0,0.25);
    -webkit-box-shadow: 3px 3px 4px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 3px 3px 0px 4px rgba(0,0,0,0.25);
    margin-top:-10px;
    margin-bottom:8px;
  `,
  RateWrapper: styled.div`
    position: absolute;
    right: 10px;
    bottom: 5px;
  `,
};
