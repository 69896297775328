import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton, {
  CustomIconButtonProps,
} from 'src/components/custom/button/CustomIconButton';
import { TypePetEnum } from 'src/model';
import styled, { keyframes } from 'styled-components';
import SelectTypePetCom from '../components/SelectTypePet.Com';
import TitleRegisterCom from '../components/TitleRegister.Com';

export interface TypePetRegisterWidgetProps extends CustomIconButtonProps {
  typePet?: TypePetEnum;
  onChange?: any;
}

const config = [
  {
    type: TypePetEnum.DOG,
  },
  {
    type: TypePetEnum.CAT,
  },
  {
    type: TypePetEnum.OTHER,
  },
];

const TypePetRegisterWidget: React.FC<TypePetRegisterWidgetProps> = ({
  typePet,
  onChange,
  ...props
}) => {
  const { push } = useHistory();
  const { t } = useTranslation();
  return (
    <Style.OuterWrapper>
      <TitleRegisterCom title={t('content.register.typePetScreen')} type={1} />
      <Style.Wrapper>
        {config.map((item, i) => {
          return (
            <div key={i} onClick={() => onChange(item.type)}>
              <SelectTypePetCom
                type={item.type}
                checked={typePet === item.type}
              />
            </div>
          );
        })}
      </Style.Wrapper>
      <Style.Button>
        <CustomIconButton
          icon={IMG_CONFIG.BUTTON.BACK}
          onClick={() => push('/')}
        />
        <CustomIconButton {...props} />
      </Style.Button>
    </Style.OuterWrapper>
  );
};

export default TypePetRegisterWidget;

const animFadeIn = keyframes`
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
`;

const Style = {
  OuterWrapper: styled.div`
    padding: 0 20px;
    height:100%;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    animation: ${animFadeIn} .5s;
  `,

  Wrapper: styled.div`
    display: grid;

    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    align-items: flex-start;
    width: 100%;
    padding-left: 38px;
  `,
  Button: styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
  `,
};
