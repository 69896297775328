import React from 'react';
import { queryFood } from 'src/api/hooks/queryFood';

import { FoodDataProps } from 'src/model/food.model';
import styled from 'styled-components';
import CardFoodCom from '../components/CardFood.Com';
import { Image } from 'antd';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import { IdataAds } from 'src/pages/news/widget/ListNews.Widget';

export interface ListFoodWidgetProps {
  data?: FoodDataProps[];
}

const ListFoodWidget: React.FC<ListFoodWidgetProps> = ({ data }: any) => {
  const { data: dataQuery } = queryFood.queryAdvertisement(Number(2));

  const separateData = data?.length / 2;

  const lowerHalfData = data.slice(separateData);
  const upperHalfData = data.slice(0, separateData);

  const handleLinkTop = (items: IdataAds) => {
    return items?.link ? window.open(items?.link, '_blank') : null;
  };

  const handleLinkMiddle = (items: IdataAds) => {
    return items?.link ? window.open(items?.link, '_blank') : null;
  };

  const handleLinkBottom = (items: IdataAds) => {
    return items?.link ? window.open(items?.link, '_blank') : null;
  };

  return (
    <>
      {dataQuery?.data?.ads?.top.length > 0 && (
        <div>
          {dataQuery?.data?.ads?.top.map((items: IdataAds, i: number) => {
            return (
              <Style.WrapperTop onClick={() => handleLinkTop(items)} key={i}>
                <Style.Img>
                  <Image
                    src={items?.media || 'error'}
                    fallback={IMG_CONFIG.ERROR}
                    style={{
                      width: '50px',
                      borderRadius: '5px',
                    }}
                    preview={false}
                  />
                </Style.Img>

                <div style={{ marginLeft: '10px' }}>
                  <h5 className="color_text small_text ">{items?.title}</h5>
                  <p className="color_des small_text ">{items?.description}</p>
                </div>
              </Style.WrapperTop>
            );
          })}
        </div>
      )}

      <>
        {upperHalfData?.map((item: FoodDataProps, i: any) => {
          return <CardFoodCom key={i} data={item} />;
        })}

        {dataQuery?.data?.ads?.middle.length > 0 && (
          <div>
            {dataQuery?.data?.ads?.middle.map((items: IdataAds, i: number) => {
              return (
                <Style.WrapperTop
                  onClick={() => handleLinkMiddle(items)}
                  key={i}>
                  <Style.Img>
                    <Image
                      src={items?.media || 'error'}
                      fallback={IMG_CONFIG.ERROR}
                      style={{
                        width: '50px',
                        borderRadius: '5px',
                      }}
                      preview={false}
                    />
                  </Style.Img>

                  <div style={{ marginLeft: '10px' }}>
                    <h5 className="color_text small_text ">{items?.title}</h5>
                    <p className="color_des small_text ">
                      {items?.description}
                    </p>
                  </div>
                </Style.WrapperTop>
              );
            })}
          </div>
        )}

        {lowerHalfData?.map((item: FoodDataProps, i: any) => {
          return <CardFoodCom key={i} data={item} />;
        })}
      </>

      <div>
        {dataQuery?.data?.ads?.bottom.length > 0 && (
          <div>
            {dataQuery?.data?.ads?.bottom.map((items: IdataAds, i: number) => {
              return (
                <Style.WrapperTop
                  onClick={() => handleLinkBottom(items)}
                  key={i}>
                  <Style.Img>
                    <Image
                      src={items?.media || 'error'}
                      fallback={IMG_CONFIG.ERROR}
                      style={{
                        width: '50px',
                        borderRadius: '5px',
                      }}
                      preview={false}
                    />
                  </Style.Img>

                  <div style={{ marginLeft: '10px' }}>
                    <h5 className="color_text small_text ">{items?.title}</h5>
                    <p className="color_des small_text ">
                      {items?.description}
                    </p>
                  </div>
                </Style.WrapperTop>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default ListFoodWidget;

const Style = {
  WrapperTop: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background: #ffffff;
    border-radius: 8px;
    padding: 10px;
    /* gap: 12px; */
    cursor: pointer;
    height: fit-content;
    width: 310px !important;
    box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 3px 3px 0px 4px rgba(0, 0, 0, 0.25);
    margin-left: 5px !important;
    margin-bottom: 10px !important;

    h5 {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    p {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  `,

  Img: styled.div`
    /* transform: translateY(5px); */
  `,
};
