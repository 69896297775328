import { Paths } from '@utils/constants';
import React, { PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import ConfirmModal from 'src/components/custom/modal/ConfirmModal';
import { ImageStageProps, MapIdEnum, RewardProps, SpotProps } from 'src/model/stageGame.model';
import styled, { keyframes } from 'styled-components';
import CoinDisplayCom from '../components/CoinDisplay.Com';
import LifeStarCom from '../components/LifeStar.Com';
import StageNameCom from '../components/StageName.Com';
import { getMapImage } from '../utils/getMapImage';
import ListRewardWidget from '../widget/ListReward.Widget';
import { WrapperFinger, WrapperStyleDisplay } from './MainMap.Layout.styled';

export interface MainMapLayoutProps extends PropsWithChildren<any> {
  idMap?: MapIdEnum;
  coin?: number;
  stageImg?: string;
  star?: number;
  rewards?: RewardProps[];
  onOpen?: any;
  spots ?: SpotProps[];
  images ?: ImageStageProps;
  children_2?: React.ReactNode;
}

const MainMapLayout: React.FC<MainMapLayoutProps> = ({
  idMap,
  coin,
  stageImg,
  star,
  children,
  rewards,
  onOpen,
  spots,
  images,
  children_2,
}) => {
  const { push } = useHistory();

  return (
    <Style.Wrapper className="hidden_scroll">
      {/* header */}
      <Style.Header>
        {/* decor */}
        <span>
          <img src={IMG_CONFIG.MAP_GAME.HEADER_MAP_LA_1} />
        </span>
        <span>
          <img src={IMG_CONFIG.MAP_GAME.HEADER_MAP_LA_2} />
        </span>

        <div
          style={{
            transform: 'scale(0.5)',
            position: 'absolute',
            left: '-14px',
            top: '-13px',
          }}>
          {stageImg ? <StageNameCom stageImg={stageImg ?? ''} /> : null}
        </div>

        <WrapperFinger>
          <CustomIconButton
            icon={IMG_CONFIG.MAP_GAME.HIROBA}
            onClick={() => push(Paths.Dashboard)}
          />
        </WrapperFinger>

        <div
          style={{
            position: 'absolute',
            right: '88px',
            top: '5px',
            transform: 'scale(0.6)',
          }}>
          <LifeStarCom star={star} />
        </div>

        <WrapperStyleDisplay>
          <CoinDisplayCom point={coin} />
        </WrapperStyleDisplay>

        <div
          style={{ transform: 'scale(0.7)', position: 'absolute', top: '8px' }}>
          <CustomIconButton icon={IMG_CONFIG.BUTTON.SETTING} onClick={onOpen} />
        </div>
      </Style.Header>

      {/* content */}
      <Style.Content>
        {idMap ? (
          <img className="img_content" src={getMapImage(idMap ? idMap : 1)} />
        ) : (
          children_2
        )}
        <div className="inner_wrapper">{children}</div>
      </Style.Content>

      <ConfirmModal title="dádsđs"></ConfirmModal>

      {/* footer */}
      <Style.Footer>
        <ListRewardWidget rewards={rewards} spots={spots} images={images}/>
      </Style.Footer>
    </Style.Wrapper>
  );
};

export default MainMapLayout;

const animFadeIn = keyframes`
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
`;

const animHeaderEntry = keyframes`
  0% {
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
`;

const animContentEntry = keyframes`
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
`;

const animFooterEntry = keyframes`
  0% {
    -webkit-transform: translateY(500px);
            transform: translateY(500px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
`;

const Style = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: ${animFadeIn} 0.4s;
    margin-top: -15px;
    justify-content: center;
    height: 100%;
    overflow-y: hidden;
    @media (min-width: 768px) {
      transform: scale(1.3);
      -webkit-transform: scale(1.3, 1.3);
    }

    /* @media (max-height: 900px) {
      transform: scale(1);
      -webkit-transform: scale(1, 1);
    } */
    @media (max-height: 736px) {
      transform:scale(0.95);
      margin-top:-40px !important;
    }
  `,
  Header: styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    width: 340px;
    max-width: 340px;
    align-items: flex-end;
    background-image: url(${IMG_CONFIG.MAP_GAME.HEADER_MAP});
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    align-items: center;
    padding: 0 8px;
    max-height: 70px;
    height: 70px;
    min-height: 70px;
    animation: ${animHeaderEntry} 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.175)
      1s both;

    span {
      position: absolute;
      z-index: 30;
      transform: scale(0.5);
    }

    span:nth-child(1) {
      left: -23px;
      top: -10px;
    }
    span:nth-child(2) {
      right: -20px;
      top: -5px;
    }
  `,
  Footer: styled.div`
    position: relative;
    margin-top: -20px;
    animation: ${animFooterEntry} 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.175)
      1s both;
      @media (max-height: 667px) {
        margin-top:-53px;
      }
  `,

  Content: styled.div`
    position: relative;
    margin-top: -20px;
    animation: ${animContentEntry} 2s cubic-bezier(0.68, -0.55, 0.265, 1.55)
      0s both;

    .img_content {
      width: 360px;
    }
    .inner_wrapper {
      position: absolute;
      top: 0;
      width: 100%;
      overflow-x: hidden;
      z-index: 20;
      overflow-y: hidden;
      height: 100%;
    }
  `,
};
