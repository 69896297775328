import { useQuery } from 'react-query';

import { DataListResponseProps, ResponseProps } from 'src/model/res.model';

import { DASHBOARD_KEY } from '../queries/keys';

import {
  AdvertisementsDataProps,
  FaqsDataProps,
  StagesListDataProps,
  TermsDataProps,
  TypesPet,
} from 'src/model/dashboard.model';
import { dashboardApi } from '../axios/dashboard';

/**
 * @class QueryDashboard()
 * @method queryList()
 * @method queryDetail()
 * @returns
 */

class QueryDashboard {
  key: { LIST: string; TERMS: string; FAQS: string; STAGES: string };

  constructor() {
    this.key = DASHBOARD_KEY;
  }

  queryStart() {
    const useQueryData = useQuery<
      ResponseProps<{
        types: TypesPet;
        advertisements: DataListResponseProps<AdvertisementsDataProps>;
      }>,
      Error
    >(this.key.LIST, () => dashboardApi.getStart(), {
      // enabled: !!query.type,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    });
    return { useQueryData };
  }
  queryTerms() {
    const useQueryData = useQuery<ResponseProps<TermsDataProps>, Error>(
      this.key.TERMS,
      () => dashboardApi.getTerms(),
      {
        // enabled: !!query.type,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
      },
    );
    return { useQueryData };
  }
  queryFaqs() {
    const useQueryData = useQuery<ResponseProps<FaqsDataProps>, Error>(
      this.key.FAQS,
      () => dashboardApi.getFaqs(),
      {
        // enabled: !!query.type,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
      },
    );
    return { useQueryData };
  }

  queryStages() {
    const useQueryData = useQuery<ResponseProps<StagesListDataProps>, Error>(
      this.key.STAGES,
      () => dashboardApi.getStages(),
      {
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
      },
    );
    return { useQueryData };
  }
}

export const queryDashboard = new QueryDashboard();
