import React from 'react';
import HU from '../../../assets/icons/register/label.svg';
import styled from 'styled-components';

export interface LabelProps {
  title?: string;
}

const Label = ({ title }: LabelProps) => {
  return (
    <Style.TitleAuthBoard>
      <img src={HU} />
      <div className="inner">
        <h5 style={{ fontSize: '18px' }}>{title}</h5>
      </div>
    </Style.TitleAuthBoard>
  );
};

export default Label;

const Style = {
  TitleAuthBoard: styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0.9);

    .inner {
      position: absolute;
      top: -2px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 100%;
      color: var(--color-main) !important;
      h5 {
        color: var(--color-main) !important;
      }
    }
  `,
};
