import { queryQuestion } from 'src/api/hooks/queryQuestion';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useEffect, useState } from 'react';
import { useInputUpload } from 'src/hooks/useInputUpload';

export const useUploadSpot = (dataImage: any) => {
  const [errorLoad, setErrorLoad] = useState<string>('');
  const { state } = useLocation<number>();
  const upload = useInputUpload();
  const query = queryQuestion.queryRemoveBackgroundPet(
    upload.dimensions,
    dataImage,
  );
  const { t } = useTranslation();

  useEffect(() => {
    setErrorLoad('');
  }, [dataImage]);

  const validationSchema = Yup.object().shape({
    image: Yup.mixed().required(t('error.image.required')),
  });

  const formik = useFormik({
    initialValues: {
      image: undefined,
    },
    validationSchema,
    onSubmit: (values: any) => {
      if (dataImage?.background) {
        query.mutate(values);
      } else {
        setErrorLoad('ちょっとお待ちください。');
      }
    },
  });

  const [image, setImage] = useState<any>(null);

  const formikChangeImage = (imageData: any, imageUrl: string) => {
    formik.setFieldValue('image', imageData);
    formik.setFieldValue('spot_id', state);
    setImage(imageUrl);
  };

  return { formik, query, formikChangeImage, upload, image, state, errorLoad };
};
