import React from 'react';
import { NewsDataProps } from 'src/model';
import NewsDetailLayout from '../layout/news-detail.layout';

export interface DetailVideoComProps {
  data?: NewsDataProps;
  related_news?: NewsDataProps[];
}

const DetailVideoCom: React.FC<DetailVideoComProps> = ({
  data,
  related_news,
}) => {
  return (
    <NewsDetailLayout
      title={data?.title}
      des={data?.description}
      bg_color={'#eaeaea'}
      related_news={related_news}>
      {/* video */}

      {data?.media && (
        <video style={{ width: '100%' }} controls>
          <source src={data?.media} type="video/mp4" />
        </video>
      )}
    </NewsDetailLayout>
  );
};

export default DetailVideoCom;
