import React from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import {
  RewardProps,
  SpotProps,
  ImageStageProps,
} from 'src/model/stageGame.model';
import styled from 'styled-components';
import ItemRewardCom from '../components/item-reward';

export interface ListRewardWidgetProps {
  // star?: number;
  rewards?: RewardProps[];
  spots?: SpotProps[];
  images?: ImageStageProps;
}

export function getStar(star?: number) {
  switch (star) {
    case 1:
      return <img src={IMG_CONFIG.GAME.STAR_1} />;
    case 2:
      return <img src={IMG_CONFIG.GAME.STAR_2} />;
    case 3:
      return <img src={IMG_CONFIG.GAME.STAR_3} />;
    default:
      return null;
  }
}

const ListRewardWidget: React.FC<ListRewardWidgetProps> = ({
  // star,
  rewards,
  spots,
  images,
}) => {
  return (
    <Style.Wrapper>
      <img
        src={IMG_CONFIG.MAP_GAME.GAME_CONFIRM}
        style={{ width: '320px', height: '170px' }}
      />
      {/* star */}
      <Style.Star>{getStar(3)}</Style.Star>
      {/* reward */}
      <Style.Reward>
        <Style.List>
          {rewards?.map((item, i) => {
            const spot = spots?.find((spot) => spot.id === item.spot_id);
          return <ItemRewardCom data={item} key={i} spot={spot} index={i} images={images}/>;
          })}
        </Style.List>
      </Style.Reward>
    </Style.Wrapper>
  );
};

export default ListRewardWidget;

const Style = {
  Wrapper: styled.div`
    position: relative;
  `,
  Star: styled.div`
    position: absolute;
    top: 10px;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  `,

  Reward: styled.div`
    display: flex;
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 0 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(10px);
    top: 0;
  `,
  List: styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    grid-gap: 5px;
  `,
};
