import { Col, Row } from 'antd';
import React from 'react';
import { queryQuestion } from 'src/api/hooks/queryQuestion';
import LoadingMoreInfinityItem from 'src/components/custom/item/loading-more-infinity.item';
import ViewCustomScroll from 'src/components/custom/views/ViewCustomScroll';
import { SpotPet } from 'src/model/stageGame.model';
import styled from 'styled-components';
import { ItemPetSpot } from '../components/camera/item-pet-spot';

export interface ViewListCameraWidgetProps {
  id: number;
}

export const ViewListCameraWidget: React.FC<ViewListCameraWidgetProps> = ({
  id,
}) => {
  const {
    data: page,
    fetchNextPage,
    hasNextPage,
  } = queryQuestion.querySpotPet(id);

  function getData() {
    const dataRen: SpotPet[] = [];
    page?.pages.forEach((data) => {
      data.data.images.data.forEach((item) => {
        dataRen.push(item);
      });
    });
    return dataRen;
  }

  return (
    <Style.Wrapper>
      {getData()[0]?.image ? (
        <ViewCustomScroll>
          <Row gutter={[10, 4]}>
            {getData().map((data) => {
              return (
                <Col span={24} key={data.id}>
                  <ItemPetSpot pet_url={data.image} name={data.name} />
                </Col>
              );
            })}
            
            <Col >
              <LoadingMoreInfinityItem
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
              />
            </Col>
          </Row>
        </ViewCustomScroll>
      ) : (
        <Style.TextNoImage>データ表示なし</Style.TextNoImage>
      )}
    </Style.Wrapper>
  );
};

const Style = {
  Wrapper: styled.div`
    width: 100%;
    height: 100%;
    background-color: #eacdad;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  TextNoImage: styled.p`
    color: var(--color-main);
    font-size: 16px;
    font-weight: 400;
  `,
};
