export interface EventListQuery {
  complete?: number;
  page?: number;
}

export interface PostVoteProps {
  id?: number;
  participant_id?: number;
}

export enum EventStatusEnum {
  REGISTER = 1,
  VOTE = 2,
  COMPLETED = 3,
}

export interface EventDataProps {
  id: number;
  title: string;
  description: string;
  content: string;
  media: string;
  start_date: Date;
  end_date: Date;
  view: number;
  status: EventStatusEnum;
  is_attended: boolean;
  is_vote : boolean;
  read: boolean;
}

export interface ParticipantDataProps {
  id: number;
  image: string;
  name: string;
  is_vote: boolean;
  total_vote: number;
}

export interface EventResultDataProps {
  id: number;
  image: string;
  name: string;
  is_vote: boolean;
  total_vote: number;
}

export interface EventRegisterDataProps {
  pet_id: number;
  name ?: any;
  image: File;
}
