import React from 'react';
import { useHistory } from 'react-router-dom';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import styled from 'styled-components';

export interface DashboardItemComProps {
  item: DashboardItemData;
}

export interface DashboardItemData {
  img: any;
  name: string;
  path: string;
}

const DashboardItemCom: React.FC<DashboardItemComProps> = ({ item }) => {
  const history = useHistory();
  return (
    <Style.Wrapper onClick={() => history.push(item.path)}>
      <img src={IMG_CONFIG.DASHBOARD.FRAME} />
      <div className="inner_wrapper_dashboard">
        <img src={item.img} />
      </div>
      {/*<div className="title_dashboard">
        <Style.DashBoardText
          className="color_white"
          size={item.name === 'ポイントの交換' ? '9px' : '10px'}>
          {item.name}
        </Style.DashBoardText>
  </div>*/}
    </Style.Wrapper>
  );
};

export default DashboardItemCom;

const Style = {
  Wrapper: styled.div`
    position: relative;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;

    :hover {
      .inner_wrapper_dashboard {
      }
    }

    .title_dashboard {
      position: absolute;
      bottom: 6px;
      left: 0;
      width: 100%;
      display: flex;
      font-weight: 600;
      justify-content: center;
      -moz-text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.25);
      -webkit-text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.25);
      -ms-text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.25);
      text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.25);
      scale:1.1;
    }

    .inner_wrapper_dashboard {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
      }
    }
  `,

  DashBoardText: styled.p<{ size?: string }>`
    font-size: ${(props) => props.size || '10px'};
    text-align: center;
  `,
};
