import { supportErrorFormik } from '@utils/support/supportErrorFormik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import { uploadImgConfig } from 'src/assets/config/imgUploadConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import CustomBoxSearch from 'src/components/custom/input/CustomBoxSearch';
import CustomDate from 'src/components/custom/input/CustomDate';
import CustomInput from 'src/components/custom/input/CustomInput';
import ViewCustomScroll from 'src/components/custom/views/ViewCustomScroll';
import { ViewContent } from 'src/components/custom/views/ViewsContent';
import UploadAvatar from 'src/components/feature/UploadAvatar';
import { useUploadAvatar } from 'src/components/feature/UploadAvatar/hook/useUploadAvatar';
import ImagePetUploadCom from 'src/pages/event/components/ImagePetUpload.Com';
import { useFormSignUpMore } from 'src/hooks/formik/useFormSignUpMore';
import { useGetListTypePet } from 'src/hooks/useGetListTypePet';
import { GenderEnum, TypePetEnum } from 'src/model';
import styled, { keyframes } from 'styled-components';
import CustomSelectTypePet from '../components/CustomSelectTypePet';

export interface AddPetMoreWidgetProps {
  typePet?: TypePetEnum;
  onBack?: any;
  onNext?: any;
}

const AddPetMoreWidget: React.FC<AddPetMoreWidgetProps> = ({
  typePet,
  onBack,
  onNext,
}) => {
  const { formik, query, upload, formikChangeImage, image } =
    useFormSignUpMore();

  const { getList } = useGetListTypePet(typePet);

  useEffect(() => {
    if (query.isSuccess) {
      onNext();
      return;
    } else return;
  }, [query.isSuccess]);
  const { t } = useTranslation();
  const { open } = useUploadAvatar(upload.imgUrl);

  return (
    <Style.Wrapper>
      <ViewCustomScroll style={{ width: '103%' }}>
        <ViewContent.List space={10} style={{ paddingLeft: '2px' }}>
          <UploadAvatar
            visible={open.isOpen}
            image={upload.imgUrl}
            onCancel={open.onClose}
            handleChange={formikChangeImage}
          />
          {/* upload */}
          <Style.UploadWrarpper>
            <ImagePetUploadCom
              onClick={open.onOpen}
              imgSrc={image}
              error={supportErrorFormik(formik, 'avatar')}
            />
            <p
              className="small_text color_white"
              style={{
                padding: '0 10px',
                marginTop: '10px',
                paddingBottom: '10px',
              }}>
              {t('content.register.upload')}
            </p>
            <input
              accept={uploadImgConfig}
              name="avatar"
              type={'file'}
              style={{ display: 'none' }}
              ref={upload.hiddenFileInput}
              onChange={upload.handleChange}
            />
            <CustomIconButton
              icon={IMG_CONFIG.BUTTON.BUTTON_UPLOAD}
              onClick={upload.handleClick}
            />
          </Style.UploadWrarpper>
          <p className="color_error small_text error_wrapper">
            {query.isError && query.error.message}
          </p>
          <div style={{ width: '95%' }}>
            <ViewContent.List
              space={10}
              style={{ width: '95%', paddingLeft: '2px' }}>
              <CustomInput
                name={'name'}
                onChange={formik.handleChange}
                placeholder={t('content.register.name')}
                value={formik.values.name}
                error={supportErrorFormik(formik, 'name')}
              />
              <CustomInput.Dropdown
                name={'gender'}
                onChange={(name, value) => {
                  formik.setFieldValue(name, value);
                }}
                defaultValue={formik.values.gender}
                placeholder={t('content.register.gender')}
                error={supportErrorFormik(formik, 'gender')}
                options={[
                  {
                    label: 'オス',
                    value: GenderEnum.MALE,
                  },
                  {
                    label: 'メス',
                    value: GenderEnum.FEMALE,
                  },
                ]}
              />
              <CustomDate
                name="dob"
                value={formik.values.dob}
                error={supportErrorFormik(formik, 'dob')}
                onChangeDate={formik.setFieldValue}
                placeholder={t('content.register.birth')}
              />
              {typePet === TypePetEnum.OTHER && (
                <CustomBoxSearch
                  name={'type_id'}
                  defaultValue={formik.values.type_id}
                  placeholder={t('content.register.typePet')}
                  onChange={formik.setFieldValue}
                  options={getList()}
                  title={'種類（犬種や猫種など）'}
                  error={supportErrorFormik(formik, 'type_id')}
                />
              )}

              {typePet !== TypePetEnum.OTHER && (
                <CustomSelectTypePet
                  name={'type_id'}
                  defaultValue={formik.values.type_id}
                  placeholder={t('content.register.typePet')}
                  onChange={formik.setFieldValue}
                  options={getList()}
                  typePet={typePet}
                  title={'種類（犬種や猫種など）'}
                  error={supportErrorFormik(formik, 'type_id')}
                />
              )}

              <div style={{ paddingBottom: '30px' }}></div>
            </ViewContent.List>
          </div>
        </ViewContent.List>
      </ViewCustomScroll>
      <Style.ButtonWrapper>
        <CustomIconButton icon={IMG_CONFIG.BUTTON.BACK} onClick={onBack} />
        <CustomIconButton
          loading={query.isLoading}
          icon={IMG_CONFIG.BUTTON.CREATE_PET}
          onClick={() => formik.handleSubmit()}
        />
      </Style.ButtonWrapper>
    </Style.Wrapper>
  );
};

export default AddPetMoreWidget;

const animFadeIn = keyframes`
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
`;

const Style = {
  ButtonWrapper: styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 3px 0 0 0;
  `,
  Button: styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
  `,
  UploadWrarpper: styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    align-items: center;
    justify-items: center;
    padding-top: 0px;
    grid-gap: 0px;
    margin-top: 0px;
  `,
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    height: 100%;
    padding: 0 30px;
    animation: ${animFadeIn} 0.5s;
  `,
};
