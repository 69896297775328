import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export interface ButtonProcessComProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    StyleProps {}

const ButtonProcessCom: React.FC<ButtonProcessComProps> = ({
  isNext,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Style.Wrapper {...props} isNext={isNext && !props.disabled}>
      {isNext ? t('auth.next') : t('auth.back')}
    </Style.Wrapper>
  );
};

export default ButtonProcessCom;

interface StyleProps {
  isNext?: boolean;
}

const Style = {
  Wrapper: styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-question);
    width: 40%;
    border-radius: 10px;
    /* box-shadow: 5px 7px 15px -4px #ffe6d1; */
    padding: 10px 30px;
    background-color: ${(p: StyleProps) =>
      p.isNext ? 'var(--color-bg-process)' : 'var(--color-bg-question)'};
  `,
};
