import { configureStore } from '@reduxjs/toolkit';
import React, { PropsWithChildren } from 'react';
import { Provider } from 'react-redux';
import { rootReducer } from '../reducer/rootReducer';

export interface StoreProviderProps extends PropsWithChildren<any> {}

export const store = configureStore({
  reducer: rootReducer,
});

const StoreProvider: React.FC<StoreProviderProps> = ({ children }) => {
  return <Provider store={store}>{children}</Provider>;
};

export type RootState = ReturnType<typeof store.getState>;
export default StoreProvider;
