import { Image } from 'antd';
import React from 'react';
import { queryFrame } from 'src/api/hooks/queryFrame';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import ViewsViewNumber from 'src/components/custom/views/ViewsViewNumber';
import styled from 'styled-components';
import ItemMyFrameCom from '../components/ItemMyFrame.Com';

export interface FrameListWidgetProps {}

const FrameListWidget: React.FC<FrameListWidgetProps> = () => {
  const { useQueryData } = queryFrame.queryList();
  const { data, isLoading } = useQueryData;

  // const current = data?.data.current_frame;

  const list = data?.data.frames.data;

  return (
    <ViewsViewNumber
      view={200}
      className={'custom_scroll'}
      isNotView
      loading={isLoading}>
      <Style.Wrapper>
        <Style.TitleWrapper>
          <Image src={IMG_CONFIG.CAFE.TITLE_FRAME} preview={false} />
          <div className="text_wrapper">
            <h5 className="medium_text color_white" style={{marginTop:'-3px', fontWeight:'bolder', fontSize:'17px'}}>フレーム交換所</h5>
          </div>
        </Style.TitleWrapper>

        {/* list */}

        <Style.ListWrapper>
          {/* <ItemMyFrameCom data={current} /> */}
          {list?.map((item, i) => {
            return <ItemMyFrameCom data={item} key={i} />;
          })}
        </Style.ListWrapper>
      </Style.Wrapper>
    </ViewsViewNumber>
  );
};

export default FrameListWidget;

const Style = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: fit-content;
    background: #f0f0f0;
    min-height: 100%;
    padding: 10px 10px;
    margin-left:2px !important;
  `,

  TitleWrapper: styled.div`
    position: relative;
    transform:scale(1.1);

    .text_wrapper {
      position: absolute;
      top: 0;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  `,

  ListWrapper: styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    margin-top:10px;
  `,
};
