import React from 'react';
import styled from 'styled-components';
import { ArticleDataProps } from 'src/model';

export interface DetailVideoComProps {
  data?: ArticleDataProps;
}

const DetailVideoCom: React.FC<DetailVideoComProps> = ({ data }) => {
  return (
    <Style.Wrapper>
      <Style.Title>
        <p className="color_main small_text">{data?.title}</p>
      </Style.Title>
      <Style.Description style={{ marginTop: '5px' }}>
        <p className="color_des small_text">{data?.description}</p>
      </Style.Description>
      {/* video */}

      {data?.media && (
        <video style={{ width: '100%' }} controls>
          <source src={data?.media} type="video/mp4" />
        </video>
      )}
      {data?.content && (
        <Style.Content>
          <p className="color_des small_text">{data?.content}</p>
        </Style.Content>
      )}
    </Style.Wrapper>
  );
};

const Style = {
  Wrapper: styled.div`
    padding: 5px;
    border-radius: 5px;
    min-height: 100%;
  `,
  Title: styled.div`
    margin-bottom: 10px;
  `,
  Description: styled.div`
    margin-bottom: 5px;
  `,
  Content: styled.div`
    margin-top: 10px;
  `,
};
export default DetailVideoCom;
