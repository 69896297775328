import React from 'react';
import { NewsDataProps } from 'src/model';
import styled from 'styled-components';

export interface CardNewsInVideoComProps
  extends React.BaseHTMLAttributes<HTMLDivElement> {
  data: NewsDataProps;
}

const CardNewsInVideoCom: React.FC<CardNewsInVideoComProps> = ({
  data,
  ...props
}) => {
  return (
    <Style.Wrapper {...props}>
      <img src={data.image} />
      <Style.Content>
        <h5 className="color_main" style={{ fontSize: '12px' }}>
          {data.title}
        </h5>
        <p className="color_des" style={{ fontSize: '10px' }}>
          {data.description}
        </p>
      </Style.Content>
    </Style.Wrapper>
  );
};

export default CardNewsInVideoCom;

const Style = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 15px;
    /* gap: 10px; */
    cursor: pointer;
    :hover {
      transform: translateY(-3px);
    }
    

    img {
      width: 96px;
      border-radius: 5px;
      border: solid 2px white;
    }
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  `,
};
