import { useAppSelector } from '@hooks';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  AnswerDataReqProps,
  AnswerMultiReqProps,
  AnswerTextReqProps,
  QuestionDataProps,
  SkipReqProp,
} from 'src/model/stageGame.model';
import * as Slide from '../slide';

class ReduxAnswer {
  useData() {
    const data = useAppSelector((p) => p.answersSlide);
    return { data };
  }

  useCheck(): boolean {
    const data = useAppSelector((p) => p.answersSlide);
    let check = false;
    data.answers.some((item) => {
      const lenght = item.answer.length;
      const skip = item.skip;
      if(!skip) {
        if (!lenght) {
          return (check = true);
        }
      }
    });
    return check;
  }

  useCheckPassQuestion(question_id: number) {
    const data = useAppSelector((p) => p.answersSlide);

    const [check, setCheck] = useState<boolean>(false);

    const [checkMax, setCheckMax] = useState<boolean>(false);

    useEffect(() => {
      const itemIndex = data.answers.findIndex(
        (p) => p.question_id === question_id,
      );
      if (itemIndex > -1) {
        if (data.answers[itemIndex].skip) {
          setCheck(true);
        } else {
          if (
            data.answers[itemIndex].answer.length === 0 ||
            data.answers[itemIndex].answer.length >
              data.answers[itemIndex].max_number_of_answers
          ) {
            setCheck(false);
          } else {
            setCheck(true);
          }
        }
        if (
          data.answers[itemIndex].answer.length >
          data.answers[itemIndex].max_number_of_answers
        ) {
          setCheckMax(false);
        } else {
          setCheckMax(true);
        }
      }
    }, [question_id, data]);

    return { check, checkMax };
  }

  useAction() {
    const dispatch = useDispatch();

    function setIdSpot(id: number) {
      const payload = {
        spot_id: id,
      };
      dispatch(Slide.answerSlide.setSpotId(payload));
    }

    function setFirthAnswer(payload: QuestionDataProps[]) {
      dispatch(Slide.answerSlide.setFirthAnswer(payload));
    }

    function changeAnswer(payload: AnswerDataReqProps) {
      dispatch(Slide.answerSlide.changeAnswer(payload));
    }

    function changeAnswerMulti(payload: AnswerMultiReqProps) {
      dispatch(Slide.answerSlide.changeAnswerMulti(payload));
    }

    function changeAnswerText(payload: AnswerMultiReqProps) {
      dispatch(Slide.answerSlide.changeAnswerText(payload));
    }

    function typeAnswerText(payload: AnswerTextReqProps) {
      dispatch(Slide.answerSlide.typeAnswerText(payload));
    }

    function changeSkip(payload: SkipReqProp) {
      dispatch(Slide.answerSlide.changeSkip(payload));
    }

    return {
      setIdSpot,
      setFirthAnswer,
      changeAnswer,
      changeSkip,
      changeAnswerMulti,
      changeAnswerText,
      typeAnswerText,
    };
  }
}

export const reduxAnswer = new ReduxAnswer();
