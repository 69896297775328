import React, { PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import ViewLoading from 'src/components/custom/views/ViewLoading';
import MainLayout from 'src/layouts/MainLayout';
import styled, { keyframes } from 'styled-components';

export interface LayoutAdsComProps extends PropsWithChildren<any> {
  button?: React.ReactNode;
  loading?: boolean;
}

const LayoutAdsCom: React.FC<LayoutAdsComProps> = ({
  children,
  button,
  loading,
}) => {
  const { goBack } = useHistory();
  return (
    <MainLayout screen={IMG_CONFIG.BACKGROUND.ADS}>
      <Style.MainWrapper>
        <Style.Wrapper>
          <img src={IMG_CONFIG.BACKGROUND.ADS_BOARD} />
          {/* title */}
          <div className="inner_titie_img">
            <img
              src={IMG_CONFIG.DASHBOARD.ADS}
              style={{ width: '75px', height: '75px' }}
            />
          </div>

          {/* button right */}
          <div className="inner_button_img">
            <CustomIconButton
              icon={IMG_CONFIG.DASHBOARD.CLOSE}
              onClick={() => goBack()}
            />
          </div>
          {/* children */}
          <div className="inner_wrapper_img">
            <Style.Content>
              {loading ? <ViewLoading /> : children}
              {button}
            </Style.Content>
          </div>
        </Style.Wrapper>
      </Style.MainWrapper>
    </MainLayout>
  );
};

export default LayoutAdsCom;

const animContentEntry = keyframes`
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
`;

const Style = {
  MainWrapper: styled.div`
    @media (min-width: 768px) {
      transform: scale(1.3);
    }
    @media (max-width: 768px) {
      min-height: 600px;
    }
    position: relative;
    margin: auto;
    min-height: 800px;
    display: flex;
  `,
  Wrapper: styled.div`
    position: relative;
    margin: auto;
    animation: ${animContentEntry} 1s cubic-bezier(0.68, -0.55, 0.265, 1.55)
      both;

    .inner_wrapper_img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .inner_titie_img {
      position: absolute;
      width: 100%;
      top: -20px;
      display: flex;
      justify-content: center;
      transform: scale(1.3);
    }

    .inner_button_img {
      position: absolute;
      z-index: 10;
      top: 0;
      right: 0;
    }
  `,

  Content: styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 70px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
  `,
};
