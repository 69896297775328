import React from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import { useIsOpen } from 'src/hooks/useIsOpen';
import {
  ImageStageProps,
  RewardCameraEnum,
  RewardProps,
  SpotProps,
} from 'src/model/stageGame.model';
import styled from 'styled-components';
import ItemRewardModal from './item-reward.modal';

export interface ItemRewardComProps {
  data: RewardProps;
  spot ?: SpotProps;
  images ?: ImageStageProps;
  index ?: number;
}
// IMG_CONFIG.MAP_GAME.STAR_LIFE

const ItemRewardCom: React.FC<ItemRewardComProps> = ({ data, spot, images, index }) => {

  // render reward
  function renderImage() {
      if(data.is_camera === RewardCameraEnum.CAMERA) {
        switch (index) {
          case 0:
            return <Style.CameraImage>
              <img style={{width : '100%'}} src={images?.back_ground_1} />
              <img style={{width : '100%'}} src={images?.foreground_image_1} className='fore-ground'/>
            </Style.CameraImage>;
          case 1:
            return <Style.CameraImage>
                <img style={{width : '100%'}} src={images?.back_ground_2} />
                <img style={{width : '100%'}} src={images?.foreground_image__2} className='fore-ground'/>
            </Style.CameraImage>;
          default:
            return null;
      }
    }
  }

  //   hook
  const open = useIsOpen();

  function handleOpenModal() {
    open.onOpen();
    // if (data.status === RewardStatusEnum.RECEIVED) {
    //   open.onOpen();
    // } else {
    //   return;
    // }
  }
  return (
    <>
      {/* footer */}
      <Style.Wrapper onClick={handleOpenModal}>
        <img
          src={
            data.is_camera === RewardCameraEnum.NO_CAMERA
              ? IMG_CONFIG.MAP_GAME.ITEM_CHEST 
              : IMG_CONFIG.MAP_GAME.ITEM_FRAME
          }
        />
        {/* reward */}
        <Style.Reward>
          {/* <img src={IMG_CONFIG.MINI_GAME.MINIGAME_COIN} /> */}
          {renderImage()}
        </Style.Reward>

        {/* is received */}
        <Style.IsReceived>
          <img
            src={
              spot?.is_pass
                ? IMG_CONFIG.MAP_GAME.ITEM_PASS
                : IMG_CONFIG.MAP_GAME.ITEM_NO_PASS
            }
          />
        </Style.IsReceived>
      </Style.Wrapper>
      {/* modal */}
      <ItemRewardModal
        visible={open.isOpen}
        onCancel={open.onClose}
        data={data}
        images={images}
        index={index}
      />
      {/* <CustomModal
        visible={open.isOpen}
        onCancel={open.onClose}
        closeIcon={<div />}>
        <ItemRewardModal />
      </CustomModal> */}
    </>
  );
};

export default ItemRewardCom;

const Style = {
  Wrapper: styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    :hover {
      transform: translateY(-3px);
    }
    img {
      width: 100%;
    }
    .ant-modal-close {
      top: 0 !important;
    }
  `,
  IsReceived: styled.div`
    position: absolute;
    bottom: -8px;
  `,
  Reward: styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 40%;
    }
  `,
  CameraImage : styled.div`
      width: 52%;
      margin-left: 2px;
      margin-top: 5px;
      position: relative;
      .fore-ground {
        position: absolute;
        left: 0;
        top: 0;
      }
  `
};
