import React from 'react';
import CustomSwitch from 'src/components/custom/button/CustomSwitch';
import styled from 'styled-components';

export interface ItemInforPetComProps {
  widthTitle?: string;
  title?: string;
  value?: string;
  onSwitch?: any;
  isOn?: boolean;
}

const ItemInforPetCom: React.FC<ItemInforPetComProps> = ({
  widthTitle,
  title,
  value,
  onSwitch,
  isOn,
}) => {
  return (
    <Style.Wrapper>
      <Style.Title widthTitle={widthTitle}>
        <div className="title_wrapper">
          <p className="color_main " style={{ fontSize: '12px' }}>
            {title}
          </p>
        </div>
        <div className="value_wrapper">
          <p className="color_main " style={{ fontSize: '13px' }}>
            {value || ' '}
          </p>
        </div>
      </Style.Title>

      {onSwitch && (
        <div style={{ width: '35px', marginLeft: '5px' }}>
          <CustomSwitch onClick={onSwitch} isOn={isOn} />
        </div>
      )}
    </Style.Wrapper>
  );
};

export default ItemInforPetCom;

const Style = {
  Wrapper: styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-left:10px;
    padding-right:20px;
    /* gap: 5px; */
  `,
  Title: styled.div`
    display: flex;
    width: 100%;

    align-items: center;
    /* gap: 5px; */

    .title_wrapper {
      width: ${(p: ItemInforPetComProps) => p.widthTitle || 'max-content'};
      margin-bottom: 0px;
      width:50px;
    }
    .value_wrapper {
      background-color: #ffffff;
      border-radius: 20px;
      width: 100%;
      padding: 4px 8px;
      min-height: 25px;
    }
  `,
};
