import React from 'react';
import { queryProfile } from 'src/api/hooks/queryProfile';
import { useFormatTypePet } from 'src/hooks/feature/useFormatTypePet';
import { PetDataProps } from 'src/model/auth.model';
import styled from 'styled-components';
import ItemFrameAvatarCom from './ItemFrameAvatar.Com';

export interface ItemPetListComProps
  extends React.BaseHTMLAttributes<HTMLDivElement> {
  data?: PetDataProps;
}

const ItemPetListCom: React.FC<ItemPetListComProps> = ({ data, ...props }) => {
  const type = useFormatTypePet(data?.type ?? 0);
  const { useQueryData } = queryProfile.queryProfile();

  const { data: frame } = useQueryData;

  return (
    <Style.Wrapper {...props}>
      <Style.AvatarWrapper>
        <ItemFrameAvatarCom
          linkPet={data?.avatar}
          frame={frame?.data.profile.current_frame}
        />
      </Style.AvatarWrapper>

      <Style.Content>
        <p>{data?.name}</p>
        <p>{type.value}</p>
        <p>{data?.dob.toString()}</p>
      </Style.Content>
    </Style.Wrapper>
  );
};

export default ItemPetListCom;

const Style = {
  Wrapper: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: fit-content;
    min-height: 114px;
    gap: 10px;
    cursor: pointer;
    margin-left: 10px;
    border-bottom: 2px solid var(--color-main);
  `,

  Content: styled.div`
    margin-left: 20px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
  `,

  AvatarWrapper: styled.div`
    transform: scale(1.3);
    margin-top: 20px;
    margin-left: 10px;
  `,
};
