import { queryProduct } from 'src/api/hooks/queryProduct';
import { useIsOpen } from 'src/hooks/useIsOpen';
import { useInterval } from 'usehooks-ts';

export const useChangeItem = () => {
  const { queryExchange } = queryProduct;

  const buy = useIsOpen();

  useInterval(buy.onClose, 5000);

  const { mutate, isSuccess } = queryExchange();

  function exChange(id: number) {
    if (!buy.isOpen) {
      mutate(id);
      buy.onOpen();
    }
  }

  return { exChange, isSuccess};
};
