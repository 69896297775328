import React from 'react';
import { ParticipantDataProps, PostVoteProps } from 'src/model';
import styled from 'styled-components';
import { Image } from 'antd';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import { queryEvent } from 'src/api/hooks/queryEvent';

export interface ItemVoteComProps {
  data?: ParticipantDataProps;
  index?: number;
  idEvent?: number;
}

const ItemVoteCom: React.FC<ItemVoteComProps> = ({ data, index, idEvent }) => {
  const { mutate } = queryEvent.queryVote();
  const body: PostVoteProps = {
    id: idEvent,
    participant_id: data?.id,
  };

  function handleVote() {
    mutate(body);
  }
  return (
    <Style.Wrapper>
      <Style.TitleWrapper>
        <div className="number_pos">
          <p className="cap_text color_white">{index}</p>
        </div>
        <div className="name_pos">
          <p className="small_text color_main">{data?.name}</p>
        </div>
        <Image
          src={IMG_CONFIG.EVENT.EVENT_TITLE_VOTE}
          width={'100%'}
          preview={false}
        />
      </Style.TitleWrapper>
      <Style.ContentWrapper>
        <Image src={data?.image} width={'100%'} />
      </Style.ContentWrapper>
      <Style.ButtonWrapper>
        <CustomIconButton
          icon={
            data?.is_vote
              ? IMG_CONFIG.EVENT.EVENT_BUTTON_UNVOTE
              : IMG_CONFIG.EVENT.EVENT_BUTTON_VOTE
          }
          onClick={handleVote}
        />
      </Style.ButtonWrapper>
    </Style.Wrapper>
  );
};

export default ItemVoteCom;

const Style = {
  Wrapper: styled.div`
    display: flex;
    width: 100%;
    padding-top: 20px;
    flex-direction: column;
  `,
  TitleWrapper: styled.div`
    position: relative;
    z-index: 20;
    .number_pos {
      position: absolute;
      top: 7px;
      left: 4px;
      z-index: 30;
      display: flex;
      justify-content: center;
      width: 30px;
    }
    .name_pos {
      position: absolute;
      top: 13px;
      left: 42px;
      z-index: 30;
      display: flex;
      justify-content: flex-start;
      width: 66px;
      overflow: hidden;
      p {
        white-space: nowrap;

        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  `,
  ContentWrapper: styled.div`
    display: block;
    padding: 0 10px;
    transform: translateY(-7px);
  `,
  ButtonWrapper: styled.div`
    display: flex;
    height: 100%;
    align-items: flex-end;
    justify-content: center;
  `,
};
