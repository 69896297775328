import { useAppSelector } from '@hooks';
import { useDispatch } from 'react-redux';

import * as Slide from '../slide';
import { NotificationSlideProps } from '../slide/notificationSlide';

class ReduxNotification {
  useData() {
    const data = useAppSelector((p) => p.notificationSlide);
    return { data };
  }

  useAction() {
    const dispatch = useDispatch();

    function openModal(title: string, des: string, body ?: string) {
      const payload: NotificationSlideProps = {
        visible: true,
        title,
        des,
        body,
        isError: false,
      };
      dispatch(Slide.notificationSlide.setModal(payload));
    }

    function openModalError(title: string, des: string) {
      const payload: NotificationSlideProps = {
        visible: true,
        title,
        des,
        isError: true,
      };
      dispatch(Slide.notificationSlide.setModal(payload));
    }

    function closeModal() {
      const payload: NotificationSlideProps = {
        visible: false,
        title: '',
        des: '',
        isError: false,
      };
      dispatch(Slide.notificationSlide.setModal(payload));
    }

    return { openModal, openModalError, closeModal };
  }
}

export const reduxNotification = new ReduxNotification();
