import { supportErrorFormik } from '@utils/support/supportErrorFormik';
import React, { useEffect } from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import { uploadImgConfig } from 'src/assets/config/imgUploadConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import CustomBoxSearch from 'src/components/custom/input/CustomBoxSearch';
import ViewCustomScroll from 'src/components/custom/views/ViewCustomScroll';
import ViewsViewNumber from 'src/components/custom/views/ViewsViewNumber';
import UploadAvatar from 'src/components/feature/UploadAvatar';
import { useUploadAvatar } from 'src/components/feature/UploadAvatar/hook/useUploadAvatar';
import { useFormRegisterEvent } from 'src/hooks/formik/useFormRegisterEvent';
import styled from 'styled-components';
import ImagePetUploadCom from '../components/ImagePetUpload.Com';
import ViewRegisterSuccessCom from '../components/ViewRegisterSuccess.Com';
import { useGetListPet } from '../hook/useGetListPet';
import { queryEvent } from 'src/api/hooks/queryEvent';
import { queryProfile } from 'src/api/hooks/queryProfile';

export interface RegisterEventWidgetProps {}

const RegisterEventWidget: React.FC<RegisterEventWidgetProps> = () => {
  // const upload = useInputUpload();
  const { formik, query, formikChangeImage, image, upload, id } =
    useFormRegisterEvent();

  const { getList } = useGetListPet();
  const { useQueryData } = queryProfile.queryProfile();
  const { data } = useQueryData;

  const { open } = useUploadAvatar(upload.imgUrl);

  function getNamePet() {
    const list = getList() || [];
    if (formik.values.name) {
      return formik.values.name;
    } else {
      const pet = list.filter((item) => item.value === formik.values.pet_id);
      if (pet.length > 0) {
        return pet[0].label;
      } else return data?.data.profile.name || '';
    }
  }

  const { mutate } = queryEvent.queryMark();

  useEffect(() => {
    if (id) {
      mutate(id);
    }
  }, [id]);

  return (
    <>
      <UploadAvatar
        visible={open.isOpen}
        image={upload.imgUrl}
        onCancel={open.onClose}
        handleChange={formikChangeImage}
      />
      <ViewsViewNumber view={200} className={'hidden_scroll'} isNotView>
        {!query.isSuccess && (
          <ViewCustomScroll>
            <Style.Wrapper className="custom_scroll">
              {/* title */}
              <div style={{ marginBottom: '5px' }}>
                <h5
                  className="small_text color_white"
                  style={{ fontSize: '18px' }}>
                  ★ 参加フォーム ★
                </h5>
              </div>

              {upload?.imgUrl !== null ? (
                <ImagePetUploadCom
                  onClick={open.onOpen}
                  imgSrc={image}
                  upload={upload}
                  error={supportErrorFormik(formik, 'image')}
                />
              ) : (
                <ImagePetUploadCom
                  imgSrc={image}
                  upload={upload}
                  error={supportErrorFormik(formik, 'image')}
                />
              )}

              {/* upload */}
              <input
                id="upload"
                name="image"
                type={'file'}
                style={{ display: 'none' }}
                ref={upload.hiddenFileInput}
                onChange={upload.handleChange}
                accept={uploadImgConfig}
              />

              {/* content */}
              <Style.Text>
                アイコン作成ボタンを押して、ペットの写真をカメラロールから
                読み込み正方形内に入るよう位置調整してください。
              </Style.Text>

              <CustomIconButton
                icon={IMG_CONFIG.BUTTON.BUTTON_UPLOAD}
                onClick={upload.handleClick}
              />
              <Style.Text>
                ※
                アカウントで登録した子以外の写真の場合は以下にお名前を入力してください。
              </Style.Text>

              <CustomBoxSearch
                name={'pet_id'}
                defaultValue={'sadd'}
                placeholder={'写真の子のお名前'}
                registerEvent={true}
                onChange={formik.setFieldValue}
                options={getList()}
              />
              {/* {formik.errors.pet_id && (
                <p className="color_error small_text error_wrapper">
                  {supportErrorFormik(formik, 'pet_id')}
                </p>
              )} */}
              <Style.Text>最後に参加ボタンを押してください。</Style.Text>
              <CustomIconButton
                loading={query.isLoading}
                icon={IMG_CONFIG.EVENT.CREATE_BUTTON}
                onClick={() => formik.handleSubmit()}
              />
              <div style={{ height: '20px' }} />
            </Style.Wrapper>
          </ViewCustomScroll>
        )}

        {query.isSuccess && <ViewRegisterSuccessCom namePet={getNamePet()} />}
      </ViewsViewNumber>
    </>
  );
};

export default RegisterEventWidget;

const Style = {
  Wrapper: styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    justify-items: center;
    background: #c79f62;
    grid-gap: 15px;
    padding: 15px;
    height: fit-content;
    min-height: 100%;
    /* overflow-y: auto; */
    transform: scale(0.95);
  `,
  Text: styled.p`
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  `,
};
