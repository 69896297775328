import React, { useEffect } from 'react';
import { useQueryMiniGame } from 'src/api/hooks/useQueryMiniGame';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import OpenModal from 'src/components/custom/modal/OpenModal';
import { useIsOpen } from 'src/hooks/useIsOpen';
import { TypeItemMiniGameEnum } from 'src/model/miniGame.model';
import styled from 'styled-components';
import RollBoardCom from '../components/RollBoard.Com';
import { CONFIG_MINI_GAME } from '../config';
import { getDegResult } from '../utils/getDegResult';
import PopupGetRewardWidget from './PopupGetReward.Widget';
import { SOUND_CONFIG } from 'src/assets/config/soundConfig';
import { Howl } from 'howler';

export interface PlayMiniGameWidgetProps {}

const PlayMiniGameWidget: React.FC<PlayMiniGameWidgetProps> = () => {
  const [deg, setDeg] = React.useState(0);
  const [turnRoll, setTurnRoll] = React.useState(0);
  const { querySpin, queryListItem, queryTextPopup } = useQueryMiniGame();
  const { data } = queryListItem();
  const { data: dataTextPopup } = queryTextPopup();

  const { mutate, isSuccess, data: spin, isLoading, isError } = querySpin();
  const spinning = useIsOpen();
  const open = useIsOpen();
  const isSpin = useIsOpen();
  let sound: any;

  const renderSound = (result: number) => {
    switch (result) {
      case TypeItemMiniGameEnum.NULL:
        sound = new Howl({
          src: [SOUND_CONFIG.SOUND_MISS_GAME_WHEEL],
          volume: 1,
        });
        break;
      case TypeItemMiniGameEnum.COIN:
        sound = new Howl({
          src: [SOUND_CONFIG.SOUND_POINT_GAME_WHEEL],
          volume: 1,
        });
        break;
      default:
        sound = new Howl({
          src: [SOUND_CONFIG.SOUND_ACQUISITION],
          volume: 1,
        });
    }
  };

  useEffect(() => {
    setTurnRoll(turnRoll + 1);
    if (isSuccess) {
      setDeg(
        getDegResult(spin.data.result, data?.data.game.items) +
          turnRoll * CONFIG_MINI_GAME.NUMBER_ROTATE * 360,
      );

      setTimeout(() => {
        // open.onOpen();
        renderSound(spin.data.result.type);

        setTimeout(() => {
            sound.play();
        }, 600);

        isSpin.onClose();
        spinning.onClose();
      }, 1000 * CONFIG_MINI_GAME.DELAY_POPUP_RESULT);
    }
  }, [isSuccess]);

  useEffect(() => {
    setTimeout(() => {
      isSpin.onClose();

      if (isSuccess) {
        open.onOpen();
      }
    }, 1000 * CONFIG_MINI_GAME.DELAY_POPUP_RESULT);
  }, [isLoading]);

  useEffect(() => {
    if (isError) {
      isSpin.onClose();
      open.onOpen();
    }
  }, [isError]);
  useEffect(() => {
    setDeg(0);
  }, [mutate]);

  const spinDebound = () => {
    if (!isSpin.isOpen) {
      mutate(data?.data.game.id);
    }
    isSpin.onOpen();
  };

  useEffect(() => {
    setTimeout(() => {
      isSpin.onClose();
    }, 1000 * CONFIG_MINI_GAME.DELAY_POPUP_RESULT);
  }, [isSpin.isOpen]);

  const renderHeaderText = () => {
    switch (spin?.data.result.type) {
      case TypeItemMiniGameEnum.NULL:
        return dataTextPopup?.data.nothing.header;
      case TypeItemMiniGameEnum.COIN:
        return dataTextPopup?.data.coin.header;
      case TypeItemMiniGameEnum.FRAME:
        return dataTextPopup?.data.frame.header;
      case TypeItemMiniGameEnum.PRODUCT:
        return dataTextPopup?.data.product.header;
      default:
        return undefined;
    }
  };
  return (
    <>
      <Style.Wrapper>
        <RollBoardCom deg={deg} />
        {!isSpin.isOpen || spinning.isOpen ? (
          <Style.ButtonWrapper>
            <CustomIconButton
              icon={IMG_CONFIG.MINI_GAME.MINIGAME_GO_START}
              onClick={spinDebound}
              className="start-btn"
            />
            <CustomIconButton
              icon={IMG_CONFIG.MINI_GAME.MINIGAME_GO_RUNNING}
              onClick={spinDebound}
              className="light-btn"
            />
          </Style.ButtonWrapper>
        ) : (
          <div style={{ paddingTop: '155px', transform: 'scale(1.2)' }}>
            <CustomIconButton
              icon={IMG_CONFIG.MINI_GAME.MINIGAME_GO_RUNNING}
              className="running-btn"
            />
          </div>
        )}
      </Style.Wrapper>

      {/* modal */}

      {!isError && isSuccess ? (
        <OpenModal
          maskClosable={false}
          visible={open.isOpen}
          onCancel={open.onClose}
          titleName={renderHeaderText()}>
          <PopupGetRewardWidget
            data={spin?.data}
            dataTextPopup={dataTextPopup?.data}
          />
        </OpenModal>
      ) : (
        <OpenModal
          maskClosable={false}
          visible={open.isOpen}
          onCancel={open.onClose}
          titleName={dataTextPopup?.data.end_of_turns.header}>
          <PopupGetRewardWidget isEnd dataTextPopup={dataTextPopup?.data} />
        </OpenModal>
      )}
    </>
  );
};

export default PlayMiniGameWidget;

const Style = {
  Wrapper: styled.div`
    width: 100%;
    height: 100%;
    background-image: url(${IMG_CONFIG.MINI_GAME.MINIGAME_BG});
    background-size: cover;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    transform: scale(1.01);
    overflow: hidden;
    .running-btn {
      transform: scale(1.5);
    }
  `,
  ButtonWrapper: styled.div`
    padding-top: 155px;
    transform: scale(1.2);
    position: relative;
    .light-btn {
      position: absolute;
      top: 155.5px;
      animation: fadeIn 0.9s linear infinite;
      transform: scale(1.5);
    }
    .start-btn {
      transform: scale(1.5);
    }
    @keyframes fadeIn {
      0%,
      100% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
    }
  `,
};
