export const API_URL = {
  AUTH: {
    PROFILE: '/api/profile',
    PROFILE_UPDATE: '/api/profile/update',
    FORGOT: '/api/auth/forgot/email',
    RESET_PASSWORD: '/api/auth/reset/password',
    LOGIN: '/api/auth/login',
    LOGOUT: '/api/auth/logout',
    SIGN_UP: '/api/auth/signup',
    SIGN_UP_MORE: '/api/sign-up-more',
    ACTIVE_LIVE: '/api/active-live',
    USER_SOUND : 'api/user-sound',
    CHECK_EMAIL: '/api/auth/check-mail',
  },

  NEWS: {
    LIST: '/api/news',
    ADVERTISEMENT: '/api/advertisements/section',
    ADVERTISEMENTS: '/api/advertisements',
  },
  RANKING: {
    LIST_POINT: '/api/rank-point',
    STAGES_PET: '/api/stage',
  },
  FOOD: {
    LIST: '/api/foods',
    ADVERTISEMENT: '/api/advertisements/section',
  },

  EVENT: {
    ADVERTISEMENT: '/api/advertisements/section',
    LIST: '/api/events',
  },
  PRODUCT: {
    LIST: '/api/products',
  },
  ARTICLE: {
    LIST: '/api/articles',
  },
  DASHBOARD: {
    START: '/api/dashboard',
    TERMS: '/api/term-of-use',
    FAQS: '/api/faqs',
    STAGES: '/api/stages',
  },
  PROFILE: {
    LIST_FRAME: '/api/frames',
    LIST_PET: '/api/pets',
    UPDATE: '/api/profile/update',
    PET_UPDATE: '/api/pets',
    PET_CHANGE: '/api/switch-pet'
  },
  QUESTION: {
    CURRENT: '/api/question-game',
    QUESTION: '/api/questions',
    SAVE_REWARD: '/api/answers',
    SPOT_PET: '/api/spot',
    REMOVE_BG : 'api/remove-background',
    BACKGROUND_SPOT : 'api/background'
  },

  FRAME: {
    LIST: '/api/frames',
    DEFAULT: '/api/frames/default',
  },

  MINI_GAME: {
    LIST: '/api/mini-game',
    TEXT_POPUP : '/api/prize-popup-text'
  },

  NOTIFICATION: {
    LIST: '/api/notifications',
  },

  ADVERTISEMENTS: {
    LIST: '/api/advertisements',
  },

  CAFE: {
    LIST: '/api/cafe',
  },
};
