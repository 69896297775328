import { Image } from 'antd';
import React from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomRatio from 'src/components/custom/button/CustomRatio';
import { AnswerDataProps, AnswerTypeEnum } from 'src/model/stageGame.model';
import styled from 'styled-components';

export interface CardAnswerComProps extends StyleProps {
  data: AnswerDataProps;
  type: AnswerTypeEnum;
  onClick?: any;
}

const CardAnswerCom: React.FC<CardAnswerComProps> = ({
  data,
  type,
  isSelect,
  onClick,
  isHide,
}) => {
  const renderAnswer = () => {
    switch (type) {
      case AnswerTypeEnum.ONLY_TEXT:
        return (
          <Style.TextOnly>
            <p>{data.title || 'null'}</p>
          </Style.TextOnly>
        );
        break;
      case AnswerTypeEnum.ONLY_IMAGE:
        return (
          <Style.Content>
            <Image
              src={data.media || 'error'}
              style={{ width: '100%', borderRadius: '10px' }}
              preview={false}
              fallback={IMG_CONFIG.ERROR}
            />
          </Style.Content>
        );
      case AnswerTypeEnum.TEXT_IMAGE:
        return (
          <Style.TextImage>
            <div>
              <p>{data.title}</p>
            </div>
            <img src={data.media} />
          </Style.TextImage>
        );
      case AnswerTypeEnum.ONLY_BIG_IMAGE:
        return (
          <Style.Content>
            <Image
              src={data.media || 'error'}
              style={{ width: '100px', borderRadius: '10px' }}
              preview={false}
              fallback={IMG_CONFIG.ERROR}
            />
            {/* <img src={data.media} /> */}
          </Style.Content>
        );
    }
  };

  return (
    <Style.Wrapper onClick={onClick} isSelect={isSelect} isHide={isHide}>
      <div style={{ marginRight: '10px' }}>
        <CustomRatio isSelect={isSelect} />
      </div>
      {renderAnswer()}
    </Style.Wrapper>
  );
};

export default CardAnswerCom;

interface StyleProps {
  isHide?: any;
  isSelect?: boolean;
}

const Style = {
  Wrapper: styled.div`
    display: ${(p: StyleProps) => (p.isHide ? 'none' : 'flex')};
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border: 2px solid;

    border-color: ${(p: StyleProps) => (p.isSelect ? '#78776b' : '#d9b6a1')};
    width: 100%;
    border-radius: 8px;
    cursor: pointer;
    padding: 10px;
    /* gap: 10px; */
    :hover {
      transform: translateY(-3px);
    }
  `,
  Content: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    /* img {
      width: 100%;
      max-width: 100px;
    } */
  `,
  TextOnly: styled.div`
    width: 100%;
    display: flex;
  `,
  TextImage: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    img {
      width: 50px;
    }
  `,
};
