import React, { PropsWithChildren } from 'react';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from './theme';
import { GlobalStyle } from './global';

export interface StyleThemeProps extends PropsWithChildren<any> {}

const StyleTheme: React.FC<StyleThemeProps> = ({ children }) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};

export default StyleTheme;
