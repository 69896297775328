import { isString } from 'lodash';
import React, { PropsWithChildren } from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import Label from 'src/components/custom/item/Label';
import styled from 'styled-components';

export interface ViewBoardAuthProps extends PropsWithChildren<any> {
  title?: string;
  onCancel?: any;
  style?: React.CSSProperties;
}

const ViewBoardAuth: React.FC<ViewBoardAuthProps> = ({
  children,
  title,
  onCancel,
  style,
}) => {
  return (
    <Style.Wrapper style={style}>
      {onCancel && (
        <Style.Close>
          <CustomIconButton
            icon={IMG_CONFIG.DASHBOARD.CLOSE}
            onClick={onCancel}
          />
        </Style.Close>
      )}
      <Style.Inner isTitle={isString(title)}>
        {title && <Label title={title} />}
        <Style.Content>{children}</Style.Content>
      </Style.Inner>

      <img src={IMG_CONFIG.BACKGROUND.BOARDWOOD} />
    </Style.Wrapper>
  );
};

export default ViewBoardAuth;

interface StyleProps {
  isTitle: boolean;
}

const Style = {
  Wrapper: styled.div`
    /* height: auto; */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
    background-position: center;
    background-size: cover;
    margin: auto;
    width: fit-content;
    height: fit-content;
    /* overflow: hidden; */
  `,

  Inner: styled.div`
    position: absolute;
    padding: 0px;
    top: 20px;
    left: 0;
    width: 100%;
    height: 100%;
    padding-bottom: ${(p: StyleProps) => (p.isTitle ? '50px' : null)};
    /* overflow-y: hidden; */
  `,
  Content: styled.div`
    width: 100%;
    height: 100%;
    padding-bottom: 55px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 260px;
    margin: auto;
  `,
  Close: styled.div`
    position: absolute;
    top: 0;
    right: 0px;
    z-index: 1000;
  `,
};
