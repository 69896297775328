import { Paths } from '@utils/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import ViewCustomScroll from 'src/components/custom/views/ViewCustomScroll';
import { ViewContent } from 'src/components/custom/views/ViewsContent';
import { PetDataProps } from 'src/model';
import styled from 'styled-components';
import ItemPetListCom from '../components/ItemPetList.Com';
import ViewsPetProfileCom from '../components/ViewsPetProfile.Com';

export interface PetListWidgetProps {
  data?: PetDataProps[];
  onChange?: any;
}

const PetListWidget: React.FC<PetListWidgetProps> = ({ data, onChange }) => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <ViewsPetProfileCom
      name={t('content.profile.listPet')}
      button={
        <Style.ButtonWrapper>
          <CustomIconButton
            icon={IMG_CONFIG.BUTTON.CONTINUE1}
            onClick={() => history.push('/')}
          />
          <CustomIconButton
            icon={IMG_CONFIG.BUTTON.CREATE_PET_GREEN}
            onClick={() => history.push(Paths.Profile_AddPet)}
            imgWidth='128px'
            imgHeight='40px'
            className="button-add-pet"
          />
        </Style.ButtonWrapper>
      }>
      <Style.List>
        <ViewCustomScroll>
          <ViewContent.List
            space={15}
            className="hidden_scroll"
            style={{
              height: 'fit-content',
              paddingTop: '0px',
              paddingBottom: '20px',
              paddingLeft: '20px',
            }}>
            {data?.map((item, i) => {
              return (
                <ItemPetListCom
                  data={item}
                  key={i}
                  onClick={() => onChange(item.id)}
                />
              );
            })}
            <div style={{ height: '20px' }} />
          </ViewContent.List>
        </ViewCustomScroll>
      </Style.List>
    </ViewsPetProfileCom>
  );
};

export default PetListWidget;

const Style = {
  ButtonWrapper: styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 10px;
    @media only screen and (max-device-width: 1024px) {
      @supports (-webkit-touch-callout: none) {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 0 10px;
        margin-left: -18px;
        .button-add-pet {
           margin-left: -14px;
        }
    }
  }
    
  `,
  List: styled.div`
    width: 90%;
    height: 100%;
  `,
};
