import React from 'react';
import { useTranslation } from 'react-i18next';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import MainLayout from 'src/layouts/MainLayout';
import LayoutRegisterCom from '../components/LayoutRegister.Com';
import { configData } from '../config';
import UpdatePetWidget from '../widget/UpdatePet.Widget';

export interface UpdatePetScreenProps {}

const UpdatePetScreen: React.FC<UpdatePetScreenProps> = () => {
  const { t } = useTranslation();
  return (
    <MainLayout
      screen={IMG_CONFIG.BACKGROUND.MAIN}
      minHeight={configData.minHeight}>
      <LayoutRegisterCom title={t('content.register.update')}>
        <UpdatePetWidget />
      </LayoutRegisterCom>
    </MainLayout>
  );
};

export default UpdatePetScreen;
