import { Image, ModalProps } from 'antd';
import React from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import CustomModal from 'src/components/custom/modal/CustomModal';
import styled from 'styled-components';

export interface PreviewImageUploadProps extends ModalProps {
  image: any;
  handleClick?: any;
}

const PreviewImageUpload: React.FC<PreviewImageUploadProps> = (props) => {
  const convertDataToUrl = () => {
    const url = props.image ? URL.createObjectURL(props.image) : '';
    return url;
  };

  const handleSave = () => {
    if (props.handleClick) {
      props.handleClick(props.image, convertDataToUrl());
    }
  };

  return (
    <CustomModal
      {...props}
      footer={null}
      closeIcon={<div />}
      maskStyle={{ background: 'rgba(0,0,0,.6)' }}
      centered>
      <Style.Wrapper>
        <Style.PreviewWrapper>
          <Image
            src={convertDataToUrl()}
            className="preview-image"
            preview={false}
            style={{ borderRadius: '10px' }}
          />
        </Style.PreviewWrapper>
        <Style.ButtonWrapperPreview>
          <CustomIconButton
            icon={IMG_CONFIG.BUTTON.BACK}
            onClick={props.onCancel}
            className="btn-back"
          />
          <div onClick={props.onCancel}>
            <CustomIconButton
              icon={IMG_CONFIG.BUTTON.OK}
              onClick={handleSave}
              className="btn-ok"
            />
          </div>
        </Style.ButtonWrapperPreview>
      </Style.Wrapper>
    </CustomModal>
  );
};

export default PreviewImageUpload;

const Style = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    width: 200px;
    background-color: #fadeb1;
    border-radius: 10px;
    overflow: hidden;
    transform: scale(1.3);
    @media (min-width: 768px) {
      transform: scale(1.7);
    }
    /* height: 260px; */
    box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 1);
    -webkit-box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 1);
  `,

  PreviewWrapper: styled.div`
    width: 100%;
    .ant-image {
      width: 100%;
    }
  `,
  ButtonWrapperPreview: styled.div`
    display: flex;
    .btn-back,
    .btn-ok {
      transform: scale(0.8);
    }

    @media only screen and (max-device-width: 1024px) {
      @supports (-webkit-touch-callout: none) {
          .btn-back {
              margin-left: 28px;
          }
      }
    }
  `,
};
