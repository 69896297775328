import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import ViewBoardAuth from 'src/components/core/View/ViewBoardAuth';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import {
  ExchangeHistoryDataProps,
  ProductDataProps,
} from 'src/model/product.model';
import { Image } from 'antd';

import styled from 'styled-components';
import ViewCustomScroll from 'src/components/custom/views/ViewCustomScroll';
import { useChangeItem } from '../hooks/useChangeItem';
import { Howl } from 'howler';
import { SOUND_CONFIG } from 'src/assets/config/soundConfig';
import LinkDirectModal from '../components/LinkDirectModal.Com';
import { useIsOpen } from 'src/hooks/useIsOpen';

export interface ShopDetailWidgetProps {
  historyItemSelected?: ExchangeHistoryDataProps;
  productSelected?: ProductDataProps;
  setOpen?: any;
}

const ShopDetailWidget: React.FC<ShopDetailWidgetProps> = ({
  productSelected,
  historyItemSelected,
  setOpen,
}) => {
  const { exChange, isSuccess } = useChangeItem();
  const open = useIsOpen();
  
  const [showMore, setShowMore] = useState<boolean>(false);
  const { t } = useTranslation();


  const sound = new Howl({
    src: [SOUND_CONFIG.SOUND_POINT_REDEMPTION],
    volume: 1,
  });

  useEffect(() => {
    if (isSuccess) {
        open.onOpen();
    }
  }, [isSuccess]);

  const handleCancelExchange = () => {
    setShowMore(false);
    setOpen.onClose();
  };

  const handleExchangeProduct = (id: number) => {
    if (productSelected) {
      exChange(id);
      setTimeout(() => {
        sound.play();
      }, 300);
    } else {
      window.open('https://page.line.me/357wemvl', '_blank');
    }
    setShowMore(false);
    setOpen.onClose();
  };
  return (
    <ViewBoardAuth title={t('content.shop.name')}>
      <ViewCustomScroll>
        <Style.Wrapper>
          <Style.ContentWrapper>
            <h4 className="color_white">
              {productSelected
                ? productSelected?.name
                : historyItemSelected?.product_name}
            </h4>
            <Image
              src={
                productSelected
                  ? productSelected?.media
                  : historyItemSelected?.media || IMG_CONFIG.ERROR
              }
              width={'72%'}
              preview={false}
              style={{ borderRadius: '10px', marginTop: '10px' }}
              fallback={IMG_CONFIG.ERROR}
            />
            {productSelected && (
              <Style.Price>
                <h5 className="color_white" style={{ fontSize: '18px' }}>
                  {productSelected?.price}
                </h5>
              </Style.Price>
            )}
            {productSelected && (
              <Style.PriceWrapper>
                <Image
                  src={IMG_CONFIG.BUTTON.SHOP_BUTTON}
                  preview={false}
                  width={'127px'}
                />
              </Style.PriceWrapper>
            )}
          </Style.ContentWrapper>
          <Style.TextWrapper historyItem={historyItemSelected}>
            <p className="color_white" style={{ fontSize: '12px' }}>
              LINEで「うちの子パラダイス公式LINEアカウント」を友達登録してください。
            </p>
            {showMore && (
              <p className="color_white" style={{ fontSize: '12px' }}>
                お友達登録すると、オリジナルスタンプ作成や商品受け取りのために必要な項目をやり取りさせて頂きます。
              </p>
            )}
            <div style={{ textAlign: 'center' }}>
              <p
                className="color_des show"
                onClick={() => setShowMore(!showMore)}>
                {showMore ? '戻す' : '詳しく'}
              </p>
            </div>
          </Style.TextWrapper>
        </Style.Wrapper>
      </ViewCustomScroll>
      <Style.ButtonWrapper>
        <CustomIconButton
          icon={IMG_CONFIG.BUTTON.CANCEL_BUTTON}
          imgHeight="38px"
          className="btn-cancel"
          onClick={handleCancelExchange}
        />
        <CustomIconButton
          imgHeight="42px"
          icon={IMG_CONFIG.BUTTON.LINE_BUTTON}
          className="btn-line"
          onClick={() => handleExchangeProduct(productSelected?.id || 0)}
        />
      </Style.ButtonWrapper>
      {/* modal */}
      < LinkDirectModal
        setOpen={open}
        visible={open.isOpen}
        onCancel={open.onClose}
      />
    </ViewBoardAuth>
  );
};

export default ShopDetailWidget;

const Style = {
  Wrapper: styled.div`
    width: 100%;
    height: 86%;
  `,
  Price: styled.div`
    position: absolute;
    bottom: -42px;
    z-index: 20;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    display: flex;
    justify-content: center;

    h5 {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  `,
  PriceWrapper: styled.div`
    position: absolute;
    bottom: 10px;
    z-index: 9;
    bottom: -48px;
    z-index: -1;
    left: 50%;
    transform: translateX(-50%);
  `,
  ButtonWrapper: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    @media only screen and (max-device-width: 1024px) {
      @supports (-webkit-touch-callout: none) {
        .btn-line {
          width: 126px;
        }
        .btn-cancel {
          width: 108px;
        }
      }
    }
  `,

  ContentWrapper: styled.div`
    display: flex;
    position: relative;
    z-index: 10;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  `,
  TextWrapper: styled.div<{ historyItem?: any }>`
    width: 100%;
    margin-top: ${({ historyItem }) => (historyItem ? '20px' : '50px')};
    .show {
      cursor: pointer;
      font-size: 12px;
    }
  `,
};
