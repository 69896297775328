import {
  FormResetPasswordProps,
  LoginProps,
  SignUpDataProps,
  SignUpMoreDataProps,
  TypeActiveEnum,
} from 'src/model';
import { ResponseProps } from 'src/model/res.model';
import { request } from './axios';
import { API_URL } from './config';

import { getStoredFirebase } from '@utils/helpers/localStorage';

class AuthAPi {
  postResetPassword(value: FormResetPasswordProps) {
    const data = new FormData();
    data.append('token', value.token ?? '');
    data.append('email', value.email ?? '');
    data.append('password', value.password);
    data.append('password_confirmation', value.password_confirmation);
    return request({
      url: API_URL.AUTH.RESET_PASSWORD,
      method: 'POST',
      data,
    });
  }
  postForgotEmail(value: string) {
    const data = new FormData();

    data.append('email', value);

    return request({
      url: API_URL.AUTH.FORGOT,
      method: 'POST',
      data,
    });
  }
  getProfile() {
    return request({
      url: API_URL.AUTH.PROFILE,
      method: 'GET',
    });
  }

  postUserSound(value: {is_bgm : number}) {
    const data = new FormData();

    data.append('is_bgm', (value.is_bgm).toString());

    return request({
      url: API_URL.AUTH.USER_SOUND,
      method: 'POST',
      data,
    });
  }

  postActiveLive(type: TypeActiveEnum) {
    const params = {
      type: type,
    };
    return request({
      url: API_URL.AUTH.ACTIVE_LIVE,
      method: 'POST',
      params,
    });
  }

  postLogin(dataLogin: LoginProps) {
    const data = new FormData();
    data.append('email', dataLogin.email);
    data.append('password', dataLogin.password);
    return request({
      url: API_URL.AUTH.LOGIN,
      method: 'POST',
      data,
    });
  }
  postSignUp(dataSignUp: SignUpDataProps) {
    const data = new FormData();
    data.append('avatar', dataSignUp.avatar);
    data.append('dob', dataSignUp.dob.toString());
    data.append('gender', dataSignUp.gender.toString());
    data.append('type_id', dataSignUp.type_id.toString());
    data.append('stage_id', dataSignUp.stage_id.toString());

    data.append('name', dataSignUp.name);

    data.append('frame_id', dataSignUp.frame_id.toString());

    data.append('email', dataSignUp.email);
    data.append('password', dataSignUp.password);
    data.append(
      'password_confirmation',
      dataSignUp.password_confirmation ?? '',
    );
    return request({
      url: API_URL.AUTH.SIGN_UP,
      method: 'POST',
      data,
    });
  }

  postSignUpMore(dataSignUp: SignUpMoreDataProps) {
    const data = new FormData();
    data.append('avatar', dataSignUp.avatar);
    data.append('dob', dataSignUp.dob.toString());
    data.append('gender', dataSignUp.gender.toString());
    data.append('type_id', dataSignUp.type_id.toString());

    data.append('name', dataSignUp.name);

    return request({
      url: API_URL.AUTH.SIGN_UP_MORE,
      method: 'POST',
      data,
    });
  }

  checkEmail(
    email: string,
  ): Promise<ResponseProps<{ email_available: boolean }>> {
    const params = {
      email,
    };
    return request({
      url: API_URL.AUTH.CHECK_EMAIL,
      method: 'GET',
      params,
    });
  }

  logout(): Promise<ResponseProps<{ message: string }>> {
    const data = new FormData();

    const tokenDevice = getStoredFirebase();
    data.append('token', tokenDevice);
    return request({
      url: API_URL.AUTH.LOGOUT,
      method: 'POST',
      data,
    });
  }
}

export const authApi = new AuthAPi();
