import { queryDashboard } from 'src/api/hooks/queryDashboard';
import { TypesDataPet } from 'src/model/dashboard.model';

export const useGetListStages = () => {
  const { useQueryData } = queryDashboard.queryStages();

  const { data } = useQueryData;

  function getListStages(): TypesDataPet[] {
    const array: any = [];

    data?.data.stages.forEach((item) => {
      return array.push({
        label: item.name,
        value: item.id,
      });
    });
    return array;
  }

  function findStage(id?: number) {
    const result = data?.data.stages.find((word) => word.id === id);
    if (result) {
      return result?.name;
    } else return null;
  }
  const listStages: any = getListStages();

  return { listStages, findStage };
};
