export interface NotificationPayloadData {
  collapseKey: any;
  data: DateNotificationProps;
  from: string;
  messageId: string;
  notification: NotificationProps;
}

export interface NotificationProps {
  title: string;
  body: string;
}

export enum DateNotificationEnum {
  HOME = 'HomeScreen',
  EVENT = 'EventDetailScreen',
}

export interface DateNotificationProps {
  screen: DateNotificationEnum;
  event_id: number;
}

export interface NotificationDetailProps {
  id: number;
  title: string;
  message: string;
  data: DateNotificationProps;
  read: number;
  time: string;
}
