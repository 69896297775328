import React from 'react';
import { useHistory } from 'react-router-dom';
import LayoutDashboard from 'src/layouts/newDashboard';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import { Image } from 'antd';
import ListCafeWidget from '../widget/ListCafe.Widget';
import { queryCafeSort } from 'src/api/hooks/queryCafeSort';

import { Paths } from '@utils/constants';
export interface ListCafeScreenProps {}

const ListCafeScreen: React.FC<ListCafeScreenProps> = () => {

  const { useQueryDataCafeList } = queryCafeSort.queryList();

  const { data: dataCafe, isLoading } = useQueryDataCafeList;

  const history = useHistory();
  
  const handleChangeView = () => {
    if(history.location.state) {
      history.push(Paths.Stage);
    }
    else {
      history.push(Paths.Dashboard);
    }
  };
  return (
    <LayoutDashboard
      loading={isLoading}
      title="CAFE"
      footerContent="ウチの子と楽しい生活するための情報がカフェにはいっぱい！アイコンのフレームも交換できるよ！"
      isButton
      onGoBackView={handleChangeView}
      leftIcon={<Image src={IMG_CONFIG.DASHBOARD.CAFE_ICON_MINI} />}>
      <ListCafeWidget dataCafe={dataCafe}/>
    </LayoutDashboard>
  );
};

export default ListCafeScreen;
