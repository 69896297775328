import { useSocket } from '@utils/socket/useSocket';
// import { notification } from 'antd';
import React from 'react';
import { queryProfile } from 'src/api/hooks/queryProfile';
// import io from 'socket.io-client';
import { getIdBrowser } from '@utils/uidBrowser';

import { reduxNotification } from 'src/redux/hooks/ReduxNotification';
import { useTranslation } from 'react-i18next';

// const socket = io('https://pet-paradise-socket-dev.adamo.tech');

const AppTemplate: React.FC<React.ComponentPropsWithoutRef<'div'>> = ({
  children,
}) => {
  const { useQueryData } = queryProfile.queryProfile();
  const { data } = useQueryData;

  const { t } = useTranslation();
  const { useAction } = reduxNotification;
  const { openModal } = useAction();
  useSocket({
    type: 'NOTIFICATION',
    callBack: (payload) => {
      if (payload.notification?.body || payload.notification?.title) {
        openModal(
          t('content.notification.name'),
          payload.notification?.title,
          payload.notification?.body,
        );
      }
      // notification.open({
      //   message: payload.notification.title,
      //   description: payload.notification.body,
      //   placement: 'top',
      // });
    },
    id: data?.data.profile.id,
  });

  getIdBrowser();
  // onMessageListener().then((payload) => {
  //   if (browserName !== 'Safari') {
  //     notification.info(
  //       notificationApp(payload.notification.title, payload.notification.body),
  //     );
  //   }
  // });
  return <>{children}</>;
};

export default AppTemplate;
