import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { ArrowDownIcon } from '@icons';
import { useIsOpen } from 'src/hooks/useIsOpen';
import { useOnClickOutside } from 'usehooks-ts';
import ShowHideIcon from '../icon/ShowHideIcon';

export interface CustomInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  name?: any;
  error?: string;
  isPassword?: boolean;
  backGroundColor ?: string;
}

const CustomInput = (props: CustomInputProps) => {
  const hide = useIsOpen();
  const ref: React.RefObject<HTMLDivElement> = useRef(null);

  function renType() {
    if (hide.isOpen) {
      return 'text';
    } else {
      return 'password';
    }
  }
  return (
    <Style.InputWrapper>
      <Style.Wrapper ref={ref} style={{backgroundColor : props.backGroundColor }}>
        {/* <img src={HU} /> */}

        <Style.Inner>
          <Style.Input
            {...props}
            type={props.isPassword ? renType() : props.type}
            style={{ width: props.isPassword ? '85%' : '100%' }}
          />
        </Style.Inner>

        {props.isPassword ? (
          <Style.EyeHide onClick={hide.onToggle}>
            <ShowHideIcon isHide={hide.isOpen} />
          </Style.EyeHide>
        ) : null}
      </Style.Wrapper>
      {props.error && (
        <p
          style={{ width: `${ref.current?.clientWidth}px` }}
          className="small_text color_error">
          {props.error}
        </p>
      )}
    </Style.InputWrapper>
  );
};

export interface CustomDropdownProps {
  name?: any;
  error?: string;
  defaultValue?: any;
  placeholder?: string;
  label?: string;
  onChange?: (name?: any, value?: any) => any;
  options?: OptionsProps[];
}

export interface OptionsProps {
  label: string;
  value: any;
}

CustomInput.Dropdown = function Dropdown({
  defaultValue,
  onChange,
  placeholder,
  name,
  error,
  options,
}: CustomDropdownProps) {
  const { isOpen, onToggle, onClose } = useIsOpen();
  const ref = useRef(null);
  const [value, setValue] = useState(defaultValue);
  const [label, setLabel] = useState('');

  const handleClick1 = (value: any) => {
    setValue(value);
    if (onChange) {
      onChange(name, value);
    }

    onClose();
  };

  useEffect(() => {
    const result = options?.find((word) => word.value === value);

    setLabel(result?.label ?? '');
  }, [value]);
  useOnClickOutside(ref, onClose);
  return (
    <Style.InputWrapper onClick={onToggle} ref={ref}>
      <Style.Wrapper>
        <Style.Inner>
          <Style.Dropdown>
            <p>{value ? label : placeholder}</p>
          </Style.Dropdown>
        </Style.Inner>
        <Style.EyeHide>
          <div style={{ transform: isOpen ? 'rotate(180deg)' : 'none' }}>
            <ArrowDownIcon />
          </div>
        </Style.EyeHide>
      </Style.Wrapper>

      {isOpen && (
        <Style.DropBox>
          {options?.map((item, i) => {
            return (
              <div
                key={i}
                className="select"
                onClick={() => handleClick1(item.value)}>
                {item.label}
              </div>
            );
          })}
        </Style.DropBox>
      )}
      {error && <p className="small_text color_error">{error}</p>}
    </Style.InputWrapper>
  );
};

export default CustomInput;

const Style = {
  InputWrapper: styled.div`
    display: grid;

    grid-template-columns: repeat(1, 1fr);
    grid-gap: 5px;
    width: 100%;
  `,
  Wrapper: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 20px;
    padding: 0 12px;
    background-color: #fadeb1;
    -webkit-box-shadow: 0px 6px 0px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 6px 0px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    /* margin-bottom: 15px; */
  `,
  Inner: styled.div`
    /* position: absolute; */
    left: 15px;
    top: 5px;
    width: 100%;
  `,

  EyeHide: styled.div`
    position: absolute;
    top: 5px;
    right: 15px;
    cursor: pointer;
  `,
  Input: styled.input`
    font-family: 'DFMRG9' !important;
    color: #833e29 !important;
    font-weight: 400 !important;
    font-size: 16px;
    width: 100%;
    background-color: transparent;
    border: none;
    display: block;

    /* Solution 1 */
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    &::placeholder {
      color: #833e29 !important;
      font-size: 16px !important;
    }

    -webkit-autofill,
    -webkit-autofill:hover,
    -webkit-autofill:focus,
    -webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: transparent !important;
    }
  `,
  Dropdown: styled.div`
    font-family: 'DFMRG9' !important;
    color: #833e29 !important;
    font-weight: 400 !important;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    padding: 0;
    font-size: 16px;
    background-color: transparent;
    border: none;
    align-items: center;
    cursor: pointer;
    transform: translateY(0px);
    &::placeholder {
      color: #833e29 !important;
      font-size: 16px !important;
    }
  `,

  DropBox: styled.div`
    width: 90%;
    background-color: #fadeb1;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 5px;
    margin: 0 auto;
    gap: 5px;

    .select {
      font-family: 'DFMRG9' !important;
      color: #833e29 !important;
      font-weight: 400 !important;
      font-size: 16px;
      border-bottom: #833e29 solid 1px;
      cursor: pointer;
      :hover {
      }
    }
  `,
};
