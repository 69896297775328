import React from 'react';
import styled from 'styled-components';
import { ArticleDataProps } from 'src/model';

export interface DetailWebViewComProps {
  data?: ArticleDataProps;
}

const DetailWebViewCom: React.FC<DetailWebViewComProps> = ({ data }) => {
  return (
    <Style.Wrapper>
      <Style.Title>
        <p className="color_main small_text">{data?.title}</p>
      </Style.Title>
      <Style.Description style={{ marginTop: '5px' }}>
        <p className="color_des small_text">{data?.description}</p>
      </Style.Description>
      <iframe
        className="hidden_scroll"
        width={'100%'}
        style={{ height: '100%' }}
        src={data?.link}
        title="description"></iframe>
      {data?.content && (
        <Style.Content>
          <p className="color_des small_text">{data?.content}</p>
        </Style.Content>
      )}
    </Style.Wrapper>
  );
};

const Style = {
  Wrapper: styled.div`
    padding: 5px;
    border-radius: 5px;
    min-height: 100%;
  `,
  Title: styled.div`
    margin-bottom: 10px;
  `,
  Description: styled.div`
    margin-bottom: 5px;
  `,
  Content: styled.div`
    margin-top: 10px;
  `,
};
export default DetailWebViewCom;
