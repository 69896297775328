import React from 'react';
import ViewCustomScroll from 'src/components/custom/views/ViewCustomScroll';
import ViewsViewNumber from 'src/components/custom/views/ViewsViewNumber';
import { TypeNewsEnum } from 'src/model/news.model';
import DetailWebViewCom from '../components/DetailWebView.Com';
import DetailBannerCom from '../components/DetailBanner.Com';
import DetailEditorCom from '../components/DetailEditor.Com';
import DetailVideoCom from '../components/DetailVideo.Com';
import { ArticleDataProps } from 'src/model';

export interface DetailCafeTravelWidgetProps {
   data?: ArticleDataProps;
}

const DetailCafeTravelWidget: React.FC<DetailCafeTravelWidgetProps> = ({data}) => {
  
  const checkType = (type?: TypeNewsEnum) => {
    switch (type) {
      case TypeNewsEnum.WEB:
        return <DetailWebViewCom data={data}/>;
      case TypeNewsEnum.BANNER:
        return <DetailBannerCom data={data} />;
      case TypeNewsEnum.EDITOR:
        return <DetailEditorCom data={data} />;
      case TypeNewsEnum.VIDEO:
        return <DetailVideoCom data={data} />;
      default:
        return null;
    }
  };

  return (
    <ViewsViewNumber view={data?.view}>
      <ViewCustomScroll>{checkType(data?.type)}</ViewCustomScroll>
    </ViewsViewNumber>
  );
};

export default DetailCafeTravelWidget;
