import { ModalProps } from 'antd';
import React from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import ViewBoardAuth from 'src/components/core/View/ViewBoardAuth';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import CustomModal from 'src/components/custom/modal/CustomModal';
import styled from 'styled-components';
import { getStar } from 'src/pages/stageGame/widget/ListReward.Widget';

export interface LinkDirectModalProps extends ModalProps {
    setOpen ?: any;
}

const LinkDirectModal: React.FC<LinkDirectModalProps> = ({ setOpen, ...props }) => {
  const handleOpenLink = () => {
      window.open('https://page.line.me/357wemvl', '_blank');
      setOpen.onClose();
  };
  return (
    <CustomModal {...props} closeIcon={<div />}>
      <ViewBoardAuth onCancel={props.onCancel}>
        <Style.TotalWrapper>
          {/* main */}
          <Style.Wrapper>
            {/* star */}
            {getStar(3)}
            {/* title */}
            <h4 className="color_white" style={{ fontSize: '20px' }}>
              ポイントの交換
            </h4>
            <Style.ButtonWrapper>
              <CustomIconButton
                icon={IMG_CONFIG.BUTTON.LINE_BUTTON}
                onClick={handleOpenLink}
                imgHeight="48px"
                className="line-btn"
              />
              <CustomIconButton
                icon={IMG_CONFIG.BUTTON.CANCEL_BUTTON}
                className="cancel-btn"
                onClick={props.onCancel}
              />
            </Style.ButtonWrapper>
          </Style.Wrapper>
        </Style.TotalWrapper>
      </ViewBoardAuth>
    </CustomModal>
  );
};

export default LinkDirectModal;

const Style = {
  TotalWrapper: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
  `,

  Wrapper: styled.div`
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    grid-gap: 10px;
    justify-items: center;
    align-content: flex-start;
  `,
  ButtonWrapper: styled.div`
    margin-top: 48px;
    height: 160px;
    width: 100%;
    background: url(${IMG_CONFIG.MAP_GAME.REWARD_BG});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .line-btn {
    }

    .cancel-btn {
      margin-top: 24px;
    }
  `,
};
