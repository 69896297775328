import React from 'react';

import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import LayoutDashboard from 'src/layouts/newDashboard';

import { Image } from 'antd';

import DetailEventRankWidget from '../widget/DetailEventRank.Widget';
import { useHistory } from 'react-router-dom';
import ArticleListWidget from '../widget/ArticleList.Widget';
import FrameListWidget from '../widget/FrameList.Widget';

export interface DetailCafeScreenProps {
  type: DetailCafeTypeEnum;
}

export enum DetailCafeTypeEnum {
  EVENT = 1,
  FRAME = 2,
  ARTICLE = 3,
}

const DetailCafeScreen: React.FC<DetailCafeScreenProps> = ({ type }) => {
  const history = useHistory();

  // const { id } = useParams<any>();
  // const { useQueryData } = queryEvent.queryResult(id);

  // const { data, isLoading } = useQueryData;
  function renderContent(type: DetailCafeTypeEnum) {
    switch (type) {
      case DetailCafeTypeEnum.EVENT:
        return <DetailEventRankWidget view={20} />;
      case DetailCafeTypeEnum.FRAME:
        return <FrameListWidget />;
      case DetailCafeTypeEnum.ARTICLE:
        return <ArticleListWidget view={20} />;
    }
  }

  return (
    <LayoutDashboard
      title="CAFE"
      button={
        <CustomIconButton
          icon={IMG_CONFIG.BUTTON.RETURN}
          onClick={history.goBack}
        />
      }
      footerContent="ウチの子と楽しい生活するための情報がカフェにはいっぱい！アイコンのフレームも交換できるよ"
      leftIcon={<Image src={IMG_CONFIG.DASHBOARD.CAFE_ICON_MINI} />}>
      {renderContent(type)}
    </LayoutDashboard>
  );
};

export default DetailCafeScreen;


