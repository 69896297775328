import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useQueryAuth } from 'src/api/hooks/useQueryAuth';

export const useFormEmail = () => {
  const { t } = useTranslation();
  const { queryEmail } = useQueryAuth();

  const query = queryEmail();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('error.email.email'))
      .required(t('error.email.required'))
      .max(100, t('error.email.max'))
      .nullable(),
  });
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: (values) => {
      query.mutate(values.email);
    },
    validateOnChange: false,
  });

  return { formik, query };
};
