import React from 'react';
import { queryNews } from 'src/api/hooks/queryNews';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';

import { Image } from 'antd';
import { NewsDataProps } from 'src/model';
import styled from 'styled-components';

import CardNewsCom from '../components/CardNews.Com';

export interface ListNewsWidgetProps {
  data?: NewsDataProps[];
}

export interface IdataAds {
  media?: string;
  title?: string;
  description?: string;
  link?: string;
  id?: number;
  is_click?: number;
}

const ListNewsWidget: React.FC<ListNewsWidgetProps> = ({ data }: any) => {
  const { data: dataQuery } = queryNews.queryAdvertisement(Number(1));
  const query = queryNews.queryMarkReadSection();

  const separateData = data?.length / 2;

  const lowerHalfData = data.slice(separateData);
  const upperHalfData = data.slice(0, separateData);

  const handleLinkTop = (item: IdataAds) => {
    if (item.is_click === 0) {
      query.mutate(item.id);
    }
    return item?.link ? window.open(item?.link, '_blank') : null;
  };

  const handleLinkMiddle = (item: IdataAds) => {
    if (item.is_click === 0) {
      query.mutate(item.id);
    }
    return item?.link ? window.open(item?.link, '_blank') : null;
  };

  const handleLinkBottom = (item: IdataAds) => {
    if (item.is_click === 0) {
      query.mutate(item.id);
    }
    return item?.link ? window.open(item?.link, '_blank') : null;
  };

  return (
    <div>
      {dataQuery?.data?.ads?.top.length > 0 && (
        <div>
          {dataQuery?.data?.ads?.top.map((item: IdataAds, i: number) => {
            return (
              <div key={i}>
                <Style.WrapperTop
                  style={{ marginBottom: '5px' }}
                  onClick={() => handleLinkTop(item)}>
                  <Style.Img>
                    <Image
                      src={item?.media || 'error'}
                      fallback={IMG_CONFIG.ERROR}
                      style={{
                        width: '50px',
                        borderRadius: '5px',
                      }}
                      preview={false}
                    />
                  </Style.Img>

                  <div style={{ marginLeft: '10px' }}>
                    <h5 className="color_text small_text ">{item?.title}</h5>
                    <p className="color_des small_text ">{item?.description}</p>
                  </div>
                </Style.WrapperTop>
              </div>
            );
          })}
        </div>
      )}

      <>
        {upperHalfData?.map((item: NewsDataProps, i: number) => {
          return <CardNewsCom key={i} data={item} />;
        })}

        {dataQuery?.data?.ads?.middle.length > 0 && (
          <div>
            {dataQuery?.data?.ads?.middle.map((items: IdataAds, i: number) => {
              return (
                <div key={i}>
                  <Style.WrapperTop
                    style={{ marginBottom: '5px' }}
                    onClick={() => handleLinkMiddle(items)}>
                    <Style.Img>
                      <Image
                        src={items?.media || 'error'}
                        fallback={IMG_CONFIG.ERROR}
                        style={{
                          width: '50px',
                          borderRadius: '5px',
                        }}
                        preview={false}
                      />
                    </Style.Img>

                    <div style={{ marginLeft: '10px' }}>
                      <h5 className="color_text small_text ">{items?.title}</h5>
                      <p className="color_des small_text ">
                        {items?.description}
                      </p>
                    </div>
                  </Style.WrapperTop>
                </div>
              );
            })}
          </div>
        )}

        {lowerHalfData?.map((item: NewsDataProps, i: number) => {
          return <CardNewsCom key={i} data={item} />;
        })}
      </>

      {dataQuery?.data?.ads?.bottom.length > 0 && (
        <div>
          {dataQuery?.data?.ads?.bottom.length > 0 && (
            <div>
              {dataQuery?.data?.ads?.bottom.map(
                (items: IdataAds, i: number) => {
                  return (
                    <Style.WrapperTop
                      style={{ marginBottom: '5px' }}
                      onClick={() => handleLinkBottom(items)}
                      key={i}>
                      <Style.Img>
                        <Image
                          src={items?.media || 'error'}
                          fallback={IMG_CONFIG.ERROR}
                          style={{
                            width: '50px',
                            borderRadius: '5px',
                          }}
                          preview={false}
                        />
                      </Style.Img>

                      <div style={{ marginLeft: '10px' }}>
                        <h5 className="color_text small_text ">
                          {items?.title}
                        </h5>
                        <p className="color_des small_text ">
                          {items?.description}
                        </p>
                      </div>
                    </Style.WrapperTop>
                  );
                },
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ListNewsWidget;

const Style = {
  WrapperTop: styled.div`
    display: flex;
    width: 310px !important;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background: #ffffff;
    border-radius: 4px;
    padding: 10px;
    /* gap: 12px; */
    cursor: pointer;
    height: fit-content;
    margin-bottom: 10px !important;
    box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 3px 3px 0px 4px rgba(0, 0, 0, 0.25);

    h5 {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    p {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  `,

  Img: styled.div`
    /* transform: translateY(5px); */
  `,
};
