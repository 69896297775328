export const uploadImgConfig =
  'image/png, image/gif, image/jpeg, image/jpg, image/heic';

export const typeImg = {
  PNG: 'image/png',
  GIF: 'image/gif',
  JPEG: 'image/jpeg',
  JPG: 'image/jpg',
  HEIC: 'image/heic',
};

export function checkType(type: string) {
  const isJpgOrPng =
    type === typeImg.PNG ||
    type === typeImg.GIF ||
    type === typeImg.HEIC ||
    type === typeImg.JPEG ||
    type === typeImg.JPG;

  return isJpgOrPng;
}
