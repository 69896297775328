import React from 'react';
import { Image, ImageProps } from 'antd';
import NewsDetailLayout from '../layout/news-detail.layout';
import { NewsDataProps } from 'src/model';

export interface DetailBannerComProps extends ImageProps {
  data?: NewsDataProps;
  related_news?: NewsDataProps[];
}

const DetailBannerCom: React.FC<DetailBannerComProps> = ({
  data,
  related_news,
}) => {
  return (
    <NewsDetailLayout
      title={data?.title}
      des={data?.description}
      related_news={related_news}>
      <Image src={data?.media} />
    </NewsDetailLayout>
  );
};

export default DetailBannerCom;
