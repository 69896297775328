import { FrameDataProps } from './frame.model';

export enum TypePetEnum {
  DOG = 1,
  CAT = 2,
  OTHER = 3,
}

export enum ActiveLineEnum {
  ACTIVE = 1,
  UNACTIVE = 0,
}

export enum TypeActiveEnum {
  ID = 1,
  NAME = 2,
}

export enum GenderEnum {
  MALE = 1,
  FEMALE = 2,
}

export interface FormResetPasswordProps {
  token?: string;
  email?: string;
  password: string;
  password_confirmation: string;
}

export interface ProfileDataProps {
  id: number;
  email: string;
  name: string;
  avatar: any;
  current_frame: FrameDataProps;
  dob: Date;
  stage_id: number;
  is_bgm: number,
  gender: GenderEnum;
  type_id: number;
  pet_id: number;
  point_of_departure: string;
  line_id: string;
  line_name: string;
  active_line_id: number;
  active_line_name: number;
  active: number;
  pets: PetDataProps[];
  points: number;
  coins: number;
  star: number;
}

export interface PetDataProps {
  id: number;
  parent_id: number;
  name: string;
  avatar: string;
  dob: Date;
  gender: number;
  type: number;
  is_used: number;
  point_of_departure: string;
}

export interface ProfileUpdateProps {
  name: string;
  line_id: string;
  line_name: string;
}

export interface LoginProps {
  email: string;
  password: string;
}

export interface LoginDataProps {
  access_token: string;
  expires_at: Date;
  message: string;
  profile: ProfileDataProps;
}

export interface SoundDataProps {
  message: string;
  profile: ProfileDataProps;
}

export interface SignUpMoreDataProps {
  name: string;
  avatar: any;
  dob: Date;
  gender: GenderEnum;
  type_id: TypePetEnum;
  point_of_departure: string;
  line_id?: string;
  line_name?: string;
  active_line_id?: ActiveLineEnum;
  active_line_name?: ActiveLineEnum;
  password_confirmation?: string;
}

export interface SignUpDataProps extends SignUpMoreDataProps {
  email: string;
  password: string;
  frame_id: number;
  stage_id: number;
}

// export interface FrameDataProps {
//   id: number;
//   media: string;
//   type: number;
//   is_used: ActiveLineEnum;
// }
