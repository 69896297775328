export const DESKTOP_BS_WIDTH = 340;
export const MOBILE_DRAWER_WIDTH = 300;
export const DESKTOP_NAV_BAR_HEIGHT = 64;
export const MOBILE_NAV_BAR_HEIGHT = 58;

export const ADMIN_DESKTOP_DRAWER_WIDTH = 230;

export const FIXTURE_HEAD_WIDTH = 408;

export const TOKEN_AUTHENTICATION = 'PetParadise';

export enum SIZE {
  small,
  medium,
  large,
}

export type TypeAnimal = 'cats' | 'dogs' | 'others';