import { Paths } from '@utils/constants';
import React, { useRef } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { queryProfile } from 'src/api/hooks/queryProfile';
import { queryQuestion } from 'src/api/hooks/queryQuestion';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import MainLayout from 'src/layouts/MainLayout';
import { RewardProps } from 'src/model/stageGame.model';
import MainMapLayout from '../layout/MainMap.Layout';
import ItemRewardEndQuestion from '../widget/ItemRewardEndQuestion';

export interface RewardGameScreenProps {}

const RewardGameScreen: React.FC<RewardGameScreenProps> = () => {
  const ref = useRef<any>(null);
  const { useQueryData: question } = queryQuestion.queryListPoint();
  const { useQueryData: getProfile } = queryProfile.queryProfile();
  const { data: profile } = getProfile;

  const { state } = useLocation<RewardProps>();

  return (
    <MainLayout
      minHeight={995}
      screen={IMG_CONFIG.BACKGROUND.START}
      hiddenSetting
      pt={20}>
      <MainMapLayout
        isReward
        onOpen={() => ref.current?.show()}
        star={question?.data?.data.user_star}
        rewards={question?.data?.data.rewards}
        spots={question?.data?.data.spots}
        images = {question.data?.data.images}
        coin={profile?.data.profile.coins}
        stageImg={question?.data?.data.current_stage.media}
        children_2={
          <>
            {!state && <Redirect to={Paths.Stage} />}
            {state && <ItemRewardEndQuestion data={state} />}
          </>
        }
      />
    </MainLayout>
  );
};

export default RewardGameScreen;
