import { combineReducers } from 'redux';
import answersSlide from '../slide/answersSlide';
import notificationSlide from '../slide/notificationSlide';
import loadingSlide from '../slide/loadingSlide';
import soundSlide from '../slide/soundSlide';

export const rootReducer = combineReducers({
  answersSlide,
  notificationSlide,
  loadingSlide,
  soundSlide
});
