import { supportErrorFormik } from '@utils/support/supportErrorFormik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import { uploadImgConfig } from 'src/assets/config/imgUploadConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import CustomBoxSearch from 'src/components/custom/input/CustomBoxSearch';
import CustomDate from 'src/components/custom/input/CustomDate';
import CustomInput from 'src/components/custom/input/CustomInput';
import ViewCustomScroll from 'src/components/custom/views/ViewCustomScroll';
import { ViewContent } from 'src/components/custom/views/ViewsContent';
import { useFormSignUp } from 'src/hooks/formik/useFormSignUp';
import { useGetListTypePet } from 'src/hooks/useGetListTypePet';
import { GenderEnum, SignUpDataProps, TypePetEnum } from 'src/model';
import styled, { keyframes } from 'styled-components';

import UploadAvatar from 'src/components/feature/UploadAvatar';
import { useUploadAvatar } from 'src/components/feature/UploadAvatar/hook/useUploadAvatar';
import { useGetListStages } from 'src/hooks/feature/useGetListStages';
import ImagePetUploadCom from 'src/pages/event/components/ImagePetUpload.Com';
import CustomSelectTypePet from '../components/CustomSelectTypePet';
export interface RegisterPetWidgetProps {
  typePet?: TypePetEnum;
  onBack?: any;
  onNext?: any;
  data?: SignUpDataProps;
}

const RegisterPetWidget: React.FC<RegisterPetWidgetProps> = ({
  typePet,
  onBack,
  onNext,
  data,
}) => {
  const { t } = useTranslation();

  const { formik, query, upload, formikChangeImage, image } =
    useFormSignUp(data);

  React.useEffect(() => {
    if (query.isSuccess) {
      onNext();
    }
  }, [query.isSuccess]);

  const { getList } = useGetListTypePet(typePet);
  const { listStages } = useGetListStages();

  const { open, handleOpen } = useUploadAvatar(upload.imgUrl);

  return (
    <Style.Wrapper>
      <ViewCustomScroll style={{ width: '103%' }}>
        <ViewContent.List space={10} style={{ paddingLeft: '2px' }}>
          <UploadAvatar
            visible={open.isOpen}
            image={upload.imgUrl}
            onCancel={open.onClose}
            handleChange={formikChangeImage}
          />
          <Style.UploadWrarpper>
            <ImagePetUploadCom
              onClick={handleOpen}
              imgSrc={image}
              error={supportErrorFormik(formik, 'avatar')}
            />

            <CustomIconButton
              icon={IMG_CONFIG.BUTTON.BUTTON_UPLOAD}
              onClick={upload.handleClick}
            />
            <input
              accept={uploadImgConfig}
              name="avatar"
              type={'file'}
              style={{ display: 'none' }}
              ref={upload.hiddenFileInput}
              onChange={upload.handleChange}
            />
          </Style.UploadWrarpper>

          {/* <Style.RegisterForm className="hidden_scroll"> */}

          <CustomInput
            name={'name'}
            onChange={formik.handleChange}
            placeholder={t('content.register.name')}
            value={formik.values.name}
            error={supportErrorFormik(formik, 'name')}
          />
          <CustomInput.Dropdown
            name={'gender'}
            onChange={(name, value) => {
              formik.setFieldValue(name, value);
            }}
            defaultValue={formik.values.gender}
            placeholder={t('content.register.gender')}
            error={supportErrorFormik(formik, 'gender')}
            options={[
              {
                label: 'オス',
                value: GenderEnum.MALE,
              },
              {
                label: 'メス',
                value: GenderEnum.FEMALE,
              },
            ]}
          />
          <CustomDate
            name="dob"
            value={formik.values.dob}
            error={supportErrorFormik(formik, 'dob')}
            onChangeDate={formik.setFieldValue}
            placeholder={t('content.register.birth')}
          />

          {typePet === TypePetEnum.OTHER && (
            <CustomBoxSearch
              name={'type_id'}
              defaultValue={formik.values.type_id}
              placeholder={t('content.register.typePet')}
              onChange={formik.setFieldValue}
              options={getList()}
              title={'種類（犬種や猫種など）'}
              error={supportErrorFormik(formik, 'type_id')}
            />
          )}

          {typePet !== TypePetEnum.OTHER && (
            <CustomSelectTypePet
              name={'type_id'}
              defaultValue={formik.values.type_id}
              placeholder={t('content.register.typePet')}
              onChange={formik.setFieldValue}
              options={getList()}
              typePet={typePet}
              title={'種類（犬種や猫種など）'}
              error={supportErrorFormik(formik, 'type_id')}
            />
          )}

          {/* {formik.errors.type_id && (
            <p className="color_error small_text error_wrapper">
              {supportErrorFormik(formik, 'type_id')}
            </p>
          )} */}
          <CustomBoxSearch
            name={'stage_id'}
            defaultValue={formik.values.stage_id}
            placeholder={t('content.register.pointOfDeparture')}
            onChange={formik.setFieldValue}
            options={listStages}
            title={'地域を選択してください'}
          />
          <p
            className="color_white"
            style={{
              fontSize: '13px',
              marginLeft: '8px',
              lineHeight: '15px',
              paddingBottom: '0',
            }}>
            *お住まいの地域が出発地になります。
          </p>
          {formik.errors.stage_id && (
            <p className="color_error small_text error_wrapper">
              {supportErrorFormik(formik, 'stage_id')}
            </p>
          )}

          <CustomInput
            autoComplete={'off'}
            onChange={formik.handleChange}
            value={formik.values.email}
            error={supportErrorFormik(formik, 'email')}
            name={'email'}
            placeholder={t('auth.email')}
          />
          <CustomInput
            isPassword
            autoComplete={'off'}
            type={'password'}
            onChange={formik.handleChange}
            value={formik.values.password}
            error={supportErrorFormik(formik, 'password')}
            name={'password'}
            placeholder={t('auth.password')}
          />
          <CustomInput
            isPassword
            type={'password'}
            name={'password_confirmation'}
            onChange={formik.handleChange}
            value={formik.values.password_confirmation}
            error={supportErrorFormik(formik, 'password_confirmation')}
            placeholder={t('auth.confirmPassword')}
          />

          <p
            className="color_white"
            style={{
              fontSize: '13px',
              marginLeft: '8px',
              lineHeight: '15px',
              paddingBottom: '30px',
            }}>
            *パスワードは6文字以上、英文字と数字のみになります。
          </p>

          {/* </Style.RegisterForm> */}
        </ViewContent.List>
      </ViewCustomScroll>

      <Style.ButtonWrapper>
        <CustomIconButton icon={IMG_CONFIG.BUTTON.BACK} onClick={onBack} />
        <CustomIconButton
          icon={IMG_CONFIG.BUTTON.CONFIRM}
          onClick={() => formik.handleSubmit()}
        />
      </Style.ButtonWrapper>
    </Style.Wrapper>
  );
};

export default RegisterPetWidget;

const animFadeIn = keyframes`
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
`;

const Style = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    height: 100%;
    padding: 0 30px;
    animation: ${animFadeIn} 0.5s;
  `,

  UploadWrarpper: styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    align-items: center;
    justify-items: center;
    padding-top: 0px;
    grid-gap: 0px;
    margin-top: -10px;
  `,

  RegisterForm: styled.form`
    max-height: 200px;
    border-radius: 10px;
    flex: 1;
    padding: 20px 10px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    background-color: #7d3f26;
    overflow-y: auto;
    grid-gap: 10px;
  `,
  ButtonWrapper: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 3px 0 0 0;
  `,
};
