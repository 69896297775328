import React from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import styled from 'styled-components';

export interface ButtonFooterComProps {
  onBack?: any;
  onSubmit?: any;
}

const ButtonFooterCom: React.FC<ButtonFooterComProps> = ({
  onBack,
  onSubmit,
}) => {
  return (
    <Style.ButtonWraper>
      <CustomIconButton icon={IMG_CONFIG.BUTTON.BACK} onClick={onBack} />
      <CustomIconButton onClick={onSubmit} />
    </Style.ButtonWraper>
  );
};

export default ButtonFooterCom;

const Style = {
  ButtonWraper: styled.div`
    display: flex;
    justify-content: center;
    gap: 30px;
    width: 100%;
  `,
};
