import React from 'react';
import styled from 'styled-components';

export interface ViewNoDataProps {
  isNoData?: boolean;
}

const ViewNoData: React.FC<ViewNoDataProps> = ({ isNoData }) => {
  return (
    <Style.Wrapper isNoData={isNoData}>
      <p className="color_error">表示するデータがありません</p>
    </Style.Wrapper>
  );
};

export default ViewNoData;

const Style = {
  Wrapper: styled.div`
    width: 100%;
    height: 100%;
    display: ${(p: ViewNoDataProps) => (p.isNoData ? 'none' : 'flex')};
    justify-content: center;
    align-items: center;
  `,
};
