import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import { MapIdEnum } from 'src/model/stageGame.model';

export function getMapImage(id: MapIdEnum) {
  switch (id) {
    case MapIdEnum.MAP_1:
      return IMG_CONFIG.MAP_GAME.MAP_1;
    case MapIdEnum.MAP_2:
      return IMG_CONFIG.MAP_GAME.MAP_2;
    case MapIdEnum.MAP_3:
      return IMG_CONFIG.MAP_GAME.MAP_3;
    case MapIdEnum.MAP_4:
      return IMG_CONFIG.MAP_GAME.MAP_4;
    case MapIdEnum.MAP_5:
      return IMG_CONFIG.MAP_GAME.MAP_5;
    case MapIdEnum.MAP_6:
      return IMG_CONFIG.MAP_GAME.MAP_6;
    case MapIdEnum.MAP_7:
      return IMG_CONFIG.MAP_GAME.MAP_7;
    case MapIdEnum.MAP_8:
      return IMG_CONFIG.MAP_GAME.MAP_8;
    case MapIdEnum.MAP_9:
      return IMG_CONFIG.MAP_GAME.MAP_9;

    case MapIdEnum.MAP_10:
      return IMG_CONFIG.MAP_GAME.MAP_10;
    case MapIdEnum.MAP_11:
      return IMG_CONFIG.MAP_GAME.MAP_11;
    case MapIdEnum.MAP_12:
      return IMG_CONFIG.MAP_GAME.MAP_12;
  }
}
