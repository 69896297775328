import { useEffect, useState } from 'react';
import { queryDashboard } from 'src/api/hooks/queryDashboard';
import { TypePetEnum } from 'src/model';

export const useFormatTypePet = (id: number) => {
  const { useQueryData } = queryDashboard.queryStart();
  const { data } = useQueryData;
  const [value, setValue] = useState<string | null>('');
  const [type, setType] = useState<TypePetEnum>(TypePetEnum.DOG);

  function findDog(id?: number) {
    const result = data?.data.types.dogs?.find((word) => word.id === id);
    if (result) {
      return result?.name;
    } else return null;
  }
  function findCat(id?: number) {
    const result = data?.data.types.cats?.find((word) => word.id === id);
    if (result) {
      return result?.name;
    } else return null;
  }
  function findOther(id?: number) {
    const result = data?.data.types.others?.find((word) => word.id === id);
    if (result) {
      return result?.name;
    } else return null;
  }

  useEffect(() => {
    if (findDog(id)) {
      setValue(findDog(id));
      setType(TypePetEnum.DOG);
    }
    if (findCat(id)) {
      setValue(findCat(id));
      setType(TypePetEnum.CAT);
    }
    if (findOther(id)) {
      setValue(findOther(id));
      setType(TypePetEnum.OTHER);
    }
  }, [id, data]);

  return { value, type };
};
