import { RequestListProps } from 'src/model';

import { request } from './axios';
import { API_URL } from './config';

class ArticleApi {
  url: { LIST: string};

  constructor() {
    this.url = API_URL.ARTICLE;
  }
  getList(params: RequestListProps) {
    return request({
      url: this.url.LIST,
      method: 'GET',
      params,
    });
  }

  getDetail(id: number) {
    return request({
      url: API_URL.ARTICLE.LIST + '/' + id + '/show',
      method: 'GET',
    });
  }
}

export const articleApi = new ArticleApi();
