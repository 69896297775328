import React from 'react';
import styled from 'styled-components';

export interface CustomRatioProps {
  isSelect?: boolean;
}

const CustomRatio: React.FC<CustomRatioProps> = ({ isSelect }) => {
  return (
    <Style.Wrapper>
      <Style.Inner isSelect={isSelect} />
    </Style.Wrapper>
  );
};

export default CustomRatio;

const Style = {
  Wrapper: styled.div`
    border-radius: 50%;
    min-width: 24px;
    width: 24px;
    height: 24px;
    border: 2px solid #78776b;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Inner: styled.div`
    border-radius: 50%;
    width: 70%;
    height: 70%;
    background-color: ${(p: CustomRatioProps) =>
      p.isSelect ? '#78776b' : 'transparent'};
  `,
};
