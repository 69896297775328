import { ModalProps } from 'antd';
import React from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import ViewBoardAuth from 'src/components/core/View/ViewBoardAuth';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import CustomModal from 'src/components/custom/modal/CustomModal';
import { RewardCameraEnum, RewardTypeEnum } from 'src/model/stageGame.model';
import styled from 'styled-components';
import { ItemRewardComProps } from '.';
import { getStar } from '../../widget/ListReward.Widget';

export interface ItemRewardModalProps extends ModalProps, ItemRewardComProps {}

const ItemRewardModal: React.FC<ItemRewardModalProps> = ({
  data,
  images,
  index,
  ...props
}) => {
  function renderReward() {
    if (data.is_camera === RewardCameraEnum.CAMERA) {
      switch (index) {
        case 0:
          return (
            <Style.CameraImage>
              <img style={{ width: '100%' }} src={images?.back_ground_1} />
              <img
                style={{ width: '100%' }}
                src={images?.foreground_image_1}
                className="fore-ground"
              />
            </Style.CameraImage>
          );
        case 1:
          return (
            <Style.CameraImage>
              <img style={{ width: '100%' }} src={images?.back_ground_2} />
              <img
                style={{ width: '100%' }}
                src={images?.foreground_image__2}
                className="fore-ground"
              />
            </Style.CameraImage>
          );
        default:
          return null;
      }
    } else {
      switch (data.type) {
        case RewardTypeEnum.COIN:
          return (
            <img
              src={IMG_CONFIG.MINI_GAME.MINIGAME_COIN}
              className={'img_coin'}
            />
          );
        case RewardTypeEnum.POINT:
          return (
            <img src={IMG_CONFIG.MAP_GAME.STAR_LIFE} className={'img_coin'} />
          );
        case RewardTypeEnum.FRAME:
          return <img src={data.item?.media} style={{ maxHeight: '90%' }} />;
        default:
          return <img src={data.item?.media} style={{ maxHeight: '68%' }} />;
      }
    }
  }

  function renderTitleMessage() {
    switch (data.is_camera) {
      case RewardCameraEnum.CAMERA:
        return 'カメラマークをクリアしたらもらえるよ！';
      case RewardCameraEnum.NO_CAMERA:
        return 'ステージをクリアしたらもらえるよ！';
    }
  }
  // function renderMessage() {
  //   switch (data.type) {
  //     case RewardTypeEnum.COIN:
  //       return 'あなたは' + data.number + 'コインを受け取りました';
  //     case RewardTypeEnum.POINT:
  //       return 'あなたは' + data.number + 'ポイントを獲得しました';
  //     case RewardTypeEnum.FRAME:
  //       return null;
  //     default:
  //       return data.item?.name;
  //   }
  // }

  return (
    <CustomModal {...props} closeIcon={<div />}>
      <ViewBoardAuth onCancel={props.onCancel}>
        <Style.TotalWrapper>
          {/* main */}
          <Style.Wrapper>
            {/* star */}
            {getStar(3)}
            {/* title */}
            <h4 className="color_white" style={{ fontSize: '20px' }}>
              クリアテージ報酬
            </h4>
            {/* image */}
            <img src={IMG_CONFIG.MAP_GAME.REWARD_BOX} />
            {/* content */}
            <Style.ContentWrapper>
              {/* title */}
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={IMG_CONFIG.MAP_GAME.REWARD_HEART} />
                <p
                  style={{
                    fontSize: '9px',
                    margin: '0 5px',
                    textAlign: 'center',
                  }}
                  className={'color_white'}>
                  {renderTitleMessage()}
                </p>
                <img src={IMG_CONFIG.MAP_GAME.REWARD_HEART} />
              </div>
              {/* content */}
              {renderReward()}
              {/* footer */}
              {
                // <p
                //   style={{ fontSize: '14px', textAlign: 'center' }}
                //   className={'color_main'}>
                //   {renderMessage()}
                // </p>
              }
            </Style.ContentWrapper>
          </Style.Wrapper>
          {/* button ok */}
          <CustomIconButton
            icon={IMG_CONFIG.BUTTON.OK}
            onClick={props.onCancel}
          />
        </Style.TotalWrapper>
      </ViewBoardAuth>
    </CustomModal>
  );
};

export default ItemRewardModal;

const Style = {
  TotalWrapper: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
  `,

  Wrapper: styled.div`
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    justify-items: center;
    align-content: flex-start;
  `,
  ContentWrapper: styled.div`
    .img_coin {
      width: 90px;
    }
    height: 160px;
    width: 100%;
    background: url(${IMG_CONFIG.MAP_GAME.REWARD_BG});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* justify-content: space-between; */
    align-items: center;
  `,
  CameraImage: styled.div`
    width: 52%;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    .fore-ground {
      position: absolute;
      left: 0;
      top: 0;
    }
  `,
};
