import { useState } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import { RequestListProps } from 'src/model';
import { AdvertisementsDataProps } from 'src/model/dashboard.model';

import { DataListResponseProps, ResponseProps } from 'src/model/res.model';
import { advertismentsApi } from '../axios/advertisements';

import { ADS_KEY } from '../queries/keys';

export const useQueryAds = (queryData?: RequestListProps) => {
  const [query, setQuery] = useState<RequestListProps>(
    queryData || {
      page: 1,
    },
  );

  const queryList = useQuery<
    ResponseProps<{ notices: DataListResponseProps<AdvertisementsDataProps> }>,
    Error
  >([ADS_KEY.LIST, query], () => advertismentsApi.getList(query), {
    // enabled: !!query.type,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });

  const queryInfinity = useInfiniteQuery<
    ResponseProps<{ ads: DataListResponseProps<AdvertisementsDataProps> }>,
    any
  >(
    [ADS_KEY.LIST_INFI],
    async ({ pageParam = 0 }) => {
      const res = await advertismentsApi.getList({
        page: pageParam + 1,
      });
      return res;
    },
    {
      getPreviousPageParam: (firstPage) =>
        firstPage.data?.ads?.meta.current_page,

      getNextPageParam: (lastPage) =>
        lastPage.data?.ads?.meta.current_page ===
        lastPage.data?.ads?.meta.last_page
          ? undefined
          : lastPage.data?.ads?.meta.current_page,
    },
  );

  return { queryList, setQuery, queryInfinity };
};
