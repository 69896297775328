import {
  BaseResponse,
  LoginForm,
  LoginResponse,
  ProfileData,
  RegisterForm,
} from 'src/utils/types';
import { request } from 'src/utils/request';
import { ResponseCode } from 'src/utils/constants';
import { setStoredAuth } from '@utils/helpers/localStorage';

export const login = async (data: LoginForm) => {
  const bodyFormData = new FormData();
  bodyFormData.append('email', data.email);
  bodyFormData.append('password', data.password);
  const response = await request.post<LoginResponse>(
    '/api/auth/login',
    bodyFormData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  setStoredAuth(response.data.data.access_token);

  return response.data;
};

export const logout = async (token?: string) => {
  try {
    if (!token) {
      throw {
        code: '99',
        message: 'not found token',
      };
    }

    const response = await request.get('/auth/logout', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    err.message = ResponseCode[err.code];
    throw err;
  }
};

export const getNameAnimals = async () => {
  const response = await request.get<LoginResponse>('/api/dashboard');

  return response.data;
};

export const register = async (data: RegisterForm) => {
  const {
    name,
    gender,
    dob,
    type_id,
    point_of_departure,
    line_id,
    line_name,
    email,
    password,
    password_confirmation,
    avatar,
    active_line_id,
    active_line_name,
  } = data;

  const bodyFormData = new FormData();
  bodyFormData.append('name', name);
  bodyFormData.append('gender', `${gender}`);
  bodyFormData.append('dob', dob);
  bodyFormData.append('type_id', `${type_id}`);
  bodyFormData.append('point_of_departure', point_of_departure);
  bodyFormData.append('line_id', line_id);
  bodyFormData.append('line_name', line_name);
  bodyFormData.append('email', email);
  bodyFormData.append('password', password);
  bodyFormData.append('password_confirmation', password_confirmation);
  bodyFormData.append('avatar', avatar);
  bodyFormData.append('active_line_id', `${active_line_id}`);
  bodyFormData.append('active_line_name', `${active_line_name}`);
  const response = await request.post<LoginResponse>(
    '/api/auth/signup',
    bodyFormData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data;
};

export const signUpMore = async (data: RegisterForm) => {
  const {
    name,
    gender,
    dob,
    type_id,
    point_of_departure,
    line_id,
    line_name,
    avatar,
    active_line_id,
    active_line_name,
  } = data;

  const bodyFormData = new FormData();
  bodyFormData.append('name', name);
  bodyFormData.append('gender', `${gender}`);
  bodyFormData.append('dob', dob);
  bodyFormData.append('type_id', `${type_id}`);
  bodyFormData.append('point_of_departure', point_of_departure);
  bodyFormData.append('line_id', line_id);
  bodyFormData.append('line_name', line_name);
  bodyFormData.append('avatar', avatar);
  bodyFormData.append('active_line_id', `${active_line_id}`);
  bodyFormData.append('active_line_name', `${active_line_name}`);
  const response = await request.post<BaseResponse<ProfileData>>(
    '/api/sign-up-more',
    bodyFormData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return response.data;
};

export const getProfile = async () => {
  const response = await request.get<any>(`/api/profile`);
  return response.data.data.profile;
};
