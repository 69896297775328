import styles from './style.module.scss';
import { useEffect, useState } from 'react';

interface ProcessProps {
  time?: number;
  success: () => any;
}
export const Process: React.FC<ProcessProps> = (props) => {
  const { time = 250, success } = props;

  const [process, setProcess] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProcess((process) => (process += 10));
    }, time);

    if (process === 120) {
      clearInterval(interval);
      success();
    }
    return () => {
      clearInterval(interval);
    };
  }, [process]);

  return (
    <div className={styles.boxProgess}>
      <div
        className={styles.progess}
        style={{ width: `calc(${process < 100 ? process : 100}% - 6px)` }}
      />
    </div>
  );
};
