export const checkDataExits = (data: any) => {
  return data && data.length > 0 ? true : false;
};

export const formatTextNumber = (value: number) => {
  if (value < 10) return `0${value}`;

  return value;
};

export const setDimensionsImage = (desireSize : number, width: number, height: number , ratio:number) => {
  if(width > height) {
      const width = desireSize;
      const height = desireSize/ratio;
      return {width , height};
  }
  else if(width < height) {
    const height = desireSize;
    const width = desireSize * ratio;
    return {width, height};
  }
  else {
      const width = desireSize;
      const height = desireSize;
      return {width , height};
  }
};
