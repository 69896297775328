import React from 'react';
import {
  ListItemTextPopup,
  ResultSpinData,
  TypeItemMiniGameEnum,
} from 'src/model/miniGame.model';
import styled from 'styled-components';
import GetCoinCom from '../components/GetCoin.Com';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
// import { getTitleResult } from '../utils/getTitleResult';

export interface PopupGetRewardWidgetProps {
  data?: ResultSpinData;
  isEnd?: boolean;
  dataTextPopup?: ListItemTextPopup;
}

const PopupGetRewardWidget: React.FC<PopupGetRewardWidgetProps> = ({
  data,
  isEnd,
  dataTextPopup,
}) => {
  const renderTitle = () => {
    switch (data?.result.type) {
      case TypeItemMiniGameEnum.FRAME:
        return (
          <h5 className="color_main big_text">{dataTextPopup?.frame.title}</h5>
        );
      case TypeItemMiniGameEnum.NULL:
        return (
          <h5 className="color_main big_text">
            {dataTextPopup?.nothing.title}
          </h5>
        );
      case TypeItemMiniGameEnum.COIN:
        return (
          <Style.WhiteBackground>
            <Style.ImageWrapper style={{ padding: '8px 0' }}>
              <img src={IMG_CONFIG.MINI_GAME.MINIGAME_COIN} />
              <Style.NumberCoin>
                <p>{data.result.coin}</p>
              </Style.NumberCoin>
            </Style.ImageWrapper>
            <h5 className="color_main small_text">
              {dataTextPopup?.coin.title}
            </h5>
          </Style.WhiteBackground>
        );
      case TypeItemMiniGameEnum.PRODUCT:
        return (
          <Style.WhiteBackground>
            <Style.ImageWrapper>
              <Style.Product>
                <img src={data.result.item?.media} />
              </Style.Product>
            </Style.ImageWrapper>
            <h5 className="color_main small_text">
              {dataTextPopup?.product.title}
            </h5>
          </Style.WhiteBackground>
        );
      default:
        return null;
    }
  };
  return (
    <Style.Wrapper>
      <Style.Title>
        {!isEnd ? (
          renderTitle()
        ) : (
          <h5 className="color_main big_text">
            {dataTextPopup?.end_of_turns.title}
          </h5>
        )}
      </Style.Title>
      <Style.Content>
        {!isEnd ? (
          <GetCoinCom data={data?.result} dataTextPopup={dataTextPopup} />
        ) : (
          <p>{dataTextPopup?.end_of_turns.main_text}</p>
        )}
      </Style.Content>
      <Style.Footer>
        {/* <p className="color_pink">
          残り{data?.number_of_plays || '0'}スピンです
        </p> */}
      </Style.Footer>
    </Style.Wrapper>
  );
};

export default PopupGetRewardWidget;

const Style = {
  Wrapper: styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  `,
  Title: styled.div`
    margin-top: 5px;
    h5 {
      text-align: center;
    }
  `,
  WhiteBackground: styled.div`
    background-color: #fff;
    margin-top: 15px;
    padding: 6px;
    width: 180px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.9);
  `,
  ImageWrapper: styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
    img {
      width: 50px;
    }
  `,
  NumberCoin: styled.div`
    p {
      color: #b28247;
      font-weight: 400;
      font-size: 40px;
      line-height: 34px;
    }
  `,
  Product: styled.div`
    img {
      width: 100px;
    }
  `,
  Content: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
  `,
  Footer: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
  `,
};
