import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import LayoutDashboard from 'src/layouts/newDashboard';
import { Image } from 'antd';
import { queryRank } from 'src/api/hooks/queryRank';
import ListRankingWidget from '../widget/ListRanking.Widget';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// import CustomIconButton from 'src/components/custom/button/CustomIconButton';
// import { ListStagesRankWidget } from '../widget/ListStagesRank.Widget';
// import { ListTaraRankWidget } from '../widget/ListTaraRank.Widget';

export interface TopPointScreenProps {}

const TopPointScreen: React.FC<TopPointScreenProps> = () => {
  const { useQueryData } = queryRank.queryListPoint();
  const { data, isLoading } = useQueryData;
  const { t } = useTranslation();
  const history = useHistory();

  const [view, setView] = useState(1); // 1,2,3 is view of each screen
  // const [id, setId] = useState(1);
  
  
  // function onNext() {
  //   if (view === 3) {
  //     return;
  //   }
  //   setView(view + 1);
  // }

  // function onPre() {
  //   if (view === 1) {
  //     return;
  //   }
  //   setView(view - 1);
  // }

  // function onDetailStages(id: number) {
  //   setId(id);
  //   setView(3);
  // }

  // function renderTitle() {
  //   switch (view) {
  //     case 1:
  //       return '猫用おもちゃランキング';
  //     case 2:
  //       return '猫用おもちゃランキング';
  //     case 3:
  //       return '猫用おもちゃランキング';
  //   }
  // }

  
  const handleChangeView = () => {
    if (view === 1) {
      history.goBack();
    }
    setView(view - 1);
  };

  return (
    <LayoutDashboard
      loading={isLoading}
      title={t('content.rank.name')}
      isButton
      onGoBackView={handleChangeView}
      leftIcon={<Image src={IMG_CONFIG.DASHBOARD.RANKING_ICON_MINI} preview={false} />}
      footerContent={t('content.rank.footer')}>
      <div style={{ height: '100%', width: '100%' }}>
        <Style.Wrapper>
          {/* <Style.Button isOpacity={view === 1}>
            {view !== 3 && (
              <CustomIconButton
                icon={IMG_CONFIG.RANKING.RANKING_ARROW_LEFT}
                onClick={onPre}
              />
            )}
          </Style.Button> */}

          {/* title */}
          <Style.Title>
            <img src={IMG_CONFIG.RANKING.RANKING_TITLE} />
            <Style.TitleContent>
              <p>みんなのポイントランキング</p>
            </Style.TitleContent>
          </Style.Title>
          {/* <Style.Button isOpacity={view === 2}>
            {view !== 3 && (
              <CustomIconButton
                icon={IMG_CONFIG.RANKING.RANKING_ARROW_RIGHT}
                onClick={onNext}
                disabled={view === 2}
              />
            )}
          </Style.Button> */}
        </Style.Wrapper>

        {view === 1 && <ListRankingWidget data={data?.data.users.data} />}
        {/* {view === 2 && <ListStagesRankWidget onDetail={onDetailStages} />}

        {view === 3 && <ListTaraRankWidget id={id} />} */}
      </div>

      {/* <RankLayout>
        <ListRankingWidget data={data?.data.users.data} />
      </RankLayout> */}
    </LayoutDashboard>
  );
};

export default TopPointScreen;

const Style = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
  `,

  Button: styled.div<{ isOpacity?: boolean }>`
    transform: scale(0.5);
    opacity: ${({ isOpacity }) => (isOpacity ? 0.3 : 1)};
  `,
  Title: styled.div`
    position: relative;
    transform: scale(1.2);
    margin-top:5px;
  `,
  TitleContent: styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    justify-content: center;
    display: flex;
    height: 80%;
    align-items: center;
    p {
      font-size: 11px;
      color: white;
      line-height: 1.2;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  `,
};
