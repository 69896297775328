import React from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import LayoutDashboard from 'src/layouts/newDashboard';
import { Image } from 'antd';
import DetailNewsWidget from '../widget/DetailNews.Widget';

import { queryNews } from 'src/api/hooks/queryNews';

import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export interface DetailNewsScreenProps {}

const DetailNewsScreen: React.FC<DetailNewsScreenProps> = () => {
  const { id } = useParams<any>();
  const { data, isLoading } = queryNews.queryDetail(Number(id));
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <LayoutDashboard
      loading={isLoading}
      title={t('content.news.name')}
      button={
        <CustomIconButton
          icon={IMG_CONFIG.BUTTON.RETURN}
          onClick={() => history.push('/dashboard/news')}
        />
      }
      footerContent={t('content.news.footerDetail')}
      leftIcon={<Image src={IMG_CONFIG.DASHBOARD.NEWS_ICON_MINI} preview={false} />}>
      <DetailNewsWidget
        related_news={data?.data.related_news}
        data={data?.data.news}
      />
    </LayoutDashboard>
  );
};

export default DetailNewsScreen;

