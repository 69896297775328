import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export interface ViewResetPassComProps extends PropsWithChildren<any> {
  des?: any;
  button?: React.ReactNode;
}

const ViewResetPassCom: React.FC<ViewResetPassComProps> = ({
  children,
  des,
  button,
}) => {
  return (
    <Style.Wrapper>
      <Style.DesWrapper className="medium_text color_white">
        {des}
      </Style.DesWrapper>
      {children}
      <Style.ButtonWrapper>{button}</Style.ButtonWrapper>
    </Style.Wrapper>
  );
};

export default ViewResetPassCom;

const Style = {
  Wrapper: styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    flex-direction: column;
    height: fit-content;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
  `,
  DesWrapper: styled.p``,
  ButtonWrapper: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  `,
};
