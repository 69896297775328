import React from 'react';
import { useReduxLoading } from 'src/redux/hooks/useReduxLoading';
import styled from 'styled-components';
import ViewLoading from '../views/ViewLoading';
// import ViewLoading from '../views/ViewLoading';
import CustomModal from './CustomModal';

export interface LoadingModalProps {}

const LoadingModal: React.FC<LoadingModalProps> = () => {
  const loading = useReduxLoading();

  return (
    <CustomModal
      footer={null}
      closeIcon={<div />}
      visible={loading.data.visible}
      title={loading.data.title || 'loading'}>
      <Style.Wrapper>
        {/* <img src={IMG_CONFIG.MAP_GAME.GAME_CONFIRM} /> */}
        {/* <Style.Title>
          <p className="color_white">{title}</p>
        </Style.Title> */}
        <Style.Content>
          <ViewLoading />
        </Style.Content>
      </Style.Wrapper>
    </CustomModal>
  );
};

export default LoadingModal;

const Style = {
  Wrapper: styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
  `,

  Title: styled.div`
    position: absolute;
    width: fit-content;
    top: 20px;
  `,
  Content: styled.div`
    padding: 0 40px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    margin-top: 70px;
    height: 80px;
    overflow-y: auto;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
};
