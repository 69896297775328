import { motion } from 'framer-motion';
import React from 'react';

import styled from 'styled-components';

interface StyleProps {
  bottom?: string;
  invisible?: boolean;
}

interface CameraButtonProps {
  x?: number;
  y?: number;
  bottom?: string;
  onclick?: () => void;
  className?: string;
  invisible?: boolean;
}

const CameraButton: React.FC<CameraButtonProps> = ({
  x,
  y,
  onclick,
  bottom,
  className,
  invisible,
}) => {
  const onClickButton = () => {
    if (!invisible && onclick) onclick();
  };

  return (
    <motion.div
      style={{
        x,
        y,
        color: '#fff',
        width: '0',
      }}
      transition={{ ease: [0.17, 0.67, 0.83, 0.67] }}
      onClick={onClickButton}>
      <Style.Wrapper
        bottom={bottom}
        className={className}
        invisible={invisible}>
        <Style.CameraButton />
      </Style.Wrapper>
    </motion.div>
  );
};

const Style = {
  Wrapper: styled.div<StyleProps>`
    position: relative;
    bottom: ${(props) => props.bottom || '60px'};
    z-index: 10;
    width: 60px;
    height: 60px;
    cursor: pointer;
    visibility: ${(props) => (props.invisible ? 'hidden' : '')};
  `,
  CameraButton: styled.p`
    position: relative;
    z-index: 8;
    display: flex;
    width: 60px;
    justify-content: center;
    align-items: center;
    height: 60px;
    overflow: hidden;
    min-width: 60px;
    min-height: 60px;
  `,
};

export default CameraButton;
