import React from 'react';
import { NewsDataProps } from 'src/model';
import NewsDetailLayout from '../layout/news-detail.layout';

export interface DetailWebViewComProps {
  data?: NewsDataProps;
  related_news?: NewsDataProps[];
}

const DetailWebViewCom: React.FC<DetailWebViewComProps> = ({
  data,
  related_news,
}) => {
  return (
    <NewsDetailLayout
      title={data?.title}
      des={data?.description}
      related_news={related_news}>
      <iframe
        className="hidden_scroll"
        width={'100%'}
        style={{ height: '100%' }}
        src={data?.link}
        title="description"></iframe>
    </NewsDetailLayout>
  );
};

export default DetailWebViewCom;
