import React from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import { TypePetEnum } from 'src/model';
import styled from 'styled-components';

export interface SelectTypePetComProps {
  checked?: boolean;
  type?: TypePetEnum;
}

const SelectTypePetCom: React.FC<SelectTypePetComProps> = ({
  checked,
  type,
}) => {
  return (
    <Style.Wrapper>
      <ItemCheckBox checked={checked} />
      <div style={{ marginLeft: '20px' }}>
        <ItemBox type={type} />
      </div>
    </Style.Wrapper>
  );
};

export default SelectTypePetCom;

const ItemCheckBox: React.FC<SelectTypePetComProps> = ({ checked }) => {
  return (
    <Style.Checkbox>
      <img src={IMG_CONFIG.REGISTER.CHECKBOX} />
      <div>{checked && <img src={IMG_CONFIG.REGISTER.CHECK} />}</div>
    </Style.Checkbox>
  );
};

const ItemBox: React.FC<SelectTypePetComProps> = ({ type }) => {
  let data = IMG_CONFIG.REGISTER.TYPE_1;
  switch (type) {
    case TypePetEnum.DOG:
      data = IMG_CONFIG.REGISTER.TYPE_1;
      break;
    case TypePetEnum.CAT:
      data = IMG_CONFIG.REGISTER.TYPE_2;
      break;
    case TypePetEnum.OTHER:
      data = IMG_CONFIG.REGISTER.TYPE_3;
      break;
  }
  return (
    <Style.Checkbox>
      <img src={data} />
    </Style.Checkbox>
  );
};

const Style = {
  Wrapper: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* gap: 20px; */
    cursor: pointer;
    :hover {
      transform: translateY(-3px);
    }
  `,

  Checkbox: styled.div`
    position: relative;
    width: fit-content;
    div {
      position: absolute;
      top: 0;
      left: 5px;
    }
  `,
};
