import { Paths } from '@utils/constants';
import React from 'react';
import ViewBoardAuth from 'src/components/core/View/ViewBoardAuth';

import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import CustomInput from 'src/components/custom/input/CustomInput';
import { MainStyle } from 'src/components/styles/MainStyles';
import styled, { keyframes } from 'styled-components';

import { supportErrorFormik } from '@utils/support/supportErrorFormik';
import { useTranslation } from 'react-i18next';
import { useFormSignIn } from 'src/hooks/formik/useFormSignIn';
export interface SignInWidgetProps {}

const SignInWidget: React.FC<SignInWidgetProps> = () => {
  const { formik, query } = useFormSignIn();

  const { t } = useTranslation();

  return (
    <Style.MainWrapper>
      <ViewBoardAuth title={t('auth.signIn')}>
        <Style.SignWrapper>
          <Style.Wrapper onSubmit={formik.handleSubmit}>
            <CustomInput
              type={'email'}
              name={'email'}
              placeholder={'Email'}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={supportErrorFormik(formik, 'email')}
            />
            <CustomInput
              type={'password'}
              name={'password'}
              isPassword
              value={formik.values.password}
              placeholder={'Password'}
              onChange={formik.handleChange}
              error={supportErrorFormik(formik, 'password')}
            />

            <MainStyle.Link to={Paths.AuthEmail}>
              {t('auth.forgotPassword')}?
            </MainStyle.Link>
            <div className="center_wrapper">
              <CustomIconButton
                loading={query.isLoading}
                type="button"
                onClick={() => {
                  formik.handleSubmit();
                }}
              />
            </div>
          </Style.Wrapper>

          {/* footer */}
          <Style.FooterWrapper className="link_wrapper">
            <p className="medium_text color_white">{t('auth.notHave')}</p>
            <MainStyle.Link to={Paths.Register}>
              <div style={{ fontSize: '20px' }}>{t('auth.register')}</div>
            </MainStyle.Link>
          </Style.FooterWrapper>
        </Style.SignWrapper>
      </ViewBoardAuth>
    </Style.MainWrapper>
  );
};

export default SignInWidget;

const animScaleOriginal = keyframes`
  from {
    transform:scale(0.5);
    opacity:0;
  }
  to {
    transform:scale(1);
    opacity:1;
  }
`;

const animScaleLarge = keyframes`
  from {
    transform:scale(1);
    opacity:0;
  }
  to {
    transform:scale(1.3);
    opacity:1;
  }
`;

const Style = {
  MainWrapper: styled.form`
    height: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
    @media (min-width: 768px) {
      animation: ${animScaleLarge} 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)
        0.4s both;
    }
    animation: ${animScaleOriginal} 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)
      0.4s both;
  `,
  Wrapper: styled.form`
    margin-bottom: 35px;
    width: 100%;
    display: grid;
    flex-direction: column;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    align-items: flex-start;
    justify-content: center;
    /* height: 100%; */

    .center_wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .link_wrapper {
      /* margin-bottom: 10px; */
      height: auto;
      display: flex;
      align-items: flex-end;
    }
  `,
  FooterWrapper: styled.div`
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  `,

  SignWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    align-items: center;
  `,
};
