import styles from './style.module.scss';
import EarthImg from '@img/earth.png';
import { Studio } from '@icons';
import { useHistory } from 'react-router-dom';
import { Paths } from '@utils/constants';
import { Process } from '@components';
import styled, { keyframes } from 'styled-components';

export const Home: React.FC = () => {
  const route = useHistory();

  return (
    <div className={styles.container}>
      <div className={styles.boxImg}>
        <Style.EarthWrapper>
          <Style.Earth>
            <Style.EarthWobble>
              <img src={EarthImg} alt="earth" />
              <div className={styles.studio}>
                <Studio />
              </div>
            </Style.EarthWobble>
          </Style.Earth>
        </Style.EarthWrapper>
      </div>
      <Process
        success={() => {
          route.push(Paths.SignIn);
        }}
      />
    </div>
  );
};

const animEarth = keyframes`
    from {
      -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
      transform: translateY(-600px) rotateX(-30deg) scale(0);
      -webkit-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
      opacity: 0;
    }
    to {
      -webkit-transform: translateY(0) rotateX(0) scale(1);
      transform: translateY(0) rotateX(0) scale(1);
      -webkit-transform-origin: 50% 1400px;
      transform-origin: 50% 1400px;
      opacity: 1;
    }
  `;

const animEarthWobble = keyframes`
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  `;

const Style = {
  Earth: styled.div`
    position: relative;
    margin: auto;
    animation: ${animEarth} 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s both;
  `,

  EarthWrapper: styled.div`
    @media (min-width: 768px) {
      transform: scale(1.7);
    }
    @media (max-height: 768px) {
      transform: scale(1);
    }
  `,

  EarthWobble: styled.div`
    animation: ${animEarthWobble} 1s linear infinite 1.1s both;
  `,
};
