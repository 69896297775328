import { RequestListProps } from 'src/model';
import { request } from './axios';
import { API_URL } from './config';

class AdvertismentsApi {
  url: { LIST: string };

  constructor() {
    this.url = API_URL.ADVERTISEMENTS;
  }
  getList(params: RequestListProps) {
    return request({
      url: this.url.LIST,
      method: 'GET',
      params,
    });
  }
}

export const advertismentsApi = new AdvertismentsApi();
