import React from 'react';
import { useTranslation } from 'react-i18next';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton, {
  CustomIconButtonProps,
} from 'src/components/custom/button/CustomIconButton';

import TitleRegisterCom from '../components/TitleRegister.Com';

export interface SlashRegisterWidgetProps extends CustomIconButtonProps {}

const SlashRegisterWidget: React.FC<SlashRegisterWidgetProps> = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <TitleRegisterCom title={t('content.register.slashScreen')} />
      <img src={IMG_CONFIG.REGISTER.SLASH} width="260px" style={{ marginTop: '8px' }}/>
      <CustomIconButton {...props} />
    </>
  );
};

export default SlashRegisterWidget;

// const Style = {
//   Wrapper: styled.div`
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: space-between;
//     height: 100%;
//   `,
// };
