// import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useRef, useState } from 'react';
// import ScrollContainer from 'react-indiana-drag-scroll';
import { useIsOpen } from 'src/hooks/useIsOpen';
import styled from 'styled-components';
import { useOnClickOutside } from 'usehooks-ts';
// import { useScrollPickDate } from './hooks/useScrollPickDate';
// @ts-ignore
import DatePicker from 'react-mobile-datepicker';

type Props = {
  error?: any;
  name?: string;
  onChangeDate?: any;
  value?: any;
  placeholder?: string;
};

const CustomDate: React.FC<Props> = (props) => {
  const ref = useRef(null);

  const open = useIsOpen();

  // function handleChange(i: any) {
  //   props.onChangeDate(props.name, i);
  // }

  const dayDisplay = moment(props.value, 'YYYY-MM-DD').format('YYYY年MM月DD日');

  const maxDate = moment().subtract(1, 'days').toString();
  useOnClickOutside(ref, open.onClose);

  const { isOpen, onClose, onOpen } = useIsOpen();
  const [time, setTime] = useState<any>(new Date(maxDate));
  const [popup, setPopup] = useState(true);

  const handleClick = () => {
    onOpen();
    setPopup(false);
  };

  const handleCancel = () => {
    onClose();
    setPopup(!popup);
  };

  const handleSelect = (val: Date) => {
    // const customVal = moment(val, 'YYYY-MM-DD').format('YYYY-MM-DD');
    setTime(val);
    props.onChangeDate('dob', val);
    onClose();
    setPopup(!popup);
  };

  return (
    <Style.InputWrapper ref={ref}>
      <Style.Wrapper>
        <Style.Inner onClick={handleClick}>
          {props.value ? <p>{dayDisplay}</p> : <p>{props.placeholder}</p>}
        </Style.Inner>
        <DatePicker
          value={time}
          isOpen={isOpen}
          max={new Date(maxDate)}
          onSelect={handleSelect}
          onCancel={handleCancel}
          theme={'default'}
          confirmText={'ok'}
          cancelText={'キャンセル'}
          isPopup={popup}
          // customHeader={props.value ? false : '日付を選択'}
        />
      </Style.Wrapper>
      {props.error && <p className="small_text color_error">{props.error}</p>}
    </Style.InputWrapper>
    //
    // <Style.InputWrapper ref={ref}>
    //   <Style.Wrapper>
    //     <Style.Inner onClick={open.onToggle}>
    //       {props.value ? <p>{dayDisplay}</p> : <p>{props.placeholder}</p>}
    //     </Style.Inner>
    //     {open.isOpen && (
    //       <SelectDate
    //         onConfirm={handleChange}
    //         onCancel={open.onClose}
    //         preValue={props.value}
    //       />
    //     )}
    //   </Style.Wrapper>
    //   {props.error && <p className="small_text color_error">{props.error}</p>}
    // </Style.InputWrapper>
  );
};

export default CustomDate;

// const SelectDate = ({
//   onConfirm,
//   onCancel,
//   preValue,
// }: {
//   onConfirm?: any;
//   onCancel: any;
//   preValue?: any;
// }) => {
//   const refDate = useRef<HTMLDivElement>(null);
//   const refMonth = useRef<HTMLDivElement>(null);
//   const refYear = useRef<HTMLDivElement>(null);
//   const [hideDay, setHideDay] = useState(false);
//   const [handleOk, setHandleOk] = useState(false);

//   const {
//     day,
//     dayDisplay,
//     listDate,
//     listMonth,
//     listYear,
//     getNameMonth,
//     setDate,
//     setMonth,
//     setYear,
//   } = useScrollPickDate(refDate, refMonth, refYear, preValue);

//   const handldeConfirm: any = () => {
//     setHandleOk(true);
//     onConfirm(day);
//     hideDay && onCancel();
//   };

//   const handleChooseYear = (item: React.SetStateAction<number>) => {
//     setYear(item);
//     setHideDay(true);
//     setHandleOk(false);
//   };
//   const handleChooseMonth = (item: React.SetStateAction<number>) => {
//     setMonth(item);
//     setHideDay(true);
//     setHandleOk(false);
//   };
//   const handleChooseDay = (item: React.SetStateAction<number>) => {
//     setDate(item);
//     setHideDay(true);
//     setHandleOk(false);
//   };

//   return (
//     <>
//       <Style.SelectDate>
//         <Style.Date>
//           <div className="title_wrapper">
//             <p onClick={onCancel}>キャンセル</p>
//             <p>{hideDay ? dayDisplay : '日付を選択'}</p>
//             <p onClick={handldeConfirm}>OK</p>
//           </div>
//           {/* content */}
//           {handleOk && (
//             <Style.TextDate>を押して日付を選択します</Style.TextDate>
//           )}
//           <div className="content_wrapper">
//             <Style.Select>
//               <span />
//               <span />
//               <span />
//             </Style.Select>
//             <ScrollContainer
//               innerRef={refYear}
//               className="hidden_scroll"
//               style={{
//                 position: 'absolute',
//                 height: '200px',
//                 overflowY: 'auto',
//                 left: '22%',
//                 fontSize: '16px',
//                 paddingTop: '90px',
//                 paddingBottom: '100px',
//               }}>
//               {listYear.map((item: any) => {
//                 return (
//                   <Style.ItemDate
//                     key={item}
//                     onClick={() => handleChooseYear(item)}>
//                     {item}
//                   </Style.ItemDate>
//                 );
//               })}
//             </ScrollContainer>

//             {/* test */}
//             <ScrollContainer
//               innerRef={refMonth}
//               className="hidden_scroll"
//               style={{
//                 left: '45%',
//                 position: 'absolute',
//                 height: '200px',
//                 overflowY: 'auto',
//                 fontSize: '16px',
//                 paddingTop: '90px',
//                 paddingBottom: '100px',
//               }}>
//               {listMonth.map((item) => {
//                 return (
//                   <Style.ItemDate
//                     key={item}
//                     onClick={() => handleChooseMonth(item)}>
//                     {getNameMonth(item)}
//                   </Style.ItemDate>
//                 );
//               })}
//             </ScrollContainer>
//             <ScrollContainer
//               innerRef={refDate}
//               className="hidden_scroll"
//               style={{
//                 right: '25%',
//                 position: 'absolute',
//                 height: '200px',
//                 fontSize: '16px',
//                 overflowY: 'auto',
//                 paddingTop: '90px',
//                 paddingBottom: '100px',
//               }}>
//               {listDate.map((item: number) => {
//                 return (
//                   <Style.ItemDate
//                     key={item}
//                     onClick={() => handleChooseDay(item)}>
//                     {item}
//                   </Style.ItemDate>
//                 );
//               })}
//             </ScrollContainer>
//           </div>
//         </Style.Date>
//       </Style.SelectDate>
//     </>
//   );
// };

const Style = {
  InputWrapper: styled.div`
    display: grid;
    flex-direction: column;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 5px;
  `,

  Wrapper: styled.div`
    position: relative;
    width: fit-content;
    width: 100%;
    background-color: rgb(250, 222, 177);
    -webkit-box-shadow: 0px 6px 0px 0px rgba(0, 0, 0, 0.25);
    height: 40px;
    border-radius: 20px;
    box-shadow: 0px 6px 0px 0px rgba(0, 0, 0, 0.25);
    padding: 0 12px;

    .datepicker.default {
      background-color: #fadeb1;
      border-radius: 20px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    .datepicker.default .datepicker-header {
      color: var(--color-main);
      border-bottom: 2px solid transparent;
      font-weight: 700;
    }
    .datepicker.default .datepicker-wheel {
      border-top: 1px solid var(--color-main);
      border-bottom: 1px solid var(--color-main);
    }
    .datepicker .datepicker-viewport::after {
      transition: none;
    }
    .datepicker .datepicker-scroll {
      font-size: 14px;
      transition: none;
    }
    .datepicker .datepicker-navbar {
      border-top: none;
    }
    .datepicker .datepicker-navbar .datepicker-navbar-btn {
      font-size: 16px;
      color: var(--color-main);
    }
  `,
  Inner: styled.div`
    cursor: pointer;
    left: 15px;
    top: 0px;
    display: flex;
    width: 100%;
    height: 100%;

    align-items: center;

    p {
      font-size: 16px;
      color: var(--color-main);
    }
    .ant-picker-focused {
      border-color: none !important;
      box-shadow: none !important;
    }
    .ant-picker:hover {
      border-color: none !important;
    }

    .ant-picker-clear {
      display: none;
    }

    input {
      font-family: 'DFMRG9' !important;
    }
  `,

  // Input: styled(DatePicker)``,

  SelectDate: styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;

    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 300;
  `,
  Date: styled.div`
    width: 375px;
    height: 375px;
    border-radius: 10px;
    background-color: #fadeb1;
    padding: 15px;

    @media screen and (max-width: 500px) {
      width: 100%;
    }

    .title_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      border-bottom: 1px solid white;
      p {
        cursor: pointer;
      }
      padding-bottom: 5px;
      z-index: 1000;
      position: relative;
    }

    .content_wrapper {
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      overflow: hidden;
      transform: scale(1.4);
      margin-left: 10px;
      margin-top: -15px;
    }
  `,

  TextDate: styled.p`
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    padding-top: 5px;
    font-size: 13px;
    color: red;
  `,

  Select: styled.div`
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    span:nth-child(1) {
      position: absolute;
      top: 0;
      height: 100%;
      width: 50px;
      border-bottom: 1px solid var(--color-main);
      border-top: 1px solid var(--color-main);
      background-color: #eed29a;
    }
    span:nth-child(2) {
      position: absolute;
      top: 0;
      left: 25%;
      height: 100%;
      transform: translateX(-20px);
      width: 50px;
      border-bottom: 1px solid var(--color-main);
      border-top: 1px solid var(--color-main);
      background-color: #eed29a;
    }

    span:nth-child(3) {
      position: absolute;
      top: 0;
      right: 20%;
      height: 100%;
      transform: translateX(-10px);
      width: 40px;
      border-bottom: 1px solid var(--color-main);
      border-top: 1px solid var(--color-main);
      background-color: #eed29a;
    }

    h2 {
      margin: 0 15px;
    }
  `,

  ItemDate: styled.p`
    height: 30px;
    min-width: 30px;
    text-align: center;
    cursor: pointer;
    :hover {
      transform: scale(1.05);
    }
  `,
};
