import { useAppSelector } from '@hooks';
import { useDispatch } from 'react-redux';
import { LoadingSlideProps } from '../slide/loadingSlide';
import * as Slide from '../slide';

export const useReduxLoading = () => {
  const data = useAppSelector((p) => p.loadingSlide);
  const dispatch = useDispatch();

  function openLoading(title?: string) {
    const payload: LoadingSlideProps = {
      visible: true,
      title,
    };
    dispatch(Slide.loadingSlide.setModal(payload));
  }

  function closeLoading() {
    const payload: LoadingSlideProps = {
      visible: false,
      title: '',
    };
    dispatch(Slide.loadingSlide.setModal(payload));
  }

  return { data, openLoading, closeLoading };
};
