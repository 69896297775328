import React from 'react';
import { EventDataProps, EventStatusEnum } from 'src/model';
import styled from 'styled-components';
import { Image } from 'antd';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import { useHistory } from 'react-router-dom';
import { Paths } from '@utils/constants';
import moment from 'moment';

export interface CardEventSoonComProps extends StyleProps {
  data?: EventDataProps;
}
function choseTypeImg( imgUrl : string, data?: EventDataProps) {
   let imgAtt = null; 
   switch(data?.status) {
     case EventStatusEnum.VOTE: 
        return imgAtt = data.is_vote ? imgUrl : null; 
     case EventStatusEnum.REGISTER: 
        return imgAtt = data.is_attended ? imgUrl : null;
     default : 
        return imgAtt; 
   }
}

function renderImg(isOld: boolean, data?: EventDataProps) {
  if (!data) {
    return {
      img: '',
      isHide: false,
    };
  }
  const imgUrl = isOld
    ? IMG_CONFIG.EVENT.LABEL_XAM_RED
    : IMG_CONFIG.EVENT.LABEL_XAM_GREEN;

  
  const imgAtt = choseTypeImg(imgUrl, data);
  
  const imgUrlLabel = data.read ? imgAtt : IMG_CONFIG.EVENT.LABEL_NEW;

  return {
    img: imgUrlLabel,
    isHide: !imgUrlLabel,
  };
}

const CardEventSoonCom: React.FC<CardEventSoonComProps> = ({ data }) => {
  // render content
  function renderContent() {
    switch (data?.status) {
      case EventStatusEnum.REGISTER:
        return <ContentRegister data={data}/>;
      case EventStatusEnum.VOTE:
        return <ContentVote data={data} />;
    }
  }
  const date = moment(data?.end_date).format('DD.MM.YYYY');
  const time = moment(data?.end_date).format('AHH:MM');
  const isOld = data?.status === EventStatusEnum.VOTE ? false : true;

  const { img, isHide } = renderImg(isOld, data);

  return (
    <Style.Wrapper isOld={isOld}>
      <div
        style={{
          position: 'absolute',
          bottom: '10px',
          left: '10px',
          zIndex: 10,
        }}>
        {!isHide && data?.status !== EventStatusEnum.COMPLETED && <img src={img || ''} style={{ width: 40, height: 40 }} />}
      </div>
      {/* title */}
      <Style.TitleWrapper>
        {/* start */}
        <Style.StartWrapper style={{ marginRight: '15px' }}>
          <div className="flag_title">
            {data?.is_attended && <img src={IMG_CONFIG.EVENT.FLAG} />}
          </div>
          <Image src={data?.media} width={'35px'} preview={false} />
        </Style.StartWrapper>
        {/* title */}

        <Style.TitleCard>
          <h5 className="color_main medium_text">{data?.title}</h5>
        </Style.TitleCard>
      </Style.TitleWrapper>
      {/* mid */}
      <Style.Sub/>
      {/* <div className="background_event">1</div> */}
      {/* content */}
      <Style.ContentWrapper>
        <Style.StartWrapper style={{ marginRight: '15px' }}>
          <div className="time_wrapper ">
            <p style={{ fontSize: '8px' }}>{date}</p>
            <p style={{ fontSize: '8px' }}>{time}まで</p>
          </div>
        </Style.StartWrapper>
        {renderContent()}
      </Style.ContentWrapper>
    </Style.Wrapper>
  );
};

const ContentRegister: React.FC<CardEventSoonComProps> = ({ data }) => {
  const history = useHistory();
  
  return (
    <div className="content_inner" style={{ height: '95px', maxHeight: '95px', overflow:'hidden', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
      <div>
        <p className="sm_text" >{data?.description}</p>
      </div>
      <div className="content_register">
        {!data?.is_attended && (
          <CustomIconButton
            icon={IMG_CONFIG.EVENT.CAFE_JOIN}
            onClick={() => history.push(Paths.Event + '/detail/' + data?.id)}
          />
        )}

        <CustomIconButton
          style={{ marginLeft: '10px' }}
          icon={IMG_CONFIG.EVENT.CAFE_DETAIL}
          onClick={() => history.push(Paths.Event + '/detail/' + data?.id)}
        />
      </div>
    </div>
  );
};

const ContentVote: React.FC<CardEventSoonComProps> = ({ data }) => {
  const history = useHistory();
  return (
    <div className="content_inner" style={{ height: '95px', maxHeight: '95px', overflow:'hidden', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
      <div>
        <p className="sm_text">{data?.description}</p>
      </div>
      <div className="content_vote">
        <CustomIconButton
          icon={IMG_CONFIG.EVENT.CAFE_VOTE}
          onClick={() => history.push(Paths.Event + '/vote/' + data?.id)}
        />
      </div>
    </div>
  );
};

export default CardEventSoonCom;

interface StyleProps {
  isOld?: boolean;
}

const Style = {
  Sub: styled.div`
    width: 100%;
    height: 1px;
    background-repeat: no-repeat;
    background-size: contain;
    background:none;
  `,
  Wrapper: styled.div`
    display: grid;
    width: 325px;
    height: 189px;
    position: relative;
    grid-template-columns: repeat(1);
    background-image: ${(p: StyleProps) =>
      p.isOld
        ? `url(${IMG_CONFIG.EVENT.EVENT_CARDBGR1})`
        : `url(${IMG_CONFIG.EVENT.EVENT_CARDBGR2})`};
    background-repeat:no-repeat;
    background-size: cover;
    transform:scale(0.95);
    margin-top:-4px;

    .background_event {
      background: ${(p: StyleProps) => (p.isOld ? '#f0f0f0' : '#C9BC9C')};
    }
    
  `,
  TitleWrapper: styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    /* gap: 15px; */
    justify-content: flex-start;
    align-items: center;
    padding: 10px 20px 5px 10px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    margin-top:-2px;

    .flag_title {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 20;
    }
    
  `,

  TitleCard: styled.div`
    background: #ffffff;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    padding: 0px;
    padding-left:10px;
    display: flex;
    width: 100%;
    height:45px;

    h5 {
      display: -webkit-box;
      -webkit-line-clamp: 1 !important;
      -webkit-box-orient: vertical;
      overflow: hidden !important;
      font-size:25px !important;
      line-height:45px !important;
    }
  `,
  StartWrapper: styled.div`
    width: 50px;
    min-width: 50px;
    display: flex;
    justify-content: center;
    .time_wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  `,
  ContentWrapper: styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    /* gap: 15px; */
    justify-content: flex-start;
    align-items: flex-start;
    height:110px;
    padding: 0 15px 0 15px;
    .content_inner {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      width: 100%;
      align-items: flex-start;
      justify-content: flex-start;
      height:100%;
      grid-gap: 5px;
      p {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size:8px;
        line-height:10px;
      }
    }
    .content_vote {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding-top: 2px;
      transform: translateX(-30px);
      justify-content: center;
    }
    .content_register {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: flex-end;
      padding-right: 10px;
      /* gap: 10px; */
    }
  `,
};
