import * as Yup from 'yup';

export const registerSchema = Yup.object().shape({
  name: Yup.string()
    .required('空白のままにすることはできません')
    .max(100, 'max 100'),
  avatar: Yup.mixed().required('空白のままにすることはできません'),
  gender: Yup.number()
    .min(1, '空白のままにすることはできません')
    .required('空白のままにすることはできません')
    .nullable(),
  dob: Yup.string().required('空白のままにすることはできません').nullable(),
  point_of_departure: Yup.string()
    .required('空白のままにすることはできません')
    .max(255, 'max 255')
    .nullable(),
  line_id: Yup.string().required('空白のままにすることはできません').nullable(),
  //   base64: Yup.string().required('空白のままにすることはできません').nullable(),
  type_id: Yup.number()
    .min(1, '空白のままにすることはできません')
    .required('空白のままにすることはできません')
    .nullable(),
  line_name: Yup.string()
    .required('空白のままにすることはできません')
    .nullable(),
  email: Yup.string()
    .email('メールフォーマットが間違っている')
    .required('空白のままにすることはできません')
    .max(100, 'max 100')
    .nullable(),
  password: Yup.string()
    .required('空白のままにすることはできません')
    .min(6, 'min 6')
    .max(32, 'max 32')
    .nullable(),
  password_confirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match',
  ),
});

export const configData = {
  minHeight: 900,
};
