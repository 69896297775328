import React from 'react';

import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import ViewModalConfirm from 'src/components/custom/views/ViewModalConfirm';
import { useIsOpen } from 'src/hooks/useIsOpen';
import styled from 'styled-components';

export interface ModalConfirmQWidgetProps {
  title?: string;
  des?: string;
  onConfirm?: any;
  onCancel?: any;
}

const ModalConfirmQWidget: React.FC<ModalConfirmQWidgetProps> = ({
  title,
  des,
  onConfirm,
  onCancel,
}) => {
  const disable = useIsOpen();
  const confirmDebound = () => {
    if (!disable.isOpen) {
      onConfirm();
      disable.onOpen();
    }
  };
  return (
    <ViewModalConfirm title={title} des={des}>
      <Style.Button>
        {onCancel ? (
          <CustomIconButton
            style={{ marginRight: '30px' }}
            icon={IMG_CONFIG.BUTTON.BACK}
            onClick={onCancel}
          />
        ) : null}
        <CustomIconButton
          disabled={disable.isOpen}
          icon={IMG_CONFIG.BUTTON.OK}
          onClick={confirmDebound}
        />
      </Style.Button>
    </ViewModalConfirm>
  );
};

export default ModalConfirmQWidget;

const Style = {
  Wrapper: styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
  `,

  Title: styled.div`
    position: absolute;
    width: fit-content;
    top: 20px;
  `,
  Content: styled.div`
    position: absolute;
    width: 100%;
    top: 40%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Button: styled.div`
    position: relative;
    display: flex;
    justify-content: center;
  `,
  Close: styled.div`
    position: absolute;
    top: 0px;
    right: -10px;
  `,
};
