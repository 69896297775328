import React from 'react';

import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import ViewModalConfirm from 'src/components/custom/views/ViewModalConfirm';
import styled from 'styled-components';
import CustomModal from './CustomModal';

export interface ConfirmSoundModalProps {
  onConfirm?: any;
  onCancel?: any;
  visible ?: boolean;
}

const ConfirmSoundModal: React.FC<ConfirmSoundModalProps> = ({
  onConfirm,
  onCancel,
  visible
}) => {
  
  return (
    <CustomModal
      closeIcon={<div />}
      visible={visible}
    //   onCancel={open.onClose}
      style={{ top: '100px' }}>
      <ViewModalConfirm title="BGM設定">
        <Style.Button>
          <CustomIconButton
            icon={IMG_CONFIG.BUTTON.OFF_BUTTON}
            onClick={onCancel}
            className='off-btn'
          />
          <CustomIconButton icon={IMG_CONFIG.BUTTON.ON_BUTTON} onClick={onConfirm} className='on-btn' />
        </Style.Button>
      </ViewModalConfirm>
    </CustomModal>
  );
};

export default ConfirmSoundModal;

const Style = {
  Button: styled.div`
    position: relative;
    display: flex;
    gap: 10px;
    justify-content: center;

    @media screen and (max-width: 500px) {
      /* .cancel-btn {
          width: 130px;
          margin-left: 30px;
      } */
    }
  `,
  Close: styled.div`
    position: absolute;
    top: 0px;
    right: -10px;
  `,
};
