import { useFormik } from 'formik';
import * as Yup from 'yup';
import { queryProfile } from 'src/api/hooks/queryProfile';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInputUpload } from '../useInputUpload';
import { convertBase64ToBlob } from '@utils/support/covert-base64-to-blob';

export const useFormUpdatePet = () => {
  const { id } = useParams<any>();
  const query = queryProfile.queryUpdatePet(id);
  const { useQueryData } = queryProfile.queryPet(id);
  const { data, isSuccess } = useQueryData;
  const pet = data?.data.pet;
  const { t } = useTranslation();
  const upload = useInputUpload();
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('error.name.required'))
      .max(50, t('error.name.max')),
    avatar: Yup.mixed().required(t('error.image.required')),
    gender: Yup.number().required(t('error.gender.required')).nullable(),
    dob: Yup.string().required(t('error.dob.required')).nullable(),
  });
  const formik = useFormik({
    initialValues: {
      name: pet?.name,
      avatar: pet?.avatar,
      gender: pet?.gender,
      dob: pet?.dob,
      type_id: pet?.type,
    },
    validationSchema,
    onSubmit: (values: any) => {
      query.mutate({...values, dob : moment(values?.dob, 'YYYY-MM-DD').format('YYYY-MM-DD').toString()});
    },
  });
  const [image, setImage] = useState<any>(null);
  const formikChangeImage = (imageData: any) => {
    convertBase64ToBlob(imageData, formik.setFieldValue, 'avatar', setImage);
  };
  useEffect(() => {
    setImage(pet?.avatar);
    formik.setValues({
      name: pet?.name,
      avatar: pet?.avatar,
      gender: pet?.gender,
      dob: pet?.dob,
      type_id: pet?.type,
    });
  }, [isSuccess, id]);
  return { formik, query, id, upload, formikChangeImage, image };
};
