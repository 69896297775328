import { clearStoredAuth, getStoredAuth } from '@utils/helpers/localStorage';
import axios, { AxiosRequestConfig } from 'axios';
import { API_TIMEOUT, BASE_URL } from 'src/assets/config/apiConfig';

const client = axios.create({
  baseURL: BASE_URL,
  timeout: API_TIMEOUT,
  timeoutErrorMessage: '🚧🚧🚧 Server connection time out !',
  headers: {
    Accept: 'application/json',
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
    responseEncoding: 'utf8',
    responseType: 'json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Access-Control-Allow-Origin': '*',
    'X-Application': 'web app',
    'X-Version': '1.0.1',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Access-Control-Allow-Headers':
      'Origin, X-Requested-With, Content-Type, Accept',
  },
});

export const request = (options: AxiosRequestConfig) => {
  const token = getStoredAuth();
  client.defaults.headers.common.Authorization = token || undefined;

  const onSuccess = (response: any) => {
    // logger.debug('Response API:', response?.data);
    return response?.data;
  };
  const onError = async (error: any) => {
    if (error?.response?.status === 403) {
      //  if(error?.response?.data. )
      clearStoredAuth();
      window.location.href = window.location.origin;
    }
    // logger.debug('Axios Options:', options);
    // optionally catch errors and add additional logging here
    await Promise.reject({
      statusCode: error?.response?.data?.statusCode,
      message: error?.response?.data?.message,
      statusText: error?.response?.statusText,
      status: error?.response?.status,
      data: error?.response?.data?.data || null,
    });
  };
  return client(options).then(onSuccess).catch(onError);
};
