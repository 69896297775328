import React from 'react';
import ViewCustomScroll from 'src/components/custom/views/ViewCustomScroll';
import ViewsViewNumber from 'src/components/custom/views/ViewsViewNumber';
import { NewsDataProps, TypeNewsEnum } from 'src/model';
import DetailBannerCom from '../components/DetailBanner.Com';
import DetailEditorCom from '../components/DetailEditor.Com';
import DetailLinkCom from '../components/DetailLink.Com';
import DetailVideoCom from '../components/DetailVideo.Com';
import DetailWebViewCom from '../components/DetailWebView.Com';

export interface DetailNewsWidgetProps {
  data?: NewsDataProps;

  related_news?: NewsDataProps[];
}

const DetailNewsWidget: React.FC<DetailNewsWidgetProps> = ({
  data,
  related_news,
}) => {
  const checkType = (type?: TypeNewsEnum) => {
    switch (type) {
      case TypeNewsEnum.WEB:
        return <DetailWebViewCom data={data} related_news={related_news} />;
      case TypeNewsEnum.BANNER:
        return <DetailBannerCom data={data} related_news={related_news} />;
      case TypeNewsEnum.EDITOR:
        return <DetailEditorCom data={data} related_news={related_news} />;
      case TypeNewsEnum.VIDEO:
        return <DetailVideoCom data={data} related_news={related_news} />;
      case TypeNewsEnum.LINK:
        return <DetailLinkCom />;
    }
  };

  return (
    <ViewsViewNumber view={data?.view}>
      <ViewCustomScroll>{checkType(data?.type)}</ViewCustomScroll>
    </ViewsViewNumber>
  );
};

export default DetailNewsWidget;
