import { Paths } from '@utils/constants';
import { Image } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import { ArticleDataProps, TypeNewsEnum } from 'src/model';
import styled from 'styled-components';

export interface CardArticleItemComProps {
  data: ArticleDataProps;
}

const CardArticleItemCom: React.FC<CardArticleItemComProps> = ({ data }) => {
  const history = useHistory();
  const handleLinkArticles = () => {
    return data?.link ? window.open(data?.link, '_blank') : null;
  };

  const handleNavigateArticles = () => {
    history.push(Paths.CafeTravel + '/' + data.id);
  };

  return (
    <Style.Wrapper>
      <Style.InnerWrapper
        onClick={
          data.type === TypeNewsEnum.LINK
            ? handleLinkArticles
            : handleNavigateArticles
        }>
        <Image
          src={data.image ?? ''}
          fallback={IMG_CONFIG.ERROR}
          width={'200px'}
          preview={false}
        />
        {/* content */}
        <Style.ContentWrapper>
          <h5 className="medium_text">{data.title}</h5>
          <p style={{ fontSize: '12px' }}>{data.description}</p>
        </Style.ContentWrapper>
      </Style.InnerWrapper>
    </Style.Wrapper>
  );
};

export default CardArticleItemCom;

const Style = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 2px solid var(--color-process);
  `,
  InnerWrapper: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  `,
  ContentWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    align-items: flex-start;
  `,
};
