import React from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import { Howl, Howler } from 'howler';
import { SOUND_CONFIG } from 'src/assets/config/soundConfig';
import styled from 'styled-components';
import { reduxSound } from 'src/redux/hooks/ReduxSound';

export interface CustomIconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: any;
  text?: string;
  loading?: boolean;
  disabled?: boolean;
  imgWidth?: string;
  imgHeight?: string;
}

const CustomIconButton: React.FC<CustomIconButtonProps> = ({
  icon,
  text,
  loading,
  disabled,
  imgWidth,
  imgHeight,
  ...props
}) => {

  const { useData } = reduxSound;
  const { data } = useData();

  let soundButton: any;

  const renderSoundButton = () => {
    switch (icon) {
      case IMG_CONFIG.BUTTON.OK:
        soundButton = new Howl({
          src: [SOUND_CONFIG.SOUND_BUTTON_2],
          volume: 1,
        });
        break;
      case IMG_CONFIG.MINI_GAME.MINIGAME_GO_RUNNING:
        soundButton = new Howl({
          src: [SOUND_CONFIG.SOUND_GO_BUTTON],
          volume: 1,
        });
        break;
      case IMG_CONFIG.MINI_GAME.MINIGAME_GO_START:
        soundButton = new Howl({
          src: [SOUND_CONFIG.SOUND_GO_BUTTON],
          volume: 1,
        });
        break;
      default:
        soundButton = new Howl({
          src: [SOUND_CONFIG.SOUND_BUTTON_1],
          volume: 1,
        });
    }
  };

  Howler.volume(data.is_bgm);

  renderSoundButton();

  props.onMouseDown = () => {
    soundButton.play();
  };
  
  return (
    <Style.Wrapper disabled={disabled || loading} {...props}>
      <Style.Image
        src={icon || IMG_CONFIG.BUTTON.CONTINUE}
        imgWidth={imgWidth}
        imgHeight={imgHeight}
      />

      <Style.Inner
        className="color_main"
        style={{ fontSize: '11px', lineHeight: '13px' }}>
        {text}
      </Style.Inner>
    </Style.Wrapper>
  );
};

export default CustomIconButton;

const Style = {
  Wrapper: styled.button`
    display: flex;
    position: relative;
    align-items: center;
  `,

  Inner: styled.p`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -1px;
  `,

  Image: styled.img<{ imgWidth?: string; imgHeight?: string }>`
    width: ${(props) => props.imgWidth || 'inherit'};
    height: ${(props) => props.imgHeight || '44px'};
  `,
};
