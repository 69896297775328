import { useFormik } from 'formik';
import * as Yup from 'yup';
import { queryProfile } from 'src/api/hooks/queryProfile';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useFormUpdateUser = () => {
  const query = queryProfile.queryUpdateUser();
  const { useQueryData } = queryProfile.queryProfile();
  const { data, isSuccess } = useQueryData;
  const pet = data?.data.profile;

  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('error.name.required'))
      .max(50, t('error.name.max')),
    line_id: Yup.string()
      .required(t('error.lineId.required'))
      .max(100, t('error.lineId.max'))
      .nullable(),
    line_name: Yup.string()
      .required(t('error.lineName.required'))
      .max(100, t('error.lineName.max'))
      .nullable(),
    stage_id: Yup.string().required(t('error.lineName.required')),
  });

  const formik = useFormik({
    initialValues: {
      name: pet?.name,
      line_id: pet?.line_id,
      line_name: pet?.line_name,
      stage_id: pet?.stage_id,
    },
    validationSchema,
    onSubmit: (values: any) => {
      query.mutate(values);
    },
  });

  useEffect(() => {
    formik.setValues({
      name: pet?.name,
      line_id: pet?.line_id,
      line_name: pet?.line_name,
      stage_id: pet?.stage_id,
    });
  }, [isSuccess]);

  return { formik, query };
};
