import { colors } from './base/color';

export const defaultTheme = {
  //   breakpoints: { ...breakpoints },
  palette: { ...colors },
  fonts: {
    title: 'DFMRG9',
    main: 'DFMRG9',
  },
  breakpoint: {
    xs: getBreakpoint(0),
    sm: getBreakpoint(576),
    md: getBreakpoint(768),
    lg: getBreakpoint(992),
    xl: getBreakpoint(1200),
    xxl: getBreakpoint(1400),
  },
};

function getBreakpoint(number: number) {
  return `only screen and (max-width: ${number}px)`;
}
