import React from 'react';
import Frame, { FrameProps } from 'src/components/custom/item/Frame';
import styled from 'styled-components';

export interface ItemFrameAvatarComProps
  extends FrameProps,
    React.BaseHTMLAttributes<HTMLDivElement> {}

const ItemFrameAvatarCom: React.FC<ItemFrameAvatarComProps> = ({
  linkPet,
  frame,
  ...props
}) => {
  return (
    <Style.Wrapper {...props}>
      <Frame linkPet={linkPet} frame={frame}/>
    </Style.Wrapper>
  );
};

export default ItemFrameAvatarCom;
const Style = {
  Wrapper: styled.div`
    display: flex;
    justify-content: center;
    cursor: pointer;
  `,
};
