import { Paths } from '@utils/constants';
import React from 'react';
import { ResultQuestionProps } from 'src/model/stageGame.model';
import styled from 'styled-components';
import { useWidget } from '../hook/useWidget';
import QuestionLayout from '../layout/Question.Layout';

export interface EndQuestionWidgetProps {
  point?: ResultQuestionProps;
}

const EndQuestionWidget: React.FC<EndQuestionWidgetProps> = ({ point }) => {
  const { push, t } = useWidget();

  function handleReturn() {
    if (point?.reward) {
      push(Paths.Reward, point.reward);
    } else {
      push(Paths.Stage);
    }
  }
  return (
    <QuestionLayout
      header={
        <Style.Header>
          <h5 className="color_question big_title">END</h5>
        </Style.Header>
      }
      isChoice>
      <Style.Wrapper>
        <Style.Board>
          <h5>おつかれさまでした。</h5>
          <Style.Text>
            <p>{point?.point}ポイントを獲得しました</p>
            <p>どんどん答えていろいろな</p>
            <p>商品もらってね。</p>
          </Style.Text>
          <Style.Button onClick={handleReturn}>
            <p>{t('content.game.returnGame')}</p>
          </Style.Button>
        </Style.Board>
      </Style.Wrapper>
    </QuestionLayout>
  );
};

export default EndQuestionWidget;

const Style = {
  Wrapper: styled.div`
    height: max-content;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    margin-top: 200px;

    p {
      color: #fbebe1;
    }
    h5 {
      color: #fbebe1;
    }
  `,
  Header: styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
  `,

  Board: styled.div`
    display: grid;
    padding: 20px;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 15px;
    justify-items: center;
    align-items: center;
    background: #706863;
    box-shadow: 0px 12px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
  `,

  Text: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Button: styled.button`
    border: 1.5px solid #fbebe1;
    border-radius: 2000px;
    padding: 8px 23px;
  `,
};
