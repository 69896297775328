import { getStoredAuth } from '@utils/helpers/localStorage';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { queryProfile } from 'src/api/hooks/queryProfile';

export interface ProtectedRouteProps extends RouteProps {
  redirect?: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  redirect,
  ...props
}) => {
  const token = getStoredAuth();

  const { useQueryData } = queryProfile.queryProfile();
  const { data, isSuccess } = useQueryData;

  if (!data && isSuccess) {
    return redirect ? (
      <Redirect
        to={{
          pathname: redirect,
          state: {
            from: props.location,
          },
        }}
      />
    ) : null;
  }

  if (!token) {
    return redirect ? (
      <Redirect
        to={{
          pathname: redirect,
          state: {
            from: props.location,
          },
        }}
      />
    ) : null;
  }

  return <Route {...props} />;
};

export default ProtectedRoute;
