import React from 'react';
import { AdvertisementsDataProps } from 'src/model/dashboard.model';
import CardAdsCom from '../components/CardAds.Com';

export interface ListAdsWidgetProps {
  data?: AdvertisementsDataProps[];
}

function checkLenght(data: any[]): boolean {
  if (data.length === 1) {
    return true;
  } else return false;
}

const ListAdsWidget: React.FC<ListAdsWidgetProps> = ({ data }) => {
  return (
    <>
      {data?.map((item, i) => {
        return <CardAdsCom isLager={checkLenght(data)} data={item} key={i} />;
      })}
    </>
  );
};

export default ListAdsWidget;
