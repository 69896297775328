import React, { PropsWithChildren } from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import styled from 'styled-components';

export interface ViewsPetProfileComProps extends PropsWithChildren<any> {
  button?: React.ReactNode;
  title?: string;
  name?: string;
}

const ViewsPetProfileCom: React.FC<ViewsPetProfileComProps> = ({
  button,
  children,
  title,
  name,
}) => {
  return (
    <Style.Wrapper>
      <Style.Title>
        <h5 style={{ fontSize: '14px', color: '#d16341' }}>{title}</h5>
        <p className="color_main" style={{ fontSize: '16px' }}>
          {name}
        </p>
      </Style.Title>

      <Style.Content>{children}</Style.Content>

      <div style={{ width: '100%' }}>{button}</div>
    </Style.Wrapper>
  );
};

export default ViewsPetProfileCom;

const Style = {
  Wrapper: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    height: 100%;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    grid-gap: 10px;
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
  `,

  Content: styled.div`
    background-image: url(${IMG_CONFIG.BACKGROUND.BG_PET});
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 60% 50%;
    padding: 40px 10px 20px 10px;
    overflow-y: hidden;
    overflow-x: hidden;
    /* min-height: 100%; */
  `,
  Title: styled.div`
    width: 70%;
    background-color: #fadeb1;
    -webkit-box-shadow: 0px 4px 0px 3px rgba(129, 60, 39, 1),
      inset -2px -8px 0px 2px rgba(245, 212, 169, 1);
    box-shadow: 0px 4px 0px 3px rgba(129, 60, 39, 1),
      nset -2px -8px 0px 2px rgba(245, 212, 169, 1);
    border-radius: 20px;
    /* background-image: url(${IMG_CONFIG.REGISTER.TITLE}); */
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 40px;
    position: absolute;
    top: 0px;
    padding: 5px 10px;
  `,
};
