import { getStoredAuth } from '@utils/helpers/localStorage';
import Echo from 'laravel-echo';
import io from 'socket.io-client';

declare global {
  interface Window {
    io: any;
    Echo: Echo;
  }
}

window.io = io;

export function createSocketConnection() {
    window.Echo = new Echo({
      broadcaster: 'socket.io',
      host: 'https://socket.uchipara.com' || '',
      transports: ['websocket', 'polling', 'flashsocket'],
      auth: {
        headers: {
          Authorization: getStoredAuth(),
        },
      },
    });
}
