import React, { PropsWithChildren } from 'react';
import { AnswerTypeEnum, QuestionDataProps } from 'src/model/stageGame.model';
import { reduxAnswer } from 'src/redux/hooks/ReduxAnswer';
import styled from 'styled-components';
import ButtonProcessCom from '../components/ButtonProcess.Com';
import HeaderQuestionCom, {
  HeaderQuestionComProps,
} from '../components/HeaderQuestion.Com';
import ProcessQuestionBarCom, {
  ProcessQuestionBarComProps,
} from '../components/ProcessQuestionBar.Com';
import QuestionLayout from '../layout/Question.Layout';
import { getProcessPercent } from '../utils/getProcessPercent';
import ListAnswerWidget from './ListAnswer.Widget';

export interface QuestionWidgetProps
  extends PropsWithChildren<any>,
    HeaderQuestionComProps,
    ProcessQuestionBarComProps,
    StyleProps {
  onPre?: any;
  onNext?: any;
  data?: QuestionDataProps;
}

const QuestionWidget: React.FC<QuestionWidgetProps> = ({
  index,
  totalQ,
  onPre,
  onNext,
  data,
  isChoice,
}) => {
  const process = {
    currentQ: index,
    totalQ,
    processPercent: getProcessPercent(index || 0, totalQ || 1),
  };

  const header = {
    question: data?.title,
    index,
    image: data?.image,
    question_id : data?.id,
    type_pet : data?.pet_type
  };

  const { useCheckPassQuestion } = reduxAnswer;
  const { check, checkMax } = useCheckPassQuestion(data?.id || 0);

  return (
    <QuestionLayout
      isChoice={isChoice}
      header={<HeaderQuestionCom {...header} />}
      footer={<ProcessQuestionBarCom {...process} />}
      button={
        <Style.Button>
          <ButtonProcessCom onClick={onPre} />
          <ButtonProcessCom isNext onClick={onNext} disabled={!check} />
        </Style.Button>
      }>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '10px',
        }}>
        {data?.video ? (
          <video style={{ width: '100%', maxWidth: '400px' }} controls>
            <source src={data?.video} type="video/mp4" />
          </video>
        ) : null}
      </div>

      <ListAnswerWidget
        question_id={data?.id ?? 0}
        data={data?.answers || []}
        type={data?.type_answer || AnswerTypeEnum.ONLY_TEXT}
        type_pet = {data?.pet_type || 0}
      />
      {!checkMax && (
        <div style={{ marginTop: '20px' }}>
          <p className="color_error">
            この質問に対して、回答が {data?.max_number_of_answers}{' '}
            つ目までしか選択できない状態となりました。
          </p>
          <p className="color_error">
            続行するために、回答を減らしてください。
          </p>
        </div>
      )}
    </QuestionLayout>
  );
};

export default QuestionWidget;

interface StyleProps {
  isChoice?: boolean;
}

const Style = {
  Button: styled.div`
    display: flex;
    justify-content: space-between;
    margin: 10px 10px 20px 10px;
    width: 100%;
    padding: 10px;
  `,
};
