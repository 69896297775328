import React from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import styled from 'styled-components';

export interface ImagePetUploadComProps extends React.BaseHTMLAttributes<any> {
  imgSrc?: any;
  error?: any;
  onOpen?: any;
}

const ItemUploadSpot: React.FC<ImagePetUploadComProps> = ({
  imgSrc,
  error,
  onOpen,
  ...props
}) => {
  return (
    <>
      <Style.Wrapper {...props} onClick={imgSrc ? onOpen : null}>
        <Style.ImgWrapper imgBg={imgSrc ?? ''}>
          {imgSrc ? null : <img src={IMG_CONFIG.EVENT.FRAME_DEFAULT} />}
        </Style.ImgWrapper>
      </Style.Wrapper>
      {error && (
        <Style.TextError className="color_error small_text error_wrapper">
          {error}
        </Style.TextError>
      )}
    </>
  );
};

export default ItemUploadSpot;

interface StyleProps {
  imgBg?: string;
}

const Style = {
  Wrapper: styled.div`
    position: relative;
    width: 117px;
    height: 117px;
    background-image: url(${IMG_CONFIG.EVENT.FRAME_UPLOAD});
    background-size: cover;
    /* padding: 25px; */
    cursor: pointer;
    span {
      transform-origin: center;
    }
  `,
  ImgWrapper: styled.div`
    width: 117px;
    height: 117px;
    overflow: hidden;
    background-image: ${(p: StyleProps) => `url(${p.imgBg})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: */
    img {
      width: 80%;
      height: 80%;
    }
  `,
  TextError: styled.p`
    text-align: center;
  `,
};
