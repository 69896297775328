import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { maxSizeImage } from 'src/assets/config/ContantsConfig';
import { checkType } from 'src/assets/config/imgUploadConfig';
import { reduxNotification } from 'src/redux/hooks/ReduxNotification';
import { setDimensionsImage } from '@utils/helpers';

export interface DataDimensionProps {
  width: number;
  height: number;
}

export const useInputUpload = (handle?: any) => {
  const [imgUrl, setImgUrl] = useState<any>(null);
  const [dimensions, setDimensions] = useState<DataDimensionProps>();
  const hiddenFileInput: any = useRef(null);
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const { useAction } = reduxNotification;

  const { t } = useTranslation();

  const { openModalError } = useAction();
  const handleChange2 = (event: any) => {
    const fileUploaded = event.target.files[0];
    const imageUrl = URL.createObjectURL(fileUploaded);
    const img = new Image();
    img.onload = function () {
      setTimeout(() => {
        const aspectRatio = img.width / img.height;
        setDimensions(setDimensionsImage(180, img.width, img.height, aspectRatio));
        URL.revokeObjectURL(imageUrl);
      }, 0);
    };
    img.src = imageUrl;
    if (handle) {
      handle(fileUploaded);
    }
    setImgUrl(fileUploaded);
  };

  const handleChange = (event: any) => {
    const size = event.target.files[0].size;
    const type = event.target.files[0].type;

    const isJpgOrPng = checkType(type);

    if (!isJpgOrPng) {
      return openModalError(t('notification.error'), t('error.image.type'));
    }

    if (maxSizeImage < size) {
      return openModalError(t('notification.error'), t('error.image.size'));
    } else {
      // formik.setFieldValue(event.target.name, event.target.files[0]);
      handleChange2(event);
      return;
    }
  };

  const handleReset = () => {
    setImgUrl(null);
  };

  return {
    imgUrl,
    handleClick,
    hiddenFileInput,
    handleChange,
    setImgUrl,
    handleReset,
    dimensions,
  };
};
