import { Image } from 'antd';

import React from 'react';
import { queryFrame } from 'src/api/hooks/queryFrame';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import { FrameDataProps } from 'src/model/frame.model';

import styled from 'styled-components';

export interface ItemMyFrameComProps {
  data?: FrameDataProps;
}

const ItemMyFrameCom: React.FC<ItemMyFrameComProps> = ({ data }) => {
  const { mutate } = queryFrame.querySet(data?.id ?? 0);
  return (
    <Style.Wrapper>
      <Style.Frame>
        <Image src={data?.media} fallback={IMG_CONFIG.ERROR} preview={false} />
      </Style.Frame>
      {!data?.is_used && (
        <Style.ButtonWrapper>
          <CustomIconButton
            onClick={() => mutate(data?.id)}
            icon={IMG_CONFIG.CAFE.SET_FRAME}
          />
        </Style.ButtonWrapper>
      )}
    </Style.Wrapper>
  );
};

export default ItemMyFrameCom;

const Style = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  `,

  ButtonWrapper: styled.div`
    transform:scale(0.7);
    margin-top:-7px;
    margin-left:2px;
  `,

  Frame: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;

    img {
      width: 100%;
    }
  `,
};
