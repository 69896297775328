import React from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import styled from 'styled-components';

export interface ViewRegisterSuccessComProps {
  namePet: string;
}

const ViewRegisterSuccessCom: React.FC<ViewRegisterSuccessComProps> = ({
  namePet,
}) => {
  return (
    <Style.Wrapper>
      <img src={IMG_CONFIG.EVENT.SUCCESS} />
      <Style.Inner>
        <div
          style={{
            maxWidth: 150,
            width: 'fit-content',
            height: '130px',
            paddingTop: '20px',
            display: 'flex',
            alignItems: 'center',
          }}>
          <p className="text_main color_main" style={{ textAlign: 'center' }}>
            {namePet}さんコンテスト参加を受け付けました! 開催までお待ちください
          </p>
        </div>
      </Style.Inner>
    </Style.Wrapper>
  );
};

export default ViewRegisterSuccessCom;

const Style = {
  Wrapper: styled.div`
    width: 100%;
    height: 100%;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }
  `,
  Inner: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  `,
};
