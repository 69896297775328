import styled from 'styled-components';

interface Props {
  space?: number;
}

export const ViewContent = {
  List: styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    /*flex-direction: 100%;*/
    /* gap: 5px; */
    grid-template-columns: repeat(1, 1fr);
    grid-gap: ${(p: Props) => `${p.space || 10}px`};
    /*flex-direction: column;*/
    padding-left: 0px;
    padding-right: 0px;
    /* overflow-y: auto; */
  `,

  ListFlex: styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: ${(p: Props) => `${p.space || 10}px`};
    padding-left: 0px;
    padding-right: 0px;
    /* overflow-y: auto; */
  `,
};
