import { useQuery } from 'react-query';
import { ArticleDataProps, RequestListProps } from 'src/model';
import { useState } from 'react';
import { DataListResponseProps, ResponseProps } from 'src/model/res.model';

import { ARTICLE_KEY } from '../queries/keys';

import { articleApi } from '../axios/article';

/**
 * @class QueryArticle()
 * @method queryList()
 * @method queryDetail()
 * @returns
 */

class QueryArticle {
  key: { LIST: string; DETAIL: string;};

  constructor() {
    this.key = ARTICLE_KEY;
  }

  queryList(queryData?: RequestListProps) {
    const [query, setQuery] = useState<RequestListProps>(
      queryData || {
        page: 1,
      },
    );
    const useQueryData = useQuery<
      ResponseProps<{ articles: DataListResponseProps<ArticleDataProps> }>,
      Error
    >([this.key.LIST, query], () => articleApi.getList(query), {
      // enabled: !!query.type,
      refetchOnMount: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
    });
    return { query, setQuery, useQueryData };
  }
  queryDetail(id: number) {
    return useQuery<
      ResponseProps<{ article: ArticleDataProps}>,
      Error
    >([this.key.DETAIL, id], () => articleApi.getDetail(id), {
      // enabled: !!id,
      refetchOnMount: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    });
  }
}

export const queryArticle = new QueryArticle();
