import React from 'react';
import ViewNoData from 'src/components/custom/views/ViewNoData';
import { NewsDataProps } from 'src/model';
import NewsDetailLayout from '../layout/news-detail.layout';

export interface DetailEditorComProps {
  data?: NewsDataProps;
  related_news?: NewsDataProps[];
}

const DetailEditorCom: React.FC<DetailEditorComProps> = ({
  data,
  related_news,
}) => {
  return (
    <NewsDetailLayout
      title={data?.title}
      des={data?.description}
      typeView={data?.type}
      related_news={related_news}>
      {data?.content ? (
        <div dangerouslySetInnerHTML={{ __html: data.content ?? '' }} />
      ) : (
        <ViewNoData />
      )}
    </NewsDetailLayout>
  );
};

export default DetailEditorCom;
