import React from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import styled from 'styled-components';

export interface ImagePetUploadComProps extends React.BaseHTMLAttributes<any> {
  imgSrc?: any;
  error?: any;
  upload?: any;
}

const ImagePetUploadCom: React.FC<ImagePetUploadComProps> = ({
  imgSrc,
  error,
  upload,
  ...props
}) => {
  return (
    <>
      <Style.Wrapper {...props}>
        {upload?.imgUrl !== null ? (
          <Style.ImgWrapper imgBg={imgSrc ?? ''}>
            {imgSrc ? null : <img src={IMG_CONFIG.EVENT.FRAME_DEFAULT} />}
          </Style.ImgWrapper>
        ) : (
          <label htmlFor="upload" style={{ cursor: 'pointer' }}>
            <Style.ImgWrapper imgBg={imgSrc ?? ''}>
              {imgSrc ? null : <img src={IMG_CONFIG.EVENT.FRAME_DEFAULT} />}
            </Style.ImgWrapper>
          </label>
        )}
      </Style.Wrapper>
      {error && <p className="color_error small_text error_wrapper">{error}</p>}
    </>
  );
};

export default ImagePetUploadCom;

interface StyleProps {
  imgBg?: string;
}

const Style = {
  Wrapper: styled.div`
    position: relative;
    width: 220px;
    height: 220px;
    background-image: url(${IMG_CONFIG.EVENT.FRAME_UPLOAD});
    background-size: cover;
    padding: 25px;
    cursor: pointer;
    span {
      transform-origin: center;
    }

    span:nth-child(1) {
      position: absolute;
      top: -0px;
      left: 40px;
    }
    span:nth-child(2) {
      position: absolute;
      bottom: -10px;
      left: 40px;
      transform: rotate(180deg);
    }
    span:nth-child(3) {
      position: absolute;
      top: 35px;
      left: -10px;
      transform: rotate(-90deg);
    }
    span:nth-child(4) {
      position: absolute;
      top: 35px;
      right: -10px;
      transform: rotate(90deg);
    }
  `,
  ImgWrapper: styled.div`
    width: 170px;
    height: 170px;
    overflow: hidden;
    background-image: ${(p: StyleProps) => `url(${p.imgBg})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    /* border: */
    img {
      width: 100%;
      height: 100%;
    }
  `,
};
