import React from 'react';
import DashboardItemCom from '../components/DashboardItem.Com';
import { listDashboard } from '../config';

import { Col, Row } from 'antd';

export interface ListFeatureWidgetProps {}

const ListFeatureWidget: React.FC<ListFeatureWidgetProps> = () => {
  return (
    <Row justify="center" gutter={[16, 8]} style={{ padding: '0 16px', transform: 'scale(1.2)', marginLeft: '-2px', marginTop: '50px'}}>
      {listDashboard.map((item, i) => {
        return (
          <Col span={8} key={i}>
            <DashboardItemCom item={item} />
          </Col>
        );
      })}
    </Row>
  );
};

export default ListFeatureWidget;
