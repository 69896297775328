import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import { TypePetGroupEnum } from 'src/model/dashboard.model';

export const configData = [
  {
    img: IMG_CONFIG.TYPE_PET.TYPE_A,
    type: TypePetGroupEnum.A,
  },
  {
    img: IMG_CONFIG.TYPE_PET.TYPE_KA,
    type: TypePetGroupEnum.KA,
  },
  {
    img: IMG_CONFIG.TYPE_PET.TYPE_SA,
    type: TypePetGroupEnum.SA,
  },
  {
    img: IMG_CONFIG.TYPE_PET.TYPE_TA,
    type: TypePetGroupEnum.TA,
  },
  {
    img: IMG_CONFIG.TYPE_PET.TYPE_NA,
    type: TypePetGroupEnum.NA,
  },
  {
    img: IMG_CONFIG.TYPE_PET.TYPE_HA,
    type: TypePetGroupEnum.HA,
  },
  {
    img: IMG_CONFIG.TYPE_PET.TYPE_MA,
    type: TypePetGroupEnum.MA,
  },
  {
    img: IMG_CONFIG.TYPE_PET.TYPE_YA,
    type: TypePetGroupEnum.YA,
  },
  {
    img: IMG_CONFIG.TYPE_PET.TYPE_RA,
    type: TypePetGroupEnum.RA,
  },
  {
    img: IMG_CONFIG.TYPE_PET.TYPE_WA,
    type: TypePetGroupEnum.WA,
  },
];
