import { useState } from 'react';

export const useIsOpen = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  function onToggle() {
    setIsOpen(!isOpen);
  }
  function onOpen() {
    setIsOpen(true);
  }
  function onClose() {
    setIsOpen(false);
  }

  return { isOpen, onClose, onOpen, onToggle };
};
