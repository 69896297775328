import React from 'react';
import { NotificationDetailProps } from 'src/model/notification.model';
import CardListNotificationCom from '../components/CardListNotification.Com';

export interface ListNotificationWidgetProps {
  data?: NotificationDetailProps[];
}

const ListNotificationWidget: React.FC<ListNotificationWidgetProps> = ({
  data,
}) => {
  return (
    <>
      {data?.map((item) => {
        return <CardListNotificationCom data={item} key={item.id} />;
      })}
    </>
  );
};

export default ListNotificationWidget;
