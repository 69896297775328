import { Image } from 'antd';
import React from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import ViewCustomScroll from 'src/components/custom/views/ViewCustomScroll';
import { IdataAds } from 'src/pages/news/widget/ListNews.Widget';
import { CafeSortKey } from 'src/model/cafesort.model';
import styled from 'styled-components';
import {
  CardArticleCom,
  CardEventCom,
  CardFrameMarketCom,
} from '../components/CardEvent.Com';

export interface ListCafeWidgetProps {
  dataCafe?: any;
}

const ListCafeWidget: React.FC<ListCafeWidgetProps> = ({ dataCafe }) => {
  const handleLink = (items: IdataAds) => {
    return items?.link ? window.open(items?.link, '_blank') : null;
  };

  function renderContentCafe(item: any) {
    switch (item.type) {
      case CafeSortKey.EVENT:
        return (
          <Style.Wrapper>
            <CardEventCom data={item} />
          </Style.Wrapper>
        );
      case CafeSortKey.ARTICLES:
        return (
          <Style.Wrapper>
            <CardArticleCom />
          </Style.Wrapper>
        );
      case CafeSortKey.FRAME:
        return (
          <Style.Wrapper>
            <CardFrameMarketCom />
          </Style.Wrapper>
        );
      case CafeSortKey.ADVERTISEMENT:
        return (
          <>
            <Style.WrapperTop
              onClick={() => handleLink(item)}
              style={{ margin: '14px 6px 14px 10px' }}>
              <Style.Img>
                <Image
                  src={item?.media || 'error'}
                  fallback={IMG_CONFIG.ERROR}
                  style={{
                    width: '50px',
                    borderRadius: '5px',
                  }}
                  preview={false}
                />
              </Style.Img>

              <div style={{ marginLeft: '10px' }}>
                <h5 className="color_text small_text ">{item?.title}</h5>
                <p
                  style={{ marginTop: '5px' }}
                  className="color_des small_text ">
                  {item?.description}
                </p>
              </div>
            </Style.WrapperTop>
          </>
        );
    }
  }

  return (
    <ViewCustomScroll>
      <>
        {dataCafe?.data.map((item: any, i: number) => (
          <div key={i}>{renderContentCafe(item)}</div>
        ))}
      </>
    </ViewCustomScroll>
  );
};

export default ListCafeWidget;

const Style = {
  Wrapper: styled.div`
    margin-top: 0px;
    margin-bottom: 14px;
    width: 305px !important;
    height: auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 12px;
    margin-left: 10px;
  `,
  WrapperTop: styled.div`
    display: flex;
    width: 305px !important;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background: #ffffff;
    border-radius: 4px;
    padding: 10px;
    /* gap: 12px; */
    cursor: pointer;
    height: fit-content;
    box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 3px 3px 0px 4px rgba(0, 0, 0, 0.25);

    h5 {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 18px !important;
      line-height: 20px !important;
    }

    p {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  `,
  Img: styled.div`
    /* transform: translateY(5px); */
  `,
};
