import React from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import { optionsKittyLoading } from './config';
export interface ViewLoadingProps {
  isLoading?: boolean;
}

const ViewLoading: React.FC<ViewLoadingProps> = ({ isLoading }) => {
  return (
    <Style.Wrapper isLoading={isLoading}>
      <Style.LoadingView>
        <Lottie
          options={optionsKittyLoading}
          height={150}
          width={150}
          isClickToPauseDisabled
        />
      </Style.LoadingView>
      {/* <LoadingOutlined style={{ color: 'red', fontSize: 50 }} /> */}
    </Style.Wrapper>
  );
};

export default ViewLoading;

const Style = {
  Wrapper: styled.div`
    width: 100%;
    height: 100%;
    display: ${(p: ViewLoadingProps) => (p.isLoading ? 'none' : 'flex')};
    justify-content: center;
    align-items: center;
  `,
  LoadingView: styled.div`
    position: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  `,
};
