import { Image } from 'antd';
import React from 'react';
import { queryArticle } from 'src/api/hooks/queryArticle';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import ViewsViewNumber from 'src/components/custom/views/ViewsViewNumber';
import styled from 'styled-components';
import CardArticleItem from '../components/CardArticleItem.Com';

export interface ArticleListWidgetProps {
  view?: number;
}

const ArticleListWidget: React.FC<ArticleListWidgetProps> = () => {
  const { useQueryData } = queryArticle.queryList();
  const { data, isLoading } = useQueryData;
  const dataList = data?.data.articles.data;

  return (
    <ViewsViewNumber
      view={20}
      loading={isLoading}
      className={'custom_scroll'}
      isNotView>
      <Style.Wrapper>
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            // justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
          <Image
            src={IMG_CONFIG.CAFE.ARTICLE}
            preview={false}
            style={{ width: '50px' }}
          />
          <p className="small_text">ペットとお泊りできる人気のホテルをご紹介！</p>
        </div>

        {/* content */}
        <Style.ListWrapper>
          {dataList?.map((item, i) => {
            return <CardArticleItem data={item} key={i} />;
          })}
        </Style.ListWrapper>
      </Style.Wrapper>
    </ViewsViewNumber>
  );
};

export default ArticleListWidget;

const Style = {
  Wrapper: styled.section`
    background: linear-gradient(90deg, #fffdee 0%, #fff3dd 100%);
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    justify-items: center;
    width: 100%;
    grid-gap: 10px;
    padding: 20px;
    height: fit-content;
    min-height: 100%;
    margin-left:2px !important;
  `,
  ListWrapper: styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    /* overflow-y: scroll; */
    grid-gap: 10px;
  `,
};
