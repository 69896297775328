import React, { PropsWithChildren } from 'react';

import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import styled from 'styled-components';

export interface ModalConfirmQWidgetProps extends PropsWithChildren<any> {
  title?: string;
  des?: string;
}

const ViewModalConfirm: React.FC<ModalConfirmQWidgetProps> = ({
  title,
  des,
  children,
}) => {
  return (
    <Style.Wrapper>
      <img src={IMG_CONFIG.MAP_GAME.GAME_CONFIRM} />
      <Style.Title>
        <p className="color_white">{title}</p>
      </Style.Title>
      <Style.Content>
        <p>{des}</p>
        <Style.Button>{children}</Style.Button>
      </Style.Content>
    </Style.Wrapper>
  );
};

export default ViewModalConfirm;

const Style = {
  Wrapper: styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
  `,

  Title: styled.div`
    position: absolute;
    width: fit-content;
    top: 20px;
  `,
  Content: styled.div`
    position: absolute;
    width: 100%;
    top: 40%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Button: styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
  `,
  Close: styled.div`
    position: absolute;
    top: 0px;
    right: -10px;
  `,
};
