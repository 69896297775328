import React from 'react';
import { useParams } from 'react-router-dom';
import { queryEvent } from 'src/api/hooks/queryEvent';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import ViewCustomScroll from 'src/components/custom/views/ViewCustomScroll';
import ViewNoData from 'src/components/custom/views/ViewNoData';
import { EventResultDataProps } from 'src/model';
import styled from 'styled-components';
import CardRankingCom from '../components/CardRanking.Com';
import { checkArray } from '../utils/checkArray';

export interface DetailEventRankWidgetProps {
  data?: EventResultDataProps[];
  view?: number;
}

const DetailEventRankWidget: React.FC<DetailEventRankWidgetProps> = () => {
  const { id } = useParams<any>();
  const { useQueryData } = queryEvent.queryResult(id);

  const { data: list } = useQueryData;
  const data = list?.data.result.data;
  return (
    <>
      {checkArray(data ?? []) ? (
        <ViewNoData />
      ) : (
        <Style.Wrapper>
          {/* absolution */}
          <span>
            <img src={IMG_CONFIG.CAFE.FLAG_1} alt={'flag'} />
          </span>
          <span>
            <img src={IMG_CONFIG.CAFE.FLAG_2} alt={'flag'} />
          </span>
          <span>
            <p className="medium_text">おめでとう！</p>
          </span>
          <span>
            <img src={IMG_CONFIG.CAFE.TEXT} alt={'text'} />
          </span>
          <TopPet image={data ? (data.length > 0 ? data[0].image : '') : ''} />
          {/* <div style={{ height: '10px' }} /> */}
          <ViewCustomScroll>
            <Style.ListWrapper>
              {data?.map((item, i) => {
                return <CardRankingCom key={i} data={item} no={i + 1} />;
              })}
            </Style.ListWrapper>
          </ViewCustomScroll>
        </Style.Wrapper>
      )}
    </>
  );
};

const TopPet = ({ image }: { image?: string }) => {
  return (
    <Style.TopWrapper>
      {/* absolution */}
      <span>
        <img src={IMG_CONFIG.CAFE.TARA} />
      </span>
      <img style={{ width: '55px', height: '55px' }} src={image ?? ''} />
      {/* <Image src={image ?? ''} height={'55px'} width={'55px'} preview={false} /> */}
    </Style.TopWrapper>
  );
};

export default DetailEventRankWidget;

const Style = {
  Wrapper: styled.section`
    position: relative;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 5px;
    justify-items: center;
    align-content: flex-start;
    border-radius: 8px;
    margin-left:2px !important;
    width: 100%;
    height: 100%;
    padding: 10px;
    background-image: url(${IMG_CONFIG.BACKGROUND.RANKING}),
      linear-gradient(90deg, #fffdee 0%, #fff3dd 100%);
    background-repeat: no-repeat;
    background-position: center;
    /* gap: 5px; */

    span:nth-child(1) {
      position: absolute;
      top: 0;
      left: 0;
    }
    span:nth-child(2) {
      position: absolute;
      top: 0;
      right: 0;
    }
    span:nth-child(3) {
      position: absolute;
      top: 50px;
      left: 20px;
    }
    span:nth-child(4) {
      position: absolute;
      top: 50px;
      right: 20px;
    }
  `,

  TopWrapper: styled.div`
    display: flex;
    width: 100%;
    align-items: flex-start;
    position: relative;
    justify-content: center;
    padding-top: 30px;
    height: fit-content;

    span:nth-child(1) {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      width: 100%;
      justify-content: center;
      z-index: 20;
    }

    /* @media (max-width: 340px) {
      transform: scale(0.8);
    } */
  `,
  ListWrapper: styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 5px;
    margin-top: 5px;
  `,
};
