import { RequestListProps } from 'src/model';
import {
  EventListQuery,
  EventRegisterDataProps,
  PostVoteProps,
} from 'src/model/event.model';
import { request } from './axios';
import { API_URL } from './config';

class EventApi {
  url: { LIST: string };

  constructor() {
    this.url = API_URL.EVENT;
  }
  getList(params: EventListQuery) {
    return request({
      url: this.url.LIST,
      method: 'GET',
      params,
    });
  }
  getResult(id: number) {
    return request({
      url: this.url.LIST + '/' + id + '/result',
      method: 'GET',
    });
  }

  getDetail(id: number) {
    return request({
      url: this.url.LIST + '/' + id,
      method: 'GET',
    });
  }

  getAdvertisement(id: number) {
    return request({
      url: API_URL.EVENT.ADVERTISEMENT,
      method: 'GET',
      params: { section_id: id },
    });
  }

  // vote
  getParticipants(params: RequestListProps, id: number) {
    return request({
      url: this.url.LIST + '/' + id + '/' + 'participants',
      method: 'GET',
      params,
    });
  }

  postVote(dataVote: PostVoteProps) {
    const data = {
      participant_id: dataVote.participant_id,
    };
    return request({
      url: this.url.LIST + '/' + dataVote.id + '/' + 'vote',
      method: 'POST',
      data,
    });
  }

  postMark(id: number) {
    return request({
      url: this.url.LIST + '/' + id + '/' + 'mark-read',
      method: 'POST',
    });
  }

  // register
  postRegister(dataRes: EventRegisterDataProps, id: number) {    
    const data = new FormData();
    data.append('pet_id', dataRes.pet_id.toString());
    if(dataRes.name) {
      data.append('name', dataRes.name);
    }
    data.append('image', dataRes.image);

    return request({
      url: this.url.LIST + '/' + id + '/' + 'register',
      method: 'POST',
      data,
    });
  }
}

export const eventApi = new EventApi();
