import React from 'react';
import { useQueryAuth } from 'src/api/hooks/useQueryAuth';

import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import ViewBoardAuth from 'src/components/core/View/ViewBoardAuth';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import CustomModal from 'src/components/custom/modal/CustomModal';
import useScrollPercentage from 'src/components/custom/views/useScrollPercent';
import { useIsOpen } from 'src/hooks/useIsOpen';
import styled from 'styled-components';
import ButtonsConfirm from '../components/ButtonsConfirm';
import ItemMenuCom from '../components/ItemMenu.Com';
import { useWidget } from '../hook/useWidget';

export interface ModalNavbarWidgetProps {
  onCancel: any;
}

interface StyleProps {
  isMax?: boolean;
  percent?: any;
  paddingTop?: number;
  paddingBottom?: number;
}

const ModalNavbarWidget: React.FC<ModalNavbarWidgetProps> = ({ onCancel }) => {
  const { t, navData } = useWidget();
  const logout = useIsOpen();

  const { queryLogout } = useQueryAuth();

  const { mutate } = queryLogout();
  const [scrollRef, scrollPercentage] = useScrollPercentage();

  return (
    <ViewBoardAuth title={t('content.profile.menu')} onCancel={onCancel}>
      <Style.Wrapper
        className={'custom_scroll'}
        percent={scrollPercentage}
        isMax={scrollPercentage === 100}
        ref={scrollRef}>
        {navData.map((item, i) => {
          return <ItemMenuCom {...item} key={i} />;
        })}
      </Style.Wrapper>
      <Style.Button>
        <Style.ButtonWrapper>
          <CustomIconButton
            icon={IMG_CONFIG.BUTTON.LOGOUT}
            onClick={logout.onOpen}
          />
        </Style.ButtonWrapper>
      </Style.Button>

      <CustomModal
        onCancel={logout.onClose}
        closeIcon={<div />}
        visible={logout.isOpen}
        footer={null}
        width={'100%'}>
        <ButtonsConfirm
          onConfirm={() => mutate('')}
          onCancel={logout.onClose}
        />
      </CustomModal>
    </ViewBoardAuth>
  );
};

export default ModalNavbarWidget;

const Style = {
  Wrapper: styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 20px;
    margin-bottom: 10px;
    grid-gap: 10px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: ${({ paddingTop }: StyleProps) => `${paddingTop || 0}px`};
    padding-bottom: ${({ paddingBottom }: StyleProps) =>
      `${paddingBottom || 0}px`};
    &.custom_scroll {
      overflow-y: auto;
      ::-webkit-scrollbar {
        width: 15px;
        background-image: url(${IMG_CONFIG.SCROLL.SCROLL_BG});
        background-repeat: repeat-y;
        background-position: center;
        cursor: pointer;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        height: 20px;
        cursor: pointer;
      }
      ::-webkit-resizer {
      }

      ::-webkit-scrollbar-track-piece {
        height: 20px;
        cursor: pointer;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb:vertical {
        height: 12px;
        background-image: url(${IMG_CONFIG.SCROLL.SCROLL});
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: ${(p: StyleProps) => `${p.percent || 0}%`};
        max-height: 12px;
        transform: translateX(3px) scale(1.5);
        cursor: pointer;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        /* background: #555; */
        transform: scale(1.02);
        cursor: pointer;
      }
    }
  `,
  Button: styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 15px;
    margin-right: 20px;
  `,
  ButtonWrapper: styled.div`
    transform: scale(1.2);
  `,
};
