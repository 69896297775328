import React from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';

import MainLayout from 'src/layouts/MainLayout';

import ForgotEmailWidget from '../widget/ForgotEmail.Widget';

export interface ForgotEmailScreenProps {}

const ForgotEmailScreen: React.FC<ForgotEmailScreenProps> = () => {
  return (
    <MainLayout
      screen={IMG_CONFIG.BACKGROUND.MAIN}
      hiddenSetting
      minHeight={500}>
      <ForgotEmailWidget />
    </MainLayout>
  );
};

export default ForgotEmailScreen;
