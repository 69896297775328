import { AppRouter } from './routes';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import 'antd/dist/antd.min.css';
import 'moment/locale/en-gb';
import StyleTheme from './assets/theme';
import StoreProvider from './redux/store/StoreProvider';
// const socketId = undefined;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <StoreProvider>
        <StyleTheme>
          <AppRouter />
        </StyleTheme>
      </StoreProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
