import React from 'react';
import CardExchangeHistoryCom from '../components/CardExchangeHistory.Com';
import { ExchangeHistoryDataProps } from 'src/model/product.model';

export interface ListExchangeHistoryWidgetProps {
  data: ExchangeHistoryDataProps[] ;
  onOpenDetail : any;
}

const ListExchangeHistoryWidget: React.FC<ListExchangeHistoryWidgetProps> = ({
  data,
  onOpenDetail
}) => {
  return (
    <>
      {data?.length > 0 ? (
        data?.map((item, i) => <CardExchangeHistoryCom key={i} data={item} onOpenDetail={() => onOpenDetail(item)}/>)
      ) : (
        <p className='color_des'>利用履歴がありません !</p>
      )}
    </>
  );
};

export default ListExchangeHistoryWidget;
