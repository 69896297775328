import { ModalProps } from 'antd';
import React, { PropsWithChildren } from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import styled from 'styled-components';
import CustomIconButton from '../button/CustomIconButton';
import CustomModal from './CustomModal';

export interface OpenModalProps extends PropsWithChildren<ModalProps> {
  titleName?: string;
}

const OpenModal: React.FC<OpenModalProps> = (props) => {
  return (
    <CustomModal {...props} footer={null} closeIcon={<div></div>}>
      <Style.Wrapper>
        <img src={IMG_CONFIG.MODAL.MODAL_BOARD} />
        {/* title */}
        <Style.Title>
          <p className="color_white "> {props.titleName}</p>
        </Style.Title>
        {/* content */}
        <Style.Content>{props.children}</Style.Content>
        {/* button */}
        <Style.Button>
          <CustomIconButton
            icon={IMG_CONFIG.BUTTON.OK}
            onClick={props.onCancel}
          />
        </Style.Button>
      </Style.Wrapper>
    </CustomModal>
  );
};

export default OpenModal;

const Style = {
  Wrapper: styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
  `,

  Content: styled.div`
    top: 50px;
    position: absolute;
    z-index: 20;
    width: 100%;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: overlay;
    padding: 0 20px;
  `,

  Button: styled.div`
    bottom: 10px;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  `,

  Title: styled.div`
    top: 5px;
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: center;
  `,
};
