import { Image } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { queryNews } from 'src/api/hooks/queryNews';

import { Paths } from '@utils/constants';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import LoadingMoreInfinityItem from 'src/components/custom/item/loading-more-infinity.item';
import ViewCustomScroll from 'src/components/custom/views/ViewCustomScroll';
import { ViewContent } from 'src/components/custom/views/ViewsContent';
import LayoutDashboard from 'src/layouts/newDashboard';
import ListNewsWidget from '../widget/ListNews.Widget';

export interface ListNewsScreenProps {}

const ListNewsScreen: React.FC<ListNewsScreenProps> = () => {
  const { t } = useTranslation();

  const { data: page, fetchNextPage, hasNextPage } = queryNews.queryScroll();
  const history = useHistory();
  
  const handleChangeView = () => {
    if(history.location.state) {
      history.push(Paths.Stage);
    }
    else {
      history.push(Paths.Dashboard);
    }
  };
  return (
      <LayoutDashboard
        isButton
        title={t('content.news.name')}
        footerContent={t('content.news.footerList')}
        onGoBackView={handleChangeView}
        leftIcon={<Image src={IMG_CONFIG.DASHBOARD.NEWS_ICON_MINI} />}>
        <ViewCustomScroll paddingTop={5} paddingBottom={40}>
          <ViewContent.List className={'custom_scroll'} style={{ marginLeft:'5px'}}>
            {page?.pages.map((data, i) => {
              return <ListNewsWidget data={data?.data.news.data} key={i} />;
            })}

            {/* loading */}
            <LoadingMoreInfinityItem
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
            />
          </ViewContent.List>
        </ViewCustomScroll>
        {/* <ListNewsWidget data={data?.data.news.data} /> */}
      </LayoutDashboard>
  );
};

export default ListNewsScreen;
