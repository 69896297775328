import React from 'react';
import { FaqDataProps } from 'src/model/dashboard.model';
import ItemListFaqsCom from '../components/ItemListFaqs.Com';

export interface ListFaqsWidgetProps {
  data: FaqDataProps[];
}

const ListFaqsWidget: React.FC<ListFaqsWidgetProps> = ({ data }) => {
  return (
    <>
      {data.map((item, index) => {
        return <ItemListFaqsCom data={item} index={index + 1} key={item.id} />;
      })}
    </>
  );
};

export default ListFaqsWidget;
