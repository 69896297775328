import { Paths } from '@utils/constants';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import CustomIconButton from 'src/components/custom/button/CustomIconButton';
import { useCheckCameraPoint } from '../hook/useCheckCameraPoint';
import { useGame } from '../hook/useGame';
import { CameraLayout } from '../layout/camera-layout';

export interface ViewSelectCameraProps {
  id: number;
}

export const ViewSelectCamera: React.FC<ViewSelectCameraProps> = ({ id }) => {
  const { push } = useHistory();
  const { state } = useLocation<number>();
  const { pos } = useGame();
  const { cameraPosition } = useCheckCameraPoint();

  const posOrder = cameraPosition?.find((item) => item.id === id);

  function upload() {
    push(Paths.Stage_Paradise_Upload, state);
  }
  function viewList() {
    push(Paths.Stage_Paradise_list, state);
  }

  return (
    <CameraLayout
      isBox
      title={
        posOrder?.order === pos.position ? (
          'クリアテージ報酬'
        ) : (
          <p style={{ fontSize: '16px', color: 'white', textAlign: 'center' }}>
            ボーナスステージ！
            <br />
            このステージへ来たら、うちの子で合成写真を作る事ができるよ！
            <br />
            たくさん質問を回答してこのステージへ来てね。
          </p>
        )
      }>
      <ButtonWrapper>
        {posOrder?.order === pos.position && (
          <CustomIconButton
            icon={IMG_CONFIG.BUTTON.CAMERA_VIEW_UPLOAD}
            onClick={upload}
            imgWidth="168px"
            imgHeight="50px"
          />
        )}

        <CustomIconButton
          style={{ marginTop: 15 }}
          icon={IMG_CONFIG.BUTTON.CAMERA_VIEW_LIST}
          onClick={viewList}
          imgWidth="180px"
          imgHeight="55px"
        />
      </ButtonWrapper>
    </CameraLayout>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
