import { useState, useEffect } from 'react';
import { useMotionValue } from 'framer-motion';
import { queryQuestion } from 'src/api/hooks/queryQuestion';

export interface PositionGameProps {
  x: number;
  y: number;
}

export const usePositionGame = (data: PositionGameProps[], pos: number) => {
  const [position, setPosition] = useState<number>(pos ?? 0);

  const { data: map, setData } = queryQuestion.queryMap();

  const [px, setPx] = useState(map?.x || 0);
  const [py, setPy] = useState(map?.y || 0);

  const x = useMotionValue(map?.x || 0);
  const y = useMotionValue(map?.y || 0);

  const next = () => {
    setPosition(position + 1);
  };

  const pre = () => {
    if (position <= 0) {
      return;
    }
    setPosition(position - 1);
  };

  const onPos = (id: number) => {
    setPosition(id);
  };

  const nx = () => {
    x.set(x.get() + 2);
    setPx(x.get());
  };
  const nxl = () => {
    x.set(x.get() - 2);
    setPx(x.get());
  };
  const ny = () => {
    y.set(y.get() + 2);
    setPy(y.get());
  };

  const nyl = () => {
    y.set(y.get() - 2);
    setPy(y.get());
  };

  const reset = () => {
    x.set(0);
    y.set(0);
  };

  useEffect(() => {
    if (position >= data.length) {
      return;
    }
    setData({
      x: data[position].x,
      y: data[position].y,
    });
    x.set(data[position].x);
    y.set(data[position].y);
  }, [position, data]);

  return { position, next, pre, x, y, nx, ny, reset, px, py, onPos, nxl, nyl };
};
