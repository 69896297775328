import React from 'react';

import { RankPointDataProps } from 'src/model/ranking.model';
import styled from 'styled-components';
import CardPointRankingCom from '../components/CardPointRanking.Com';

export interface ListRankingWidgetProps {
  data?: RankPointDataProps[];
}

const ListRankingWidget: React.FC<ListRankingWidgetProps> = ({ data }) => {
  return (
    <Style.Wrapper className="hidden_scroll">
      {data?.map((item: RankPointDataProps, i: any) => {
        return <CardPointRankingCom key={i} data={item} />;
      })}
    </Style.Wrapper>
  );
};

export default ListRankingWidget;

const Style = {
  Wrapper: styled.div`
    display: grid;
    width: 100%;
    height : 90%;
    grid-template-columns: repeat(1, 1fr);

    grid-gap: 10px;
    flex-direction: column;
    overflow-y: scroll;
    /* ::-webkit-scrollbar {
      display: none;
    } */

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* margin-top: 25px;
    margin-bottom: 20px; */
    padding: 10px 20px;
    padding-bottom: 30px;
  `,
};
