import React from 'react';

import ConfirmModal from 'src/components/custom/modal/ConfirmModal';
import LoadingModal from 'src/components/custom/modal/LoadingModal';

import styled from 'styled-components';
import styles from './basic.module.css';

interface BasicTemplateProps extends React.ComponentPropsWithoutRef<'div'> {
  className?: string;
}

const BasicTemplate: React.FC<BasicTemplateProps> = (props) => {
  return (
    <Style.Wrapper>
      <ConfirmModal />

      <LoadingModal />

      <main className={styles.container}>{props.children}</main>
    </Style.Wrapper>
  );
};

export default BasicTemplate;

const Style = {
  Wrapper: styled.div`
    display: flex;
    justify-content: center;
    min-width: 375px;
    background-position: center;
    background-size: cover;
    max-width:100vw;
  `,
};
