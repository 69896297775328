import { Paths } from '@utils/constants';
import React, { useRef } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { queryProfile } from 'src/api/hooks/queryProfile';
import { queryQuestion } from 'src/api/hooks/queryQuestion';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import MainLayout from 'src/layouts/MainLayout';
import { CameraLayout } from '../layout/camera-layout';
import MainMapLayout from '../layout/MainMap.Layout';
import { ViewCollageImage } from '../widget/view-collage-image';
import { DataDimensionProps } from 'src/hooks/useInputUpload';
import { setDimensionsImage } from '@utils/helpers';
// import { ViewUploadCamera } from '../widget/view-upload-camera';

export interface CollageImageScreenProps {}
interface TypeState {
  state: number;
  image: string;
  dimensions: DataDimensionProps;
  dataImage : any;
}

export const CollageImageScreen: React.FC<CollageImageScreenProps> = () => {
  const { useQueryData: question } = queryQuestion.queryListPoint();
  const { useQueryData: getProfile } = queryProfile.queryProfile();
  const { data: profile } = getProfile;
  const { state } = useLocation<TypeState>();
  const ref = useRef<any>();

  const dimensionsForeground = () => {
    const ratio =
      state?.dataImage?.foreground.width / state?.dataImage?.foreground.height;
    return setDimensionsImage(
      80,
      state?.dataImage?.foreground.width,
      state?.dataImage?.foreground.height,
      ratio,
    );
  };

  return (
    <MainLayout
      ref={ref}
      minHeight={995}
      screen={IMG_CONFIG.BACKGROUND.START}
      hiddenSetting
      pt={20}>
      <MainMapLayout
        isReward
        onOpen={() => ref.current?.show()}
        star={question?.data?.data.user_star}
        rewards={question?.data?.data.rewards}
        spots={question?.data?.data.spots}
        images = {question.data?.data.images}
        coin={profile?.data.profile.coins}
        stageImg={question?.data?.data.current_stage.media}
        children_2={
          <>
            {!state && <Redirect to={Paths.Stage} />}

            {state && (
              <CameraLayout title="写真を編集する">
                <ViewCollageImage
                  stateProp={state}
                  backgroundImage={state?.dataImage?.background}
                  foregroundImage={state?.dataImage?.foreground.data}
                  foregroundDimensions={dimensionsForeground()}
                />
              </CameraLayout>
            )}

            {/* {!state && <Redirect to={Paths.Stage} />}
    {state && <ItemRewardEndQuestion data={state} />} */}
          </>
        }
      />
    </MainLayout>
  );
};
