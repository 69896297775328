import React, { PropsWithChildren } from 'react';
import ViewCustomScroll from 'src/components/custom/views/ViewCustomScroll';
import ViewLoading from 'src/components/custom/views/ViewLoading';
import styled from 'styled-components';

export interface ComcomLayoutProps extends PropsWithChildren<any> {
  title?: string;
  loading?: boolean;
}

const ComcomLayout: React.FC<ComcomLayoutProps> = ({
  children,
  title,
  loading,
}) => {
  return (
    <Style.Wrapper>
      <Style.Title>
        <h5 className="big_text color_main">{title}</h5>
      </Style.Title>
      <Style.Content>
        {loading ? (
          <ViewLoading />
        ) : (
          <ViewCustomScroll>{children}</ViewCustomScroll>
        )}
      </Style.Content>
    </Style.Wrapper>
  );
};

export default ComcomLayout;

const Style = {
  Wrapper: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  `,
  Title: styled.div`
    width: 100%;

    h5 {
      text-align: center;
      text-decoration: underline;
    }
  `,
  Content: styled.div`
    padding: 0 10px;
    width: 100%;
    height: 100%;
    overflow: hidden;
  `,
};
