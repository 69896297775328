import { CAFE_KEY } from "../queries/keys";
import { cafeSortApi } from "../axios/cafesort";
import { useState } from "react";
import { useQuery } from "react-query";
import {  CafeSortListQuery } from "src/model/cafesort.model";
import {
    DataListResponseProps,
    ResponseProps,
  } from 'src/model/res.model';
/**
 * @class QueryNews()
 * @method queryList()
 * @returns
 */
class QueryCafeSort {
    key : {
        LIST: string;
    };

    constructor() {
        this.key = CAFE_KEY;
    }

    queryList(queryData?: CafeSortListQuery) {
        const [query, setQuery] = useState<CafeSortListQuery>(
          queryData || {
            complete: 1,
          },
        );
        const useQueryDataCafeList = useQuery<
          ResponseProps<{ data : DataListResponseProps<any> }>,
          Error,
          any
        >([this.key.LIST, query], () => cafeSortApi.getList(query), {
          // enabled: !!query.type,
          refetchOnMount: false,
          refetchOnReconnect: true,
          refetchOnWindowFocus: true,
        });
        return { query, setQuery, useQueryDataCafeList };
      }
    
}

export const queryCafeSort = new QueryCafeSort();