import { createGlobalStyle } from 'styled-components';
// import { IMG_CONFIG } from 'src/assets/config/imgConfig';
export const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
    transition: all .2s linear;
  }
  *:focus {
  outline: 0;
  outline: none;
  }
  html {
    font-size: 62.5%;
    background-color: #eef0f1;
    box-sizing: border-box;
    @media ${(props) => props.theme.breakpoint.lg} {
      font-size: 60%;
    }
    @media ${(props) => props.theme.breakpoint.md} {
      font-size: 55%;
    }
    /* color */
    --color-main: ${(props) => props.theme.palette.text.main};
    --color-title: ${(props) => props.theme.palette.text.main};
    --color-des: ${(props) => props.theme.palette.text.des};
    --color-button: #fadeb1!important;
    --color-question: #6F6763;
    --color-process: #6F6763;
    --color-bg-question: #F8E8DE;
    --color-bg-process: #E5CABB;
;
    --bs-primary: #dc1928;
    /* backgrond */
    --color-bg-home: ${(props) => props.theme.palette.background.home};
  }
  body {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.6;
    text-decoration: none;
    background-color: black !important; /* transparent */
    /* text-transform: capitalize; */
    transition: all .2s linear;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    border: none;
    a:-webkit-any-link {
    /* color: black; */
    cursor: pointer;
    text-decoration: none;
}
h1,h2,h3,h4,h5,h6,button {
    font-family: ${(props) => props.theme.fonts.title};
    border: none;
    margin-bottom: 0 !important;
    word-break: break-all;
  }

  p {
    margin-bottom: 0 !important;
    word-break: break-all;
  }

  button {
    background-color: transparent;
    width: fit-content;
    cursor: pointer;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    :hover{
      transform: translateY(-3px);
    }
  }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    
}
input:-webkit-autofill{
    -webkit-text-fill-color: var(--color-main) !important;
}


  p {
    font-family: ${(props) => props.theme.fonts.main};
  }

  .error_wrapper {
      width: 100%;
    }

  .color_main {
    color: var(--color-main) !important;
  }

  .color_white {
    color: white !important;
  }

  .color_text {
    color: #583017 !important;
  }

  .color_des {
    color: var(--color-des) !important;
  }
  .color_error {
    color: #fe5f5f !important;
  }

  .color_gray {
    color: #6E6E6E;
  }

  .color_question {
    color: #6F6763;
  }

  .color_bg_question {
    background: #F8E8DE;
  }

  .color_pink {
    color: #FF4C72;
  }

  .small_text {
    font-size: 13px;
    font-weight: 400;
    line-height:14px;
  }

  .sm_text {
    font-size: 8px;
    font-weight: 400;
  }

  .medium_text {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }

  .cap_text {
    font-size: 16px;
    font-weight: 400;
  }


  .big_text {
    font-size: 18px;
    font-weight: 400;
  }

  .big_title {
    font-size: 22px;
  }

  .ranking_text_dropshadow {
    -moz-text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.1);
    -webkit-text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.1);
    -ms-text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.1);
    text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.1);
  }

  .max_title {
    font-size: 28px;
  }

  .ant-notification-notice-message{
    font-family: ${(props) => props.theme.fonts.main} !important;
    font-weight: 800 !important;
    color: var(--color-main) !important;
    font-size: 12px;
  }

  .ant-notification-notice-description{
    font-family: ${(props) => props.theme.fonts.main} !important;
    font-weight: 400 !important;
    color: var(--color-question) !important;
    font-size: 14px;
  }

  .ant-modal-wrap {
      overflow-x: hidden !important;
      overflow-y: auto !important;
    }

  h1 {
   font-weight: 500;
  }
  h2 {
    font-weight: 500;
    
  }
  h4 {
    font-weight: 500;
    }
    .container{
      padding: 0 20rem;
      @media ${(props) => props.theme.breakpoint.xxl} {
        padding: 0 15rem;
    }
      @media ${(props) => props.theme.breakpoint.lg} {
        padding: 0 10rem;
    }
    @media ${(props) => props.theme.breakpoint.md} {
        padding: 0 5rem;
    }
    @media ${(props) => props.theme.breakpoint.sm} {
        padding: 0 1rem;
    }
    }

    .hidden_scroll {
      ::-webkit-scrollbar {
      display: none;
      overflow-y: auto;
    }
    }

  }

  .custom-modal-wrapper {
    @media (min-width: 768px) {
      transform:scale(1.3);
    }
  }

`;
