import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryMiniGame } from 'src/api/hooks/useQueryMiniGame';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import LayoutDashboard from 'src/layouts/newDashboard';
import PlayMiniGameWidget from '../widget/PlayMiniGame.Widget';
import { Image } from 'antd';
import { useHistory } from 'react-router-dom';

export interface PlayMiniGameScreenProps {}

const PlayMiniGameScreen: React.FC<PlayMiniGameScreenProps> = () => {
  const { t } = useTranslation();
  const { queryListItem } = useQueryMiniGame();
  const { isLoading } = queryListItem();
  const history = useHistory();

  const handleChangeView = () => {
    history.goBack();
  };
  return (
    <>
      <LayoutDashboard
        isButton
        loading={isLoading}
        title={'ゲーム'}
        footerContent={t('content.game.footer')}
        onGoBackView={handleChangeView}
        leftIcon={<Image src={IMG_CONFIG.DASHBOARD.GAME_ICON_MINI} preview={false} />}>
        <PlayMiniGameWidget />
      </LayoutDashboard>
    </>
  );
};

export default PlayMiniGameScreen;
