export interface NewsDataProps {
  id?: number;
  title?: string;
  description?: string;
  is_click ?: number;
  type?: TypeNewsEnum;
  link?: string;
  image?: string;
  media?: string;
  view?: number;
  content?: string;
  open_time?: Date;
  close_time?: Date;
}

export enum TypeNewsEnum {
  WEB = 1,
  BANNER = 2,
  VIDEO = 3,
  EDITOR = 4,
  LINK = 5,
}
