import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { RequestListProps } from 'src/model';
import { useState } from 'react';
import {
  DataListResponseProps,
  ResponseProps,
  RestApi,
} from 'src/model/res.model';

import { AUTH_KEY, PRODUCT_KEY } from '../queries/keys';
import { productApi } from '../axios/product';
import { ExchangeHistoryDataProps, ProductDataProps } from 'src/model/product.model';

import { useReduxLoading } from 'src/redux/hooks/useReduxLoading';
import { useTranslation } from 'react-i18next';
import { reduxNotification } from 'src/redux/hooks/ReduxNotification';

/**
 * @class QueryProduct()
 * @method queryList()
 * @method queryDetail()
 * @returns
 */

class QueryProduct {
  key: { LIST: string, LIST_HISTORY : string };

  constructor() {
    this.key = PRODUCT_KEY;
  }

  queryList(queryData?: RequestListProps) {
    const [query, setQuery] = useState<RequestListProps>(
      queryData || {
        page: 1,
      },
    );
    const useQueryData = useQuery<
      ResponseProps<{
        products: DataListResponseProps<ProductDataProps>;
        my_coins: number;
      }>,
      Error
    >([this.key.LIST, query], () => productApi.getList(query), {
      // enabled: !!query.type,
      refetchOnMount: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
    });
    return { query, setQuery, useQueryData };
  }

  queryScroll() {
    return useInfiniteQuery<
      ResponseProps<{
        products: DataListResponseProps<ProductDataProps>;
        my_coins: number;
      }>,
      Error
    >(
      [this.key.LIST],
      async ({ pageParam = 0 }) => {
        const res = await productApi.getList({
          page: pageParam + 1,
        });
        return res;
      },
      {
        getPreviousPageParam: (firstPage) =>
          firstPage.data.products.meta.current_page,

        getNextPageParam: (lastPage) =>
          lastPage.data.products.meta.current_page ===
          lastPage.data.products.meta.last_page
            ? undefined
            : lastPage.data.products.meta.current_page,
      },
    );
  }

  queryExchange() {
    const queryClient = useQueryClient();

    const loading = useReduxLoading();

    const { useAction } = reduxNotification;
    const { t } = useTranslation();
    const { openModalError } = useAction();

    return useMutation<
      ResponseProps<RestApi>,
      ResponseProps<RestApi>,
      any,
      any
    >((body: number) => productApi.postExchange(body), {
      onMutate: () => {
        loading.openLoading();
      },
      onSuccess: async () => {
        await queryClient.refetchQueries(AUTH_KEY.PROFILE);
        queryClient.refetchQueries(PRODUCT_KEY.LIST);
        queryClient.refetchQueries(PRODUCT_KEY.LIST_HISTORY);
        loading.closeLoading();
      },
      onError: (error: any) => {
        loading.closeLoading();
        openModalError(t('notification.error'), error.message);
      },
    });
  }

  queryInfinityHistory() {
    return useInfiniteQuery<
      ResponseProps<{purchase : DataListResponseProps<ExchangeHistoryDataProps>}>,
      Error
    >(
      [this.key.LIST_HISTORY],
      async ({ pageParam = 0 }) => {
        const res = await productApi.getListExchangeHistory({
          page: pageParam + 1,
        });
        return res;
      },
      {
        getPreviousPageParam: (firstPage) =>
          firstPage.data.purchase.meta.current_page,

        getNextPageParam: (lastPage) =>
          lastPage.data.purchase.meta.current_page ===
          lastPage.data.purchase.meta.last_page
            ? undefined
            : lastPage.data.purchase.meta.current_page,
      },
    );
  }
}

export const queryProduct = new QueryProduct();
