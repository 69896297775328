import React from 'react';
import { FrameDataProps } from 'src/model/frame.model';
import styled from 'styled-components';

export interface FrameProps {
  linkPet?: any;
  frame?: FrameDataProps;
}

const Frame: React.FC<FrameProps> = ({ linkPet, frame }) => {
  return (
    <Style.Wrapper>
      <Style.Pet src={linkPet} />
      {/* frame */}
      {frame?.media && frame?.top && frame?.left && (
        <Style.Frame
          zoom={frame?.zoom}
          top={frame?.top}
          left={frame?.left}
          src={frame?.media}
        />
      )}
    </Style.Wrapper>
  );
};

export default Frame;

interface StyleProps {
  zoom?: number;
  top?: number;
  left?: number;
}

const Style = {
  Wrapper: styled.div`
    position: relative;
    z-index: 10;
    width: 60px;
    height: 60px;
  `,
  Pet: styled.img`
    position: relative;
    /* z-index: 10; */
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    /* img {
      width: 100%;
      height: 100%;
      min-width: 60px;
      min-height: 60px;
    } */
  `,
  Frame: styled.img`
    position: absolute;
    z-index: 0;
    top: ${({ top }: StyleProps) => `${top}%`};

    left: ${({ left }: StyleProps) => `${left}%`};
    transform-origin: top left;

    transform: ${({ zoom }: StyleProps) => `scale(${zoom})`};
    width: 100%;
    height: 100%;

    /* img {
      width: 100%;
      height: 100%;
      min-width: 60px;
      min-height: 60px;
    } */
  `,
};
