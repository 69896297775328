import { Paths } from '@utils/constants';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import { ItemMenuComProps } from '../components/ItemMenu.Com';

export const useWidget = () => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const navData: ItemMenuComProps[] = [
    {
      icon: IMG_CONFIG.DASHBOARD.MODAL_ACCOUNT,
      name: t('content.profile.account'),
      link: Paths.Profile,
    },

    {
      icon: IMG_CONFIG.DASHBOARD.NOTI,
      name: t('content.notification.name'),
      link: Paths.ListNotification,
    },
    {
      icon: IMG_CONFIG.DASHBOARD.SETTINGS_ICON_NEWS,
      name: t('content.news.name'),
      link: Paths.News,
    },
    {
      icon: IMG_CONFIG.DASHBOARD.SETTINGS_ICON_RANKING,
      name: t('content.rank.name'),
      link: Paths.Ranking,
    },
    {
      icon: IMG_CONFIG.DASHBOARD.SETTINGS_ICON_GAME,
      name: t('GAME'),
      link: Paths.Game,
    },
    {
      icon: IMG_CONFIG.DASHBOARD.SETTINGS_ICON_FOOD,
      name: t('content.food.name'),
      link: Paths.Food,
    },
    {
      icon: IMG_CONFIG.DASHBOARD.SETTINGS_ICON_CONTEST,
      name: t('content.event.name'),
      link: Paths.Event,
    },
    {
      icon: IMG_CONFIG.DASHBOARD.SETTINGS_ICON_CAFE,
      name: t('content.cafe.name'),
      link: Paths.Cafe,
    },
    {
      icon: IMG_CONFIG.DASHBOARD.SETTINGS_ICON_POINTS,
      name: t('content.shop.name'),
      link: Paths.PoExchange,
    },
    {
      icon: IMG_CONFIG.DASHBOARD.TERMS,
      name: '利用規約',
      link: Paths.Terms,
    },
    {
      icon: IMG_CONFIG.DASHBOARD.FAQ,
      name: 'FAQs',
      link: Paths.Faqs,
    },
    {
      icon: IMG_CONFIG.DASHBOARD.SETTING_ICON_HISTORY,
      name: 'ポイント交換履歴',
      link: Paths.ExchangeHistory,
    },
    {
      icon: IMG_CONFIG.DASHBOARD.SETTINGS_ICON_SOUND,
      name: 'BGM設定',
    },
  ];

  return { t, push, navData };
};
