export const colors = {
  background: {
    default: '#f8f9fa',
    home: '#fcf0f1',
  },

  text: {
    main: '#833e29',
    des: '#F39200',
  },
};
