import { RequestListProps } from 'src/model';
import {
  AnswersDataReqProps,
  QueryQuestionProps,
} from 'src/model/stageGame.model';
import { request } from './axios';
import { API_URL } from './config';

class QuestionAPi {
  url: {
    CURRENT: string;
    QUESTION: string;
    SAVE_REWARD: string;
    SPOT_PET: string;
    REMOVE_BG : string;
    BACKGROUND_SPOT : string;
  };

  constructor() {
    this.url = API_URL.QUESTION;
  }
  getCurrent() {
    return request({
      url: this.url.CURRENT,
      method: 'GET',
    });
  }
  getQuestion(params: QueryQuestionProps) {
    return request({
      url: this.url.QUESTION,
      method: 'GET',
      params,
    });
  }
  postAnswer(data: AnswersDataReqProps) {
    return request({
      url: this.url.SAVE_REWARD,
      method: 'POST',
      data,
    });
  }

  getSpotPet(id: number, params: RequestListProps) {
    return request({
      url: this.url.SPOT_PET + '/' + id + '/images',
      method: 'GET',
      params,
    });
  }

  postSpotImage(id: number, dataSpot: any) {
    const data = new FormData();
    data.append('image', dataSpot.image);

    return request({
      url: this.url.SPOT_PET + '/' + id + '/images',
      method: 'POST',
      data,
    });
  }
  removeBackgroundImage(dataSpot: any) {
    const data = new FormData();
    data.append('image', dataSpot.image);
    data.append('spot_id', dataSpot.spot_id);

    return request({
      url: this.url.REMOVE_BG ,
      method: 'POST',
      data,
    });
  }

  getBackgroundSpot(spot_id : number) {
    return request({
      url: this.url.BACKGROUND_SPOT + '/' + spot_id,
      method: 'GET',
    });
  }
}

export const questionAPi = new QuestionAPi();
