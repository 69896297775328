import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
// import { Paths } from '@utils/constants';
import ViewBoardAuth from 'src/components/core/View/ViewBoardAuth';
import { getStar } from '../widget/ListReward.Widget';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';

export interface CameraLayoutProps extends PropsWithChildren<any> {
  isBox?: boolean;
  title?: React.ReactNode;
  des?: string;
}

export const CameraLayout: React.FC<CameraLayoutProps> = ({
  children,
  isBox,
  title,
  des,
}) => {
  const history = useHistory();

  function goPreviousPage() {
    // history.push(Paths.Stage_Paradise);
    history.goBack();
  }

  return (
    <ViewBoardAuth onCancel={goPreviousPage} style={{ margin: '30px 0' }}>
      <Style.TotalWrapper>
        <Style.Wrapper>
          {/* star */}
          {getStar(3)}
          {typeof title === 'string' ? (
            <h4 className="color_white" style={{ fontSize: '20px' }}>
              {title}
            </h4>
          ) : (
            <>{title}</>
          )}
          {des && (
            <p
              className="color_white"
              style={{
                fontSize: '12px',
                color: '#FCF1EC',
                fontWeight: 400,
                fontFamily: 'DFMaruGothic-Md',
              }}>
              {des}
            </p>
          )}
          {/* image */}
          {isBox && <img src={IMG_CONFIG.MAP_GAME.REWARD_BOX} />}
          <Style.ContentWrapper isBox={isBox}>{children}</Style.ContentWrapper>
        </Style.Wrapper>
      </Style.TotalWrapper>
    </ViewBoardAuth>
  );
};

const Style = {
  TotalWrapper: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 40;
  `,

  Wrapper: styled.div`
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    justify-items: center;
    align-content: flex-start;
  `,
  ContentWrapper: styled.div<{ isBox?: boolean }>`
    .img_coin {
      width: 90px;
    }
    height: ${({ isBox }) => (isBox ? '200px' : '268px')};
    width: 100%;
    /* background: url(${IMG_CONFIG.MAP_GAME.REWARD_BG}); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  `,
};
