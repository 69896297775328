import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { queryProfile } from 'src/api/hooks/queryProfile';
import { useTranslation } from 'react-i18next';
import { useInputUpload } from '../useInputUpload';
import { useHistory } from 'react-router-dom';
import { Paths } from '@utils/constants';
import { useEffect, useState } from 'react';
import { SignUpDataProps } from 'src/model';
import { convertBlocToBase64 } from '@utils/support/converBloctoBase64';
import { authApi } from 'src/api/axios/auth';
import { convertBase64ToBlob } from '@utils/support/covert-base64-to-blob';

export const useFormSignUp = (data?: SignUpDataProps) => {
  // check email

  const { t } = useTranslation();
  const upload = useInputUpload();
  const { push } = useHistory();
  const [image, setImage] = useState<any>(null);

  const query = queryProfile.querySignUp();
  const currentDate = moment(new Date()).format('YYYY-MM-DD');
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('error.name.required'))
      .max(50, t('error.name.max')),
    avatar: Yup.mixed().required(t('error.image.required')),
    gender: Yup.number().required(t('error.gender.required')).nullable(),
    dob: Yup.string()
      .required(t('error.dob.required'))
      .nullable()
      .test('is-valid', (value: any, ctx: any) => {
        if (value <= currentDate) {
          return ctx.createError({ message: t('error.dob.minDate') });
        }
        return value;
      }),
    stage_id: Yup.string()
      .required(t('error.pointOfDeparture.required'))
      .nullable(),
    // line_id: Yup.string()
    //   .required(t('error.lineId.required'))
    //   .max(100, t('error.lineId.max'))
    //   .nullable(),
    type_id: Yup.number().required(t('error.typeId.required')).nullable(),
    // line_name: Yup.string()
    //   .required(t('error.lineName.required'))
    //   .max(255, t('error.lineName.max'))
    //   .nullable(),
    email: Yup.string()
      .email(t('error.email.email'))
      .test(
        'is-valid',
        '電子メールはすでにシステムに存在します。もう一度やり直してください',
        async (value: any) => {
          const result = await authApi.checkEmail(value);
          return result.data.email_available || false;
        },
      )
      .required(t('error.email.required'))
      .max(100, t('error.email.max'))
      .nullable(),
    password: Yup.string()
      .required(t('error.password.required'))
      .matches(
        /^(?=.*[0-9])(?=.*[a-z])/,
        'パスワードは6文字以上で、文字と数字が含まれている必要があります',
      )
      .min(6, t('error.password.min'))
      .max(12, t('error.password.max'))
      .nullable(),
    password_confirmation: Yup.string()
      .required(t('error.passwordConfirm.required'))
      .oneOf([Yup.ref('password'), null], t('error.passwordConfirm.match')),
  });

  const formik = useFormik({
    initialValues: {
      name: data?.name,
      avatar: data?.avatar,
      gender: data?.gender,
      dob: data?.dob,
      type_id: data?.type_id,
      stage_id: data?.stage_id,
      line_id: '',
      line_name: '',
      active_line_id: 0,
      active_line_name: 0,
      email: data?.email,
      password: data?.password,
      password_confirmation: data?.password_confirmation,
    },
    validationSchema,
    onSubmit: (values) => {
      push(Paths.RegisterConfirm, { values });
    },
    validateOnChange: false,
  });

  const formikChangeImage = (imageData: any) => {
    convertBase64ToBlob(imageData, formik.setFieldValue, 'avatar', setImage);
  };

  useEffect(() => {
    if (data?.avatar) {
      convertBlocToBase64(data.avatar, setImage);
    }
  }, []);

  return { formik, query, formikChangeImage, upload, image };
};
