import { Image } from 'antd';
import React from 'react';
import { IMG_CONFIG } from 'src/assets/config/imgConfig';
import { ProductDataProps } from 'src/model/product.model';
import styled from 'styled-components';

export interface CardProductPriceComProps {
  data?: ProductDataProps;
  index?: number;
  onExchange?: any;
}

const CardProductPriceCom: React.FC<CardProductPriceComProps> = ({
  data,

  onExchange,
}) => {
  return (
    <Style.Wrapper onClick={onExchange}>
      <Style.ImageWrapper>
        <h5 className="color_text">{data?.name}</h5>
        <Image
          src={data?.media || IMG_CONFIG.ERROR}
          width={'72%'}
          preview={false}
          style={{ borderRadius: '10px' }}
          fallback={IMG_CONFIG.ERROR}
        />
      </Style.ImageWrapper>
      <Style.Price>
        <h5 className="color_white" style={{ fontSize: '18px' }}>
          {data?.price}
        </h5>
      </Style.Price>
      <Style.PriceWrapper>
        <Image
          src={IMG_CONFIG.BUTTON.SHOP_BUTTON}
          preview={false}
          style={{ cursor: 'pointer' }}
          width={'127px'}
        />
      </Style.PriceWrapper>
    </Style.Wrapper>
  );
};

export default CardProductPriceCom;

interface StyleProps {
  isSelect?: boolean;
}

const Style = {
  Wrapper: styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    position: relative;
    min-width: 117px;
    max-width: 100%;
    padding-bottom: 40px;
    /* margin-right: ${(p: StyleProps) => (p.isSelect ? '40px' : null)};
    margin-left: ${(p: StyleProps) => (p.isSelect ? '40px' : null)}; */
    margin-bottom: 20px;
    :hover {
      transform: translateY(-3px);
    }
  `,

  Price: styled.div`
    position: absolute;
    bottom: 4px;
    z-index: 20;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    display: flex;
    justify-content: center;

    h5 {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      cursor: pointer;
    }
  `,
  PriceWrapper: styled.div`
    position: absolute;
    bottom: 10px;
    z-index: 9;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
    
  `,
  ImageWrapper: styled.div`
    display: flex;
    position: relative;
    z-index: 10;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h5 {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  `,
};
